import { getSessionNumberFromURL } from "../../../helpers/url-manager";
import {
  CHANGE_SCREEN_TYPE_REQUEST,
  GET_SESSION_NUMBER,
  SHOW_FORBIDDEN_MODAL,
  RESET_FORBIDDEN_MODAL,
  PUA_REMINDER_MODAL_SHOWING
} from "../../action-types";
import get from "lodash/get";
import { errorMapping } from "../../../shared/constants/errors";
export const changeScreenSize = payload => ({
  type: CHANGE_SCREEN_TYPE_REQUEST,
  payload
});

export const getSessionNumber = () => {
  return {
    type: GET_SESSION_NUMBER,
    payload: getSessionNumberFromURL()
  };
};

export const showForbiddenModal = forbiddenErrorMsg => ({
  type: SHOW_FORBIDDEN_MODAL,
  payload: forbiddenErrorMsg
});
export const resetForbiddenModal = () => ({
  type: RESET_FORBIDDEN_MODAL
});
export const showForbidden = error => async dispatch => {
  try {
    const code =
      get(error, "response.status", 0) || get(error, "response.data.code", 0);
    if (code === 403) {
      const errorKey = get(error, "response.data.message", 0);
      const forbiddenErrorMsg =
        errorMapping[errorKey] || errorMapping["default-msg"];
      dispatch(showForbiddenModal(forbiddenErrorMsg));
    }
  } catch {}
};

export const setPuaReminderShowing = isShowing => dispatch => {
  dispatch({ type: PUA_REMINDER_MODAL_SHOWING, payload: isShowing });
};
