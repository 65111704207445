import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.space.md};

  h3 {
    font-style: italic;
  }
`;

export const StyledType = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.lg};
  }
`;
