import styled from "styled-components";
import {
  Flex,
  ContentFormatter
} from "discover.medical.react-component-library";

export const StatementContainer = styled(Flex)`
  padding: ${({ theme }) => theme.space.sm} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};
`;

export const StatementValue = styled(ContentFormatter)`
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding-left: 0.5em;
  text-align: right;
  white-space: pre-wrap;
  hyphens: auto;
`;
