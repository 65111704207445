import styled from "styled-components";
import { Divider } from "discover.medical.react-component-library";

export const DescriptionBar = styled.div`
  margin: ${({ theme }) => theme.space.sm} 0;
  max-width: 80%;
`;

export const StyledDivider = styled(Divider)`
  margin-top: ${({ theme }) => theme.space.sm};
`;
