import React from "react";
import PropTypes from "prop-types";
import { Root } from "./index.styled";

export default function CalculatedValue({ value, dataAuto }) {
  return (
    <Root data-auto={dataAuto} $isValue={!!value}>
      {value ? `${value}` : "—"}
    </Root>
  );
}

CalculatedValue.propTypes = {
  dataAuto: PropTypes.string,
  value: PropTypes.string
};

CalculatedValue.defaultProps = {
  dataAuto: "",
  value: ""
};
