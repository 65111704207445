import * as amplitude from "../../middleware/telemetry/analytics";

const UNKNOWN_VALUE = "unknown";

export const instantiateAmplitude = ({ amplitudeInfo, userData, optOut }) => {
  const { amplitudeApiKey, analyticsApi } = amplitudeInfo;

  if (!amplitudeApiKey) {
    return;
  }

  // Instantiate Amplitude
  const opts = {
    city: false,
    country: false,
    dma: false,
    ip_address: false,
    region: false,
    includeReferrer: true,
    unsetParamsReferrerOnNewSession: true,
    optOut,
    apiEndpoint: analyticsApi
  };

  amplitude.initAnalytics(amplitudeApiKey, opts);
  if (userData) {
    updateAmplitudeUser(userData);
  }
};

export const updateAmplitudeUser = ({
  puaId,
  user,
  interfaceId = UNKNOWN_VALUE
}) => {
  amplitude.setUser(puaId || null);
  amplitude.setUserInfo({
    interface_id: interfaceId,
    customer_id: user.affiliationId || UNKNOWN_VALUE,
    customer_ID: user.affiliationId || UNKNOWN_VALUE,
    auth_method_primary: user.authMethodPrimary || UNKNOWN_VALUE,
    auth_method_secondary: user.authMethodSecondary,
    ehr_vendor: user.ehrVendor,
    screen_w: `${window.screen.width}`,
    screen_h: `${window.screen.height}`,
    viewport_w: `${window.innerWidth}`,
    viewport_h: `${window.innerHeight}`
  });
};

/**
 * Handles appending amplitude device ID query param to a given url
 *
 * @param {string} url
 */
export const appendAmplitudeDeviceId = url => {
  const deviceId = amplitude.getUserDevice();
  if (typeof window !== "undefined" && url && deviceId) {
    const separator = url.indexOf("?") === -1 ? "?" : "&";
    return `${url}${separator}amp_device_id=${deviceId}`;
  } else {
    return url;
  }
};
