import React from "react";
import componentStateMapper from "../componentsStateMapper";
import BasicText from "../basic-text";
import { StyledBodyTitle } from "./index.styled";

export class BodyTitle extends BasicText {
  getYear = () => {
    return this._getText("year");
  };

  renderCompositeComponent = () => {
    return (
      <StyledBodyTitle element="h3">
        {this.getBaseContent()}&nbsp;{this.getYear()}
      </StyledBodyTitle>
    );
  };
}

export default componentStateMapper(BodyTitle);
