import React from "react";
import BasicNumeric from "../basic-numeric";
import componentStateMapper from "../componentsStateMapper";
import { getFeetAndInches, calcInches } from "../../../helpers/numeric-height";
import { Wrapper, FieldHolder, HeightInput } from "./index.styled";
import ExtraLabel from "../shared/extra-label";
import { isNumber } from "../../../shared/util/numberUtils";

export class NumericHeight extends BasicNumeric {
  state = {
    feet: "",
    inches: ""
  };

  _didMountExtension = () => {
    const value = parseFloat(this.props.value);
    if (isNumber(value)) {
      this.setState(getFeetAndInches(value));
    }
  };

  handleSetValueE2ECommand = (_, value) => {
    value = parseFloat(value);
    if (isNumber(value)) {
      this.setState(getFeetAndInches(value));
      this.changeValue(value);
    }
  };

  calculateHeight = event => {
    const { value, id } = event.target;
    const { feet, inches } = this.state;
    let sum;
    if (id === "inches" && (Number(value) >= 12 || Number(value) < 0)) {
      this.setState(prevState => ({ [id]: prevState.inches }));
      return;
    }
    if (id === "feet") {
      sum = calcInches(value, inches);
    } else {
      sum = calcInches(feet, value);
    }
    this.setState({
      [id]: value
    });
    this.handleChange({ target: { value: sum }, isTrusted: event.isTrusted });
  };

  renderComponent = () => {
    const { feet, inches } = this.state;
    const { name } = this.props;
    return (
      <Wrapper
        data-auto="numeric-height-component"
        data-testid={`${name}-component`}
      >
        <FieldHolder cell align={{ item: "center" }}>
          <HeightInput
            id="feet"
            data-testid="feet-numeric-height"
            name={`${name}-feet-input`}
            value={feet}
            onChange={this.calculateHeight}
            hasError={this.hasErrors()}
            hasWarning={this.hasWarnings()}
            data-auto="numeric-height-component-foot-input"
            min={3}
            max={8}
          />
          <ExtraLabel htmlFor="feet">ft</ExtraLabel>
        </FieldHolder>

        <FieldHolder cell align={{ item: "center" }}>
          <HeightInput
            id="inches"
            data-testid="inches-numeric-height"
            name={`${name}-inches-input`}
            value={inches}
            onChange={this.calculateHeight}
            hasError={this.hasErrors()}
            hasWarning={this.hasWarnings()}
            data-auto="numeric-height-component-inch-input"
          />
          <ExtraLabel htmlFor="inches">in</ExtraLabel>
        </FieldHolder>
      </Wrapper>
    );
  };
}

export default componentStateMapper(NumericHeight);
