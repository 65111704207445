import React from "react";
import BasicUnit from "../basic-unit";
import componentStateMapper from "../componentsStateMapper";
import Statement from "../basic-statement";

export class PatientDataSummaryStatement extends BasicUnit {
  renderCompositeComponent = () => {
    return (
      <Statement
        content={this.getBaseContent()}
        value={this.getFieldValue()}
        units={this.getUnits()}
      />
    );
  };
}

export default componentStateMapper(PatientDataSummaryStatement);
