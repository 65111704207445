import React from "react";
import { LabDateInfoText } from "./index.styled";

function LabDateInfo(props) {
  return (
    <LabDateInfoText
      fontSize="xs"
      fontFamily="base"
      fontWeight="normal"
      data-test="lab-date-info-text"
    >
      {props.message}
    </LabDateInfoText>
  );
}
export default LabDateInfo;
