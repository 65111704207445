import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { shouldClearSnapshotId } from "./events";
import {
  RESET_TOOL_DATA,
  SNAPSHOT_RESET_DATA,
  SNAPSHOT_CONTEXT_CHANGE,
  SNAPSHOT_DATA_REQUEST,
  SNAPSHOT_DATA_REQUEST_SUCCESS,
  SNAPSHOT_DATA_REQUEST_ERROR,
  SNAPSHOT_CREATE_REQUEST,
  SNAPSHOT_CREATE_REQUEST_ERROR,
  SNAPSHOT_CREATE_REQUEST_SUCCESS,
  SNAPSHOT_EVENT_REQUEST,
  SNAPSHOT_EVENT_REQUEST_ERROR,
  SNAPSHOT_EVENT_REQUEST_SUCCESS,
  SNAPSHOT_EVENT_CREATE_OPEN_LINK,
  SNAPSHOT_EVENT_CREATE_OPEN_LINK_SUCCESS,
  SNAPSHOT_EVENT_CREATE_OPEN_LINK_ERROR,
  SNAPSHOT_OPEN_LINK,
  SNAPSHOT_QR_CODE
} from "../action-types";
import { v4 as uuid } from "uuid";

export const initialState = {
  events: {
    // This string value is the unique identifier for the collection of events
    // that are triggered for an individual tool the user is interacting with.
    // It is randomly generated by the user interface and is passed along to
    // all event triggers with their payload data. The identifier is set
    // whenever the action SNAPSHOT_CONTEXT_CHANGE is dispatched.
    aggregateId: null,

    // This boolean value is a simple flag used to determine if a new snapshot
    // can be created. The value will be `false` if there are any pending
    // network requests that need to finish before a valid snapshot can be
    // created, or if any event network requests have failed and not been
    // retried.
    canSnapshot: true,

    // This object is used to track recoverable event trigger failures. The
    // key is the name of the event and the value is the number of times the
    // network request failed. If a single successful request occurs, the
    // key for that specific event is deleted.
    failed: {},

    // This integer value is a count of the number of pending network requests
    // there are for triggered events. The value is incremented for each
    // request made, and is decremented for each request completed, failed, or
    // timed out.
    // todo When we begin implementing the event triggers in the UI, we need to figure out how
    //      to gracefully handle when a network request fails. Do we roll back the changes that
    //      triggered the request? Do we simply lock up any further attempts at creating
    //      snapshots? How do we track what errors have been gracefully handled? What events
    //      are worth graceful error handling? What happens when the handling cannot be
    //      graceful?
    errorsNotHandled: 0,

    // This integer value is a count of the number of pending network requests there are for
    // triggered events. The value is incremented for each request made, and is decremented
    // for each request completed, failed, or timed out.
    pending: 0
  },

  // This string value is the unique identifier for the last generated
  // snapshot. If the value is `null`, it can mean one of two things: 1) a
  // snapshot has not been created yet, or 2) a new snapshot is required
  // because new events have occurred since the last snapshot was created.
  snapshotId: "",

  snapshotData: {
    // This variable indicates whether snapshot data is loaded and stored in
    // redux successfully. So this variable has true value only if a tool is
    // opened with a snapshotId parameter in the url and the tool is rendered
    // based on related snapshot data.
    isSnapshotAvailable: false,
    createdAt: null,
    expiresAt: null
  },

  loading: false,
  isCreationError: false,

  task: null,
  isOpenLinkEventError: false
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch(type) {
    case RESET_TOOL_DATA:
      return initialState;

    case SNAPSHOT_RESET_DATA:
      return {
        ...state,
        snapshotId: "",
        loading: false,
        isCreationError: false
      };

    case SNAPSHOT_CONTEXT_CHANGE:
      return {
        ...state,
        events: {
          ...state.events,
          aggregateId: uuid()
        }
      };

    case SNAPSHOT_DATA_REQUEST:
    case SNAPSHOT_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case SNAPSHOT_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        snapshotData: {
          isSnapshotAvailable: true,
          ...payload
        }
      };

    case SNAPSHOT_DATA_REQUEST_ERROR:
      return {
        ...state,
        loading: false
      };

    case SNAPSHOT_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        snapshotId: payload,
        loading: false
      };

    case SNAPSHOT_CREATE_REQUEST_ERROR:
      return {
        ...state,
        isCreationError: true,
        loading: false
      };

    case SNAPSHOT_EVENT_REQUEST:
      const updatedState = {
        ...state,
        events: {
          ...state.events,
          canSnapshot: false,
          pending: state.events.pending + 1
        }
      };

      // Any event that mutates snapshot should remove snapshotId.
      // TODO: Set snapshotId to null, not empty string. Not doing it now
      //       because it was changed to empty string at some point and we
      //       need to learn why.
      // TODO: Remove any use of snapshotData.
      if (shouldClearSnapshotId(payload.event)) {
        updatedState.snapshotData = {
          isSnapshotAvailable: false,
          createdAt: null,
          expiresAt: null
        };
        updatedState.snapshotId = '';
      }

      return updatedState;

    case SNAPSHOT_EVENT_REQUEST_ERROR:
      return {
        ...state,
        events: {
          ...state.events,
          canSnapshot: false,
          failed: {
            ...state.events.failed,
            [payload.event]: get(state.events.failed, payload.event, 0) + 1
          },
          pending: state.events.pending - 1
        }
      };

    case SNAPSHOT_EVENT_REQUEST_SUCCESS:
      const failed = { ...state.events.failed };

      delete failed[payload.event];

      const pending = state.events.pending - 1;

      return {
        ...state,
        events: {
          ...state.events,
          canSnapshot: isEmpty(failed) && pending === 0,
          failed,
          pending
        }
      };
      
    case SNAPSHOT_OPEN_LINK:
      return {
        ...state,
        task: SNAPSHOT_OPEN_LINK
      };

    case SNAPSHOT_QR_CODE:
      return {
        ...state,
        task: SNAPSHOT_QR_CODE
      };
        
    case SNAPSHOT_EVENT_CREATE_OPEN_LINK:
      return {
        ...state,
      };

    case SNAPSHOT_EVENT_CREATE_OPEN_LINK_SUCCESS:
      return {
        ...state,
        task: undefined
      };

    case SNAPSHOT_EVENT_CREATE_OPEN_LINK_ERROR:
      return {
        ...state,
        isOpenLinkEventError: true,
        task: undefined
      };

    default:
      return state;
  }
};
