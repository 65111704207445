import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCategories } from "../../../../../../store/tool-browse/actions";
import ToolList from "../../../../../tools-browse-shared-components/tools-list";

const isMHD = true;

export const ToolGroup = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories("sharedTools", isMHD));
  }, [dispatch]);

  return (
    <section data-auto="tools-list">
      <ToolList isMHD={isMHD} />
    </section>
  );
};

export default ToolGroup;
