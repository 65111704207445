import { log } from "../../analytics";
import { events } from "../index";
import Epic from "../../../../../client/epic";

export default (appState, payload, type) => {
  let epicContext = false;
  const epic = new Epic();
  if (epic.checkIsEpic()) {
    epicContext = true;
  }

  const { tool = {} } = appState;
  const { metadata = {} } = tool;
  const { aggregateId } = appState.snapshot.events;

  const logObject = {
    type: events.PATIENT_DATA_ENTRY_VIEWED,
    data: {
      tool_type: metadata.toolType,
      tool_slug: metadata.toolSlug,
      aggregate_id: aggregateId,
      auto_populated: epicContext
    }
  };

  log(logObject.type, logObject.data);
};
