import axiosInstance from "../../../services/axios-instance";
import {
  AUTOCOMPLETE_SUCCESS,
  AUTOCOMPLETE_REQUEST,
  AUTOCOMPLETE_ERROR
} from "../../action-types";

export const autocompleteSuccess = content => ({
  type: AUTOCOMPLETE_SUCCESS,
  payload: content
});

export const autocompleteRequest = term => ({
  type: AUTOCOMPLETE_REQUEST,
  payload: term
});

export const autocompleteError = err => ({
  type: AUTOCOMPLETE_ERROR,
  payload: err
});

export const getSuggestions = (term, max = 5) => async (dispatch, getState) => {
  const { paths } = getState();
  let url = `${paths["api-decisions-experience"]}/api/decisions/v1/completion?query=${term}&max=${max}`;

  dispatch(autocompleteRequest(term));

  try {
    const { data } = await axiosInstance.get(url);
    dispatch(
      autocompleteSuccess({
        term,
        data: data
      })
    );
  } catch (error) {
    autocompleteError(error.toString());
  }
};
