import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const EligibilityText = styled(Type)`
  p {
    margin-bottom: 0.5rem;
    font-style: italic;

    &:last-of-type {
      display: inline;
      margin-bottom: 0;
    }
  }
`;

export const InfoButtonWrapper = styled.div`
  position: relative;
  bottom: 1px;
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  margin-left: ${({ theme }) => theme.space.xs};
`;
