import React from "react";
import BasicComponent from "../basic/BasicComponent";
import componentStateMapper from "../componentsStateMapper";
import DynamicForm from "../DynamicForm";
import tabComponentsRetriever from "../../templates/components/tabComponentsRetriever";

export class Group extends BasicComponent {
  renderCompositeComponent = () => {
    const { view } = this.props;
    const componentsList = tabComponentsRetriever(view, "children", "");

    return (
      <div>
        {view.children && <DynamicForm componentsList={componentsList} />}
      </div>
    );
  };
}

export default componentStateMapper(Group);
