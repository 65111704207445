import React, { useState } from "react";
import PropType from "prop-types";
import { Link } from "react-router-dom";
import {
  Flex,
  HamburgerButton,
  User,
  Navigation,
  NavigationItem,
  Icon,
  Tray
} from "discover.medical.react-component-library";
import InterfaceSwitcher from "./tabs";
import "./_index.css";
import {
  StyledFlex,
  StyledHeader,
  StyledAppBar,
  StyledCustomUserArea,
  StyledHamburgerWrapper,
  StyledNavigationWrapper,
  StyledNavigationFlex,
  StyledMaskingContainer,
  StyledSearch,
  StyledSearchButton,
  SearchTrayWrapper,
  StyledUserLink
} from "./styled";
import HamburgerMenu from "./hamburger";
import UserDetailTrigger from "./UserDetailTrigger";
import { connect, useSelector } from "react-redux";
import isNil from "lodash/isNil";
import { newLinks } from "../../shared/constants/navigation";
import useIndicatorIndex from "../../shared/hooks/useIndicatorIndex";
import { getLinkProps } from "../../shared/util/navigationHelper";
import { isMHDDomain } from "../../helpers/domain-manager";
import Logo from "../logo";
import PuaWarning from "../pua-warning";

// product tabs should be shown in the header when coming into DSD from DM/X
export const AppHeader = ({
  shouldRedirect = true,
  referrer,
  sessionNumber,
  isSnapshotIdInURL,
  isNavigationExpanded,
  user = {}
}) => {
  const links = newLinks;
  const [isHamburgerOpen, setHamburgerOpen] = useState(false);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [mobileSearchActive, setMobileSearchActive] = useState(false);
  const { location } = useSelector(state => state.app);
  const isMHD = isMHDDomain(location);
  const hasNavigation = !isMHD && !isSnapshotIdInURL;
  const showInterfaceSwitcher = referrer;
  const indicatorIndex = useIndicatorIndex(links);

  const toggleHamburgerMenu = () => {
    setHamburgerOpen(!isHamburgerOpen);
  };

  const toggleMobileSearch = () => {
    setMobileSearchActive(!mobileSearchActive);
    setHamburgerOpen(false);
  };

  return (
    <StyledHeader data-auto="header">
      {showInterfaceSwitcher && <InterfaceSwitcher referrer={referrer} />}
      <StyledAppBar isPageScrolled={hasNavigation} visible>
        <StyledFlex
          container
          noGap
          align={{ content: "center", item: "center" }}
          justify={{ content: "space-between" }}
        >
          <Flex align={{ item: "center" }} cell>
            {hasNavigation && (
              <StyledMaskingContainer
                $isNavigationExpanded={isNavigationExpanded}
              >
                <StyledHamburgerWrapper data-testid="hamburger-menu">
                  <HamburgerButton
                    isOpen={isHamburgerOpen}
                    onClick={toggleHamburgerMenu}
                  />
                  <HamburgerMenu
                    sessionNumber={sessionNumber}
                    isOpen={isHamburgerOpen}
                    setOpen={setHamburgerOpen}
                  />
                </StyledHamburgerWrapper>
              </StyledMaskingContainer>
            )}
            <div className="Header_logoContainer">
              {shouldRedirect ? (
                <Link
                  data-auto="header-logo-link"
                  to={`/${
                    !isNil(sessionNumber)
                      ? `?session-number=${sessionNumber}`
                      : ""
                  }`}
                >
                  <Logo />
                </Link>
              ) : (
                <Logo />
              )}
            </div>
          </Flex>
          <Flex align={{ item: "center" }} cell>
            {user.hasFetched && !isSnapshotIdInURL && (
              <span>
                <User
                  isOpen={isUserProfileOpen}
                  triggerProps={{
                    onClick: () => setIsUserProfileOpen(!isUserProfileOpen)
                  }}
                  triggerAnchor={{
                    vertical: "bottom",
                    horizontal: "left"
                  }}
                  menuAnchor={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  organization={user.organization}
                  userName={user.userName}
                  linkURLs={user.linkURLs}
                  trigger={
                    <UserDetailTrigger
                      isActive={isUserProfileOpen}
                      icon={{ glyph: "person_outline", size: "xl" }}
                      data-testid="user-detail-trigger"
                    />
                  }
                  userState={user.userState}
                  messaging={user.messaging}
                  customChildren={
                    user.customLinks?.length > 0 && (
                      <StyledCustomUserArea container={true} column={true}>
                        {user.customLinks.map(linkData => {
                          const linkProps = linkData.isExternal
                            ? {
                                target: "_blank",
                                rel: "noopener noreferrer"
                              }
                            : {};
                          return (
                            <>
                              <StyledUserLink
                                {...linkProps}
                                href={linkData.path}
                                linkComponent={linkData.isExternal ? "a" : Link}
                              >
                                {linkData.text}
                              </StyledUserLink>
                            </>
                          );
                        })}
                      </StyledCustomUserArea>
                    )
                  }
                />
              </span>
            )}
            {hasNavigation && (
              <>
                <StyledSearch data-testid="appbar-inline-search" />
                <StyledSearchButton
                  data-testid="appbar-search-trigger"
                  isActiveForStyle={mobileSearchActive}
                  variant="secondary"
                  label="search"
                  kind={mobileSearchActive ? "outlined" : "text"}
                  onClick={toggleMobileSearch}
                  active={mobileSearchActive}
                >
                  <Icon icon={{ glyph: "search" }} />
                </StyledSearchButton>
              </>
            )}
          </Flex>
        </StyledFlex>
      </StyledAppBar>
      {isNavigationExpanded && hasNavigation && !!links.length && (
        <StyledNavigationWrapper data-testid="tabs-menu">
          <StyledNavigationFlex>
            <Navigation
              indicatorIndex={indicatorIndex}
              data-testid="header-navigation"
            >
              {links.map(link => {
                const { href, ...linkProps } = getLinkProps(
                  link,
                  sessionNumber
                );
                return (
                  <NavigationItem
                    key={link.id}
                    linkProps={linkProps}
                    linkText={link.label}
                    href={href}
                  />
                );
              })}
            </Navigation>
          </StyledNavigationFlex>
        </StyledNavigationWrapper>
      )}
      {!isMHD && (
        <Tray toggle={mobileSearchActive}>
          <SearchTrayWrapper data-testid="appbar-mobile-search" />
        </Tray>
      )}
      <PuaWarning />
    </StyledHeader>
  );
};

AppHeader.propTypes = {
  isNavigationExpanded: PropType.bool
};

AppHeader.defaultProps = {
  isNavigationExpanded: false
};

export const mapStateToProps = ({ sharedData: { sessionNumber }, user }) => ({
  sessionNumber,
  user
});

export default connect(mapStateToProps)(AppHeader);
