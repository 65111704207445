import React, { useEffect, useState } from "react";

export default function useSnapshot({
  createSnapshotAction,
  aggregateId,
  snapshotProcessingInfo: {
    isEventsLoading,
    isSnapshotLoading,
    snapshotId,
    isError
  }
}) {
  const [isSpinnerShown, setIsSpinnerShown] = useState(false);

  const createSnapshot = React.useCallback(() => {
    createSnapshotAction(aggregateId);
  }, [createSnapshotAction, aggregateId]);

  useEffect(() => {
    if (!isEventsLoading && isSpinnerShown) {
      createSnapshot();
    }
  }, [createSnapshot, isEventsLoading, isSpinnerShown]);

  useEffect(() => {
    if (!isSnapshotLoading && snapshotId) {
      // for smooth PDF modal closing animation
      setTimeout(() => {
        setIsSpinnerShown(false);
      }, 1000);
    }
  }, [isSnapshotLoading, snapshotId]);

  useEffect(() => {
    if (!isSnapshotLoading && isError) {
      setIsSpinnerShown(false);
    }
  }, [isSnapshotLoading, isError]);

  return { isSpinnerShown, setIsSpinnerShown };
}
