import React from "react";
import { default as iconSVGPaths } from "../icons"; //NOSONAR
import { StyledSVG } from "./index.styled";
import PropTypes from "prop-types";

function LegendIcon({ glyph }) {
  if (!iconSVGPaths[glyph]) return null;
  const { icon, viewBox = "0 0 24 24" } = iconSVGPaths[glyph];
  return <StyledSVG viewBox={viewBox}>{icon}</StyledSVG>;
}

LegendIcon.propTypes = {
  glyph: PropTypes.string.isRequired
};

export default LegendIcon;
