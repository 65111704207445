export default function populateMissingEhr(ehr, missingEhr = []) {
  const ehrCopy = { ...ehr };

  missingEhr.forEach(ehrValue => {
    if (ehrCopy[ehrValue] === undefined) {
      ehrCopy[ehrValue] = "";
    }
  });

  return ehrCopy;
}
