import { CombinedInput as RCLCombinedInput } from "discover.medical.react-component-library";
import React from "react";
import BasicNumeric from "../basic-numeric";
import componentStateMapper from "../componentsStateMapper";
import MeasureInfo from "../shared/measure-info";
import { Input } from "./index.styled";

export class NumericMeasure extends BasicNumeric {
  state = {
    value: ""
  };

  _didMountExtension = () => {
    const initialStateValue =
      this.props.value !== "" ? Number(this.props.value) : "";
    this.setState({ value: initialStateValue });
  };

  handleInputChange = event => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    this.setState({ value });
    this.hideLabDateInfoOnUserAction(event.isTrusted); //passing a value to determine if action was performed by user
  };

  handleSetValueE2ECommand = (_, value) => {
    this.handleInputChange({ target: { value } });
  };

  renderComponent = () => {
    const { name } = this.props;
    const { value } = this.state;
    const isMeasureSingle =
      this.props.view?.attributes?.measureInfo?.labels?.length === 1;
    return (
      <RCLCombinedInput>
        <Input
          autoComplete="off"
          id={name}
          data-auto={`${name}-numeric-measure`}
          value={value}
          onChange={this.handleInputChange}
          hasError={this.hasErrors()}
          hasWarning={this.hasWarnings()}
          isMeasureSingle={isMeasureSingle}
        />
        <MeasureInfo
          {...this.props}
          initialValue={value}
          handleChangeMeasureValue={this.handleChangeMeasureValue}
          handleChange={this.handleChange}
        />
      </RCLCombinedInput>
    );
  };
}

export default componentStateMapper(NumericMeasure);
