/* istanbul ignore file */
import React from "react";
import { Route } from "react-router-dom";

const RouteStatus = ({ statusCode, children, ...routeProps }) => (
  <Route
    {...routeProps}
    render={({ staticContext }) => {
      if (staticContext) {
        staticContext.statusCode = statusCode;
      }
      return children;
    }}
  />
);

export default RouteStatus;
