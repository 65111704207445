import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { getComponent, isComponentExist } from "./getComponent";
import { useFeature } from "../../helpers/amplitude";
import { redirectTo } from "../../helpers/url-manager";
import { useSelector } from "react-redux";
import { TOOL_TYPE_RELEASED } from "../../shared/constants/toolTypes";

export const DynamicForm = ({ componentsList, className, recalculate }) => {
  const isNotExist = componentsList.some(
    ({ style }) => !isComponentExist(style)
  );
  const isToolReleased = useSelector(
    ({ tool }) => tool?.metadata?.status === TOOL_TYPE_RELEASED
  );
  const history = useHistory();
  const [isNumericHeightWeightEnabled] = useFeature(
    "f55612_metric_height_weight"
  );

  useEffect(() => {
    if (isNotExist && isToolReleased) {
      redirectTo("unavailable", history);
    }
  }, [history, isNotExist, isToolReleased]);

  return (
    <div
      className={cn("dynamic-form-wrapper", {
        [className]: className
      })}
    >
      {componentsList.map(comp => {
        let styleToGet = comp.style;
        if (
          comp.style === "numeric.heightweight" &&
          !isNumericHeightWeightEnabled
        ) {
          styleToGet = "group";
        }
        const ContentComponent = getComponent(styleToGet); // NOSONAR (Seems sonar does not understand jsx syntax)
        return (
          <ContentComponent
            name={comp.name}
            cpdn={comp.cpdn}
            type={comp.type}
            key={`${comp.type || comp.style}.${comp.name}`}
            recalculate={recalculate}
          />
        );
      })}
    </div>
  );
};

DynamicForm.propTypes = {
  componentsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      cpdn: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      style: PropTypes.string.isRequired
    })
  ).isRequired,
  recalculate: PropTypes.bool,
  history: PropTypes.object.isRequired,
  className: PropTypes.string
};

DynamicForm.defaultProps = {
  recalculate: false,
  className: null
};

export default DynamicForm;
