import React from "react";
import PropTypes from "prop-types";
import { Icon } from "discover.medical.react-component-library";
import { StyledUserDetailTrigger } from "./styled.js";

const UserDetailTrigger = ({ isActive = false }) => {
  return (
    <StyledUserDetailTrigger
      variant="primary"
      kind="outlined"
      size="md"
      label="toggle user menu"
      isActive={isActive}
      data-testid="user-detail-trigger"
      nobackground
    >
      <Icon icon={{ glyph: "person_outline", size: "xl" }} />
    </StyledUserDetailTrigger>
  );
};

UserDetailTrigger.propTypes = {
  isActive: PropTypes.bool
};

UserDetailTrigger.displayName = "UserDetailTrigger";

export default UserDetailTrigger;
