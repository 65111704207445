import { log } from "../../analytics";
import { events } from "../index";

export default (appState, payload, type) => {
  const { tool = {} } = appState;

  const { aggregateId } = appState.snapshot.events;

  const { metadata = {} } = tool;

  const viewType = appState.optionGrid.viewType;
  let affordance = "";

  if (viewType == "OPTION_GRID_GRID_VIEW") {
    affordance = "grid";
  } else if (viewType == "OPTION_GRID_LIST_VIEW") {
    affordance = "list";
  }

  const logObject = {
    type: events.PATIENT_OPTIONS_VIEW_TYPE,
    data: {
      tool_type: metadata.toolType,
      tool_slug: metadata.toolSlug,
      aggregate_id: aggregateId,
      affordance: affordance
    }
  };

  log(logObject.type, logObject.data);
};
