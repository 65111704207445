import styled, { css } from "styled-components";
import ExtraLabel from "../shared/extra-label";

export const Column = styled.div`
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.space.sm};

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-right: ${({ theme }) => theme.space.md};
    }
  }

  & input {
    width: 5rem;
  }
`;

export const Label = styled(ExtraLabel)`
  display: block;
  margin-bottom: 0.375rem;
`;

export const getLabelStyles = isLabelHidden => css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: ${isLabelHidden || "1.625rem"};
  }
`;

export const infoButtonStyles = css`
  align-self: flex-end;
`;
