import React from "react";
import {
  Type,
  Flex,
  Button,
  Icon,
  useMediaQuery
} from "discover.medical.react-component-library";
import { Link } from "react-router-dom";
import InfoModal from "../../../../../tools-browse-shared-components/info-modal-v2";
import ToolsItem from "../../../../../tools-browse-shared-components/tools-item";
import useModal from "../../../../../../shared/hooks/useModal";
import { ToolsWrapper, ViewAllButton } from "./index.styled";
import { useSelector } from "react-redux";
import isNil from "lodash/isNil";

const ToolsList = ({ tools, listType }) => {
  const isDesktop = useMediaQuery("min", "md");
  const isMobile = useMediaQuery("max", "sm");
  const [isInfoModal, openInfoModal, closeInfoModal] = useModal();
  const sessionNumber = useSelector(state => state.sharedData.sessionNumber);

  return (
    <>
      {tools && (
        <Flex data-testid={`${listType}-tools-container`}>
          <Flex display="flex">
            {tools.icon && (
              <Icon
                icon={{
                  glyph: tools.icon?.glyph,
                  color: tools.icon?.color,
                  size: tools.icon?.size
                }}
              />
            )}

            <Type
              data-auto={`${listType}-tools-list-item-title`}
              element="h2"
              color="headingprimary"
              fontFamily="secondary"
              marginLeft={tools.icon ? "sm" : "0"}
            >
              {tools?.title}
            </Type>
          </Flex>
          <ToolsWrapper data-auto="my-tools-item">
            {tools.items?.map(tool => (
              <ToolsItem
                toolInfo={tool}
                onInfoModalOpen={openInfoModal}
                key={tool.title}
              />
            ))}
          </ToolsWrapper>

          <InfoModal
            isOpen={isInfoModal}
            isMobile={isMobile}
            isDesktop={isDesktop}
            onClose={closeInfoModal}
          />

          {tools?.viewAll && (
            <ViewAllButton
              as={Button}
              linkComponent={Link}
              data-auto="content-section-view-all-btn"
              href={`${tools.viewAll.route}${
                !isNil(sessionNumber) ? `?session-number=${sessionNumber}` : ""
              }`}
            >
              <>
                {tools.viewAll.label}
                <Icon
                  icon={{ glyph: "chevron_right", size: "sm", color: "white" }}
                />
              </>
            </ViewAllButton>
          )}
        </Flex>
      )}
    </>
  );
};

ToolsList.displayName = "ToolsList";

export default ToolsList;
