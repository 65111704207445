import React from "react";
import get from "lodash/get";
import { Button } from "discover.medical.react-component-library";
import { componentStateMapper } from "../componentsStateMapper";
import "./_index.css";
import BasicMultipleSelect from "../basic-multiple-select";

export class MultiselectFilter extends BasicMultipleSelect {
  handleClick(evt, option) {
    const { value } = this.props;
    let selected = value ? value.slice() : [];

    selected.includes(option)
      ? (selected = selected.filter(item => item !== option))
      : selected.push(option);

    this.handleToggleChange(evt, selected);
  }

  renderComponent = () => {
    const { value, view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    return (
      <div className="MultiselectFilter">
        {Array.isArray(labels) &&
          labels.map(label => (
            <Button.Filter
              key={label.value}
              className="MultiselectFilter_button"
              onClick={evt => this.handleClick(evt, label.value)}
              label={label.name}
              selected={value && value.includes(label.value)}
            >
              {label.name}
            </Button.Filter>
          ))}
      </div>
    );
  };
}

export default componentStateMapper(MultiselectFilter);
