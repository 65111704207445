//tool data
export const TOOL_DATA_REQUEST = "TOOL_DATA_REQUEST";
export const TOOL_DATA_REQUEST_SUCCESS = "TOOL_DATA_REQUEST_SUCCESS";
export const TOOL_DATA_REQUEST_ERROR = "TOOL_DATA_REQUEST_ERROR";
export const RESET_TOOL_DATA = "RESET_TOOL_DATA";
export const TOOL_METADATA_REQUEST = "TOOL_METADATA_REQUEST";
export const TOOL_METADATA_REQUEST_SUCCESS = "TOOL_METADATA_REQUEST_SUCCESS";
export const TOOL_METADATA_REQUEST_ERROR = "TOOL_METADATA_REQUEST_ERROR";
export const TOOL_TABS_REQUEST = "TOOL_TABS_REQUEST";
export const TOOL_TABS_REQUEST_SUCCESS = "TOOL_TABS_REQUEST_SUCCESS";
export const TOOL_TABS_REQUEST_ERROR = "TOOL_TABS_REQUEST_ERROR";
export const TOOL_ACTIVE_TAB_INDEX_CHANGE = "TOOL_ACTIVE_TAB_INDEX_CHANGE";

//tools data
export const TOOLS_REQUEST = "TOOLS_REQUEST";
export const TOOLS_REQUEST_SUCCESS = "TOOLS_REQUEST_SUCCESS";
export const TOOLS_REQUEST_ERROR = "TOOLS_REQUEST_ERROR";
export const RESET_TOOLS = "RESET_TOOL_DATA";

//fields data
export const CHANGE_FIELD_UI_VALUE = "CHANGE_FIELD_UI_VALUE";
export const FIELDS_DATA_REQUEST = "FIELDS_DATA_REQUEST";
export const FIELDS_DATA_REQUEST_SUCCESS = "FIELDS_DATA_REQUEST_SUCCESS";
export const FIELDS_DATA_REQUEST_ERROR = "FIELDS_DATA_REQUEST_ERROR";
export const RESET_FIELDS_DATA = "RESET_FIELDS_DATA";
export const FIELDS_DATA_OUTCOME_SUCCESS = "FIELDS_DATA_OUTCOME_SUCCESS";
export const OPTION_GRID_DATA_REQUEST = "OPTION_GRID_DATA_REQUEST";
export const OPTION_GRID_DATA_REQUEST_ERROR = "OPTION_GRID_DATA_REQUEST_ERROR";
export const OPTION_GRID_DATA_OUTCOME_SUCCESS =
  "OPTION_GRID_DATA_OUTCOME_SUCCESS";
export const FIELD_VALUE_IS_VALID = "FIELD_VALUE_IS_VALID";
export const FIELD_VALUE_IS_INVALID = "FIELD_VALUE_IS_INVALID";
export const FIELD_IS_HIDDEN = "FIELD_IS_HIDDEN";
export const FIELD_IS_VISIBLE = "FIELD_IS_VISIBLE";
export const FIELD_SET_IS_MOUNTED = "FIELD_SET_IS_MOUNTED";
export const FIELDS_DATA_EHR_REQUEST = "FIELDS_DATA_EHR_REQUEST";
export const FIELDS_DATA_EHR_REQUEST_SUCCESS =
  "FIELDS_DATA_EHR_REQUEST_SUCCESS";
export const FIELDS_DATA_EHR_REQUEST_ERROR = "FIELDS_DATA_EHR_REQUEST_ERROR";
export const GET_SESSION_NUMBER = "GET_SESSION_NUMBER";
export const SET_MEASURE_VALUE = "SET_MEASURE_VALUE";
export const SET_ERRORS_NUMBER = "SET_ERRORS_NUMBER";
export const CHANGE_USER_PREFERRED_MEASURE = "CHANGE_USER_PREFERRED_MEASURE";
export const TOGGLE_LAB_DATE_INFO = "TOGGLE_LAB_DATE_INFO";

//option grid data
export const OPTION_GRID_REQUEST = "OPTION_GRID_REQUEST";
export const OPTION_GRID_REQUEST_SUCCESS = "OPTION_GRID_REQUEST_SUCCESS";
export const OPTION_GRID_REQUEST_ERROR = "OPTION_GRID_REQUEST_ERROR";
export const RESET_OPTION_GRID = "RESET_OPTION_GRID";
export const OPTION_GRID_SET_SELECTED = "OPTION_GRID_SET_SELECTED";
export const OPTION_GRID_SELECT_VIEW = "OPTION_GRID_SELECT_VIEW";
export const OPTION_GRID_PDF_VIEW_OPEN = "OPTION_GRID_PDF_VIEW_OPEN";

//shared data
export const CHANGE_SCREEN_TYPE_REQUEST = "CHANGE_SCREEN_TYPE_REQUEST";

//snapshot
export const SNAPSHOT_CONTEXT_CHANGE = "SNAPSHOT_CONTEXT_CHANGE";
export const SNAPSHOT_RESET_DATA = "SNAPSHOT_RESET_DATA";
export const SNAPSHOT_CREATE_REQUEST = "SNAPSHOT_CREATE_REQUEST";
export const SNAPSHOT_CREATE_REQUEST_SUCCESS =
  "SNAPSHOT_CREATE_REQUEST_SUCCESS";
export const SNAPSHOT_CREATE_REQUEST_ERROR = "SNAPSHOT_CREATE_REQUEST_ERROR";
export const SNAPSHOT_DATA_REQUEST = "SNAPSHOT_DATA_REQUEST";
export const SNAPSHOT_DATA_REQUEST_SUCCESS = "SNAPSHOT_DATA_REQUEST_SUCCESS";
export const SNAPSHOT_DATA_REQUEST_ERROR = "SNAPSHOT_DATA_REQUEST_ERROR";
export const SNAPSHOT_EVENT_REQUEST = "SNAPSHOT_EVENT_REQUEST";
export const SNAPSHOT_EVENT_REQUEST_ERROR = "SNAPSHOT_EVENT_REQUEST_ERROR";
export const SNAPSHOT_EVENT_REQUEST_SUCCESS = "SNAPSHOT_EVENT_REQUEST_SUCCESS";
export const SNAPSHOT_EVENT_CREATE_OPEN_LINK =
  "SNAPSHOT_EVENT_CREATE_OPEN_LINK";
export const SNAPSHOT_EVENT_CREATE_OPEN_LINK_SUCCESS =
  "SNAPSHOT_EVENT_CREATE_OPEN_LINK_SUCCESS";
export const SNAPSHOT_EVENT_CREATE_OPEN_LINK_ERROR =
  "SNAPSHOT_EVENT_CREATE_OPEN_LINK_ERROR";
export const SNAPSHOT_OPEN_LINK = "SNAPSHOT_OPEN_LINK";
export const SNAPSHOT_QR_CODE = "SNAPSHOT_QR_CODE";

//tool-browse
export const TOOL_BROWSE_GET_CATEGORIES = "TOOL_BROWSE_GET_CATEGORIES";
export const TOOL_BROWSE_GET_CATEGORIES_SUCCESS =
  "TOOL_BROWSE_GET_CATEGORIES_SUCCESS";
export const TOOL_BROWSE_GET_CATEGORIES_ERROR =
  "TOOL_BROWSE_GET_CATEGORIES_ERROR";
export const TOOL_BROWSE_GET_TOOLS = "TOOL_BROWSE_GET_TOOLS";
export const TOOL_BROWSE_GET_TOOLS_SUCCESS = "TOOL_BROWSE_GET_TOOLS_SUCCESS";
export const TOOL_BROWSE_GET_TOOLS_ERROR = "TOOL_BROWSE_GET_TOOLS_ERROR";
export const TOOL_BROWSE_GET_TOOL_INFO = "TOOL_BROWSE_GET_TOOL_INFO";
export const TOOL_BROWSE_GET_TOOL_INFO_SUCCESS =
  "TOOL_BROWSE_GET_TOOL_INFO_SUCCESS";
export const TOOL_BROWSE_GET_TOOL_INFO_ERROR =
  "TOOL_BROWSE_GET_TOOL_INFO_ERROR";
export const TOOL_BROWSE_SET_TOOL_INFO_DATA = "TOOL_BROWSE_SET_TOOL_INFO_DATA";
export const TOOL_BROWSE_RESET = "TOOL_BROWSE_RESET";

//user
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_ERROR = "USER_GET_ERROR";

//AutoComplete Action Types
export const AUTOCOMPLETE_SUCCESS = "AUTOCOMPLETE_SUCCESS";
export const AUTOCOMPLETE_REQUEST = "AUTOCOMPLETE_REQUEST";
export const AUTOCOMPLETE_ERROR = "AUTOCOMPLETE_ERROR";

//feedback
export const FEEDBACK_REQUEST = "FEEDBACK_REQUEST";
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS";
export const FEEDBACK_ERROR = "FEEDBACK_ERROR";
export const FEEDBACK_TOAST_RESET = "FEEDBACK_TOAST_RESET";

//search
export const SEARCH_TOOLS_REQUEST = "SEARCH_TOOLS_REQUEST";
export const SEARCH_TOOLS_SUCCESS = "SEARCH_TOOLS_SUCCESS";
export const SEARCH_TOOLS_ERROR = "SEARCH_TOOLS_ERROR";

//User's favorite tools
export const FAVORITE_TOOLS_REQUEST = "FAVORITE_TOOLS_REQUEST";
export const FAVORITE_TOOLS_REQUEST_SUCCESS = "FAVORITE_TOOLS_REQUEST_SUCCESS";
export const FAVORITE_TOOLS_REQUEST_ERROR = "FAVORITE_TOOLS_REQUEST_ERROR";
export const FAVORITE_TOOL_ADD_SUCCESS = "FAVORITE_TOOL_ADD_SUCCESS";
export const FAVORITE_TOOL_ADD_ERROR = "FAVORITE_TOOL_ADD_ERROR";
export const FAVORITE_TOOL_REMOVE_SUCCESS = "FAVORITE_TOOL_REMOVE_SUCCESS";
export const FAVORITE_TOOL_REMOVE_ERROR = "FAVORITE_TOOL_REMOVE_ERROR";
export const TOOL_BROWSE_GET_FAV_TOOLS_SUCCESS =
  "TOOL_BROWSE_GET_FAV_TOOLS_SUCCESS";
export const FAVORITE_TOOL_MODAL_TOGGLE = "FAVORITE_TOOL_MODAL_TOGGLE";
export const FAVORITE_TOOL_RESET_TOAST = "FAVORITE_TOOL_RESET_TOAST";
export const FAVORITE_TOOL_RESET = "FAVORITE_TOOL_RESET";

export const SHOW_FORBIDDEN_MODAL = "SHOW_FORBIDDEN_MODAL";
export const RESET_FORBIDDEN_MODAL = "RESET_FORBIDDEN_MODAL";

export const PUA_REMINDER_MODAL_SHOWING = "PUA_REMINDER_MODAL_SHOWING";

export const USER_PREFERENCE = "USER_PREFERANCE";
export const USER_PREFERENCE_ERROR = "USER_PREFERENCE_ERROR";

// patient encounter report
export const PATIENT_ENCOUNTER_REPORT_REQUEST =
  "PATIENT_ENCOUNTER_REPORT_REQUEST";
export const PATIENT_ENCOUNTER_REPORT_REQUEST_SUCCESS =
  "PATIENT_ENCOUNTER_REPORT_REQUEST_SUCCESS";
export const PATIENT_ENCOUNTER_REPORT_REQUEST_ERROR =
  "PATIENT_ENCOUNTER_REPORT_REQUEST_ERROR";
export const PATIENT_ENCOUNTER_REPORT_STATUS_REQUEST =
  "PATIENT_ENCOUNTER_REPORT_STATUS_REQUEST";
export const PATIENT_ENCOUNTER_REPORT_STATUS_ERROR =
  "PATIENT_ENCOUNTER_REPORT_STATUS_ERROR";
export const PATIENT_ENCOUNTER_REPORT_STATUS_TIMEOUT =
  "PATIENT_ENCOUNTER_REPORT_STATUS_TIMEOUT";
export const PATIENT_ENCOUNTER_REPORT_STATUS_SUCCESS =
  "PATIENT_ENCOUNTER_REPORT_STATUS_SUCCESS";
export const PATIENT_ENCOUNTER_REPORT_DOWNLOAD_REQUEST =
  "PATIENT_ENCOUNTER_REPORT_DOWNLOAD_REQUEST";
export const PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SUCCESS =
  "PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SUCCESS";
export const PATIENT_ENCOUNTER_REPORT_DOWNLOAD_ERROR =
  "PATIENT_ENCOUNTER_REPORT_DOWNLOAD_ERROR";
export const PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SKIP =
  "PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SKIP";
export const PATIENT_ENCOUNTER_REPORT_RESET = "PATIENT_ENCOUNTER_REPORT_RESET";

//Terms of Use Dialog
export const GET_TERMS_DLG = "GET_TERMS_DLG";
export const GET_TERMS_DLG_SUCCESS = "GET_TERMS_DLG_SUCCESS";
export const GET_TERMS_DLG_ERROR = "GET_TERMS_DLG_ERROR";
export const GET_TERMS_DLG_ERROR_MSG =
  "Unable to fetch the terms of use, please try again.";

//Home Data
export const HOME_DATA_REQUEST = "HOME_DATA_REQUEST";
export const HOME_DATA_REQUEST_ERROR = "HOME_DATA_REQUEST_ERROR";
export const HOME_DATA_REQUEST_SUCCESS = "HOME_DATA_REQUEST_SUCCESS";
export const HOME_DATA_NOTIFY_STALE = "HOME_DATA_NOTIFY_STALE";

export const TOOL_INFO_REQUEST = "TOOL_INFO_REQUEST";
export const TOOL_INFO_REQUEST_ERROR = "TOOL_INFO_REQUEST_ERROR";
export const TOOL_INFO_REQUEST_SUCCESS = "TOOL_INFO_REQUEST_SUCCESS";

export const SET_TOOL_INFO = "SET_TOOL_INFO";
