import React from "react";
import PropTypes from "prop-types";
import { Root } from "./index.styled";

export default function ExtraLabel({ htmlFor, className, children }) {
  return (
    <Root className={className} htmlFor={htmlFor}>
      {children}
    </Root>
  );
}

ExtraLabel.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string
};

ExtraLabel.defaultProps = {
  className: "",
  htmlFor: ""
};
