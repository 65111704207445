import {
  Avatar,
  CallToAction,
  Flex
} from "discover.medical.react-component-library";
import React from "react";
import { AlertContainer } from "./index.styled";

export const PersistentAlert = ({ alert, heading = "alert", message }) => (
  <AlertContainer container justify={{ content: "center" }} maxWidth="100%">
    <Flex cell maxWidth="1440px" width="100%">
      <CallToAction
        alert={alert}
        avatar={
          <Avatar foregroundColor="red.base" glyph="warning" marginRight="md" />
        }
        heading={heading}
        message={message}
      />
    </Flex>
  </AlertContainer>
);

export default PersistentAlert;
