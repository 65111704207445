import React from "react";
import Basic from "../../../../../content-components/basic";
import IconGrid, { VERTICAL } from "../icon-grid";

import parser from "../../../../../../helpers/parser";
import componentStateMapper from "../../../../../content-components/componentsStateMapper";
import get from "lodash/get";

import iconMapPerson from "../../../../../content-components/shared/icon-map-person";
import iconMapBlock from "../../../../../content-components/shared/icon-map-block";

import { default as iconSVGPaths } from "../icons"; //NOSONAR
import iconArraySettings from "./iconArraySettings";

export class IconArray extends Basic {
  createIcon(name) {
    return iconSVGPaths[name]?.icon;
  }

  getDimensions() {
    const populationValue = this.getPopulationValue();
    return iconArraySettings[populationValue];
  }

  getIcons() {
    let icons = [];

    // Collect the visible icon groups into a flat array.
    get(this.props.view, "attributes.iconGroups").forEach(group => {
      if (
        !group.visibility ||
        (group.visibility && this.isVisible(group.visibility))
      ) {
        icons = icons.concat(group.icons);
      }
    });

    icons.sort((a, b) => a.order - b.order);

    const instances = [];

    icons.forEach((element, index) => {
      const count = parseInt(get(this.props.fields, element.count, "0"));

      for (let i = 0; i < count; i++) {
        instances.push(this.createIcon(this.mapIcon(element.icon)));
      }
    });
    return instances;
  }

  isVisible(expression) {
    return parser.evaluate(expression, this.props.fields);
  }

  mapIcon(code) {
    const populationValue = this.getPopulationValue();
    const iconMap = populationValue === "100" ? iconMapPerson : iconMapBlock;
    return iconMap[code] || "help_outline";
  }

  getPopulationValue() {
    const populationExpression = get(
      this.props.view,
      "attributes.parameters.population"
    );
    const populationValue = `${parser.evaluate(
      populationExpression,
      this.props.fields
    )}`;
    return populationValue;
  }

  renderCompositeComponent = () => {
    const dimensions = this.getDimensions();

    if (!dimensions) {
      return null;
    }

    const icons = this.getIcons();
    const { rows, columns } = dimensions;

    if (icons.length !== rows * columns) {
      return null;
    }

    return (
      <IconGrid flow={VERTICAL} {...dimensions}>
        {icons}
      </IconGrid>
    );
  };

  // Removes the unused label from the base component.
  renderLabel = () => {};
}

export default componentStateMapper(IconArray);
