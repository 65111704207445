import React from "react";
import BasicComponent from "../basic/BasicComponent";
import componentStateMapper from "../componentsStateMapper";
import tabComponentsRetriever from "../../templates/components/tabComponentsRetriever";
import InputLabel from "../shared/inputlabel";
import {
  containerStyles,
  controlColumnStyles,
  StyledDynamicForm
} from "./index.styled";
import ControlField from "../basic/field";

export class HorizontalGroup extends BasicComponent {
  isChildRequired = () => {
    const { view } = this.props;

    return Object.values(view.children || {}).some(child => {
      return (
        child.attributes?.fieldInfo?.constraints?.required?.toLowerCase() ===
        "yes"
      );
    });
  };

  isChildVisible = () => {
    const { view, fields } = this.props;

    return Object.keys(view.children || {}).some(child => {
      return fields.ui[child] && fields.ui[child].isHidden === false;
    });
  };

  renderLabel = stylesExtension => {
    const { name } = this.props;

    return this.isChildVisible() ? (
      <InputLabel
        text={this.getBaseContent()}
        name={name}
        isRequired={this.isChildRequired()}
        stylesExtension={stylesExtension}
        isEmpty={this.isBaseContentEmpty()}
      />
    ) : null;
  };

  renderCompositeComponent = () => {
    const { type } = this.props;
    return (
      <ControlField
        type={type}
        renderLabel={this.renderLabel}
        renderComponent={this.renderComponent}
        renderInfo={this.renderInfoButton}
        renderErrors={this.renderValidationError}
        containerStyles={containerStyles}
        controlColumnStyles={controlColumnStyles}
        renderLabDateInfo={this.renderLabDateInfo}
        renderDefaultMessage={this.renderDefaultMessage}
        renderDefaultMessageLink={this.renderDefaultMessageLink}
      />
    );
  };

  renderComponent = () => {
    const { view, name } = this.props;

    return (
      <div container noGap data-auto={`${name}-horizontal-group`}>
        {view.children && (
          <StyledDynamicForm
            componentsList={tabComponentsRetriever(view, "children", "")}
          />
        )}
      </div>
    );
  };
}

export default componentStateMapper(HorizontalGroup);
