import styled from "styled-components";
import { Modal, Type } from "discover.medical.react-component-library";

export const StyledModal = styled(Modal)`
  && {
    align-items: center;
  }
`;

export const Content = styled.div`
  padding: 1.75rem 0.625rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1.75rem;
  }
`;

export const SectionTitle = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};
`;
