import styled from "styled-components";
import { Input, InputGroup } from "discover.medical.react-component-library";

export const RadioInputGroup = styled(InputGroup)`
  svg {
    flex-shrink: 0;
    z-index: auto;
  }
`;

export const RadioInput = styled(Input.Radio)`
  > div {
    align-items: flex-start;
  }
`;
