import React from "react";
import BasicComponent from "../basic/BasicComponent";
import componentStateMapper from "../componentsStateMapper";
import DynamicForm from "../DynamicForm";
import { Card, CardContent } from "discover.medical.react-component-library";
import tabComponentsRetriever from "../../templates/components/tabComponentsRetriever";
import { Container, ControlColumn, StyledButton } from "./index.styled";

export class NumericHeightWeight extends BasicComponent {
  state = {
    preferredSystem: "",
    inputs: [],
    bmi: []
  };

  systemByMeasure = {
    cm: "metric",
    inches: "imperial",
    kg: "metric",
    lbs: "imperial"
  };

  constructor(props) {
    super(props);

    const components = tabComponentsRetriever(props.view, "children", "");

    const inputs = components.filter(comp =>
      ["numeric.height", "numeric.weight"].includes(comp.type)
    );

    const bmi = components.filter(
      comp => comp.type.toLowerCase() === "numeric.bmi"
    );
    if (inputs.length >= 1) {
      const firstDefaultMeasure = inputs[0]["uiDefaultMeasure"];
      const defaultSystem = this.systemByMeasure[firstDefaultMeasure] || "";
      this.state.preferredSystem = defaultSystem;
      this.state.inputs = inputs;
    }
    if (bmi.length >= 1) {
      this.state.bmi = bmi;
    }
  }

  renderCompositeComponent = () => {
    const { changeUserPreferredMeasure } = this.props;
    const { preferredSystem, inputs, bmi } = this.state;

    const systemToggle = {
      metric: "imperial",
      imperial: "metric"
    };

    const alternateSystem = systemToggle[preferredSystem] || "";
    const getMeasureForSystem = (measureInfoLabels, system) => {
      const preferredMeasureInfoLabel = measureInfoLabels.find(label => {
        return this.systemByMeasure[label.value] === system;
      });
      return preferredMeasureInfoLabel.value;
    };

    const changeMeasure = () => {
      const userPreferredSystem = systemToggle[this.state.preferredSystem];
      this.setState({ preferredSystem: userPreferredSystem }, () => {
        inputs.forEach(component => {
          const preferredMeasure = getMeasureForSystem(
            component.measureInfoLabels,
            userPreferredSystem
          );
          if (preferredMeasure) {
            changeUserPreferredMeasure(component.name, preferredMeasure);
          }
        });
      });
    };

    return (
      <Card size="sm" data-testid="numeric-height-weight-component">
        <CardContent
          borderColor="gray400"
          borderStyle="solid"
          borderWidth="1px"
        >
          {inputs && (
            <DynamicForm
              data-testid="inputs-dynamic-form"
              componentsList={inputs}
            />
          )}
          {alternateSystem && (
            <Container container>
              <ControlColumn cell>
                <StyledButton
                  data-testid="measure-toggle-button"
                  data-auto="change-measure"
                  onClick={changeMeasure}
                  kind="anchor"
                >
                  {`Switch to ${alternateSystem}`}
                </StyledButton>
              </ControlColumn>
            </Container>
          )}
          {bmi && (
            <DynamicForm data-testid="bmi-dynamic-form" componentsList={bmi} />
          )}
        </CardContent>
      </Card>
    );
  };
}

export default componentStateMapper(NumericHeightWeight);
