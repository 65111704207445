import { useCallback, useEffect } from "react";
import useModal from "../../../../../../../shared/hooks/useModal";
import { SNAPSHOT_OPEN_LINK } from "../../../../../../../store/action-types";

export default function useLinkGeneration({
  setIsSpinnerShown,
  sendOpenAccessGeneratedEventAction,
  resetSnapshotDataAction,
  openLinkAction,
  snapshotProcessingInfo,
  aggregateId
}) {
  const [isLinkOpen, openLinkModal, closeLinkModal] = useModal();

  const sendOpenAccessGeneratedEvent = useCallback(() => {
    if (
      snapshotProcessingInfo &&
      snapshotProcessingInfo.task === SNAPSHOT_OPEN_LINK &&
      snapshotProcessingInfo.snapshotId &&
      !snapshotProcessingInfo.isSnapshotLoading
    ) {
      sendOpenAccessGeneratedEventAction(
        snapshotProcessingInfo.snapshotId,
        aggregateId
      );
    }
  }, [sendOpenAccessGeneratedEventAction, snapshotProcessingInfo, aggregateId]);

  useEffect(() => {
    if (isLinkOpen) {
      resetSnapshotDataAction();
      openLinkAction();
      setIsSpinnerShown(true);
    }
  }, [isLinkOpen, resetSnapshotDataAction, setIsSpinnerShown, openLinkAction]);

  useEffect(() => {
    sendOpenAccessGeneratedEvent();
  }, [sendOpenAccessGeneratedEvent, snapshotProcessingInfo]);

  return { isLinkOpen, openLinkModal, closeLinkModal };
}
