import React from "react";
import { Flex } from "discover.medical.react-component-library";
import { StatementContainer, StatementValue } from "./index.styled";

const Statement = ({ content, value, units }) => (
  <StatementContainer container justify={{ content: "space-between" }}>
    <Flex cell>{content}</Flex>
    <Flex cell>
      <StatementValue lang="en">{value + " " + units}</StatementValue>
    </Flex>
  </StatementContainer>
);
export default Statement;
