import React from "react";
import Basic from "../basic";
import { Icon } from "discover.medical.react-component-library";
import IconGrid, { VERTICAL } from "../icon-grid";

import parser from "../../../helpers/parser";
import componentStateMapper from "../componentsStateMapper";
import get from "lodash/get";

import iconMap from "../shared/icon-map";

import "./_index.css";

export class IconArray extends Basic {
  compareOrder(a, b) {
    if (a.order > b.order) {
      return 1;
    } else if (a.order < b.order) {
      return -1;
    }

    return 0;
  }

  createIcon(group, offset, name) {
    const key = "g" + group + "-o" + offset;

    return (
      <Icon
        className="IconArray_icon"
        icon={{ glyph: name, size: "xl" }}
        key={key}
      />
    );
  }

  getDimensions() {
    const populationExpression = get(
      this.props.view,
      "attributes.parameters.population"
    );
    const populationValue = parser.evaluate(
      populationExpression,
      this.props.fields
    );

    switch (populationValue) {
      case "100":
        return {
          columns: 20,
          rows: 5
        };
      default:
        return null;

      // case "1000":
      //   return {
      //     columns: ?,
      //     rows: ?
      //   };

      // case "10000":
      //   return {
      //     columns: ?,
      //     rows: ?
      //   };
    }
  }

  getIcons() {
    let icons = [];

    // Collect the visible icon groups into a flat array.
    get(this.props.view, "attributes.iconGroups").forEach(group => {
      if (
        !group.visibility ||
        (group.visibility && this.isVisible(group.visibility))
      ) {
        icons = icons.concat(group.icons);
      }
    });

    icons.sort(this.compareOrder);

    const instances = [];

    icons.forEach((element, index) => {
      const count = parseInt(get(this.props.fields, element.count, "0"));

      for (let i = 0; i < count; i++) {
        instances.push(this.createIcon(index, i, this.mapIcon(element.icon)));
      }
    });

    return instances;
  }

  isVisible(expression) {
    return parser.evaluate(expression, this.props.fields);
  }

  mapIcon(code) {
    if (iconMap[code]) {
      return iconMap[code];
    }

    return "help_outline";
  }

  renderComponent = () => {
    const dimensions = this.getDimensions();

    if (!dimensions) {
      return null;
    }

    return (
      <IconGrid
        flow={VERTICAL}
        columns={dimensions.columns}
        rows={dimensions.rows}
      >
        {this.getIcons()}
      </IconGrid>
    );
  };

  // Removes the unused label from the base component.
  renderLabel = () => {};
}

export default componentStateMapper(IconArray);
