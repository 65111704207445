import { useState } from "react";
import useModal from "../../../../../../../shared/hooks/useModal";

export default function useInfoPanel({ options }) {
  const [isInfoPanelOpen, openInfoPanel, closeInfoPanel] = useModal(false);
  const [selectedOption, setSelectedOption] = useState("");

  const openInfoPanelWrapper = option => {
    openInfoPanel();
    if (option) {
      setSelectedOption(option);
    }
  };

  const getOptionName = key => {
    const option = options.find(({ optionKey }) => optionKey === key);
    return option && option.name ? option.name : "";
  };

  return {
    openInfoPanel: openInfoPanelWrapper,
    closeInfoPanel,
    isInfoPanelOpen,
    selectedOption,
    getOptionName
  };
}
