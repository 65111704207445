import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cn from "classnames";
import { Input, Button } from "discover.medical.react-component-library";
import OptionGridDropdown from "../option-grid-dropdown";
import { setSelected } from "../../../../../../store/option-grid/actions";
import { canToggleOptions } from "../../../../../../store/option-grid/selectors";
import { useOptionGridContext } from "../OptionGridContext";
import { patientOptionsVisibilityClicked } from "../../../../../../store/telemetry/actions";
import "./_index.css";

export const OptionGridOptionSelector = ({
  options,
  selected,
  setSelectedOptions,
  isHidden,
  showAllEnabled,
  onPatientVisibilityClicked
}) => {
  const [open, setOpen] = useState(false);
  const { isSingleColumn } = useOptionGridContext();

  const handleSelectOption = optionKey => {
    if (selected.includes(optionKey)) {
      setSelectedOptions(selected.filter(item => item !== optionKey));
      onPatientVisibilityClicked({ value: optionKey, affordance: "hidden" });
    } else {
      setSelectedOptions(selected.concat(optionKey));
      onPatientVisibilityClicked({ value: optionKey, affordance: "unhidden" });
    }
  };

  const handleSelectAll = () => {
    setSelectedOptions(options.map(option => option.optionKey));
  };

  const getHiddenOptions = (selectedOptions, allOptions) => {
    return allOptions.filter(
      option => !selectedOptions.includes(option.optionKey)
    );
  };

  let text = "Show/Hide";
  const hiddenOptions = getHiddenOptions(selected, options);

  if (hiddenOptions) {
    if (hiddenOptions.length > 1) {
      text = `Hidden: ${hiddenOptions[0].name} +${hiddenOptions.length - 1}`;
    }
    if (hiddenOptions.length === 1) {
      text = `Hidden: ${hiddenOptions[0].name}`;
    }
  }

  const toggleMenu = () => {
    setOpen(!open);
  };

  if (isHidden || isSingleColumn) {
    return null;
  }

  return (
    <OptionGridDropdown
      className="OptionGridOptionSelector"
      toggleMenu={toggleMenu}
      open={open}
      text={text}
    >
      <div className="OptionGridOptionSelector_contents">
        {showAllEnabled && (
          <Button
            data-auto="option-grid-show-all-button"
            kind="text"
            class="OptionGridOptionSelector_textButton"
            onClick={handleSelectAll}
          >
            Show All
          </Button>
        )}
        <ul>
          {options.map(item => (
            <li key={item.optionKey} className="OptionGridOptionSelector_item">
              <Input.Checkbox
                className={cn("OptionGridOptionSelector_label", {
                  OptionGridOptionSelector_label__checked: selected.includes(
                    item.optionKey
                  )
                })}
                label={item.name}
                name={item.name}
                id={item.optionKey}
                checked={selected.includes(item.optionKey)}
                onChange={() => handleSelectOption(item.optionKey)}
              />
            </li>
          ))}
        </ul>
      </div>
    </OptionGridDropdown>
  );
};

OptionGridOptionSelector.propTypes = {
  className: PropTypes.string,
  glyph: PropTypes.string,
  text: PropTypes.string,
  showAllEnabled: PropTypes.bool,
  onPatientVisibilityClicked: PropTypes.func.isRequired
};

OptionGridOptionSelector.defaultProps = {
  className: "",
  glyph: "",
  text: "Show/Hide",
  showAllEnabled: false
};

const mapDispatchToProps = {
  setSelectedOptions: setSelected,
  onPatientVisibilityClicked: patientOptionsVisibilityClicked
};

const mapStateToProps = state => {
  return {
    options: state.optionGrid.options,
    selected: state.optionGrid.selected,
    isHidden: !canToggleOptions(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionGridOptionSelector);
