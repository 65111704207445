import { Button, Tooltip } from "discover.medical.react-component-library";
import styled from "styled-components";

export const ActionButton = styled(Button)`
  width: 2.375rem;
  height: 2.375rem;
  margin: 0.2rem;

  && {
    padding: 0;
  }
`;

export const ActionTooltip = styled(Tooltip)`
  z-index: 50;
  position: relative;
  text-wrap: nowrap;
  white-space: nowrap;
`;
