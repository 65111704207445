import React from "react";
import {
  StyledInfoLayer,
  StyledInfoLabel,
  StyledFlexBanner,
  HighlightedSpan,
  BoldSpan,
  StyledDivider
} from "./index.styled";

const EhrEnabledFieldsBanner = () => {
  return (
    <>
      <StyledInfoLayer>
        <StyledFlexBanner container>
          <StyledInfoLabel data-auto="ehr-enabled-banner-label">
            <span>
              <HighlightedSpan>
                <BoldSpan>Highlighted fields</BoldSpan>
              </HighlightedSpan>{" "}
              show or are capable of showing{" "}
              <BoldSpan>EHR-enabled responses</BoldSpan>. Any lab dates are date
              of collection or, if unavailable, the result date.
            </span>
          </StyledInfoLabel>
        </StyledFlexBanner>
      </StyledInfoLayer>
      <StyledDivider />
    </>
  );
};

export default EhrEnabledFieldsBanner;
