import get from "lodash/get";
import union from "lodash/union";
import parser from "../../parser";
import {
  getUiVariablesNames,
  expressionContainsEhrVariables
} from "../../inline-expressions";
import { EHR_VARIABLE_PREFFIX } from "../../../calculators/constants";
import { Parser } from "@ebsco-cd/expr-eval-lib";

export default class UiDefaults {
  static parseCondition(uiDefault) {
    let conditionsVariables = [];
    for (const { condition } of uiDefault) {
      if (condition) {
        const expression = parser.parse(condition);
        const variables = expression.variables({ withMembers: true });
        conditionsVariables = union(conditionsVariables, variables);
      }
    }
    return conditionsVariables;
  }

  static getFieldsNames(reorderedFields) {
    return reorderedFields.map(field => {
      return get(field, ["attributes", "name"], null);
    });
  }

  static checkDependencies(elem, reorderedFields) {
    const uiDefault = get(
      elem,
      ["attributes", "fieldInfo", "ui-default"],
      null
    );

    if (!uiDefault || !Array.isArray(uiDefault)) {
      return true;
    }

    const conditionsVariables = this.parseCondition(uiDefault);
    const uiVariablesNames = getUiVariablesNames(conditionsVariables);
    const reorderedFieldsNames = this.getFieldsNames(reorderedFields);
    return uiVariablesNames.every(variableName => {
      return reorderedFieldsNames.some(name => name === variableName);
    });
  }

  static parseUiDefault(field, ui, ehr = {}) {
    const responseObject = {
      value: "",
      ehrEnabledField: false
    };

    const uiDefault = get(
      field,
      ["attributes", "fieldInfo", "ui-default"],
      null
    );

    if (!uiDefault || !Array.isArray(uiDefault)) {
      return responseObject;
    }

    let result = "";
    for (const { value, condition, link, message } of uiDefault) {
      if (responseObject.ehrEnabledField !== true) {
        if (
          expressionContainsEhrVariables(value) ||
          expressionContainsEhrVariables(condition)
        ) {
          responseObject.ehrEnabledField = true;
        }
      }

      if (!condition || parser.evaluate(condition, { ui, ehr })) {
        result = value;
        responseObject.link = link;
        responseObject.message = message;
        break;
      }
    }
    try {
      result = parser.evaluate(result, { ui, ehr });
      responseObject.value = result !== undefined ? result.toString() : "";
    } catch (e) {
      responseObject.value = result;
    }

    return responseObject;
  }

  static parseEhrProp(field, ui, ehr = {}) {
    const uiDefault = get(
      field,
      ["attributes", "fieldInfo", "ui-default"],
      null
    );

    if (!uiDefault || !Array.isArray(uiDefault)) {
      return "";
    }
    let ehrExpr = "";

    for (const { value, condition } of uiDefault) {
      /**The ehr value to be parsed only for no-condition or it is true. */
      if (!condition || parser.evaluate(condition, { ui, ehr })) {
        /**If value field has ehr property (like "ehr.osmolality_serum_unit_mosmkgh2o"), then parse the same. */
        if (value.startsWith(EHR_VARIABLE_PREFFIX)) {
          ehrExpr = value;
        } else {
          /**If the value field contains ui value like ("yes", "<280" etc.) instead of ehr property used,
           * then need to look into condition for ehr property. */
          ehrExpr = this.extractEhrPropFromCondition(condition);
        }
        break;
      }
    }
    /**Get the ehr property except the prefix ("ehr.")*/
    const ehrExprAr = ehrExpr?.split(".");
    return ehrExprAr && ehrExprAr.length > 1 ? ehrExprAr[1] : "";
  }

  static extractEhrPropFromCondition = condition => {
    if (!condition) {
      return "";
    }
    /**Need to check how many ehr properties are used in the condition*/
    const expr = Parser.parse(condition);
    const variables = expr?.variables({ withMembers: true });
    /**If more than one ehr property are used in the condition,
     * then we cannot determine the value for which ehr property to be shown as ehr value on the UI.
     * Hence we need to ignore those conditions, as discussed with feature owner. */
    return variables && variables.length === 1 ? variables[0] : "";
  };
}
