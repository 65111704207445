import axiosInstance from "../../../services/axios-instance";

import {
  TOOL_INFO_REQUEST,
  TOOL_INFO_REQUEST_ERROR,
  TOOL_INFO_REQUEST_SUCCESS,
  SET_TOOL_INFO
} from "../../action-types";

export const getToolInfo = toolInfoUrl => async dispatch => {
  dispatch({
    type: TOOL_INFO_REQUEST
  });
  try {
    const { data } = await axiosInstance.get(`${toolInfoUrl}`, {});
    dispatch({
      type: TOOL_INFO_REQUEST_SUCCESS,
      payload: { toolInfoUrl, info: data }
    });
  } catch {
    dispatch({
      type: TOOL_INFO_REQUEST_ERROR
    });
  }
};

export const setToolInfo = (title, toolUrl) => dispatch => {
  dispatch({
    type: SET_TOOL_INFO,
    payload: { title, toolUrl }
  });
};
