import Basic from "../basic";
import get from "lodash/get";

export class BasicSingleSelect extends Basic {
  handleSetValueE2ECommand = (_, value) => {
    return this.changeValue(value);
  };

  handleGetValueE2ECommand = () => {
    return this.props.value;
  };

  handleSetLabelE2ECommand = (_, value) => {
    const { view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    let res = labels.find(label => label.name === String(value)) || {};
    this.changeValue(res.value || "");
  };

  handleGetLabelE2ECommand = () => {
    const { view, value } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    let res = labels.find(label => String(value) === label.value) || {};
    return res.name || "";
  };
}

export default BasicSingleSelect;
