import React from "react";
import PropTypes from "prop-types";
import { Step } from "discover.medical.react-component-library";
import { StepAnchorStyled, StepperStyled } from "./index.styled";

const StepperComponent = ({ steps, activeStepIndex, moveToStep }) => (
  <StepperStyled activeIndex={activeStepIndex}>
    {steps.map(({ label, disabled }, index) => (
      <Step isDisabled={disabled} key={label}>
        <StepAnchorStyled
          isDisabled={disabled}
          onClick={e => {
            e.preventDefault();
            moveToStep(index);
          }}
          data-auto={`stepper-step-${label.toLowerCase()}`}
        >
          {label}
        </StepAnchorStyled>
      </Step>
    ))}
  </StepperStyled>
);
export default StepperComponent;

StepperComponent.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  moveToStep: PropTypes.func.isRequired
};
