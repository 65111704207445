import { log } from "../../analytics";
import { events } from "../index";

export default (appState, payload, type) => {
  const { tool = {} } = appState;

  const { aggregateId } = appState.snapshot.events;

  let options = "";
  if (appState.optionGrid && appState.optionGrid.selected) {
    options = appState.optionGrid.selected;
  }

  const { metadata = {} } = tool;

  const logObject = {
    type: events.PATIENT_OPTIONS_VIEWED,
    data: {
      tool_type: metadata.toolType,
      tool_slug: metadata.toolSlug,
      aggregate_id: aggregateId,
      visible: options.toString()
    }
  };

  log(logObject.type, logObject.data);
};
