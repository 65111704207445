/* istanbul ignore file */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./root";
import telemetry from "../middleware/telemetry/index.js";

export default (initialState = {}) => {
  const middlewares = [thunk, telemetry];
  const composeEnhancers =
    (typeof window === "object" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const enhancers = composeEnhancers(applyMiddleware(...middlewares));
  return createStore(rootReducer, initialState, enhancers);
};
