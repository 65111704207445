import styled from "styled-components";
import {
  Flex,
  Badge,
  Button,
  ButtonAction
} from "discover.medical.react-component-library";

export const ToolsListWrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 0;
`;

export const StyledBadge = styled(Badge)`
  vertical-align: text-bottom;
`;

export const ToolsItemInfoBtn = styled(ButtonAction)`
  width: 1.25rem;
  height 1.25rem;
`;

export const ToolsItemDeleteBtn = styled(Button)`
  margin-left: ${({ theme }) => theme.space.sm};
`;
