import React from "react";
import get from "lodash/get";
import componentStateMapper from "../componentsStateMapper";
import BasicSingleSelect from "../basic-single-select";
import { StyledDropdown } from "./index.styled";
import convertType from "../../../helpers/convertors/types-converter";

export class DecisionInput extends BasicSingleSelect {
  renderLabel = () => null;
  handleChange = (event, __, item) => {
    const { value } = this.props;
    if (item && convertType(item.value) !== convertType(value)) {
      this.changeValue(item.value, event);
    }
  };
  renderCompositeComponent = () => {
    const { name, view, value } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    let defaultIndex = labels.length > 0 ? 0 : -1;
    labels.forEach((item, ind) => {
      item.title = item.name;
      if (item.value === String(value)) {
        defaultIndex = ind;
      }
    });
    return (
      <>
        {Array.isArray(labels) && (
          <StyledDropdown
            id={name}
            name={name}
            hasError={this.hasErrors()}
            options={labels}
            onChange={this.handleChange}
            defaultIndex={defaultIndex}
          />
        )}
      </>
    );
  };
}

export default componentStateMapper(DecisionInput);
