import styled from "styled-components";
import InfoButton from "../../../../../content-components/shared/infobutton";

export const StyledLegend = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.colors.gray400};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
`;

export const LegendText = styled.span`
  font-family: var(--font-open-sans);
  font-weight: ${({ theme }) => theme.font.weight.normal};
  padding: 0.625rem ${({ theme }) => theme.space.xs};
  color: ${({ theme }) => theme.colors.gray800};
  margin-right: auto;
  p {
    margin-bottom: 0;
  }
`;

export const LegendCount = styled.b`
  padding: 0.625rem ${({ theme }) => theme.space.xs};
  font-size: ${({ theme }) => theme.font.size.xl};
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.gray800};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  white-space: nowrap;
  width: 2.75rem;
  text-align: center;
`;

export const LegendInfoButton = styled(InfoButton)`
  padding: 0.625rem ${({ theme }) => theme.space.xs};
`;
