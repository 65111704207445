import React from "react";
import BasicText from "../basic-text";
import componentStateMapper from "../componentsStateMapper";
import { Flex } from "discover.medical.react-component-library";
import InfoButton from "../shared/infobutton";
import { StyledContentBlock } from "./index.styled";

export class GuidanceBodyStatement extends BasicText {
  getPlainBaseContent = () => {
    return this._getText("baseContent");
  };

  renderCompositeComponent = () => (
    <Flex cell align={{ item: "flex-start" }}>
      <StyledContentBlock>{this.getPlainBaseContent()}</StyledContentBlock>
      <InfoButton text={this.getBackgroundText()} />
    </Flex>
  );
}

export default componentStateMapper(GuidanceBodyStatement);
