const countOfInchesInFoot = 12;

export const getFeetAndInches = num => {
  const feet = Math.floor(Number(num / countOfInchesInFoot));
  const inches = num - feet * countOfInchesInFoot;
  return {
    feet,
    inches
  };
};

export const calcInches = (feet, inches) => {
  return Number(inches) + feet * Number(countOfInchesInFoot);
};
