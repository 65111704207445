import { log } from "../../analytics";
import { events } from "../";

export default (appState, payload, type) => {
  const { tool = {} } = appState;
  const { metadata = {} } = tool;
  const { aggregateId } = appState.snapshot.events;

  let affordanceValue;

  if (payload === "options") {
    affordanceValue = "patient_options";
  } else {
    affordanceValue = "assessment_guidance";
  }

  const logObject = {
    type: events.CHART_NOTE_COPY_CLICKED,
    data: {
      tool_type: metadata.toolType,
      tool_slug: metadata.toolSlug,
      aggregate_id: aggregateId,
      affordance: affordanceValue
    }
  };

  log(logObject.type, logObject.data);
};
