// patient encounter report status constants
export const REPORT_REQUESTING = "requesting";
export const REPORT_REQUESTED = "requested";
export const REPORT_PENDING = "pending";
export const REPORT_GENERATING = "generating";
export const REPORT_NO_DATA = "no-data";
export const REPORT_FAILED = "failed";
export const REPORT_READY = "ready";
export const REPORT_ERROR = "error";
export const REPORT_TIMEOUT = "timeout";
export const REPORT_DONE = "done";
