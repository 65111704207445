import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "discover.medical.react-component-library";
import { VIEWPORT_MEDIUM } from "../../../../../../helpers/aliases";

const OptionGridButton = ({ text, glyph, viewport }) => {
  const size = viewport === VIEWPORT_MEDIUM ? "sm" : "md";
  return (
    <Button variant="primary" borderRadius="sm" size={size}>
      {glyph && <Icon icon={{ glyph, size: size }} />}
      {text}
      <Icon icon={{ glyph: "expand_more" }} />
    </Button>
  );
};

OptionGridButton.propTypes = {
  className: PropTypes.string,
  glyph: PropTypes.string,
  viewport: PropTypes.string
};

OptionGridButton.defaultProps = {
  className: "",
  glyph: ""
};

export default OptionGridButton;
