import styled from "styled-components";

export const Root = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  color: ${({ theme, color }) => color || theme.colors.gray700};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-family: inherit;
  line-height: 1.5;
  cursor: pointer;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
`;
