import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "discover.medical.react-component-library";
import InfoModalContentItem from "../info-modal-content-item";
import { getToolInfo } from "../../../store/tool-info/actions";
import { SectionTitle, Content, StyledModal } from "./index.styled";

const InfoModal = ({ isMobile, isDesktop, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const currentInfo = useSelector(state => state?.toolInfo?.currentInfo);
  const info = useSelector(state => state?.toolInfo?.info);
  const isInfoLoading = useSelector(state => state?.toolInfo?.isInfoLoading);
  useEffect(() => {
    if (isOpen && currentInfo && !info[currentInfo?.toolUrl]) {
      dispatch(getToolInfo(currentInfo?.toolUrl));
    }
  }, [dispatch, info, isOpen, currentInfo]);

  let toolInfo = info?.[currentInfo?.toolUrl];

  return (
    <StyledModal
      v2
      borderRadius="sm"
      padding={false}
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overflow: "auto",
        maxWidth: !isMobile && !isDesktop ? "40rem" : "47rem",
        maxHeight: "100%"
      }}
      fullScreen={isMobile}
      data-testId="info-modal-container"
    >
      {isInfoLoading ? (
        <Spinner data-testId="info-modal-spinner" />
      ) : (
        <Content data-auto="info-modal" data-testId="info-modal-content">
          <SectionTitle
            data-auto="info-modal-title"
            element="h2"
            fontFamily="secondary"
          >
            {currentInfo?.title}
          </SectionTitle>

          {toolInfo?.eligibility && (
            <InfoModalContentItem
              title="Eligibility"
              description={toolInfo?.eligibility}
            />
          )}
        </Content>
      )}
    </StyledModal>
  );
};

InfoModal.displayName = "InfoModal";

export default InfoModal;
