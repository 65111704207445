import styled from "styled-components";
import { Label } from "discover.medical.react-component-library";

export const Root = styled(Label)`
  display: inline-flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.gray800};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  text-align: left;
  ${({ $stylesExtension }) => $stylesExtension};

  p {
    margin-bottom: 0;
    display: inline;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-top: ${({ theme, type }) =>
      type === "singleselect.radiogroup" ? "0.125rem" : theme.space.xs};

    text-align: right;

    p {
      display: block;
    }
  }
`;

export const Container = styled.span`
  position: relative;
  padding-right: 0.75rem;
  line-height: 1.25rem;
`;

export const Star = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  display: inline-block;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: absolute;
    margin-left: 0;
    top: 0;
    right: 0;
  }
`;

export const EhrEnabledSpan = styled.span`
  p {
    display: inline;
    background-color: ${({ theme }) => theme.colors.yellow};
  }
`;
