// EXPORT ALL LOGGERS FOR EASY IMPORTS

export { default as logHomePageViewed } from "./home-page-viewed";
export { default as logPatientDataEntryViewed } from "../loggers/patient-data-entry-viewed";
export { default as logPdfGenerated } from "../loggers/pdf-generated";
export { default as logPatientOptionsVisibilityClicked } from "./patient-options-visibility-clicked";
export { default as logPatientOptionsViewed } from "../loggers/patient-options-viewed";
export { default as logKeyDataViewed } from "../loggers/key-data-viewed";
export { default as logAssessmentAndGuidanceViewed } from "./assessment-and-guidance-viewed";
export { default as logGenerateLinkClicked } from "./generate-link-clicked";
export { default as logGenerateQRCodeClicked } from "./generate-qr-code-clicked";
export { default as logChartNoteClicked } from "./chart-note-clicked";
export { default as logChartNoteCopyClicked } from "./chart-note-copy-clicked";
export { default as logGenerateLinkCopyClicked } from "./generate-link-copy-clicked";
export { default as logGenerateQrCodeCopyClicked } from "./generate-qr-code-copy-clicked";
export { default as logNavigationItemClicked } from "./navigation-item-clicked";
export { default as logPatientOptionsViewType } from "./patient-options-view-type";
export { default as logToolLaunched } from "./tool-launched";

// CONSTANTS FOR CONSISTENCY IN Amplitude
export const events = {
  HOME_VIEWED: "Home – viewed",
  PATIENT_DATA_ENTRY_VIEWED: "Patient Data Entry - viewed",
  PATIENT_OPTIONS_VISIBILITY_CLICKED: "Patient Options - visibility_clicked",
  ASSESSMENT_GUIDANCE_VIEWED: "Assessment And Guidance - viewed",
  PATIENT_OPTIONS_VIEWED: "Patient Options - viewed",
  KEY_DATA_VIEWED: "Key Data - viewed",
  CHART_NOTE_CLICKED: "Chart Note - clicked",
  CHART_NOTE_COPY_CLICKED: "Chart Note - copy_clicked",
  NAVIGATION_PATIENT_FEATURE_CLICKED: "Navigation - patient_feature_clicked",
  NAVIGATION_PDF_CLICKED: "Navigation - pdf_clicked",
  NAVIGATION_ITEM_CLICKED: "Navigation Item - clicked",
  PATIENT_DATA_ENTRY_UPDATED: "Patient Data Entry - updated",
  PATIENT_OPTIONS_VIEW_TYPE: "Patient Options - view_type",
  PDF_GENERATED: "PDF - generated",
  ADD_SSR_EVENT: "Add SSR Event",
  CLEAR_SSR_EVENTS: "Clear all SSR Events",
  GENERATE_QR_CODE_CLICKED: "Generate QR Code - clicked",
  GENERATE_QR_CODE_COPY_CLICKED: "Generate QR Code - copy_clicked",
  GENERATE_LINK_CLICKED: "Generate Link - clicked",
  GENERATE_LINK_COPY_CLICKED: "Generate Link - copy_clicked",
  TOOL_LAUNCHED: "Tool - launched"
};
