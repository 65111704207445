import { getImageDomain, getAjaxDomain } from "../util/urlFilters";
export const DSD_DCD_URLS = [
  {
    domain: "resources.dynamed.com",
    pingUrl:
      "https://resources.dynamed.com/discover.medical.dynamedui/assets/dmp/favicon-16x16.png",
    strategy: "image"
  },
  {
    domain: "ab-configuration-cdn.ebsco.com",
    pingUrl: "https://ab-configuration-cdn.ebsco.com/favicon.ico",
    strategy: "image"
  },
  {
    domain: "telemetry.ebsco.com",
    pingUrl: "https://telemetry.ebsco.com",
    strategy: "ajax"
  },
  {
    domain: "connect.ebsco.com",
    pingUrl:
      "https://connect.ebsco.com/resource/1623748976000/EBSCOHOMEPAGELOGO/IMAGES/logo.PNG",
    strategy: "image"
  },
  {
    domain: "login.ebsco.zone",
    pingUrl: "https://login.ebsco.zone/userinfo",
    strategy: "ajax"
  },
  {
    domain: "resources.ebsco.zone",
    pingUrl:
      "https://resources.ebsco.zone/discover.medical.auth0-universal-login/logo192.png",
    strategy: "image"
  },
  {
    domain: "myaccount.ebsco.healthcare",
    pingUrl: "https://myaccount.ebsco.healthcare/api",
    strategy: "ajax"
  },
  {
    domain: "cmp.osano.com",
    pingUrl: "https://cmp.osano.com/favicon.ico",
    strategy: "image"
  },
  {
    domain: "p.typekit.net",
    pingUrl: "https://p.typekit.net/p.gif",
    strategy: "image"
  },
  {
    domain: "use.typekit.net",
    pingUrl: "https://use.typekit.net/typekit",
    strategy: "ajax"
  }
];
export const DSD = "dsd";
export const DCD = "dcd";
export const DSD_DCD_IMG_SRC_DOMAIN = getImageDomain(DSD_DCD_URLS);
export const DSD_DCD_CONNECT_SRC_DOMAIN = getAjaxDomain(DSD_DCD_URLS);
