import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Accordion } from "discover.medical.react-component-library";
import DynamicForm from "../../../../../content-components";
import tabComponentsRetriever from "../../../tabComponentsRetriever";
import cn from "classnames";
import "./_index.css";

export class OptionGridGlossary extends Component {
  render() {
    const { definitions, definitionsNames } = this.props;
    return (
      <Fragment>
        {definitions &&
          !!definitions.length &&
          definitionsNames &&
          !!definitionsNames.length && (
            <div className="OptionGridGlossary">
              <Accordion
                variant="quaternary"
                toggleText={
                  <span className="OptionGridGlossary_Header">
                    Glossary Terms
                  </span>
                }
              >
                <DynamicForm
                  className={cn("DefinitionWrapper", {
                    DefinitionWrapper__single: definitions.length === 1
                  })}
                  componentsList={definitions}
                />
              </Accordion>
            </div>
          )}
      </Fragment>
    );
  }
}

OptionGridGlossary.propTypes = {
  definitions: PropTypes.array.isRequired,
  definitionsNames: PropTypes.array.isRequired
};

export const mapStateToProps = state => ({
  definitions: tabComponentsRetriever(state, "definitions"),
  definitionsNames: Object.values(state.tool.controls.definitions)
    .map(definition =>
      definition.attributes.fieldInfo.baseContent.replace(/<\/?[^>]+(>|$)/g, "")
    )
    .filter(definition => !!definition)
});

export default connect(mapStateToProps)(OptionGridGlossary);
