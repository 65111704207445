import styled, { css } from "styled-components";
import { Label } from "discover.medical.react-component-library";
import DynamicForm from "../../../../content-components";

export const PopulationView = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.white};
  .IconArray_icon {
    max-width: 100%;
  }
  .Subheading {
    margin-top: 0;
  }
  .Text_baseContent {
    font-size: ${({ theme }) => theme.font.size.sm};
    line-height: inherit;
  }
`;

export const PopulationViewHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: 0.75rem 0.75rem 0.75rem ${({ theme }) => theme.space.md};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-wrap: nowrap;
    padding-bottom: ${({ theme }) => theme.space.sm};
  }
`;

export const StyledLabel = styled(Label)`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-right: ${({ theme }) => theme.space.xl};
  display: block;
  order: 1;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.sm}) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    margin-right: auto;
    flex: 1 0 50%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`;

export const TreatmentOptions = styled(DynamicForm)`
  margin-right: auto;
  order: 1;
  width: 100%;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.sm}) and (max-width: ${({ theme }) =>
      theme.breakpoints.md}) {
    order: 2;
  }
  .General-content-component-wrapper {
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex: 1 0 50%;

    .General-content-component-wrapper {
      margin-bottom: ${({ theme }) => theme.space.sm};
    }
  }
`;

export const HeaderControls = styled(DynamicForm)`
  display: flex;
  flex: none;
  order: 2;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    order: 2;
  }
  .General-content-component-wrapper {
    margin-left: ${({ theme }) => theme.space.md};
    width: 100%;

    &:first-child {
      margin-left: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex: 1 0 100%;
  }
`;

export const PopulationViewContent = styled.div`
  box-shadow: none;
  background-color: white;
  padding: ${({ theme }) => theme.space.md} 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  .Subheading {
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: ${({ theme }) => theme.font.size.lg};
    }
  }
`;

export const PopulationSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.space.sm};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const IconGrid = styled.div`
  ${({ denominator }) =>
    denominator === "100" ? "flex: 0 0 28%;" : "flex: 0 0 40%"};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-right: ${({ theme }) => theme.space.lg};
  }
`;

const population100Mixin = css`
  flex-direction: row;
  flex: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .dynamic-form-wrapper {
    flex: 1;
    margin-left: ${({ theme }) => theme.space.xl};
  }
  .dynamic-form-wrapper:first-child {
    flex: 1;
    margin-left: 0;
  }
`;

const population1000Mixin = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: ${({ theme }) => theme.space.md};
`;

export const LegendsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    ${({ denominator }) =>
      denominator === "100" ? population100Mixin : population1000Mixin};
  }
  .Definition_Term {
    text-transform: uppercase;
    font-weight: 600;
  }
`;

export const Legend = styled(DynamicForm);
