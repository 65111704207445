import styled from "styled-components";

export const StyledErrorMsgContainer = styled.div`
  text-align: center;
  margin-top: 15rem;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledActionMsg = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 15rem;
  text-align: center;
`;
