import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Select as RCLSelect } from "discover.medical.react-component-library";
import { Select, Label } from "./index.styled";
import parser from "../../../../helpers/parser";

const MeasureInfo = ({
  name,
  measure,
  value,
  view: {
    attributes: {
      measureInfo: { labels, precisions }
    }
  },
  initialValue,
  handleChangeMeasureValue,
  handleChange
}) => {
  const changeValueWithMeasure = useCallback(
    ({ parsedValue, measureValue }) => {
      if (typeof parsedValue === "number" && measureValue) {
        const selectedMeasure = labels.find(l => l.value === measureValue);
        const expr = parser.parse(selectedMeasure.format);
        let alteredValue = expr.evaluate({ value: parsedValue });
        const precision = precisions.find(p => p.measure === measureValue);
        if (precision) {
          const length = precision.value.length;
          alteredValue = Number(
            alteredValue.toFixed(length > 2 ? length - 2 : 0)
          );
        }
        handleChange({ target: { value: alteredValue } });
      } else if (initialValue !== value) {
        handleChange({ target: { value: "" } });
      }
    },
    [handleChange, initialValue, labels, precisions, value]
  );

  useEffect(() => {
    changeValueWithMeasure({
      parsedValue: initialValue,
      measureValue: measure.value
    });
  }, [changeValueWithMeasure, handleChange, initialValue, measure.value]);

  const changeMeasure = useCallback(
    newValue => {
      const selectedMeasure = labels.find(l => l.value === newValue);
      if (selectedMeasure) {
        handleChangeMeasureValue({
          value: selectedMeasure.value,
          label: selectedMeasure.name
        });
        changeValueWithMeasure({
          parsedValue: initialValue,
          measureValue: selectedMeasure.value
        });
      }
    },
    [labels, handleChangeMeasureValue, changeValueWithMeasure, initialValue]
  );

  const handleMeasureChange = e => {
    changeMeasure(e.target.value);
  };

  return (
    <>
      {labels.length === 1 && (
        <Label data-auto="measure-info-option-label" htmlFor={name}>
          {labels[0].name}
        </Label>
      )}
      {labels.length >= 2 && (
        <Select
          onChange={handleMeasureChange}
          value={measure?.value}
          data-auto="measure-info-select"
          name={name}
        >
          {!measure?.value && (
            <RCLSelect.Option
              data-auto="measure-info-option"
              value=""
              name=""
            />
          )}
          {labels.map(l => (
            <RCLSelect.Option
              data-auto="measure-info-option"
              key={l.value}
              value={l.value}
              name={l.name}
            >
              {l.name}
            </RCLSelect.Option>
          ))}
        </Select>
      )}
    </>
  );
};

MeasureInfo.propTypes = {
  name: PropTypes.string,
  measure: PropTypes.object,
  view: PropTypes.object,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChangeMeasureValue: PropTypes.func,
  handleChange: PropTypes.func
};

MeasureInfo.defaultProps = {
  name: "",
  measure: { value: "" },
  view: {
    attributes: {
      measureInfo: { labels: [], precisions: [] }
    }
  },
  initialValue: "",
  value: "",
  handleChangeMeasureValue: () => {},
  handleChange: () => {}
};

export default MeasureInfo;
