import styled from "styled-components";
import { Button, Icon } from "discover.medical.react-component-library";

export const ButtonStyled = styled(Button)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 2.375rem;
    height: 2.375rem;
    margin: 0.2rem;
  }
`;

export const PatientChartNoteTextStyled = styled.span`
  paddingleft: ${({ theme }) => theme.space.sm};
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const PatientChartNoteIconStyled = styled(Icon)`
  margin-right: 0.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-right: 0;
  }
`;
