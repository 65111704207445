import React from "react";
import get from "lodash/get";
import componentStateMapper from "../componentsStateMapper";
import BasicSingleSelect from "../basic-single-select";
import { Select } from "discover.medical.react-component-library";
import { StyledSelect } from "./index.styled";
import MeasureInfo from "../shared/measure-info";

export class Dropdown extends BasicSingleSelect {
  state = {
    value: ""
  };

  _didMountExtension = () => {
    const initialStateValue =
      this.props.value !== "" ? Number(this.props.value) : "";
    this.setState({ value: initialStateValue });
  };

  handleInputChange = event => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    this.setState({ value });
    this.hideLabDateInfoOnUserAction(event.isTrusted); //Passing isTrusted value to determine if action / event was user generated
  };

  handleSetValueE2ECommand = (_, value) => {
    const { view } = this.props;
    const changeHandler = this.getChangeHandler(view);
    changeHandler({
      target: {
        value: value
      }
    });
  };

  handleSetLabelE2ECommand = (_, value) => {
    const { view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    let res = labels.find(label => label.name === String(value));
    if (!!res) {
      const changeHandler = this.getChangeHandler(view);
      changeHandler({
        target: {
          value: res.value
        }
      });
    }
  };

  getChangeHandler = view =>
    this.checkIfMeasureComponent(view)
      ? this.handleInputChange
      : this.handleChange;

  checkIfMeasureComponent = view =>
    !!get(view, "attributes.measureInfo", false);

  handleGetLabelE2ECommand = () => {
    const { view, value } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    let res = labels.find(label => String(value) === String(label.value)) || {};
    return res.name || "";
  };

  renderComponent = () => {
    const { name, view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    const isMeasureComponent = this.checkIfMeasureComponent(view);
    const value = isMeasureComponent ? this.state.value : this.props.value;
    return (
      <>
        {Array.isArray(labels) && (
          <StyledSelect
            id={name}
            name={name}
            onChange={this.getChangeHandler(view)}
            value={value}
            hasError={this.hasErrors()}
          >
            <Select.Option value="" disabled />
            {labels.map(item => (
              <Select.Option key={item.value} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </StyledSelect>
        )}
        {isMeasureComponent && (
          <MeasureInfo
            {...this.props}
            initialValue={value}
            handleChangeMeasureValue={this.handleChangeMeasureValue}
            handleChange={this.handleChange}
          />
        )}
      </>
    );
  };
}

export default componentStateMapper(Dropdown);
