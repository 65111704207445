import axios from "axios";
import {
  GET_TERMS_DLG,
  GET_TERMS_DLG_SUCCESS,
  GET_TERMS_DLG_ERROR,
  GET_TERMS_DLG_ERROR_MSG
} from "../../action-types";

export const fetchTermsDlg = url => async dispatch => {
  dispatch({ type: GET_TERMS_DLG });
  try {
    const policy = (await axios.get(url)).data;
    if (policy) {
      dispatch({
        type: GET_TERMS_DLG_SUCCESS,
        payload: policy
      });
    } else {
      dispatch({
        type: GET_TERMS_DLG_ERROR,
        payload: GET_TERMS_DLG_ERROR_MSG
      });
    }
  } catch (error) {
    dispatch({
      type: GET_TERMS_DLG_ERROR,
      payload: GET_TERMS_DLG_ERROR_MSG
    });
  }
};
