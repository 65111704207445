import React from "react";
import { useSelector } from "react-redux";
import { useFeature } from "../../../../../../helpers/amplitude";
import {
  Button,
  Icon,
  useMediaQuery
} from "discover.medical.react-component-library";
import {
  AccountabilityMessage,
  ButtonsBlock,
  Mark,
  RedMark,
  StyledRow
} from "./index.styled";
import useEhrpdnsExist from "../hooks/useEhrpdnsExist";

export default function PatientStepStickyFooterContent({ handleSubmit }) {
  const toolType = useSelector(({ tool }) => tool.metadata.toolType);
  const btnSize = useMediaQuery("min", "sm") ? "md" : "sm";
  const isEhrpdnsExist = useEhrpdnsExist();
  const [isPopulatingNoFeatureEnabled] = useFeature(
    "f65288_prepopulating_no_for_high_usage_ehr_tools"
  );

  return (
    <>
      {isEhrpdnsExist && isPopulatingNoFeatureEnabled && (
        <AccountabilityMessage data-auto="accountability-message">
          {
            "Please confirm that any EHR-enabled responses are accurate before continuing."
          }
        </AccountabilityMessage>
      )}
      <StyledRow>
        <ButtonsBlock>
          <Button
            size={btnSize}
            onClick={handleSubmit}
            data-auto="continue-button"
          >
            {toolType === 2
              ? [
                  "View Assessment & Guidance",
                  <Icon
                    key="assesment-guidance-button"
                    icon={{ glyph: "navigate_next" }}
                  />
                ]
              : "Continue"}
          </Button>
        </ButtonsBlock>
        <Mark>
          <RedMark>*</RedMark> Required
        </Mark>
      </StyledRow>
    </>
  );
}
