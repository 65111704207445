import React from "react";
import PropTypes from "prop-types";

import { Provider as ReduxProvider, connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { EdgeApiProvider } from "../../universal/components/edge-api/context";
import { AmplitudeProvider } from "../../universal/helpers/amplitude/context";

const ConnectedIntlProvider = connect(state => ({
  locale: state.intl.locale,
  messages: state.intl.messages
}))(IntlProvider);

const ConnectedEdgeApiProvider = connect(state => ({
  baseUrl: state.paths.api
}))(EdgeApiProvider);

const ProvidersWithoutRouter = ({ store, children, amplitude }) => (
  <ReduxProvider store={store}>
    <AmplitudeProvider {...amplitude}>
      <ConnectedEdgeApiProvider>
        <ConnectedIntlProvider>{children}</ConnectedIntlProvider>
      </ConnectedEdgeApiProvider>
    </AmplitudeProvider>
  </ReduxProvider>
);

ProvidersWithoutRouter.propTypes = {
  store: PropTypes.object,
  useFeature: PropTypes.func,
  children: PropTypes.node
};

export default ProvidersWithoutRouter;
