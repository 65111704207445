import React from "react";
import Preloader from "./components/preloader";
import PropTypes from "prop-types";
import ToolFooter from "./components/tool-footer";
import ModalReferences from "./components/modal-references/ModalReferences";
import ModalAbout from "./components/modal-about/ModalAbout";
import { useSelector } from "react-redux";
import useModal from "../../../shared/hooks/useModal";
import Wizard from "./components/wizard";
import { Main, Content } from "./index.styled";
import OptionGridTab from "../components/optiongridtab";
import { isMHDDomain } from "../../../helpers/domain-manager";

export default function DefaultNewTemplate({ isLoading }) {
  const { location } = useSelector(({ app }) => app);
  const { modified } = useSelector(({ tool }) => tool.metadata);
  const { isSnapshotAvailable, createdAt: snapshotCreatedAt } = useSelector(
    ({ snapshot }) => snapshot.snapshotData
  );
  const [
    isReferencesOpen,
    openReferencesModal,
    closeReferencesModal
  ] = useModal();
  const [isAboutOpen, openAboutModal, closeAboutModal] = useModal();

  return (
    <Main data-auto="default-template">
      <Preloader isLoading={isLoading} />
      <Content data-auto="default-template-content">
        {isSnapshotAvailable ? (
          <div data-auto="tool-template-implementation-hide-tabs">
            <OptionGridTab key="Option Grid" isHiddenBtn={true} />
          </div>
        ) : (
          <section>
            <Wizard />
          </section>
        )}

        {isMHDDomain(location) || (
          <>
            <ToolFooter
              openAboutModal={openAboutModal}
              openReferencesModal={openReferencesModal}
              isSnapshotAvailable={isSnapshotAvailable}
              snapshotCreatedAt={snapshotCreatedAt}
              modified={modified}
            />

            <ModalReferences
              closeReferencesModal={closeReferencesModal}
              isReferencesOpen={isReferencesOpen}
            />
            <ModalAbout
              closeAboutModal={closeAboutModal}
              isAboutOpen={isAboutOpen}
            />
          </>
        )}
      </Content>
    </Main>
  );
}

DefaultNewTemplate.propTypes = {
  isLoading: PropTypes.bool,
  toolTabs: PropTypes.array
};

DefaultNewTemplate.defaultProps = {
  isLoading: false,
  toolTabs: []
};
