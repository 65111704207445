import { events } from "../../middleware/telemetry/loggers";

const initialState = {
  ssrQueue: []
};

// next line is excluded from a sonar because an order of variables is provided by redux
// prettier-ignore
export default (state = initialState, action) => { //NOSONAR
  switch (action.type) {
    case events.ADD_SSR_EVENT:
      return {
        ssrQueue: state.ssrQueue.slice().concat([action.payload])
      };
    case events.CLEAR_SSR_EVENTS:
      return {
        ssrQueue: []
      };
    default:
      return state;
  }
};
