import Basic from "../basic";

export class BasicText extends Basic {
  handleSetValueE2ECommand = (_, value) => {
    this.changeValue(value);
  };

  handleGetValueE2ECommand = () => {
    return this.props.value;
  };
}

export default BasicText;
