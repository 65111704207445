import styled from "styled-components";
import { Popover } from "discover.medical.react-component-library";

export const Root = styled.div`
  line-height: 0;
  ${({ $stylesExtension }) => $stylesExtension};
`;

export const StyledPopover = styled(Popover)`
  ul {
    list-style: outside;
    margin-left: ${({ theme }) => theme.space.md};
  }
`;
