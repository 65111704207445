import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import cn from "classnames";
import { patientOptionsVisibilityClicked } from "../../../../../../../../../universal/store/telemetry/actions";
import { Icon, Button } from "discover.medical.react-component-library";
import "./_index.css";

export const OptionGridHeaderCell = ({
  className,
  children,
  isHidden,
  optionKey,
  onCloseButtonClick,
  showCloseButton,
  colspan,
  isFirst
}) => {
  const dispatch = useDispatch();

  const handleCloseButtonClick = useCallback(() => {
    onCloseButtonClick(optionKey);
    dispatch(
      patientOptionsVisibilityClicked({
        value: optionKey,
        affordance: "hidden"
      })
    );
  }, [onCloseButtonClick, optionKey, dispatch]);

  return (
    <th
      className={cn("OptionGridHeaderCell", className, {
        OptionGridHeaderCell__hidden: isHidden,
        OptionGridHeaderCell__first: isFirst
      })}
      colspan={colspan}
    >
      {!isHidden && (
        <div className="OptionGridHeaderCell_content">
          {children}
          {showCloseButton && (
            <Button
              className="OptionGridHeaderCell_button"
              onClick={handleCloseButtonClick}
              padded={false}
              size="iconOnly"
              kind="text"
              aria-label="Hide Option"
            >
              <Icon icon={{ glyph: "close", size: "sm" }} />
            </Button>
          )}
        </div>
      )}
    </th>
  );
};

OptionGridHeaderCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  optionKey: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onCloseButtonClick: PropTypes.func,
  colspan: PropTypes.number,
  onPatientVisibilityClicked: PropTypes.func.isRequired
};

OptionGridHeaderCell.defaultProps = {
  className: "",
  children: null,
  optionKey: "",
  showCloseButton: false,
  onCloseButtonClick: () => {},
  colspan: 1
};

export default React.memo(OptionGridHeaderCell);
