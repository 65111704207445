import React from "react";
import styled from "styled-components";
import {
  Type,
  List,
  Flex,
  Button
} from "discover.medical.react-component-library";

export const Root = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const QRCodeBlock = styled(Flex)`
  padding-top: ${({ theme }) => theme.space.md};
`;

export const QRCodeBlockMobile = styled(Flex)`
  padding-top: ${({ theme }) => theme.space.md};
  padding-bottom: ${({ theme }) => theme.space.md};
`;

export const Title = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};
`;

export const Text = styled(Type)`
  display: flex;
  margin-bottom: 0.75rem;
`;

export const ListStyled = styled(List)`
  padding-left: 1.75rem;
  margin-bottom: 0.75rem;
  && {
    list-style-position: outside;
  }
`;

export const ListItem = styled(List.Item)`
  margin-bottom: ${({ theme }) => theme.space.sm};
`;

export const QRWrapper = styled(({ forwardedRef, ...rest }) => (
  <div {...rest} ref={forwardedRef} />
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CopyButton = styled(Button)`
  margin-top: ${({ theme }) => theme.space.md};
`;
