import { USER_GET_SUCCESS, USER_GET_ERROR } from "../../action-types";
import userProfileDataGenerator from "./../../../helpers/userProfile";
import axiosInstance from "../../../services/axios-instance";
import { showForbidden } from "../../shared-data/actions";
import get from "lodash/get";

export const getUserError = payload => ({
  type: USER_GET_ERROR,
  payload
});

export const getUserSuccess = payload => ({
  type: USER_GET_SUCCESS,
  payload
});

export const getUser = (headers, baseUrl = "", params) => async (
  dispatch,
  getState
) => {
  const sessionNumber = get(getState(), ["sharedData", "sessionNumber"]);
  const userUrl = `${baseUrl}/api-tool-experience/user`;
  try {
    const { data } = await axiosInstance.get(userUrl, {
      headers: { Pragma: "no-cache", ...headers }, // disable cache so ie11 will get latest content
      params
    });

    const userProfileConfig = userProfileDataGenerator({
      user: data,
      sessionNumber,
      displaySignIn: data.displaySignIn,
      showLoginPrompt: false
    });
    dispatch(getUserSuccess({ ...data, ...userProfileConfig }));
  } catch (e) {
    const userProfileConfig = userProfileDataGenerator({});
    dispatch(getUserError({ ...userProfileConfig }));
    dispatch(showForbidden(e));
  }
};
