import { useEffect } from "react";
import {
  OPTION_GRID_GRID_VIEW,
  OPTION_GRID_LIST_VIEW,
  VIEWPORT_SMALL
} from "../../../../../../../helpers/aliases";

export default function useViewType({
  windowSize,
  viewType,
  setViewType,
  singleColumn
}) {
  useEffect(() => {
    if (windowSize === VIEWPORT_SMALL) {
      if (viewType !== OPTION_GRID_LIST_VIEW) {
        setViewType(OPTION_GRID_LIST_VIEW);
      }
    } else if (singleColumn) {
      if (viewType !== OPTION_GRID_GRID_VIEW) {
        setViewType(OPTION_GRID_GRID_VIEW);
      }
    }
  }, [windowSize, setViewType, singleColumn, viewType]);
}
