import styled from "styled-components";
import { Type, Flex, Divider } from "discover.medical.react-component-library";
import DynamicForm from "../../../../../content-components/DynamicForm";

export const PatientDataSummaryContainer = styled(Flex)`
  width: 100%;
  min-width: 15rem;
  margin-bottom: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 30%;
    margin-right: ${({ theme }) => theme.space.xl};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 23%;
  }
`;

export const PatientDataSummaryLabel = styled(Type)`
  margin-right: ${({ theme }) => theme.space.sm};
`;

export const PatientDataSummaryLabelContainer = styled(Flex)`
  padding-bottom: ${({ theme }) => theme.space.sm};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const StyledDynamicFormDataSummary = styled(DynamicForm)`
  font-size: ${({ theme }) => theme.font.size.sm};
`;
