import {
  OPTION_GRID_REQUEST,
  OPTION_GRID_REQUEST_SUCCESS,
  OPTION_GRID_REQUEST_ERROR,
  RESET_OPTION_GRID,
  OPTION_GRID_SET_SELECTED,
  OPTION_GRID_SELECT_VIEW,
  OPTION_GRID_PDF_VIEW_OPEN
} from "../../action-types";

export const getOptionGrid = () => ({ type: OPTION_GRID_REQUEST });
export const getOptionGridError = () => ({ type: OPTION_GRID_REQUEST_ERROR });
export const getOptionGridSuccess = payload => ({
  type: OPTION_GRID_REQUEST_SUCCESS,
  payload
});
export const resetOptionGrid = () => ({ type: RESET_OPTION_GRID });

export const setSelected = selected => {
  return {
    type: OPTION_GRID_SET_SELECTED,
    payload: selected
  };
};

export const selectOptionGridViewType = payload => ({
  type: OPTION_GRID_SELECT_VIEW,
  payload
});

export const pdfViewOpen = () => ({
  type: OPTION_GRID_PDF_VIEW_OPEN
});
