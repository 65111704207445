import React from "react";

import { ButtonElement, Text, Root } from "./index.styled";

export const OptionGridSnapshotError = ({ onClick, message }) => {
  return (
    <Root>
      <Text data-auto="snapshot-error-text">{message}</Text>
      <ButtonElement
        data-auto="snapshot-error-close-button"
        onClick={onClick}
        size="md"
      >
        Close
      </ButtonElement>
    </Root>
  );
};

export default OptionGridSnapshotError;
