// Actions
const REQUEST = "my-app/intl/REQUEST";
const SUCCESS = "my-app/intl/SUCCESS";

// Reducer
const initialState = {
  loading: false,
  locale: "en",
  messages: {}
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST:
      return { ...state, loading: true };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        locale: action.payload.locale,
        messages: action.payload.messages
      };
    default:
      return state;
  }
}

// Action Creators
export const selectLocaleRequest = () => ({ type: REQUEST });

export const selectLocaleSuccess = ({ locale, messages }) => ({
  type: SUCCESS,
  payload: { locale, messages }
});

/*
We don't use locale functionality fow now
export const selectLocale = locale => dispatch => {
  // If the default locale is selected, default messages are already loaded
  if (locale === initialState.locale) {
    dispatch(selectLocaleSuccess(initialState));
  } else {
    dispatch(selectLocaleRequest());
    // Load messages for requested locale; fall back to default if not found
    return Promise.all([
      import(`../../locales/messages/${locale}.json`),
      loadReactIntlData(locale)
    ])
      .then(([{ default: messages }]) =>
        dispatch(selectLocaleSuccess({ locale, messages }))
      )
      .catch(() => dispatch(selectLocaleSuccess(initialState)));
  }
};*/
