import styled from "styled-components";
import { Type, Button } from "discover.medical.react-component-library";

export const Title = styled(Type)`
  padding: ${({ theme }) => `${theme.space.sm} 0 ${theme.space.sm}`};
  font-family: ${({ theme }) => theme.font.family.base};
`;

export const Text = styled(Type)`
  color: ${({ theme }) => theme.colors.gray800};
  margin: 0;
`;

export const ButtonsBlock = styled.div`
  padding-top: ${({ theme }) => theme.space.md};
`;

export const ButtonElement = styled(Button)`
  margin-right: ${({ theme }) => theme.space.sm};
`;
