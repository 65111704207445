import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "discover.medical.react-component-library";
import "./_index.css";
import { Root, StyledPopover } from "./index.styled";

export class InfoButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverIsOpen: false
    };
    this.handleClickInfo = this.handleClickInfo.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.openPopover = this.openPopover.bind(this);
  }

  clickInfo() {
    this.setState({ popoverIsOpen: !this.state.popoverIsOpen });
  }

  handleClickInfo(event) {
    event.stopPropagation();
    this.clickInfo();
  }

  closePopover() {
    this.setState({ popoverIsOpen: false });
  }

  openPopover() {
    this.setState({ popoverIsOpen: true });
  }

  createMarkup(text) {
    return { __html: text };
  }

  render() {
    const { text, stylesExtension, className } = this.props;
    let infoButtonStyle = "";

    return (
      <Root className={className} $stylesExtension={stylesExtension}>
        {text ? (
          <StyledPopover
            placement="right"
            isOpen={this.state.popoverIsOpen}
            toggle="click"
            popperComponentProps={{
              variant: "primary",
              width: "medium",
              componentClassName: infoButtonStyle
            }}
            trigger={
              <Icon
                icon={{ glyph: "info_outline", size: "md", color: "dark" }}
              />
            }
          >
            <div dangerouslySetInnerHTML={this.createMarkup(text)} />
          </StyledPopover>
        ) : null}
      </Root>
    );
  }
}

InfoButton.propTypes = {
  text: PropTypes.string.isRequired,
  popoverIsOpen: PropTypes.bool,
  updatePopoverState: PropTypes.func,
  stylesExtension: PropTypes.array
};

InfoButton.defaultProps = {
  stylesExtension: null
};

export default InfoButton;
