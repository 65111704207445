import styled from "styled-components";
import { Button } from "discover.medical.react-component-library";

export const StyledUserDetailTrigger = styled(Button.Action)`
  padding: 0;
  color: ${({ theme }) => theme.colors.quaternary.base};
  border-color: ${({ theme }) => theme.colors.quaternary.base};
  border-style: none;
  > svg {
    fill: ${({ theme, isActive }) =>
      isActive ? theme.colors.white : theme.colors.quaternary.base};
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.white};
    background: none;
    & > svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  &:focus {
    box-shadow: none;
    outline: none;
  }
`;
