import styled, { css } from "styled-components";
import ExtraLabel from "../shared/extra-label";

export const Label = styled(ExtraLabel)`
  margin-bottom: 0.375rem;
`;

export const labelStyles = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 1.625rem;
  }
`;

export const infoButtonStyles = css`
  align-self: flex-end;
`;
