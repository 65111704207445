import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";

export const Root = styled(Flex)`
  min-width: 6.25rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray400};
`;

export const FieldName = styled.div`
  margin-right: ${({ theme }) => theme.space.gutter.base};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  color: ${({ theme }) => theme.colors.gray800};

  p {
    display: inline;
  }
`;
