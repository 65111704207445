import React, { Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import StackedBarChart from "../../components/content-components/stacked-bar-chart";

const contentParser = (content, isHorizontal) => {
  const trimmedContent = content.trim();
  const graphRegExp = new RegExp("<graph>(.*?)<\\/graph>", "gm");
  const match = graphRegExp.exec(trimmedContent);
  if (match && match.length !== 0) {
    const numerator = parseInt(
      match[0].match(/<numerator>(.*?)<\/numerator>/)[1],
      10
    );
    const denominator = parseInt(
      match[0].match(/<denominator>(.*?)<\/denominator>/)[1],
      10
    );
    const graph = (
      <StackedBarChart
        numerator={numerator}
        denominator={denominator}
        isHorizontal={isHorizontal}
      />
    );
    const strippedContent = () => trimmedContent.replace(graphRegExp, "");
    let leftContent = null;
    let rightContent = null;

    // Is the graph at the beginning?
    if (match.index === 0) {
      rightContent = strippedContent();
    }
    // Is the graph at the end?
    else if (match.index + match[0].length === trimmedContent.length) {
      leftContent = strippedContent();
    }
    // Is the graph somewhere in the middle?
    else {
      leftContent = trimmedContent.substring(0, match.index);
      rightContent = trimmedContent.substring(match.index + match[0].length);
    }

    return (
      <Fragment>
        {leftContent && ReactHtmlParser(leftContent)}
        {graph}
        {rightContent && ReactHtmlParser(rightContent)}
      </Fragment>
    );
  }
  return ReactHtmlParser(trimmedContent);
};

export default contentParser;
