const AGE_BASE_VALUE = 0.993;
const MAX_EXPONENT = -1.209;
const EGFR_COEFFICIENT = 141;

export const calculateEgfr = ({ sex, aa_status, creatinine, age, race }) => {
  if (Number(creatinine) && Number(age)) {
    let k;
    let a;
    let SEX_COEFFICIENT = 1;
    let RACE_COEFFICIENT = 1;
    if (sex === "female") {
      k = 0.7;
      a = -0.329;
      SEX_COEFFICIENT = 1.018;
    } else {
      k = 0.9;
      a = -0.411;
    }
    if (aa_status === "yes" || race === "aa") {
      RACE_COEFFICIENT = 1.159;
    }

    const result =
      EGFR_COEFFICIENT *
      Math.pow(Math.min(creatinine / k, 1), a) *
      Math.pow(Math.max(creatinine / k, 1), MAX_EXPONENT) *
      Math.pow(AGE_BASE_VALUE, age) *
      SEX_COEFFICIENT *
      RACE_COEFFICIENT;

    return {
      unit: "mL/min",
      value: Math.round(result)
    };
  }
  return null;
};
