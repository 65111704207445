import styled from "styled-components";

export const SearchTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.lg};
  }
`;

export const FoundToolsList = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};
  border-top: 1px solid ${({ theme }) => theme.colors.gray400};
`;
