import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const AccessInfoContent = styled.div`
  text-align: center;
`;

export const AccessInfoFooter = styled(Type)`
  margin: 0;
`;
