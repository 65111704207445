import styled from "styled-components";
import { Icon, Type } from "discover.medical.react-component-library";

export const InfoModalLegendItemRoot = styled.div`
  padding: ${({ theme }) => theme.space.sm} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};
  &:last-child {
    border-bottom: none;
  }
`;

export const InfoModalIcon = styled(Icon)`
  vertical-align: text-top;
  margin-right: 0.625rem;
`;

export const InfoModalCaption = styled(Type)`
  & em {
    font-style: italic;
  }
`;
