export function checkIsSafari() {
  return (
    typeof navigator !== "undefined" &&
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  );
}

export const checkIsIE = () =>
  typeof navigator !== "undefined" &&
  navigator.userAgent &&
  isInternetExplorerUserAgent(navigator.userAgent);

export const isInternetExplorerUserAgent = userAgentString =>
  userAgentString.indexOf("MSIE") > -1 ||
  userAgentString.indexOf("Trident/") > -1;
