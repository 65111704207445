import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";
import NumericControl from "../shared/numeric-control";

export const Wrapper = styled.div`
  display: inline-flex;
  min-width: 9rem;
  align-items: center;
`;

export const HeightInput = styled(NumericControl)`
  width: 4rem;
  margin-right: ${({ theme }) => theme.space.sm};
`;

export const FieldHolder = styled(Flex)`
  &:not(:last-child) {
    margin-right: 1.25rem;
  }
`;
