import styled from "styled-components";
import { Button, Flex } from "discover.medical.react-component-library";

export const Container = styled(Flex)`
  flex-direction: column;
  padding-bottom: 1.125rem;
  padding: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row-reverse;
  }
`;

export const ControlColumn = styled(Flex)`
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-basis: 58.33333%;
  font-size: ${({ theme }) => theme.font.size.sm};
  padding: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-basis: 66.66666%;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 0.75rem;
  }
`;

export const StyledButton = styled(Button)`
  font-size: ${({ theme }) => theme.font.size.sm};
`;
