import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Type, Popover } from "discover.medical.react-component-library";
import "./_index.css";

export class InfoLink extends Component {
  state = {
    popoverIsOpen: false
  };

  createMarkup(text) {
    return { __html: text };
  }

  render() {
    const { text, anchorText } = this.props;

    let infoLinkStyle = "";
    if (text) {
      const textLength = text.length;
      infoLinkStyle =
        textLength < 10
          ? "InfoLinkPopover_XS"
          : textLength < 100
          ? "InfoLinkPopover_SM"
          : textLength < 500
          ? "InfoLinkPopover_MD"
          : textLength < 1000
          ? "InfoLinkPopover_LG"
          : textLength < 2500
          ? "InfoLinkPopover_XL"
          : "InfoLinknPopover_XXL";
    }

    return (
      <Fragment>
        {text ? (
          <Popover
            placement="right"
            isOpen={this.state.popoverIsOpen}
            toggle="click"
            popperComponentProps={{
              variant: "primary",
              componentClassName: infoLinkStyle,
              width: "small"
            }}
            trigger={
              <Type
                className="InfoLink_anchor"
                fontSize="sm"
                color="secondary.base"
              >
                <div dangerouslySetInnerHTML={this.createMarkup(anchorText)} />
              </Type>
            }
          >
            <div dangerouslySetInnerHTML={this.createMarkup(text)} />
          </Popover>
        ) : null}
      </Fragment>
    );
  }
}

InfoLink.propTypes = {
  text: PropTypes.string.isRequired,
  popoverIsOpen: PropTypes.bool,
  updatePopoverState: PropTypes.func
};

export default InfoLink;
