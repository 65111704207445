const iconArraySettings = {};
iconArraySettings["100"] = {
  columns: 10,
  rows: 10,
  cellWidth: 34,
  cellHeight: 34,
  hGap: 4,
  vGap: 4
};

iconArraySettings["1000"] = {
  columns: 40,
  rows: 25,
  cellWidth: 14,
  cellHeight: 14,
  hGap: 2,
  vGap: 2
};

export default iconArraySettings;
