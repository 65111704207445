import { useEffect } from "react";

export default function useInterfaceChangeEvent({
  aggregateId,
  canSnapshot,
  isSnapshotAvailable,
  optionGridLoaded,
  selected,
  triggerInterfaceChangeAction,
  viewType
}) {
  useEffect(() => {
    if (canSnapshot && !isSnapshotAvailable && optionGridLoaded) {
      triggerInterfaceChangeAction(aggregateId, {
        selected,
        viewType
      });
    }
  }, [
    aggregateId,
    canSnapshot,
    isSnapshotAvailable,
    optionGridLoaded,
    selected,
    triggerInterfaceChangeAction,
    viewType
  ]);
}
