import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PatientStepHeader from "./patient-step-header";
import PatientStepForm from "./patient-step-form";
import usePatientControls from "./hooks/usePatientControls";
import useForm from "./hooks/useForm";
import useStepsSwitchingHandlers from "./hooks/useStepsSwitchingHandlers";
import { patientDataEntryViewed } from "../../../../../../universal/store/telemetry/actions";
import { useDispatch } from "react-redux";

const PatientStep = ({
  moveStepForward,
  moveToStep,
  activeStepIndex,
  componentRef
}) => {
  const { topControls, rightControls, leftControls } = usePatientControls();
  const { submitForm, errorsNumber } = useForm();
  const {
    handleCalculateClick,
    handleStepperItemClick
  } = useStepsSwitchingHandlers({ moveStepForward, moveToStep, submitForm });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(patientDataEntryViewed());
  }, [dispatch]);

  return (
    <>
      <PatientStepHeader
        moveToStep={handleStepperItemClick}
        activeStepIndex={activeStepIndex}
        topControls={topControls}
      />
      <PatientStepForm
        rightControls={rightControls}
        leftControls={leftControls}
        errorsNumber={errorsNumber}
        handleSubmit={handleCalculateClick}
        componentRef={componentRef}
      />
    </>
  );
};

PatientStep.propTyes = {
  moveStepBack: PropTypes.func.isRequired,
  moveStepForward: PropTypes.func.isRequired,
  moveToStep: PropTypes.func.isRequired,
  activeStepIndex: PropTypes.number.isRequired
};

export default PatientStep;
