import styled from "styled-components";

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export const Tools = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.md};

  @media screen and (min-width: 48rem) {
    margin: 0 0 0 auto;
  }
`;
