import {
  MILLISECONDS_PER_DAY,
  MILLISECONDS_PER_HOUR,
  MILLISECONDS_PER_MINUTE,
  TIMEOUT_AFTER_MINUTES
} from "../constants";

export const statusChecksHaveTimedOut = (interval, statusCheckStart) => {
  const now = new Date(Date.now());
  if (!interval.current && !statusCheckStart) {
    return false;
  }
  const diffSinceStatusCheckStart = now - statusCheckStart;
  const diffInMinutes = Math.round(
    ((diffSinceStatusCheckStart % MILLISECONDS_PER_DAY) %
      MILLISECONDS_PER_HOUR) /
      MILLISECONDS_PER_MINUTE
  );
  if (interval.current && diffInMinutes >= TIMEOUT_AFTER_MINUTES) {
    return true;
  }
  return false;
};
