import {
  GET_TERMS_DLG,
  GET_TERMS_DLG_SUCCESS,
  GET_TERMS_DLG_ERROR,
  GET_TERMS_DLG_ERROR_MSG
} from "../../store/action-types";

export const defaultTermsDlgState = {
  isFetching: false,
  hasError: false,
  policyText: GET_TERMS_DLG_ERROR_MSG
};

const termsDlg = (state = defaultTermsDlgState, { type, payload }) => {
  switch (type) {
    case GET_TERMS_DLG:
      return {
        ...state,
        isFetching: true,
        policyText: payload,
        hasError: false
      };
    case GET_TERMS_DLG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        policyText: payload
      };
    case GET_TERMS_DLG_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        policyText: payload
      };
    default:
      return state;
  }
};

export default termsDlg;
