import { log } from "../../analytics";
import { events } from "../";

export default (appState, payload, type) => {
  const logObject = {
    type: events.PDF_GENERATED,
    data: {
      tool_type: appState?.tool?.metadata?.toolType,
      tool_slug: appState?.tool?.metadata?.toolSlug,
      aggregate_id: appState?.snapshot?.events?.aggregateId
    }
  };
  log(logObject.type, logObject.data);
};
