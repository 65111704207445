let token;

class Epic {
  constructor() {
    if (!Epic.instance) {
      Epic.instance = this;
    }
    return Epic.instance;
  }

  checkIsEpic = () => {
    return typeof token !== "undefined";
  };

  handleClick = event => {
    let targetElement = event.target;

    let hyperLink = null;
    if (targetElement.tagName == "a") {
      hyperLink = targetElement;
    } else {
      const closestElement = targetElement.closest("a");
      if (closestElement != null) {
        hyperLink = closestElement;
      }
    }

    if (hyperLink != null) {
      if (
        hyperLink.getAttribute("target") === "_blank" &&
        hyperLink.getAttribute("href") !== "#"
      ) {
        // Convert relative URLs to absolute
        const url = new URL(
          hyperLink.getAttribute("href"),
          window.location.href
        ).href;
        event.preventDefault();
        this.openExternalWindow(url);
      }
    }
  };

  epicHyperspaceListener = event => {
    for (const type in event.data) {
      const payload = event.data[type];
      // eslint-disable-next-line
      if (type === "token") {
        token = payload;
        document.addEventListener("click", this.handleClick);
      }
    }
  };

  openExternalWindow = url => {
    // This window method is required by EPIC to open target url in new window.
    if (typeof window !== "undefined") {
      window.parent.postMessage(
        {
          token: token,
          action: "Epic.Clinical.Informatics.Web.OpenExternalWindow",
          args: url
        },
        "*"
      );
    }
  };

  initialHandshakeWithEpic = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", this.epicHyperspaceListener, false);
      window.parent.postMessage(
        { action: "Epic.Clinical.Informatics.Web.InitiateHandshake" },
        "*"
      );
    }
  };
}

export default Epic;
