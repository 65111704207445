import React from "react";
import componentStateMapper from "../componentsStateMapper";
import BasicText from "../basic-text";
import { TitleRoot } from "./index.styled";

export class PatientDataSummaryTitle extends BasicText {
  renderCompositeComponent = () => {
    return (
      <TitleRoot
        className="Heading"
        element="h6"
        fontFamily="primary"
        color="gray800"
        fontWeight="normal"
        textTransform="uppercase"
      >
        {this.getBaseContent()}
      </TitleRoot>
    );
  };
}

export default componentStateMapper(PatientDataSummaryTitle);
