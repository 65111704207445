import React, { Component } from "react";
import PropTypes from "prop-types";
import "./_index.css";

export class Blank extends Component {
  render() {
    const { message, type, name } = this.props;

    return (
      <div className="Blank-content-component">
        <p>{message}!</p>
        <p>{`Component type: '${type}', component name: '${name}'!`}</p>
      </div>
    );
  }
}

Blank.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default Blank;
