import get from "lodash/get";
import { isComponentExist } from "../../content-components/getComponent";

// The list of component types which may contain output classes
// All the components that can be resolved by output class should be listed here
// !!! IMPORTANT !!! All the types below should be listed in the lower case
const typesWithOutputClasses = [
  "group",
  "horizontal_group",
  "text",
  "text.paragraph",
  "text.subheading",
  "text.definition",
  "text.heading",
  "text.paragraph",
  "text.legend",
  "text.reference",
  "singleselect.filter",
  "visual.iconarray",
  "singleselect.dropdown"
];

export const getStyle = ({ type, outputClass }) => {
  const typeLower = type.toLowerCase();
  const isTypeWithOutputClass = typesWithOutputClasses.includes(typeLower);
  return isTypeWithOutputClass && isComponentExist(outputClass)
    ? outputClass
    : typeLower;
};

export const tabComponentsRetriever = (
  state,
  tabName,
  path = ["tool", "controls"]
) => {
  return Object.keys(get(state, [...path, tabName], [])).map(name => ({
    type: get(state, [...path, tabName, name, "type"], "").toLowerCase(),
    cpdn: get(state, [...path, tabName, name, "attributes", "cpdn"], ""),
    name,
    uiDefaultMeasure: get(
      state,
      [...path, tabName, name, "attributes", "measureInfo", "uiDefaultMeasure"],
      ""
    ),
    measureInfoLabels: get(
      state,
      [...path, tabName, name, "attributes", "measureInfo", "labels"],
      ""
    ),
    style: getStyle(get(state, [...path, tabName, name]))
  }));
};

export default tabComponentsRetriever;
