import styled from "styled-components";

export const StyledSVG = styled.svg`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100%;
    padding-bottom: ${({ paddingBottom }) => paddingBottom};
    overflow: visible;
    height: 1px;
  }
`;
