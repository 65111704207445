import parser from "../../parser";
import { EHR_VARIABLE_PREFFIX } from "../../../calculators/constants";

export default function expressionContainsEhrVariables(expression) {
  if (!expression) {
    return false;
  }
  let variables;
  try {
    variables = parser.parse(expression).variables({ withMembers: true });
  } catch (err) {
    return false;
  }
  if (!variables || !Array.isArray(variables)) {
    return false;
  }
  return variables.some(
    variable => variable.substring(0, 4) === EHR_VARIABLE_PREFFIX
  );
}
