import React from "react";
import { PageRoot, SectionTitle } from "./index.styled";

const NotAvailable = ({ children }) => (
  <PageRoot>
    <SectionTitle element="h1" color="senary" fontFamily="secondary">
      Page Not Available
    </SectionTitle>
    {children}
  </PageRoot>
);

export default NotAvailable;
