import React from "react";
import get from "lodash/get";
import { Button } from "discover.medical.react-component-library";
import { componentStateMapper } from "../componentsStateMapper";
import "./_index.css";
import BasicSingleSelect from "../basic-single-select";

export class SingleSelectFilter extends BasicSingleSelect {
  renderComponent = () => {
    const { value, view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    return (
      <div className="SingleSelectFilter">
        {Array.isArray(labels) &&
          labels.map(label => (
            <Button.Filter
              key={label.value}
              className="SingleSelectFilter_button"
              onClick={evt => this.handleToggleChange(evt, label.value)}
              label={label.name}
              selected={value === label.value}
            >
              {label.name}
            </Button.Filter>
          ))}
      </div>
    );
  };
}

export default componentStateMapper(SingleSelectFilter);
