import { USER_PREFERENCE, USER_PREFERENCE_ERROR } from "../action-types";

export const initialState = {
  puaId: "",
  showBanner: true,
  productCode: ""
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
switch (type) {
  case USER_PREFERENCE:
    return {
      ...state,
      hasFetched: true,
      ...payload.data
    }
    case USER_PREFERENCE_ERROR:
    return {
      ...state,
      payload
    }
  default:
    return state;
}
};
