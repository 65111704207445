import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Type,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogTitleActions,
  DialogScroll,
  Spinner
} from "discover.medical.react-component-library";
import showdown from "showdown";
import { TermsContent } from "./TermsOfUse.styled";
import { fetchTermsDlg } from "../../store/terms-of-use/actions";

const TermsOfUseDialog = props => {
  const {
    isTermsDlgOpen,
    handleCloseDlg,
    policyText,
    isFetching,
    termsUrl,
    fetchTermsDlg
  } = props;

  useEffect(() => {
    if (isTermsDlgOpen) {
      fetchTermsDlg(termsUrl);
    }
  }, [fetchTermsDlg, isTermsDlgOpen, termsUrl]);

  return (
    <Dialog
      aria-labelledby="scrollable-dialog-title"
      maxWidth="md"
      isOpen={isTermsDlgOpen}
      onRequestClose={handleCloseDlg}
      data-testid="terms_and_conditions_dlg"
    >
      <DialogTitle>
        <Type element="h3" id="scrollable-dialog-title">
          Terms of Use
        </Type>
        <DialogTitleActions onRequestClose={handleCloseDlg} />
      </DialogTitle>
      <DialogScroll style={{ maxHeight: "78vh" }}>
        <Card borderYColor="gray300" borderYStyle="solid" borderYWidth="1px">
          <CardContent>
            <Type element="p">
              {isFetching ? (
                <Spinner size="sm" data-testid="termsDlg-spinner" />
              ) : (
                <TermsContent
                  dangerouslySetInnerHTML={{
                    __html: new showdown.Converter({}).makeHtml(policyText)
                  }}
                />
              )}
            </Type>
          </CardContent>
        </Card>
      </DialogScroll>
      <DialogActions>
        <Button
          id="close_button"
          type="button"
          onClick={handleCloseDlg}
          data-testid="terms_close_button"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTermsDlg
    },
    dispatch
  );

export const mapStateToProps = ({ termsDlg }) => {
  const { policyText } = termsDlg || {};
  const { isFetching } = termsDlg || {};
  return {
    policyText,
    isFetching
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUseDialog);
