import axiosInstance from "../../../services/axios-instance";
import { notifyStateStale } from "../../home/actions";
import {
  FAVORITE_TOOLS_REQUEST,
  FAVORITE_TOOLS_REQUEST_SUCCESS,
  FAVORITE_TOOLS_REQUEST_ERROR,
  FAVORITE_TOOL_ADD_SUCCESS,
  FAVORITE_TOOL_ADD_ERROR,
  FAVORITE_TOOL_REMOVE_SUCCESS,
  FAVORITE_TOOL_REMOVE_ERROR,
  TOOL_BROWSE_GET_FAV_TOOLS_SUCCESS,
  FAVORITE_TOOL_MODAL_TOGGLE,
  FAVORITE_TOOL_RESET_TOAST,
  FAVORITE_TOOL_RESET
} from "../../action-types";

const favoriteToolsUrl = "/api-tool-metadata/metadata/favourite-tool";

export const getFavoriteTools = () => async dispatch => {
  dispatch({
    type: FAVORITE_TOOLS_REQUEST
  });
  try {
    const { data } = await axiosInstance.get(favoriteToolsUrl, {
      headers: { Pragma: "no-cache" } // disable cache so ie11 will get latest content
    });
    dispatch({
      type: FAVORITE_TOOLS_REQUEST_SUCCESS,
      payload: data
    });
    dispatch({
      type: TOOL_BROWSE_GET_FAV_TOOLS_SUCCESS,
      payload: { tools: [...data.sharedTools, ...data.clinicalTools] }
    });
  } catch {
    dispatch({
      type: FAVORITE_TOOLS_REQUEST_ERROR
    });
  }
};

export const addFavoriteTool = tool => async dispatch => {
  try {
    delete tool.favoriteToolId;
    await axiosInstance.post(favoriteToolsUrl, tool);
    dispatch({ type: FAVORITE_TOOL_ADD_SUCCESS });
    dispatch(toggleModal(true));
  } catch (error) {
    const payload = { showToast: error.response?.data?.status === 409 };
    dispatch({ type: FAVORITE_TOOL_ADD_ERROR, payload });
  } finally {
    dispatch(getFavoriteTools());
    dispatch(notifyStateStale());
  }
};

export const removeFavoriteTool = ({ favoriteToolId }) => async dispatch => {
  try {
    await axiosInstance.delete(`${favoriteToolsUrl}/${favoriteToolId}`);
    dispatch({ type: FAVORITE_TOOL_REMOVE_SUCCESS });
  } catch {
    dispatch({ type: FAVORITE_TOOL_REMOVE_ERROR });
  } finally {
    dispatch(getFavoriteTools());
    dispatch(notifyStateStale());
  }
};

export const handleToastFinish = () => dispatch => {
  dispatch({ type: FAVORITE_TOOL_RESET_TOAST });
};

export const toggleModal = modalStatus => dispatch => {
  dispatch({ type: FAVORITE_TOOL_MODAL_TOGGLE, payload: { modalStatus } });
};

export const resetTools = () => dispatch => {
  dispatch({ type: FAVORITE_TOOL_RESET });
};
