import styled from "styled-components";
import { Anchor } from "discover.medical.react-component-library";

export const ToolsWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray400};
  margin-bottom: 1.125rem;
  margin-top: ${({ theme }) => theme.space.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.lg};
  }
`;

export const ViewAllButton = styled(Anchor)`
  font-size: ${({ theme }) => theme.font.size.xs};
  padding: 0.375rem 0.8125rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.md};
    padding: ${({ theme }) => `${theme.space.sm} ${theme.space.md}`};
  }
`;
