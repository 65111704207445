import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import tabComponentsRetriever from "../tabComponentsRetriever";
import { Button } from "discover.medical.react-component-library";
import "./_index.css";
import DynamicForm from "../../../content-components";

export class RiskGuidelinesTab extends Component {
  render() {
    const {
      isHiddenBtn,
      changeTab,
      index,
      riskControls,
      guidelinesControls
    } = this.props;
    return (
      <Fragment>
        <div
          className="RiskAndGuidelines"
          data-auto="tool-template-implementation-tabs-patientdata"
        >
          <section
            className="RiskAndGuidelines_col RiskAndGuidelines_col__risk"
            data-auto="your-risks-column"
          >
            <DynamicForm componentsList={riskControls} recalculate={true} />
            <h2 className="RiskAndGuidelines_title">Your Risk</h2>
          </section>
          <section
            className="RiskAndGuidelines_col RiskAndGuidelines_col__guide"
            data-auto="guidelines-recomendations-column"
          >
            <DynamicForm componentsList={guidelinesControls} />
            <h2 className="RiskAndGuidelines_title RiskAndGuidelines_title__guide">
              GUIDELINES RECOMMENDATIONS
            </h2>
          </section>
        </div>
        {!isHiddenBtn && (
          <div className="Button-wrap">
            <Button
              variant="primary"
              onClick={() => changeTab(index)}
              data-auto="continue-button"
            >
              Continue
            </Button>
          </div>
        )}
      </Fragment>
    );
  }
}

RiskGuidelinesTab.propTypes = {
  riskControls: PropTypes.array.isRequired,
  guidelinesControls: PropTypes.array.isRequired,
  changeTab: PropTypes.func.isRequired,
  isHiddenBtn: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  const riskControls = tabComponentsRetriever(state, "risk");
  const guidelinesControls = tabComponentsRetriever(state, "guideline");
  return { riskControls, guidelinesControls };
};

export default connect(mapStateToProps)(RiskGuidelinesTab);
