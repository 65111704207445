import React from "react";
import { Anchor } from "discover.medical.react-component-library";
import { epochConverter } from "../../../../../shared/util/epochConverter";
import { Disclaimer, Item, List, Date, Panel, MainBlock } from "./index.styled";

function ToolFooter({
  isSnapshotAvailable,
  snapshotCreatedAt,
  openReferencesModal,
  openAboutModal,
  modified
}) {
  const handleReferencesClick = e => {
    e.preventDefault();
    openReferencesModal();
  };

  const handleAboutClick = e => {
    e.preventDefault();
    openAboutModal();
  };

  return (
    <MainBlock>
      <Panel>
        <List>
          <Item>
            <Anchor
              onClick={handleReferencesClick}
              fontSize="sm"
              data-auto="tool-footer-references"
            >
              References
            </Anchor>
          </Item>

          <Item>
            <Anchor
              onClick={handleAboutClick}
              fontSize="sm"
              data-auto="tool-footer-about"
            >
              More About This Tool
            </Anchor>
          </Item>
        </List>

        <Date data-auto="tool-footer-date">
          {isSnapshotAvailable
            ? `Generated on: ${epochConverter(snapshotCreatedAt)}`
            : `Last Update: ${epochConverter(modified)}`}
        </Date>
      </Panel>

      <Disclaimer data-auto="tool-footer-disclaimer">
        {isSnapshotAvailable
          ? "If you have concerns that information might be out of date, please contact your healthcare provider."
          : "DynaMed Decisions’ Clinical Decision Tools are reviewed on an ongoing basis and updated to reflect the latest evidence."}
      </Disclaimer>
    </MainBlock>
  );
}

export default ToolFooter;
