const initialState = {
  hasFetched: false,
  isFetching: false,
  error: "",
  value: {},
  data: []
};
/// next line is excluded from a sonar because an order of variables is provided by redux
// prettier-ignore
export default (state = initialState, action) => { //NOSONAR
  switch (action.type) {
    case "AUTOCOMPLETE_REQUEST":
      return {
        ...initialState,
        term: action.payload,
        isFetching: true
      };
    case "AUTOCOMPLETE_SUCCESS":
      // Ignore response if it doesn't match the most recent request
      if (action.payload.term !== state.term) {
        return state;
      }
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        data: action.payload.data,
        error: ""
      };
    case "AUTOCOMPLETE_ERROR":
      return {
        ...state,
        hasFetched: true,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};
