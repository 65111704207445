import React, { useRef, useEffect } from "react";
import {
  Modal,
  Type,
  Button,
  Tooltip
} from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import {
  Title,
  Text,
  ListItem,
  ListStyled,
  Root,
  AccessLink
} from "./index.styled";
import SpinnerWithText from "../../../../../spinner-with-text/SpinnerWithText";
import OptionGridSnapshotError from "../option-grid-snapshot-error/OptionGridSnapshotError";
import useLinkCopy from "../hooks/useLinkCopy";
import {
  generateLinkClicked,
  generateLinkCopyClicked
} from "../../../../../../store/telemetry/actions/index";
import { useDispatch } from "react-redux";

function OptionGridLinkModal() {
  const {
    closeLinkModal,
    isLinkOpen,
    snapshotId,
    isSpinnerShown,
    snapshotProcessingInfo
  } = useOptionGridContext();
  const { isError } = snapshotProcessingInfo;
  const sharedLink = `${window.location.href}${
    window.location.search ? "&" : "?"
  }snapshotId=${snapshotId}`;

  const accessLinkRef = useRef(null);
  const { copy } = useLinkCopy();
  const dispatch = useDispatch();
  const handleCopyButtonClick = () => {
    copy(accessLinkRef.current);
    dispatch(generateLinkCopyClicked({ affordance: "patient_options" }));
  };
  useEffect(() => {
    if (isLinkOpen) {
      dispatch(generateLinkClicked({ affordance: "patient_options" }));
    }
  }, [dispatch, isLinkOpen]);

  return (
    <Modal
      v2
      borderRadius="sm"
      padding={true}
      onRequestClose={closeLinkModal}
      isOpen={isLinkOpen}
      className="OptionGridLinkModal"
      style={{ maxWidth: "41rem" }}
      data-auto="option-grid-link-modal"
    >
      {isSpinnerShown && !isError && (
        <SpinnerWithText>
          <b>Your link is being generated.</b> This may take a moment...
        </SpinnerWithText>
      )}

      {isSpinnerShown || isError || (
        <Root data-auto="option-grid-link-modal-root">
          <Title
            element="h3"
            styledAs="h2"
            color="primary"
            fontFamily="secondary"
          >
            Link for Patient
          </Title>

          <Text element="i">The link below:</Text>

          <ListStyled>
            <ListItem>
              <Type element="strong">Will work for anyone</Type>
            </ListItem>
            <ListItem>
              <Type element="strong" display="inline">
                Will show content as is&nbsp;
              </Type>
              and not include any updates or changes available on the live site.
            </ListItem>
            <ListItem>
              <Type element="strong" display="inline">
                Will not show any options that you have hidden&nbsp;
              </Type>
              (although these will be available for recipients to “unhide”).
            </ListItem>
          </ListStyled>

          <AccessLink
            data-auto="option-grid-link-modal-field"
            forwardedRef={accessLinkRef}
          >
            <Type element="p">{sharedLink}</Type>
          </AccessLink>

          <Tooltip
            border={false}
            content="Link copied to clipboard"
            hasArrow={true}
            position="center"
            variant="dark"
            toggle="click"
            contentSize="xs"
            placement="right"
            trigger={
              <Button
                variant="primary"
                onClick={handleCopyButtonClick}
                data-auto="option-grid-link-modal-copy"
              >
                Copy
              </Button>
            }
          />
        </Root>
      )}

      {isError && (
        <OptionGridSnapshotError
          onClick={closeLinkModal}
          message="We're sorry! We were unable to generate a link. Please try again later."
        />
      )}
    </Modal>
  );
}

export default OptionGridLinkModal;
