import { useCallback } from "react";

export default function useCopy() {
  const copy = useCallback(node => {
    window.getSelection().removeAllRanges();
    const range = document.createRange();
    range.selectNode(node);
    window.getSelection().addRange(range);
    document.execCommand("copy");
  }, []);

  return { copy };
}
