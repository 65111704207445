import BasicComponent from "./basic";
import NumericInput from "./numericinput";
import Legend from "./legend";
import Subheading from "./subheading";
import Heading from "./heading";
import Text from "./text";
import RadioGroup from "./radiogroup";
import Toggle from "./toggle";
import Dropdown from "./dropdown";
import CalculatedText from "./calculated-text";
import CalculatedTextHidden from "./calculated-text-hidden";
import NumericHeight from "./numeric-height";
import NumericHeightWeight from "./numeric-height-weight";
import NumericMeasureWithLabel from "./numeric-measure-with-label";
import NumericPackYears from "./numeric-pack-years";
import NumericSmoking from "./numeric-smoking";
import NumericSmokingWithoutLabels from "./numeric-smoking-without-labels";
import MultiselectFilter from "./multiselect-filter";
import MultiselectToggle from "./multiselect-toggle";
import SingleSelectFilter from "./singleselect-filter";
import IconArray from "./icon-array";
import Reference from "./reference";
import Definition from "./definition";
import Group from "./group";
import Pill from "./pill";
import HorizontalGroup from "./horizontal-group";
import SingleSelectToggleWithLabels from "./single-select-toggle-with-labels";
import PatientDataSummaryStatement from "./patient-data-summary-statement";
import PatientDataSummaryStatementNested from "./patient-data-summary-statement-nested";
import BodyTitle from "./body-title/BodyTitle";
import ChoiceInput from "./choice-input/ChoiceInput";
import IconArrayNew from "../templates/default-new/components/content-components/icon-array/IconArray";
import LegendNew from "../templates/default-new/components/content-components/legend/Legend";
import NumericMeasure from "./numeric-measure";
import DecisionInput from "./decision-input";
import GuidelineReference from "./guideline_reference";
import GuidanceBodyStatement from "./guidance-body-statement";
import SectionTitle from "./section-title";
import EligibilityStatement from "./eligibility-statement";
import PatientDataSummaryTitle from "./patient-data-summary-title";

const componentsMap = {
  basic: BasicComponent,
  group: Group,
  horizontal_group: HorizontalGroup,
  "numeric.basic": NumericInput,
  "numeric.height": NumericHeight,
  "numeric.weight": NumericMeasureWithLabel,
  "numeric.heightweight": NumericHeightWeight,
  "numeric.egfr": CalculatedText,
  "numeric.startsmoking": NumericSmoking,
  "numeric.quitsmoking": NumericSmokingWithoutLabels,
  "numeric.packsperday": NumericPackYears,
  "list.radiogroup": RadioGroup,
  "singleselect.dropdown": Dropdown,
  "singleselect.toggle": Toggle,
  "singleselect.filter": SingleSelectFilter,
  "singleselect.radiogroup": RadioGroup,
  "singleselect.scale": SingleSelectToggleWithLabels,
  "multiselect.toggle": MultiselectToggle,
  "multiselect.filter": MultiselectFilter,
  "text.subheading": Subheading,
  "text.definition": Definition,
  "text.heading": Heading,
  "text.paragraph": Text,
  "text.legend": Legend,
  "text.reference": Reference,
  "numeric.bmi": CalculatedText,
  "numeric.pkyrs": CalculatedTextHidden,
  "numeric.measure": NumericMeasure,
  "visual.iconarray": IconArray,
  // Sonar rules do not allow adding quotes to the below ones.
  chart_note_all: Text,
  chart_note_sdm: Text,
  chart_note_cc: Text,
  assessment_result_statement: Pill,
  patient_data_summary: Group,
  patient_data_summary_statement: PatientDataSummaryStatement,
  "patient_data_summary_statement-nested": PatientDataSummaryStatementNested,
  guidance_body_statement: GuidanceBodyStatement,
  guidance_body_title: BodyTitle,
  decision_warning: Text,
  population_summary: Subheading,
  legend_title: Definition,
  text: Text,
  choice_input: ChoiceInput,
  icon_array: IconArrayNew, // temporary Fix to use the new IconArray component
  legend_statement: LegendNew, //temporary fix to use the new Legend component
  assessment_result_title: BodyTitle,
  assessment_result: Group,
  decision_input: DecisionInput,
  guideline_reference: GuidelineReference,
  risk_section_title: SectionTitle,
  guidance_section_title: SectionTitle,
  patient_data_summary_title: PatientDataSummaryTitle,
  eligibility_statement: EligibilityStatement
};

export const getComponent = style => {
  return componentsMap[style] || componentsMap["basic"];
};

export const isComponentExist = style => {
  return !!componentsMap[style];
};

export default getComponent;
