import find from "lodash/find";
import get from "lodash/get";

export const getLabelByValue = (view, value) => {
  const options = get(view, "attributes.fieldInfo.labels", []);
  if (Array.isArray(value)) {
    return options
      .filter(option => value.includes(option.value))
      .map(option => option.name);
  } else {
    const current = find(options, option => option.value === value.toString());
    return current ? current.name : "";
  }
};
