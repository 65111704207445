import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";

export const Header = styled(Flex)`
  position: relative;
  margin-bottom: ${({ theme }) => theme.space.xs};
`;

export const HeaderTools = styled(Flex)`
  margin-left: ${({ theme }) => theme.space.md};
`;
