import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";

export const ToolsListItemIconHolder = styled(Flex)`
  margin-right: 0.625rem;

  &:last-of-type {
    margin-right: 0;
  }

  & [data-auto="popper-trigger"] {
    display: flex;
  }
`;
