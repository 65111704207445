export const INIT_OPTIONS_API_KEY_EXPECTED =
  "init(options): expected object with apiKey prop";
export const SET_DEVICEID_STRING_EXPECTED =
  "setUserDevice(deviceId): expected string for deviceId";
export const SET_USERID_STRING_EXPECTED =
  "setUser(userId): expected string or null for userId";
export const SET_USERINFO_OBJECT_EXPECTED =
  "setUserInfo(properties): expected object for properties";
export const SET_GROUPINFO_GROUP_TYPE_STRING_EXPECTED =
  "setGroupInfo(groupType, groupName): expected string for groupType";
export const SET_GROUPINFO_GROUP_NAME_STRING_EXPECTED =
  "setGroupInfo(groupType, groupName): expected string for groupName";
export const LOG_EVENT_TYPE_STRING_EXPECTED =
  "log(eventType, eventProperties): expected string for eventType";
export const LOG_EVENT_PROPERTIES_OBJECT_EXPECTED =
  "log(eventType, eventProperties): expected object for eventProperties";

export const EVENT_TYPE_SEARCH_ITEM_CLICKED = "SEARCH_ITEM_CLICKED";
export const EVENT_TYPE_SEARCH_SUBITEM_CLICKED = "SEARCH_SUBITEM_CLICKED";
