/**
 * This object maps the icon code from the content to the icon name in the shared library.
 *
 * Any icon codes that do not have a corresponding icon name have not been created yet (they are
 * not MVP). They may be included in the object to acknowledge that these icons will eventually be
 * used. Unmapped (or code with no icon names) should use a default icon that indicates none is
 * available.
 *
 * @link https://confluence.epnet.com/display/ART/Icons
 * @link https://ebscois.github.io/discover.medical.react-component-library/docs/components/icon/#all-icons
 */

export default {
  ie1: "infographicBlock1",
  ie1_t1_iese0: "infographicBlock9",
  ie1_t2_iese0: "infographicBlock9",
  ie3: "infographicBlock2",
  ie3_t1_ie1: "infographicBlock3",
  ie3_t1_iese0: "infographicBlock8",
  ie3_t2_iese0: "infographicBlock8",
  iese0: "infographicBlock7",
  iese0_t1_ie1: "infographicBlock4",
  iese0_t1_se2: "infographicBlock5",
  iese0_t2_se1: "infographicBlock11",
  iese0_t2_se2: "infographicBlock5",
  iese0_t2_se3: "infographicBlock12",
  iese0_t1_se1: "infographicBlock11",
  iese0_t1_se3: "infographicBlock12",
  se1: "infographicBlock10",
  se2: "infographicBlock6"
};
