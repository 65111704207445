import { USER_GET_SUCCESS, USER_GET_ERROR } from "../action-types";

export const initialState = {
  hasFetched: false,
  organization: "",
  userName: "",
  affiliationId: "",
  userState: {
    organizational: false,
    personalized: false,
    subscriptionActive: false
  },
  displaySignIn: true,
  messaging: null,
  linkURLs: null
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case USER_GET_SUCCESS:
    case USER_GET_ERROR:
      return {
        ...state,
        hasFetched: true,
        ...payload
      }
    default:
      return state;
  }
};
