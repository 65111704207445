import React from "react";

import { Block, SpinnerText, SpinnerElement } from "./index.styled";

export const SpinnerWithText = ({ children }) => {
  return (
    <Block data-auto="spinner-with-text-root">
      <SpinnerElement size="md" data-auto="spinner" />
      <SpinnerText data-auto="text">{children}</SpinnerText>
    </Block>
  );
};

export default SpinnerWithText;
