import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import tabComponentsRetriever from "../tabComponentsRetriever";
import { Button } from "discover.medical.react-component-library";
import DynamicForm from "../../../content-components";
import "./_index.css";

export class PopulationViewTab extends Component {
  groupLegends = list => {
    let group = -1;
    let groups = [];
    let sub = false;

    list.forEach(item => {
      if (item.type === "text.subheading") {
        if (!sub) {
          group = group + 1;
          sub = true;

          groups[group] = [];
        }
      } else if (item.type === "text.legend") {
        sub = false;
      }

      /*
       * According to Aaron Greenblatt, a group of legends will ALWAYS be preceded by one or more
       * subheadings for the legends. If we have an item before a subheading, there is a content
       * problem.
       */
      if (group >= 0) {
        groups[group].push(item);
      }
    });

    return groups;
  };

  splitControls = list => {
    let offset = -1;
    let split = {
      iconArray: [],
      iconControls: [],
      iconHeading: [],
      legends: []
    };

    /*
     * How population controls are split up:
     *
     * - Process in order until `visual.iconarray` found, then break loop.
     *     - Put `text.subheading` into `split.iconHeading`.
     *     - Put any control not `text.subheading` or `visual.iconarray` into `split.iconControls`.
     *     - If `visual.iconarray` found,
     *         - Add `visual.iconarray` to `split.iconArray`
     *         - Remember current index
     *         - Break the loop
     * - Take all items after `visual.iconarray` (remembered index) and add to `split.legends`.
     * - Return `split`.
     */

    listLoop: for (const [index, item] of list.entries()) {
      switch (item.type) {
        case "text.subheading":
          split.iconHeading.push(item);

          break;

        case "visual.iconarray":
          split.iconArray = [item];
          offset = index;

          break listLoop;

        default:
          split.iconControls.push(item);
      }
    }

    split.legends = list.slice(offset + 1);

    return split;
  };

  render() {
    const {
      populationHeader,
      population,
      index,
      changeTab,
      isHiddenBtn
    } = this.props;
    const {
      iconArray,
      iconControls,
      iconHeading,
      legends
    } = this.splitControls(population);
    return (
      <Fragment>
        <div
          data-auto="tool-template-implementation-tabs-populationview"
          className="PopulationView"
        >
          <div className="PopulationView_section PopulationView_header">
            <DynamicForm componentsList={populationHeader} recalculate={true} />
          </div>

          <div className="PopulationView_content">
            <div className="PopulationView_section PopulationView_iconHeading">
              <DynamicForm componentsList={iconHeading} />
            </div>

            <div className="PopulationView_section PopulationView_iconArrayWrap">
              <DynamicForm componentsList={iconArray} />
              <DynamicForm componentsList={iconControls} recalculate={true} />
            </div>

            <div className="PopulationView_section PopulationView_legends">
              {this.groupLegends(legends).map(group => (
                <DynamicForm componentsList={group} />
              ))}
            </div>
            {!isHiddenBtn && (
              <div className="Button-wrap">
                <Button
                  className="PopulationView_button"
                  variant="primary"
                  onClick={() => changeTab(index)}
                  data-auto="continue-button"
                >
                  Continue
                </Button>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

PopulationViewTab.propTypes = {
  populationHeader: PropTypes.array.isRequired,
  population: PropTypes.array.isRequired,
  isHiddenBtn: PropTypes.bool.isRequired,
  changeTab: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  populationHeader: tabComponentsRetriever(state, "populationHeader"),
  population: tabComponentsRetriever(state, "population")
});

export default connect(mapStateToProps)(PopulationViewTab);
