import React from "react";
import PropTypes from "prop-types";
import { Flex, Icon, Type } from "discover.medical.react-component-library";

const PatientDataSummaryLabelMobile = ({ onClick, iconGlyph, label }) => (
  <Flex container onClick={onClick}>
    <Icon
      icon={{
        glyph: iconGlyph,
        size: "sm",
        color: "link"
      }}
      background={{ clickable: true }}
    />
    <Type element="p" fontSize="sm" color="secondary.base">
      {label}
    </Type>
  </Flex>
);

export default PatientDataSummaryLabelMobile;

PatientDataSummaryLabelMobile.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconGlyph: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
