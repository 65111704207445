import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const StyledHeading = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.sm};
`;

export const StyledDescription = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: ${({ theme }) => theme.space.lg};
  }
`;
