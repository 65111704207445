import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import optionGridLogo from "../../../../shared/images/option-grid-logo.svg";
import { setOptionGridOutcomeData } from "../../../../store/fields/actions";
import { patientOptionsViewed } from "../../../../store/telemetry/actions";
import OptionGrid from "./option-grid";
import "./_index.css";
import get from "lodash/get";
import { isMHDDomain } from "../../../../helpers/domain-manager";
import { Header, Controls, Tools } from "./index.styled";

export const OptionGridTab = ({
  showChartNote = true,
  showFavButton = true,
  className
}) => {
  const location = useSelector(state => get(state, "app.location", ""));
  const toolType = useSelector(state =>
    get(state, "tool.metadata.toolType", "")
  );
  const dispatch = useDispatch();
  const { isSnapshotAvailable } = useSelector(
    ({ snapshot }) => snapshot.snapshotData
  );
  const snapshotId = new URLSearchParams(window.location.search).get(
    "snapshotId"
  );
  const toolTabs = useSelector(({ tool }) =>
    get(tool, "controls.toolTabs", [])
  );
  const optionGridOptions = useSelector(({ optionGrid }) =>
    get(optionGrid, "options", [])
  );

  useEffect(() => {
    if (
      !isSnapshotAvailable &&
      toolTabs.includes("optionGrid") &&
      optionGridOptions.length
    ) {
      if (toolType !== 1) {
        dispatch(setOptionGridOutcomeData());
      }
      dispatch(patientOptionsViewed());
    }
  }, [isSnapshotAvailable, toolTabs, optionGridOptions, dispatch, toolType]);

  const isMHD = isMHDDomain(location);

  return (
    <div
      className={`${className} OptionGridTab `}
      data-auto="tool-template-implementation-tabs-optiongrid"
    >
      <OptionGrid
        header={
          <OptionGrid.Header>
            <Controls>
              <OptionGrid.OptionSelector />
              <OptionGrid.ViewSelector />
            </Controls>
            <Tools>
              {!snapshotId && (
                <img
                  className="OptionGridHeader_logo"
                  src={optionGridLogo}
                  alt="Option Grid"
                />
              )}
              <OptionGrid.ActionPanel
                showFavButton={showFavButton}
                showChartNote={showChartNote && !isMHD}
              />
            </Tools>
          </OptionGrid.Header>
        }
        content={
          <Fragment>
            <OptionGrid.View />
            <OptionGrid.AgreeModal />
            <OptionGrid.QrCodeModal />
            <OptionGrid.LinkModal />
            <OptionGrid.WarningModal />
            <OptionGrid.PdfViewer />
            <OptionGrid.ChartNote />
            <OptionGrid.InfoPanel />
            <OptionGrid.Glossary />
            {isMHD && (
              <>
                <OptionGrid.Footer />
                <OptionGrid.References />
                <OptionGrid.About />
              </>
            )}
          </Fragment>
        }
      ></OptionGrid>
    </div>
  );
};

export default OptionGridTab;
