import React from "react";
import PropTypes from "prop-types";
import ListView from "./list-view";
import AccordionView from "./accordion-view";
import { useOptionGridContext } from "../../OptionGridContext";
import { VIEWPORT_SMALL } from "../../../../../../../helpers/aliases";
import "./_index.css";
import BackgroundInfoButton from "../../option-grid-info-panel/background-info-button";
import { backgroundTextCheckerList } from "../../option-grid-info-panel/background-util";

export const getOptionName = (options, key) => {
  const option = options.find(({ optionKey }) => optionKey === key);
  return option && option.name ? option.name : "";
};

export const OptionGridList = props => {
  const { options, questions, windowSize } = props;
  const optionGridContext = useOptionGridContext();
  const isMobile = windowSize === VIEWPORT_SMALL;

  return (
    <div data-auto="optiongrid-list" className="OptionGridList">
      <div className="OptionGridList_header">
        Patient Questions
        {backgroundTextCheckerList(questions, options) && (
          <BackgroundInfoButton
            onClick={() => optionGridContext.openInfoPanel()}
            iconColor="white"
          />
        )}
      </div>
      {isMobile ? (
        <AccordionView getOptionName={getOptionName} {...props} />
      ) : (
        <ListView getOptionName={getOptionName} {...props} />
      )}
    </div>
  );
};

OptionGridList.propTypes = {
  questions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  fields: PropTypes.object
};

OptionGridList.defaultProps = {
  selected: [],
  fields: {}
};

export default OptionGridList;
