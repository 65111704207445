import React from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import optionGridLogo from "../../../../../../shared/images/option-grid-logo.svg";
import { tabComponentsRetriever } from "../../../tabComponentsRetriever";
import { Button, Type } from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import "./_index.css";
import { Header, Content, Logo, Toolbar } from "./index.styled";
import { VIEWPORT_SMALL } from "../../../../../../helpers/aliases";
import { useFeature } from "../../../../../../helpers/amplitude";
import pdfJson from "./utils/pdfJson.json";

const useShouldShowStaticPDFLink = contentName => {
  const featureFlag =
    contentName in pdfJson ? pdfJson[contentName].featureFlag : null;
  const [isFeatureEnabled] = useFeature(featureFlag);

  return isFeatureEnabled ? pdfJson[contentName] : null;
};

export const OptionGridHeader = props => {
  const { printPDF, contentName } = useOptionGridContext();
  const toolType = useSelector(state => state.tool?.metadata?.toolType);

  const snapshotId = new URLSearchParams(window.location.search).get(
    "snapshotId"
  );
  const { componentsList, children, windowSize } = props;

  const pdfMetadata = useShouldShowStaticPDFLink(contentName);

  return (
    <>
      <div
        className={`${props.className} OptionGridHeader`}
        data-auto="option-grid-header-desktop"
      >
        {(snapshotId || toolType === 1) && (
          <Header>
            {snapshotId && (
              <Logo
                className="OptionGridHeader_logo"
                src={optionGridLogo}
                alt="Option Grid"
              />
            )}
            <Content componentsList={componentsList} />
          </Header>
        )}
        {pdfMetadata && (
          <div className="OptionGridHeader_staticlink">
            {pdfMetadata.labelText && (
              <Type
                className="OptionGridHeader_staticlinktext"
                element="p"
                fontFamily="primary"
                fontWeight="semibold"
              >
                {pdfMetadata.labelText}
              </Type>
            )}
            {pdfMetadata.englishFilename && (
              <Button
                data-auto="option-grid-static-pdf-button-english"
                className="OptionGridHeader_staticlinkbutton"
                id="option-grid-static-pdf-button-english"
                kind="anchor"
                size={windowSize === VIEWPORT_SMALL ? "sm" : "md"}
                onClick={() =>
                  printPDF(false, true, pdfMetadata.englishFilename)
                }
              >
                English PDF
              </Button>
            )}
            {pdfMetadata.spanishFilename && (
              <Button
                data-auto="option-grid-static-pdf-button-spanish"
                className="OptionGridHeader_staticlinkbutton"
                id="option-grid-static-pdf-button-spanish"
                kind="anchor"
                size={windowSize === VIEWPORT_SMALL ? "sm" : "md"}
                onClick={() =>
                  printPDF(false, true, pdfMetadata.spanishFilename)
                }
              >
                Spanish PDF
              </Button>
            )}
          </div>
        )}
        <Toolbar container>{children}</Toolbar>
      </div>
    </>
  );
};

OptionGridHeader.propTypes = {
  componentsList: PropTypes.array.isRequired,
  windowSize: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  componentsList: tabComponentsRetriever(state, "optiongridHeader"),
  windowSize: state.sharedData.windowSize
});

export default connect(mapStateToProps)(OptionGridHeader);
