import React from "react";
import { Type } from "discover.medical.react-component-library";
import componentStateMapper from "../componentsStateMapper";
import BasicText from "../basic-text";

export class Heading extends BasicText {
  renderCompositeComponent = () => {
    return (
      <Type
        className="Heading"
        element="h1"
        styledAs="h2"
        color="gray800"
        fontFamily="secondary"
        fontWeight="normal"
        marginBottom="0"
      >
        {this.getBaseContent()}
      </Type>
    );
  };
}

export default componentStateMapper(Heading);
