export const isScrolled = () => {
  let scrollPos;
  if (typeof document !== "undefined") {
    const scrollTop = document.scrollingElement
      ? document.scrollingElement.scrollTop
      : document.documentElement.scrollTop;
    scrollPos = scrollTop;
  } else {
    scrollPos = 0;
  }
  return scrollPos > 50;
};
