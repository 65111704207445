import chunk from "lodash/chunk";

export const getIconArrayControlName = list => {
  for (let item of list) {
    if (item.type === "visual.iconarray") {
      return item.name;
    }
  }
};

export const splitControls = list => {
  let split = {
    iconArray: [],
    iconControls: [],
    iconHeading: [],
    legends: { column_one: [], column_two: [] }
  };
  let legends = [];
  list.forEach(item => {
    switch (item.type) {
      case "text.subheading":
        split.iconHeading.push(item);
        break;
      case "visual.iconarray":
        split.iconArray = [item];
        break;
      case "group":
        legends.push(item);
        break;
      default:
        split.iconControls.push(item);
    }
  });
  legends = chunk(legends, Math.ceil(legends.length / 2));
  split.legends.column_one = legends[0] || [];
  split.legends.column_two = legends[1] || [];
  return split;
};

export const splitHeader = list => {
  const split = { treatmentOptions: [], headerControls: [], warning_note: [] };
  list.forEach(item => {
    switch (item.style) {
      case "decision_warning":
        split.warning_note.push(item);
        break;
      case "decision_input":
        split.headerControls.push(item);
        break;
      default:
        split.treatmentOptions.push(item);
    }
  });
  return split;
};
