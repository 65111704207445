import {
  SEARCH_TOOLS_REQUEST,
  SEARCH_TOOLS_SUCCESS,
  SEARCH_TOOLS_ERROR
} from "../../action-types";

import axiosInstance from "../../../services/axios-instance";

export const searchToolsRequest = () => ({
  type: SEARCH_TOOLS_REQUEST
});

export const searchToolsError = () => ({
  type: SEARCH_TOOLS_ERROR
});

export const searchToolsSuccess = payload => ({
  type: SEARCH_TOOLS_SUCCESS,
  payload
});

export const searchTools = query => async (dispatch, getState) => {
  const { paths } = getState();

  let searchUrl = `${paths["api-decisions-experience"]}/api/decisions/v1/search?term=${query}`;

  dispatch(searchToolsRequest());
  try {
    const { data } = await axiosInstance.get(searchUrl);
    dispatch(searchToolsSuccess(data.items ? data.items : []));
  } catch (error) {
    dispatch(searchToolsError());
  }
};
