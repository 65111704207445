/* istanbul ignore file */
import { combineReducers } from "redux";

import intl from "./intl";
import tool from "./tool";
import toolBrowse from "./tool-browse";
import fields from "./fields";
import optionGrid from "./option-grid";
import search from "./search";
import searchAutoSuggest from "./search-autosuggest";
import sharedData from "./shared-data";
import snapshot from "./snapshot";
import user from "./user";
import telemetry from "./telemetry";
import ssr from "./ssr";
import favoriteTools from "./favorite-tools";
import userPreference from "./user-preference";
import patientEncounterReport from "./patient-encounter-report";
import termsDlg from "./terms-of-use";
import home from "./home";
import toolInfo from "./tool-info";

export default combineReducers({
  intl,
  paths(state = {}) {
    return state;
  },
  app(state = {}) {
    return state;
  },
  ssr,
  tool,
  toolBrowse,
  fields,
  optionGrid,
  sharedData,
  search,
  searchAutoSuggest,
  snapshot,
  user,
  telemetry,
  favoriteTools,
  userPreference,
  patientEncounterReport,
  termsDlg,
  home,
  toolInfo
});
