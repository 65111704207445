import React, { useEffect, useState } from "react";
import PageLayout from "../../templates/page";
import { useSelector } from "react-redux";
import NotAvailable from "../not-available";
import PatientEncounterDashboard from "../../patient-encounter-dashboard";
import AccessInfo from "../access-info";
import "./style.css";

export const PatientEncounterPage = () => {
  const user = useSelector(state => state.user);
  const [isAllowed, setIsAllowed] = useState(
    user?.access?.patientEncounterReport
  );
  const errorCode = useSelector(
    state => state.patientEncounterReport?.errorCode
  );

  useEffect(() => {
    setIsAllowed(user?.access?.patientEncounterReport);
  }, [user]);

  useEffect(() => {
    if ([401, 403].includes(errorCode)) {
      setIsAllowed(false);
    }
  }, [errorCode]);

  return (
    <PageLayout
      dataAuto="patient-encounter-page"
      shouldRedirect={true}
      className="PatientEncounterPage"
    >
      {user.hasFetched && !isAllowed && (
        <NotAvailable>
          <AccessInfo />
        </NotAvailable>
      )}
      {user.hasFetched && isAllowed && <PatientEncounterDashboard />}
    </PageLayout>
  );
};
