import React from "react";
import PageLayout from "../../templates/page";
import EditorialLeadershipContent from "./content/editorialLeadership.json";
import { Type, Anchor } from "discover.medical.react-component-library";
import {
  BackgroundInformation,
  EditorialInformation,
  EditorialPersonDetails
} from "./styled";

const EditorialLeadership = () => {
  const {
    meetOurExpertsLink,
    editorialLeadership
  } = EditorialLeadershipContent;

  return (
    <PageLayout>
      <Type element="h1" fontFamily="secondary">
        Editorial Leadership
      </Type>
      <BackgroundInformation element="p" marginBottom="lg">
        {`For detailed background information, please go to `}
        <Anchor
          href={meetOurExpertsLink}
          data-testid="meet-our-experts-link"
          rel="noopener noreferrer"
          target="_blank"
        >
          Meet Our Experts
        </Anchor>
        .
      </BackgroundInformation>
      {editorialLeadership.map((info, index) => (
        <EditorialInformation key={index}>
          <Type
            element="h2"
            fontFamily="secondary"
            styledAs="h4"
            marginBottom="sm"
            marginTop={!meetOurExpertsLink ? "md" : ""}
          >
            {info.category}
          </Type>
          {info.people && (
            <ul>
              {info.people.map((person, personIndex) => (
                <EditorialPersonDetails element="li" key={personIndex}>
                  <Type element="span" fontWeight="bold">
                    {person.name}
                  </Type>
                  <Type element="span">, {person.credentials}</Type>
                </EditorialPersonDetails>
              ))}
            </ul>
          )}
        </EditorialInformation>
      ))}
    </PageLayout>
  );
};

export default EditorialLeadership;
