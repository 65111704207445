import styled, { css } from "styled-components";

export const StyledLogo = styled.img`
  ${props =>
    props.brand === "MHD"
      ? css`
          width: 11.25rem;

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.sm}) {
            width: 20.25rem;
          }

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.md}) {
            width: 23.25rem;
          }
        `
      : css`
          width: 11.25rem;

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.sm}) {
            width: 16.5rem;
          }

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.md}) {
            width: 17.25rem;
          }
        `}
`;
