import React from "react";
import parser from "../../../../../../helpers/parser";
import { Flex } from "discover.medical.react-component-library";

import {
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps
} from "../../../../../content-components/componentsStateMapper";

import get from "lodash/get";
import BasicText from "../../../../../content-components/basic-text";
import iconMapPerson from "../../../../../content-components/shared/icon-map-person";
import iconMapBlock from "../../../../../content-components/shared/icon-map-block";
import {
  StyledLegend,
  LegendText,
  LegendCount,
  LegendInfoButton
} from "./index.styled";
import LegendIcon from "../legend-icon";
import { connect } from "react-redux";

export class Legend extends BasicText {
  renderCompositeComponent = () => {
    const icon = this.getIconName();
    return (
      <StyledLegend>
        {icon && <LegendIcon glyph={this.getIconName()} />}
        <Flex container>
          <LegendCount>{this.getFieldValue()}</LegendCount>
          <LegendText>{this.getBaseContent()}</LegendText>
          <LegendInfoButton text={this.getBackgroundText()} />
        </Flex>
      </StyledLegend>
    );
  };

  getPopulationExpression = allControls => {
    let keys = Object.keys(allControls);
    for (let key of keys) {
      const control = allControls[key];
      if (control.type === "visual.iconarray") {
        return get(control, ["attributes", "parameters", "population"]);
      }
    }
  };

  getIconName = () => {
    const { view, allControls } = this.props;
    const iconType = get(view, "attributes.fieldInfo.iconType", "");
    const populationPath = this.getPopulationExpression(allControls);
    const populationValue = `${parser.evaluate(
      populationPath,
      this.props.fields
    )}`;
    const iconMap = populationValue === "1000" ? iconMapBlock : iconMapPerson;
    return get(iconMap, iconType, "");
  };
}

export const mapStateToProps = (state, ownProps) => {
  const baseProps = mapStateToPropsBase(state, ownProps);
  const allControls = get(state, ["tool", "controls", "_all"]);
  return {
    ...baseProps,
    allControls
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Legend);
