import React from "react";
import { Icon } from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import { ActionButton, ActionTooltip } from "./index.styled";
import ToolActions from "../../../tool-actions";
import { isMHDDomain } from "../../../../../../helpers/domain-manager";
import get from "lodash/get";
import { useSelector } from "react-redux";

const OptionGridActionPanel = ({ showChartNote, showFavButton }) => {
  const {
    canSnapshot,
    printPDF,
    openChartNoteModal,
    openLinkModal,
    openQrCodeModal,
    selectedOptionsList,
    isSnapshotAvailable
  } = useOptionGridContext();
  const location = useSelector(state => get(state, "app.location", ""));

  if (isSnapshotAvailable) {
    return null;
  }

  return (
    <div data-auto="option-grid-action-panel">
      {canSnapshot && (
        <>
          <ActionTooltip
            border={false}
            content="QR Code"
            contentSize="md"
            hasArrow={true}
            variant="light"
            trigger={
              <ActionButton
                data-auto="option-grid-patient-qr-code-button"
                onClick={openQrCodeModal}
                kind="outlined"
                size="iconOnly"
                variant="primary"
                aria-label="QR Code"
              >
                <Icon icon={{ glyph: "qr_code", size: "md" }} glyph="true" />
              </ActionButton>
            }
          />
          <ActionTooltip
            border={false}
            content="Link for Patient"
            contentSize="md"
            hasArrow={true}
            variant="light"
            trigger={
              <ActionButton
                data-auto="option-grid-patient-link-button"
                onClick={openLinkModal}
                kind="outlined"
                size="iconOnly"
                variant="primary"
                aria-label="Link for Patient"
              >
                <Icon icon={{ glyph: "link", size: "md" }} />
              </ActionButton>
            }
          />
          <ActionTooltip
            border={false}
            content="PDF"
            contentSize="md"
            hasArrow={true}
            variant="light"
            trigger={
              <ActionButton
                data-auto="option-grid-view-pdf-button"
                onClick={() => printPDF(false)}
                kind="outlined"
                size="iconOnly"
                variant="primary"
                aria-label="View PDF"
                disabled={
                  !selectedOptionsList || selectedOptionsList.length === 0
                }
              >
                <Icon icon={{ glyph: "picture_as_pdf", size: "md" }} />
              </ActionButton>
            }
          />
        </>
      )}
      {showChartNote && (
        <ActionTooltip
          border={false}
          content="Patient Chart Note"
          contentSize="md"
          hasArrow={true}
          variant="light"
          trigger={
            <ActionButton
              data-auto="option-grid-chart-note-button"
              onClick={openChartNoteModal}
              kind="outlined"
              size="iconOnly"
              variant="primary"
              aria-label="Patient Chart Note"
            >
              <Icon icon={{ glyph: "assignment", size: "md" }} />
            </ActionButton>
          }
        />
      )}
      {!isMHDDomain(location) && showFavButton && <ToolActions />}
    </div>
  );
};

OptionGridActionPanel.propTypes = {};

OptionGridActionPanel.defaultProps = {
  showChartNote: true,
  showFavButton: true
};

export default OptionGridActionPanel;
