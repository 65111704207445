import styled from "styled-components";
import { Button } from "discover.medical.react-component-library";

export const StyledButton = styled(Button)`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.gray800 : theme.colors.primary.base} !important;
  border-bottom: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.gray800 : "transparent"};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme, selected }) =>
    selected ? theme.font.weight.bold : theme.font.weight.normal};
  border-radius: 0;
  padding: 0 0 ${({ theme }) => theme.space.xs} 0;
  margin: 0 ${({ theme }) => theme.space.xl} ${({ theme }) => theme.space.sm} 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &&&&:hover {
    background: none;
  }
`;
