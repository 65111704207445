import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";
import callToActionImg from "../../../../../../shared/images/call-to-action.jpg";

export const SectionWrapper = styled.section`
  margin-top: ${({ theme }) => theme.space.xl};
`;

export const SectionTitle = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.sm};
  }
`;

export const SectionContent = styled.div`
  border: 1px solid #d9dadb;
  display: flex;
  margin-bottom: ${({ theme }) => theme.space.md};
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 11rem;
    flex-direction: row;
  }
`;

export const SectionContentTextWrapper = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 2;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 67%;
    order: 1;
  }
`;

export const SectionContentText = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: ${({ theme }) => theme.space.lg};
  }
`;
export const SectionContentBody = styled.span`
  font-size: ${props => props.theme.font.size.sm};
  font-family: ${props => props.theme.font.family.base};
  margin-bottom: ${({ theme }) => theme.space.md};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: ${({ theme }) => theme.space.lg};
  }
`;

export const SectionContentImage = styled.div`
  background: url(${callToActionImg}) center center;
  background-size: cover;
  min-height: 8.25rem;
  order: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 33%;
    order: 2;
    min-height: 100%;
  }
`;
