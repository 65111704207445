import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useOptionGridContext } from "../OptionGridContext";
import OptionGridModal from "../option-grid-modal";
import {
  OPTION_GRID_GRID_VIEW,
  OPTION_GRID_LIST_VIEW
} from "../../../../../../helpers/aliases";
import { TableBackgroundInfo } from "./table-background-info/TableBackgroundInfo";
import { ListBackgroundInfo } from "./list-background-info/ListBackgroundInfo";
import { SingleColumnBackgroundInfo } from "./single-column-background-info/SingleColumnBackgroundInfo";

export const OptionGridInfoPanel = ({ questions, options }) => {
  const {
    closeInfoPanel,
    isInfoPanelOpen,
    selectedOption,
    getOptionName,
    viewType,
    singleColumn
  } = useOptionGridContext();

  return (
    <OptionGridModal
      className="OptionGridModal"
      onRequestClose={closeInfoPanel}
      isOpen={isInfoPanelOpen}
      header="Clinical Information"
      size="large"
      showToolName={false}
    >
      {viewType === OPTION_GRID_GRID_VIEW && !singleColumn && (
        <TableBackgroundInfo
          questions={questions}
          title={getOptionName(selectedOption)}
          optionKey={selectedOption}
        />
      )}
      {viewType === OPTION_GRID_GRID_VIEW && singleColumn && (
        <SingleColumnBackgroundInfo questions={questions} />
      )}
      {viewType === OPTION_GRID_LIST_VIEW && (
        <ListBackgroundInfo
          questions={questions}
          options={options}
          getOptionName={getOptionName}
        />
      )}
    </OptionGridModal>
  );
};

OptionGridInfoPanel.propTypes = {
  questions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  questions: state.optionGrid.questions,
  options: state.optionGrid.options
});

export default connect(mapStateToProps)(OptionGridInfoPanel);
