import styled from "styled-components";
import {
  Accordion,
  Button,
  Flex
} from "discover.medical.react-component-library";

export const ToolsListAccordion = styled(Accordion)`
  margin-bottom: 0.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.sm};
  }
`;

export const ToolsListToolbar = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.md};
`;

export const SingleToolsListButtonsRoot = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
    margin-right: auto;
  }
`;

export const ToolsListButton = styled(Button)`
  padding: 0;
  margin-right: 1.25rem;

  &:last-of-type {
    margin-right: 0;
  }
`;
