import React from "react";
import styled from "styled-components";
import { Type, List } from "discover.medical.react-component-library";

export const Root = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const Title = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};
`;

export const Text = styled(Type)`
  display: block;
  margin-bottom: 0.75rem;
`;

export const ListStyled = styled(List)`
  padding-left: 1.75rem;
  margin-bottom: 0.75rem;
  && {
    list-style-position: outside;
  }
`;

export const ListItem = styled(List.Item)`
  margin-bottom: ${({ theme }) => theme.space.sm};
`;

export const AccessLink = styled(({ forwardedRef, ...rest }) => (
  <div {...rest} ref={forwardedRef} />
))`
  margin-bottom: 1.25rem;
  padding: ${({ theme }) => theme.space.md};
  border: 1px solid #9b9b9b;
  border-radius: 0;
  word-break: break-all;
`;
