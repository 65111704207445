import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";

export const StyledPage = styled(Flex)`
  height: 100%;
  ${({ theme, hero }) =>
    hero &&
    ` background: linear-gradient(
        to bottom,
        ${theme.colors.secondary.base} 10.25rem,
        ${theme.colors.white} 0
      );`}
`;

export const StyledWrapper = styled(Flex)`
  flex: 1 0 ${({ isSafari }) => (isSafari ? 0 : "auto")};
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 1.5;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.md};
  }
`;

export const StyledContainer = styled(Flex)`
  padding: ${({ theme, $isBottomPadding }) =>
    `${theme.space.md} ${theme.space.md} ${
      $isBottomPadding ? theme.space.md : 0
    }`};
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme, $isBottomPadding }) =>
      `${theme.space.md} ${theme.space.md} ${
        $isBottomPadding ? theme.space.lg : 0
      }`};
  }
`;
