import { connect } from "react-redux";
import { setIsFieldMounted, validate } from "../../store/fields/actions";
import {
  changeFieldsUiValues,
  setIsVisible,
  setFieldsOutcomeData,
  changeFieldMeasureValue,
  changeUserPreferredMeasure,
  toggleLabDateInfo
} from "../../store/fields/actions";
import { get } from "lodash";

export const mapStateToProps = (state, ownProps) => ({
  view: get(state, ["tool", "controls", "_all", ownProps.name], {}),
  value: get(state, ["fields", "ui", ownProps.name, "value"], ""),
  ehrEnabledField: get(
    state,
    ["fields", "ui", ownProps.name, "ehrEnabledField"],
    false
  ),
  defaultMessage: get(
    state,
    ["fields", "ui", ownProps.name, "defaultMessage"],
    ""
  ),
  defaultMessageLink: get(
    state,
    ["fields", "ui", ownProps.name, "defaultMessageLink"],
    ""
  ),
  ehrPrepopulationMessage: get(
    state,
    [
      "tool",
      "controls",
      "_all",
      ownProps.name,
      "attributes",
      "fieldInfo",
      "ehrPrepopulationMessage"
    ],
    ""
  ),
  labDateInfo: get(state, ["fields", "ui", ownProps.name, "labDateInfo"], ""),
  measure: get(state, ["fields", "ui", ownProps.name, "measure"], {}),
  validationResult: get(
    state,
    ["fields", "ui", ownProps.name, "validation"],
    []
  ),
  fields: state.fields,
  isHidden: get(state, ["fields", "ui", ownProps.name, "isHidden"]),
  isRequired:
    get(
      state,
      [
        "tool",
        "controls",
        "_all",
        ownProps.name,
        "attributes",
        "fieldInfo",
        "constraints",
        "required"
      ],
      ""
    ).toLowerCase() === "yes",
  sessionNumber: get(state, ["sharedData", "sessionNumber"], null)
});

export const mapDispatchToProps = {
  onRecalculate: setFieldsOutcomeData,
  onValueChange: changeFieldsUiValues,
  validateValue: validate,
  changeVisibility: setIsVisible,
  setIsFieldMounted: setIsFieldMounted,
  onMeasureValueChange: changeFieldMeasureValue,
  changeUserPreferredMeasure,
  hideLabDateInfo: name =>
    toggleLabDateInfo({
      name,
      isHidden: true
    })
};

export const componentStateMapper = component =>
  connect(mapStateToProps, mapDispatchToProps)(component);

export default componentStateMapper;
