import axiosInstance from "../../../services/axios-instance";
import {
  parseToolData,
  parseToolMetadata,
  parseFieldsData
} from "../../../helpers/tool-data";
import {
  getToolData,
  getToolDataSuccess,
  getToolDataError,
  getToolMetadata,
  getToolMetadataSuccess,
  getToolMetadataError,
  getToolTabs,
  getToolTabsSuccess,
  getToolTabsErrors,
  resetToolData
} from "../../tool/actions";
import {
  getFieldsData,
  getFieldsDataSuccess,
  getFieldsDataError,
  getFieldsDataEhr,
  getFieldsDataEhrError,
  getFieldsDataEhrSuccess,
  retrieveFieldsDataOutcomeSuccess,
  retrieveOptionGridOutcomeSuccess,
  resetFieldsData
} from "../../fields/actions";
import {
  getOptionGrid,
  getOptionGridError,
  getOptionGridSuccess,
  resetOptionGrid,
  selectOptionGridViewType,
  setSelected
} from "../../option-grid/actions";
import {
  getSnapshotData,
  getSnapshotDataSuccess,
  getSnapshotDataError,
  changeSnapshotContext
} from "../../snapshot/actions";
import { userPreference } from "../../user-preference/actions";
import { cleanUpValidatorsCache } from "../../fields/validators";
import { getBatchId } from "../../../shared/util/idUtils";
import { get } from "lodash";
import processEhr from "./process-ehr";
export const getToolDataRequest = (
  { pubType, toolSlug, snapshotId, version, history },
  errorHandler,
  redirectHandler
) => async (dispatch, getState) => {
  dispatch(changeSnapshotContext());
  dispatch(getToolData());
  dispatch(getFieldsDataEhr());
  dispatch(getFieldsData());
  dispatch(getToolMetadata());
  dispatch(getToolTabs());

  try {
    const fetchToolExperience = `/api-tool-experience`;

    const isAccuracyTest = getState().ssr.isAccuracyTest;
    const patient = !isAccuracyTest && getState().ssr.patient ? true : null;
    const { aggregateId } = getState().snapshot.events;
    const params = {
      patientId: patient,
      snapshotId,
      aggregateId,
      batchId: getBatchId(true),
      language: "en-US",
      version
    };

    const fetchToolExperienceUrl = pubType
      ? `${fetchToolExperience}/tool/${pubType}/${toolSlug}`
      : `${fetchToolExperience}/tool/${toolSlug}`;

    const { data } = await axiosInstance.get(fetchToolExperienceUrl, {
      params
    });
    const toolMetadata = parseToolMetadata({
      ...data.metadata,
      toolSlug,
      pubType,
      isFree: data.controls.metadata.isFree,
      toolName: data.controls.metadata.name,
      toolEligibility: data.controls.metadata.eligibility,
      toolDescription: data.controls.metadata.description,
      toolType: data.controls.metadata.toolType
    }); // todo change tool_metadata to tool

    dispatch(getToolMetadataSuccess(toolMetadata));

    let ehr;
    let ehrMetadata = {};
    let missingEhr = [];
    /**
     * This code is executed when UI application is approached by accuracy E2E test.
     * In this case mock data is not really requested from EHR, but mocked with values provided by an accuracy test (using saucelabs intercept).
     */
    if (isAccuracyTest) {
      const {
        data: { ehrpdns, missing_ehrpdns }
      } = await axiosInstance.post("/mock-ehr");
      ehr = processEhr(ehrpdns, missing_ehrpdns);
    } else if (patient && data.ehr) {
      ehr = processEhr(data.ehr.ehrpdns, data.ehr.missing_ehrpdns);
      ehrMetadata = data.ehr.metadata?.ehrpdns || {};
      missingEhr = data.ehr.missing_ehrpdns;
      dispatch(userPreference({ data: { showInfoPopup: true } }));
    } else {
      ehr = {};
    }
    dispatch(getFieldsDataEhrSuccess({ ehr, ehrMetadata, missingEhr }));
    const dsdControls = data.controls.controls;
    const toolData = parseToolData(dsdControls);
    const steps = data.controls.metadata.steps;
    const ehrpdns = data.controls.metadata.ehrpdns;
    if (ehrpdns) {
      toolData.ehrpdns = ehrpdns;
    }
    if (steps) {
      toolData.steps = steps;
    }
    let snapshotData = {};
    if (snapshotId) {
      dispatch(getSnapshotData());
      snapshotData = data.snapshot;
      dispatch(
        retrieveFieldsDataOutcomeSuccess(
          get(snapshotData, "calculator.output", {})
        )
      );
      dispatch(
        retrieveOptionGridOutcomeSuccess(
          get(snapshotData, "calculatorBatch.output", {})
        )
      );
      dispatch(
        getSnapshotDataSuccess({
          createdAt: snapshotData.createdAt,
          expiresAt: snapshotData.expiresAt
        })
      );
    }
    const fieldsData = parseFieldsData(
      toolData._all,
      ehr,
      snapshotData?.calculator?.input,
      ehrMetadata
    );
    await dispatch(getFieldsDataSuccess({ ui: fieldsData }));
    dispatch(getToolDataSuccess(toolData));

    let { sections } = data.controls.metadata;
    // TODO Remove this block when the snapshot API is unmocked
    if (snapshotId) {
      sections = sections.filter(section => section === "optionGrid");
    }
    dispatch(getToolTabsSuccess({ toolTabs: sections }));

    try {
      dispatch(getOptionGrid());
      const optionGridResponse = data.sections.optionGrid;
      const {
        references,
        contributors,
        certifications,
        competingInterests,
        definitions,
        popUpComponents,
        ...optionGridData
      } = optionGridResponse;
      const optionGridToolData = parseToolData({
        references,
        contributors,
        certifications,
        competingInterests,
        popUpComponents,
        definitions
      });
      dispatch(getToolDataSuccess({ ...optionGridToolData }));
      dispatch(getOptionGridSuccess({ ...optionGridData }));

      if (snapshotData?.ui?.selected) {
        dispatch(setSelected(snapshotData.ui.selected));
      }
      if (snapshotData?.ui?.viewType) {
        dispatch(selectOptionGridViewType(snapshotData.ui.viewType));
      }
    } catch (err) {
      dispatch(getOptionGridError());
    }
  } catch (error) {
    dispatch(getToolDataError());
    dispatch(getFieldsDataError());
    dispatch(getToolMetadataError());
    dispatch(getToolTabsErrors());
    dispatch(getFieldsDataEhrError());
    dispatch(getSnapshotDataError());
    const errorData = error?.response?.data;
    if (
      errorData &&
      errorData.code === 400 &&
      errorData.type === "slug" &&
      errorData.redirectPath &&
      errorData.redirectQuery
    ) {
      const redirectQuery = history.location.search;
      const redirectPath = errorData.redirectPath.substring(1);
      return redirectHandler(redirectPath, `${redirectQuery}`);
    } else {
      errorHandler(error.response.status);
    }
  }
};

export const cleanUpTool = () => async dispatch => {
  cleanUpValidatorsCache();
  dispatch(resetToolData());
  dispatch(resetFieldsData());
  dispatch(resetOptionGrid());
};
