import styled from "styled-components";
import { Button } from "discover.medical.react-component-library";

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.green};

  &:hover {
    background-color: ${({ theme }) => theme.colors.green};
  }
`;
