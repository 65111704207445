import React, { Component } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import PropTypes from "prop-types";

import tabComponentsRetriever from "../tabComponentsRetriever";
import { Button } from "discover.medical.react-component-library";
import DynamicForm from "../../../content-components";
import { setFieldsOutcomeData } from "../../../../store/fields/actions";
import { getValidationErrors } from "../../../../helpers/selectors";

import "./_index.css";

export class PatientDataTab extends Component {
  splitControls = sidesControlsList => {
    const halfLength = Math.ceil(sidesControlsList.length / 2);
    const leftControls = sidesControlsList.splice(0, halfLength);

    return { rightControls: sidesControlsList, leftControls };
  };

  handleSubmit = async () => {
    await this.props.setFieldsOutcomeData();
    this.props.changeTab(this.props.index);
  };

  renderSubmitButton = () => {
    const { hasValidationErrors } = this.props;
    return (
      <Button
        variant="primary"
        onClick={this.handleSubmit}
        data-auto="continue-button"
        disabled={hasValidationErrors}
      >
        Continue
      </Button>
    );
  };

  render() {
    const {
      topComponentsList: topControls,
      sidesComponentsList,
      isHiddenBtn
    } = this.props;

    const { rightControls, leftControls } = this.splitControls(
      sidesComponentsList
    );

    return (
      <div data-auto="tool-template-implementation-tabs-patientdata">
        <div className="PatientData_mainFrame">
          <div className="PatientData_side PatientData_topSide">
            <DynamicForm componentsList={topControls} />
          </div>
          <div className="PatientData_side PatientData_leftSide">
            <DynamicForm componentsList={leftControls} />
          </div>
          <div className="PatientData_side PatientData_rightSide">
            <DynamicForm componentsList={rightControls} />
          </div>
          {!isHiddenBtn && (
            <div className="PatientData_side PatientData_bottomSide PatientData_submitButtonWrapper">
              {this.renderSubmitButton()}
            </div>
          )}
        </div>
      </div>
    );
  }
}

PatientDataTab.propTypes = {
  topComponentsList: PropTypes.array.isRequired,
  sidesComponentsList: PropTypes.array.isRequired,
  hasValidationErrors: PropTypes.bool.isRequired,
  calcId: PropTypes.string.isRequired,
  setFieldsOutcomeData: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isHiddenBtn: PropTypes.bool.isRequired,
  changeTab: PropTypes.func.isRequired
};

export const mapStateToProps = state => ({
  topComponentsList: tabComponentsRetriever(state, "dataHeader"),
  sidesComponentsList: tabComponentsRetriever(state, "data"),
  calcId: get(state, "tool.metadata.calcId", ""),
  toolTabs: get(state, "tool.controls.toolTabs", []),
  hasValidationErrors: !!getValidationErrors(state).length,
  optionGridOptions: get(state, "optionGrid.options", [])
});

const mapDispatchToProps = {
  setFieldsOutcomeData
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDataTab);
