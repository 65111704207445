import React from "react";
import { useSelector } from "react-redux";
import tabComponentsRetriever from "../../../../templates/components/tabComponentsRetriever";
import ModalTemplate from "../modal-template/ModalTemplate";
import DynamicForm from "../../../../content-components/DynamicForm";
import { MainBlock, Description } from "./index.styled";

export const ModalReferences = ({ closeReferencesModal, isReferencesOpen }) => {
  const state = useSelector(storageState => storageState);
  const references = tabComponentsRetriever(state, "references");
  return (
    <ModalTemplate
      onRequestClose={closeReferencesModal}
      isOpen={isReferencesOpen}
      header="References"
    >
      <MainBlock>
        <Description fontSize="sm">
          Our decision aids are created using the best-available evidence. The
          creation process centers on a systematic approach to evidence search,
          selection, appraisal, and synthesis that leverages the systematic
          literature surveillance process of DynaMed.
        </Description>

        <DynamicForm componentsList={references} />
      </MainBlock>
    </ModalTemplate>
  );
};

export default ModalReferences;
