import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import isNil from "lodash/isNil";
import {
  Badge,
  Button,
  Spinner
} from "discover.medical.react-component-library";
import {
  EligibilityText,
  SectionContent,
  SectionTitle,
  ToolTitle
} from "./index.styled";

const FeatureTool = () => {
  const featureTool = useSelector(state => state.toolBrowse.featureTool);
  const isLoading = useSelector(state => state.toolBrowse.isLoading);
  const sessionNumber = useSelector(state => state.sharedData.sessionNumber);

  return (
    <section data-auto="featured-tool">
      {isLoading ? (
        <Spinner />
      ) : (
        featureTool && (
          <>
            <SectionTitle
              data-auto="featured-tool-section-title"
              element="h2"
              color="senary"
              fontFamily="secondary"
            >
              {featureTool?.sectionTitle}
            </SectionTitle>

            <SectionContent>
              <ToolTitle
                element="h3"
                color="gray800"
                fontFamily="secondary"
                display="inline"
                data-auto="featured-tool-title"
              >
                {featureTool?.title}&nbsp;
              </ToolTitle>

              {featureTool?.isFree && <Badge color="green">FREE</Badge>}

              <EligibilityText
                dangerouslySetInnerHTML={{
                  __html: featureTool?.eligibility
                }}
              />

              <Button
                as={Link}
                data-auto="featured-tool-try-button"
                to={`/${featureTool?.slug}${
                  !isNil(sessionNumber)
                    ? `?session-number=${sessionNumber}`
                    : ""
                }`}
              >
                Try Now
              </Button>
            </SectionContent>
          </>
        )
      )}
    </section>
  );
};

export default FeatureTool;
