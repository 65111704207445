import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TableBackgroundInfo from "../table-background-info";

export const ListBackgroundInfo = ({ questions, options, getOptionName }) => {
  return (
    <Fragment>
      {options.map(option => (
        <TableBackgroundInfo
          questions={questions}
          title={getOptionName(option.optionKey)}
          optionKey={option.optionKey}
        />
      ))}
    </Fragment>
  );
};

ListBackgroundInfo.propTypes = {
  questions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  getOptionName: PropTypes.func.isRequired
};

export default ListBackgroundInfo;
