import React from "react";

import DynamicForm from "../../../../../content-components/DynamicForm";
import { Container, StyledType } from "./index.styled";

const PatientAssessment = ({ title, componentsList }) => {
  return (
    <Container>
      {title ? (
        <DynamicForm componentsList={[title]} />
      ) : (
        <StyledType element="h2" color="senary" fontFamily="secondary">
          Patient Assessment
        </StyledType>
      )}
      <DynamicForm componentsList={componentsList} />
    </Container>
  );
};

export default PatientAssessment;
