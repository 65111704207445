import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const SectionTitle = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.md};
  }
`;

export const SectionContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray400};
  padding: 1.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: ${({ theme }) => `${theme.space.lg} 1.25rem 1.875rem`};
  }
`;

export const ToolTitle = styled(Type)`
  vertical-align: text-top;
`;

export const EligibilityText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.space.lg};
`;
