import React, { createRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { StyledSVG } from "./index.styled";

const HORIZONTAL = "h";
const VERTICAL = "v";

const processChildren = (flow, columns, rows, children) => {
  const grid = [];
  const gridSize = columns * rows;
  for (let i = 0; i < gridSize; i++) {
    let column = 0;
    let row = 0;

    if (flow === HORIZONTAL) {
      column = i % columns;

      if (i > 0) {
        row = Math.floor(i / columns);
      }
    } else {
      if (i > 0) {
        column = Math.floor(i / rows);
      }
      row = i % rows;
    }
    // Initialize the group if its new.
    if (!grid[row]) {
      grid[row] = [];
    }
    // Use the child, or a placeholder if not available.
    if (!children || i >= children.length) {
      grid[row][column] = <g />;
    } else {
      grid[row][column] = children[i];
    }
  }
  return grid;
};
function IconGrid({
  children,
  columns,
  flow,
  rows,
  cellWidth,
  cellHeight,
  hGap,
  vGap
}) {
  const totalWidth = columns * cellWidth + (columns - 1) * hGap;
  const totalHeight = rows * cellHeight + (rows - 1) * vGap;
  const grid = processChildren(flow, columns, rows, children);
  return (
    <div className="IconGrid">
      <StyledSVG
        viewBox={`0 0 ${totalWidth} ${totalHeight}`}
        preserveAspectRatio="xMidYMin slice"
        paddingBottom={`${(totalHeight / totalWidth) * 100}%`}
      >
        {grid.map((row, rowIndex) => (
          <g
            className="IconGrid_row"
            transform={`translate(0, ${rowIndex * (cellHeight + vGap)})`}
          >
            {row.map((column, columnIndex) => (
              <g
                transform={`translate(${columnIndex * (cellWidth + hGap)}, 0)`}
              >
                <IconGridElement
                  content={column}
                  width={cellWidth}
                  height={cellHeight}
                ></IconGridElement>
              </g>
            ))}
          </g>
        ))}
      </StyledSVG>
    </div>
  );
}

// Default to a 10 by 10 grid with vertical flow.
IconGrid.defaultProps = {
  flow: VERTICAL,
  columns: 10,
  rows: 10,
  hGap: 2,
  vGap: 2
};

// Define required component properties.
IconGrid.propTypes = {
  columns: PropTypes.number.isRequired,
  flow: PropTypes.oneOf([HORIZONTAL, VERTICAL]).isRequired,
  rows: PropTypes.number.isRequired,
  hGap: PropTypes.number.isRequired,
  vGap: PropTypes.number.isRequired
};

// Export the component by default.
export default IconGrid;

// Export the flow constants.
export { HORIZONTAL, VERTICAL };

const IconGridElement = ({ content, width, height }) => {
  const ref = createRef();
  useLayoutEffect(() => {
    const bb = ref.current.getBBox();
    const offsetX = (width - bb.width - bb.x) / 2;
    const offsetY = (height - bb.height - bb.y) / 2;
    const scale = Math.min(1, Math.min(width / bb.width, height / bb.height));
    ref.current.setAttribute(
      "transform",
      `translate(${offsetX}, ${offsetY}) scale(${scale}, ${scale})`
    );
  });
  return <g ref={ref}>{content}</g>;
};
