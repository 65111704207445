import React from "react";
import styled, { css } from "styled-components";
import DynamicForm from "../DynamicForm";

export const containerStyles = css`
  padding-bottom: 0;
`;

export const controlColumnStyles = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0;
  }
`;

// The arrow function is needed below to fix the next issue
// https://github.com/styled-components/styled-components/issues/1449#issuecomment-420087359
export const StyledDynamicForm = styled(props => <DynamicForm {...props} />)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }

  .General-content-component-wrapper:not(:last-child) > div {
    padding-bottom: ${({ theme }) => theme.space.gutter.base};

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-bottom: 0;
    }
  }
`;
