import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";
import externalLinkLogo from "../../../../../../shared/images/external-link.svg";

export const StyledType = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.lg};
  }
`;

export const Container = styled.div`
  a[href^="http"] {
    margin-right: ${({ theme }) => theme.space.sm};
    white-space: nowrap;

    &:after {
      content: url(${externalLinkLogo});
      color: ${({ theme }) => theme.colors.secondary.base};
      margin-left: ${({ theme }) => theme.space.sm};
      width: 1.125rem;
      height: 1.125rem;
      display: inline-block;
      vertical-align: middle;
    }
  }

  h3 {
    font-style: italic;
  }

  .Text_block {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${({ theme }) => theme.space.md};

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      align-items: center;
      flex-direction: row;
    }
  }

  .Text_baseContent {
    margin: 0;

    + div {
      margin-bottom: ${({ theme }) => theme.space.sm};
    }
  }
`;
