import React from "react";
import { Flex } from "discover.medical.react-component-library";
import PropTypes from "prop-types";
import { get } from "lodash";
import componentStateMapper from "../componentsStateMapper";
import BasicNumeric from "../basic-numeric";
import Basic from "../basic";
import {
  infoButtonStyles,
  getLabelStyles,
  Label,
  Column
} from "./index.styled";
import NumericControl from "../shared/numeric-control";

const CURRENT_YEAR = new Date().getFullYear();

export class NumericSmoking extends BasicNumeric {
  state = {
    age: "",
    year: ""
  };

  constructor(props) {
    super(props);

    const parentsRenderLabel = this.renderLabel;
    this.renderLabel = () =>
      parentsRenderLabel(getLabelStyles(props.isLabelHidden));

    const parentsRenderInfoButton = this.renderInfoButton;
    this.renderInfoButton = () => parentsRenderInfoButton(infoButtonStyles);
  }

  handleAgeChange = event => {
    event.persist();

    const { value } = event.target;
    const {
      fields: { ui }
    } = this.props;
    const userAge = ui.age.value;

    if (userAge) {
      this.setState(
        {
          year: value !== "" ? CURRENT_YEAR - (userAge - value) : "",
          age: value
        },
        () =>
          this.handleChange({
            target: {
              value: value !== "" ? userAge - value : ""
            },
            isTrusted: event.isTrusted
          })
      );
    } else {
      this.setState({
        age: value
      });
    }
  };

  handleYearChange = event => {
    event.persist();

    const { value } = event.target;
    const {
      fields: { ui }
    } = this.props;
    const userAge = ui.age.value;

    if (userAge) {
      this.setState(
        {
          year: value,
          age: value !== "" ? userAge - (CURRENT_YEAR - value) : ""
        },
        () =>
          this.handleChange({
            target: {
              value: value !== "" ? CURRENT_YEAR - value : ""
            },
            isTrusted: event.isTrusted
          })
      );
    } else {
      this.setState({
        year: value
      });
    }
  };

  handleYearsAgoChange = event => {
    event.persist();
    const { value } = event.target;

    const {
      fields: { ui }
    } = this.props;
    const userAge = ui.age.value;

    if (userAge) {
      this.setState({
        year: value !== "" ? CURRENT_YEAR - value : "",
        age: value !== "" ? userAge - value : ""
      });
    }

    this.changeValue(value === "" ? value : Number(value), event);
  };

  _didMountExtension = () => {
    const {
      value,
      fields: { ui }
    } = this.props;
    const userAge = get(ui, "age.value", "");

    if (value && userAge) {
      this.refreshAllFields();
    } else if (!userAge && value) {
      this.resetFields();
    }
  };

  _didUpdateExtension = prevProps => {
    const { year, age } = this.state;
    const {
      value,
      fields: { ui }
    } = this.props;
    const prevUserAge = get(prevProps, "fields.ui.age.value", "");
    const currUserAge = get(ui, "age.value", "");

    if (
      prevUserAge !== currUserAge &&
      currUserAge &&
      [value, age, year].some(elem => elem)
    ) {
      this.refreshAllFields();
    }
  };

  resetFields = () => {
    this.changeValue("");
  };

  refreshAllFields = () => {
    const { year, age } = this.state;
    const {
      value,
      fields: { ui }
    } = this.props;
    const userAge = ui.age.value;
    const newAge =
      userAge -
      (value ? value : CURRENT_YEAR - (year || CURRENT_YEAR - (userAge - age)));

    this.setState(
      {
        year: CURRENT_YEAR - (value ? value : userAge - (age || newAge)),
        age: newAge
      },
      () =>
        this.handleChange({
          target: {
            value: CURRENT_YEAR - this.state.year
          },
          isTrusted: false
        })
    );
  };

  renderComponent = () => {
    const { age, year } = this.state;
    const { value, isLabelHidden, name, autocomplete } = this.props;

    return (
      <Flex cell>
        <Column>
          {!isLabelHidden && <Label htmlFor="smoking-age">Age</Label>}
          <NumericControl
            id="smoking-age"
            name="age"
            value={age.toString()}
            hasError={this.hasErrors()}
            hasWarning={this.hasWarnings()}
            onChange={this.handleAgeChange}
            data-auto={`${name}-component-age-field`}
            autocomplete={autocomplete}
          />
        </Column>
        <Column>
          {!isLabelHidden && <Label htmlFor="smoking-year">Year</Label>}
          <NumericControl
            onChange={this.handleYearChange}
            id="smoking-year"
            name="year"
            value={year.toString()}
            hasError={this.hasErrors()}
            hasWarning={this.hasWarnings()}
            data-auto={`${name}-component-year-field`}
            autocomplete={autocomplete}
          />
        </Column>
        <Column>
          {!isLabelHidden && (
            <Label htmlFor="smoking-years-ago">Years ago</Label>
          )}
          <NumericControl
            id="smoking-years-ago"
            name="smoking-years-ago"
            value={value.toString()}
            onChange={this.handleYearsAgoChange}
            hasError={this.hasErrors()}
            hasWarning={this.hasWarnings()}
            data-auto={`${name}-component-years-ago-field`}
            autocomplete={autocomplete}
          />
        </Column>
      </Flex>
    );
  };
}

NumericSmoking.propTypes = {
  ...Basic.propTypes,
  isLabelHidden: PropTypes.bool
};

NumericSmoking.defaultProps = {
  ...Basic.defaultProps,
  isLabelHidden: false
};

export default componentStateMapper(NumericSmoking);
