import React, { useEffect, useState } from "react";
import isNil from "lodash/isNil";
import { useSelector } from "react-redux";
import useModal from "../../../../../../../shared/hooks/useModal";

export default function usePdfGeneration({
  toolSlug,
  options,
  selected,
  setIsSpinnerShown,
  resetSnapshotDataAction,
  aggregateId,
  snapshotProcessingInfo: { isSnapshotLoading, snapshotId }
}) {
  const [isWarningModalOpen, openWarningModal, closeWarningModal] = useModal();
  const [pdfUrl, setPdfUrl] = useState(null);
  const sessionNumber = useSelector(state => state.sharedData.sessionNumber);

  const closePdfViewer = React.useCallback(() => {
    setPdfUrl(null);
  }, [setPdfUrl]);

  const printPDF = React.useCallback(
    (isFeatureDisabled, isStaticLinkClicked, filename) => {
      if (isFeatureDisabled) {
        const pdfGenerationUrl = `/api-datasource/pdf/default/${toolSlug}.pdf`;
        setPdfUrl(pdfGenerationUrl);
      } else if (isStaticLinkClicked) {
        setPdfUrl(
          `https://resources.dynamed.com/discover.medical.hippocratesui/assets/pdf/${filename}.pdf`
        );
      } else {
        if (options.length <= selected.length) {
          setIsSpinnerShown(true); // this triggers snapshot creation
        }
        resetSnapshotDataAction();
        openWarningModal();
      }
    },
    [
      toolSlug,
      options.length,
      selected.length,
      resetSnapshotDataAction,
      openWarningModal,
      setIsSpinnerShown,
      setPdfUrl
    ]
  );

  useEffect(() => {
    if (!isSnapshotLoading && snapshotId && isWarningModalOpen && aggregateId) {
      closeWarningModal();
      const pdfGenerationUrl = `/api-tool-experience/pdf?snapshotId=${snapshotId}&aggregateId=${aggregateId}${
        !isNil(sessionNumber) ? `&session-number=${sessionNumber}` : ""
      }`;
      setPdfUrl(pdfGenerationUrl);
    }
  }, [
    isSnapshotLoading,
    snapshotId,
    closeWarningModal,
    isWarningModalOpen,
    aggregateId,
    setPdfUrl,
    sessionNumber
  ]);

  return {
    isWarningModalOpen,
    closeWarningModal,
    openWarningModal,
    printPDF,
    pdfUrl,
    closePdfViewer
  };
}
