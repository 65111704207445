import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkIsSafari } from "../../../helpers/browser-checker";
import useIsSnapshotId from "../../../shared/hooks/useIsSnapshotId";
import { getHomeData } from "../../../store/home/actions";
import Footer from "../../footer";
import Header from "../../header";
import MaintenanceAlert from "../../maintenance-alert";
import { StyledContainer, StyledPage, StyledWrapper } from "./styled";
import BrowserNoticeDialog from "../../browser-notice-dialog/BrowserNoticeDialog";

export const PageTemplate = ({
  children,
  dataAuto,
  hero,
  shouldRedirect,
  referrer,
  staticContext,
  isBottomPadding,
  isNavigationExpanded
}) => {
  let { pathname, search, hash } = useLocation();

  const getReturnToUri = () => {
    const paramSearch = search.replace("?", "&");
    const encodedPathname = encodeURIComponent(pathname);
    const encodedSearch = encodeURIComponent(search);
    const encodedHash = encodeURIComponent(hash);
    if (search.includes("session-number")) {
      return `${encodedPathname}${encodedSearch}${encodedHash}${paramSearch}`;
    } else {
      return `${encodedPathname}${encodedSearch}${encodedHash}`;
    }
  };
  const getSeamlessReferrer = seamlessUri => {
    let referer;
    if (seamlessUri) {
      const interfaceIdMatch = seamlessUri.match(/toInterfaceId=(dmx|dmp)/);
      if (interfaceIdMatch) {
        const interfaceId = interfaceIdMatch[1];
        const path = getReturnToUri();
        return {
          host: `${seamlessUri}&returnToUri=`,
          interfaceId,
          path
        };
      }
    }
    return referer;
  };
  const dispatch = useDispatch();
  const [hasMounted, setHasMounted] = useState(false);
  const isSnapshotIdInURL = useIsSnapshotId(staticContext);
  const { seamlessAuthUrl, hasLoaded } = useSelector(state => state.home || {});
  const refer = getSeamlessReferrer(seamlessAuthUrl);
  const showTabs = hasMounted && referrer;
  const isSafari = hasMounted && checkIsSafari();

  useEffect(() => {
    if (!hasLoaded) {
      dispatch(getHomeData());
    }
  }, [dispatch, hasLoaded]);
  // forces the component to re-render after mounting
  // had to add this to fix SSR hydration issues
  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <StyledPage hero={hero} container column maxWidth="none">
      <Header
        referrer={refer}
        shouldRedirect={shouldRedirect}
        isSnapshotIdInURL={isSnapshotIdInURL}
        isNavigationExpanded={isNavigationExpanded}
      />
      <StyledWrapper
        isSnapshotIdInURL={isSnapshotIdInURL}
        showTabs={showTabs}
        role="main"
        data-auto={dataAuto}
        maxWidth="none"
        isSafari={isSafari}
      >
        <MaintenanceAlert />
        <BrowserNoticeDialog />
        {hero}
        <StyledContainer $isBottomPadding={isBottomPadding}>
          {children}
        </StyledContainer>
      </StyledWrapper>
      <Footer />
    </StyledPage>
  );
};

PageTemplate.propTypes = {
  dataAuto: PropTypes.string.isRequired,
  className: PropTypes.string,
  hero: PropTypes.node,
  staticContext: PropTypes.object,
  isBottomPadding: PropTypes.bool,
  isNavigationExpanded: PropTypes.bool
};

PageTemplate.defaultProps = {
  className: "",
  shouldRedirect: true,
  hero: null,
  staticContext: null,
  isBottomPadding: true,
  isNavigationExpanded: false
};

export const mapStateToProps = state => ({
  referrer: state.app.referrer
});
export default connect(mapStateToProps)(PageTemplate);
