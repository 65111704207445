import styled from "styled-components";

export const InfoModalContentItemRoot = styled.div`
  margin-bottom: ${({ theme }) => theme.space.md};

  &:last-child {
    margin-bottom: ${({ $isSDM }) => !$isSDM && "0"};
  }
`;

export const InfoModalContentText = styled.span`
  a {
    word-break: break-all;
    color: ${({ theme }) => theme.colors.secondary.base};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  p:first-child {
    display: inline;
  }
`;
