import React from "react";
import {
  Hamburger,
  HamburgerLink,
  Anchor
} from "discover.medical.react-component-library";
import { newLinks } from "../../../shared/constants/navigation";
import useIndicatorIndex from "../../../shared/hooks/useIndicatorIndex";
import { NavLink } from "react-router-dom";
import isNil from "lodash/isNil";

export const HamburgerMenu = ({ isOpen, setOpen, sessionNumber }) => {
  const links = newLinks;
  const indicatorIndex = useIndicatorIndex(links);
  const handleClickOutside = () => {
    if (isOpen) {
      setOpen(false);
    }
  };

  const getLinkProps = link => {
    return link.isExternal
      ? {
          target: "_blank",
          linkComponent: ({ to, ...rest }) => <Anchor {...rest} href={to} />,
          onClick: handleClickOutside,
          href: link.route
        }
      : {
          linkComponent: NavLink,
          href: `${link.route}${
            !isNil(sessionNumber) ? `?session-number=${sessionNumber}` : ""
          }`
        };
  };

  return (
    <Hamburger
      isOpen={isOpen}
      onClickOutside={handleClickOutside}
      indicatorIndex={indicatorIndex}
    >
      {links.map(link => (
        <HamburgerLink
          key={link.id}
          text={link.label}
          data-auto="hamburger-link"
          data-testid={`${link.id}-hamburger-link`}
          {...getLinkProps(link)}
        />
      ))}
    </Hamburger>
  );
};

export default HamburgerMenu;
