import React, { memo } from "react";
import PropTypes from "prop-types";
import isNil from "lodash/isNil";
import { Badge, Flex, Icon } from "discover.medical.react-component-library";
import ToolsTypeIcon from "../tools-type-icon";
import {
  ToolsListItemContent,
  ToolsListItemInfo,
  ToolsListItemInfoBtn,
  ToolsListItemRoot,
  ToolsListItemLink,
  ToolsListItemTitle,
  PubTypeTitle
} from "./index.styled";
import { useSelector, useDispatch } from "react-redux";
import { setToolInfoData } from "../../../store/tool-browse/actions";
import { NavLink } from "react-router-dom";

const ToolsListItem = ({
  title,
  an,
  version,
  category,
  pubTypePretty,
  isPatientData,
  isSharedDecision,
  isPatientFAQ,
  isFree,
  isForbidden,
  slug,
  isMobile,
  onInfoModalOpen,
  onSubscriptionModalOpen,
  isSDM,
  className
}) => {
  const dispatch = useDispatch();
  const sessionNumber = useSelector(state => state.sharedData.sessionNumber);
  const { isAuthorized, isLoading } = useSelector(state => state.ssr);

  return (
    <ToolsListItemRoot data-auto="tools-list-item" cell>
      <ToolsListItemContent
        className={className}
        isSDM={isSDM}
        cell
        align={{ item: "center" }}
        justify={{ content: isSDM ? "space-between" : "flex-start" }}
        pubTypePretty={pubTypePretty}
      >
        <div>
          <PubTypeTitle element="p" fontSize="xs">
            {pubTypePretty}
          </PubTypeTitle>
          <ToolsListItemLink
            data-auto="tools-list-item-title"
            data-paid={!isFree}
            href={`/${slug}${
              !isNil(sessionNumber) ? `?session-number=${sessionNumber}` : ""
            }`}
            fontSize={isMobile ? "sm" : "md"}
            linkComponent={NavLink}
            onClick={e => {
              if (isForbidden) {
                e.preventDefault();
                onSubscriptionModalOpen();
              }
            }}
          >
            <ToolsListItemTitle>{title}</ToolsListItemTitle>&nbsp;
            {isFree && !isAuthorized && !isLoading && (
              <Badge color="green">FREE</Badge>
            )}
          </ToolsListItemLink>
        </div>

        {!isMobile && isSDM && (
          <Flex
            data-auto="tools-list-item-icon-set"
            cell
            align={{ item: "center" }}
          >
            <ToolsTypeIcon
              isActive={isPatientData}
              glyph="how_to_reg"
              label="Accepts Patient Data"
              content="Accepts Patient Data"
            />
            <ToolsTypeIcon
              isActive={isSharedDecision}
              glyph="insert_chart"
              label="Includes Key Data Calculations"
              content="Includes Key Data Calculations"
            />
            <ToolsTypeIcon
              isActive={isPatientFAQ}
              glyph="grid_on"
              label="Includes Option Grid patient FAQs"
              content="Includes Option Grid patient FAQs"
            />
          </Flex>
        )}
      </ToolsListItemContent>

      <ToolsListItemInfo
        cell
        align={{ item: "center" }}
        pubTypePretty={pubTypePretty}
      >
        <ToolsListItemInfoBtn
          data-auto="tools-list-info-button"
          variant="primary"
          label="info"
          onClick={() => {
            dispatch(setToolInfoData(category, an, version));
            onInfoModalOpen();
          }}
        >
          <Icon icon={{ glyph: "information", color: "white" }} />
        </ToolsListItemInfoBtn>
      </ToolsListItemInfo>
    </ToolsListItemRoot>
  );
};

ToolsListItem.propTypes = {
  title: PropTypes.string.isRequired,
  an: PropTypes.string.isRequired,
  version: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  isPatientData: PropTypes.bool.isRequired,
  isSharedDecision: PropTypes.bool.isRequired,
  isPatientFAQ: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
  isForbidden: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onInfoModalOpen: PropTypes.func.isRequired,
  onSubscriptionModalOpen: PropTypes.func.isRequired,
  isSDM: PropTypes.bool.isRequired,
  pubTypePretty: PropTypes.string
};

export default memo(ToolsListItem);
