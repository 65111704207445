const decimalPlacesMap = {
  "numeric.bmi": 1
};

export default function cropDecimalPlaces(type, value) {
  const decimalPlaces = decimalPlacesMap[type];
  let convertedValue = value;

  if (decimalPlaces !== undefined && typeof value === "number") {
    convertedValue = value.toFixed(decimalPlaces);
  }

  return convertedValue;
}
