import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Type,
  Anchor,
  Spinner
} from "discover.medical.react-component-library";
import { StyledErrorMsgContainer, StyledActionMsg } from "./styled";
import { getParamFromUrl } from "../../../helpers/url-manager";
import { errorMappings } from "./constants";
import PageLayout from "../../templates/page";

const AuthError = () => {
  const errorCode = getParamFromUrl("errorMsg", useLocation());
  const { isLoading } = useSelector(state => state.ssr);

  // Wait for SSR to finish loading then render the error message
  return isLoading ? (
    <PageLayout>
      <StyledErrorMsgContainer>
        <Spinner size="lg" />
      </StyledErrorMsgContainer>
    </PageLayout>
  ) : (
    <PageLayout>
      <StyledErrorMsgContainer>
        <Type
          element="h2"
          fontWeight="bold"
          children={`${
            (errorMappings[errorCode] || errorMappings.default).errorMsgTitle
          }`}
        />
        <Type
          element="h6"
          fontWeight="normal"
          className="Definition_Term"
          children={`${
            (errorMappings[errorCode] || errorMappings.default)
              .errorMsgDescription
          }`}
        ></Type>
      </StyledErrorMsgContainer>
      <StyledActionMsg>
        <Anchor href="/">
          {`${(errorMappings[errorCode] || errorMappings.default).actionMsg}`}
        </Anchor>
      </StyledActionMsg>
    </PageLayout>
  );
};

export default AuthError;
