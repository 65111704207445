import {
  SEARCH_TOOLS_REQUEST,
  SEARCH_TOOLS_SUCCESS,
  SEARCH_TOOLS_ERROR
} from "../action-types";

export const initialState = {
  isLoading: true,
  foundTools: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_TOOLS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SEARCH_TOOLS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        foundTools: payload
      };

    case SEARCH_TOOLS_ERROR:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
