import React from "react";
import PropTypes from "prop-types";
import { buildVerticalChart, buildHorizontalChart } from "./chartBuilders";
import "./_index.css";

const StackedBarChart = ({ numerator, denominator, isHorizontal }) => {
  const svg = isHorizontal
    ? buildHorizontalChart(numerator)
    : buildVerticalChart(numerator);
  const src = `data:image/svg+xml;base64,${btoa(svg)}`;
  return (
    <div className="StackedBarChart">
      <img src={src} alt="" aria-hidden="true" />
    </div>
  );
};

StackedBarChart.propTypes = {
  numerator: PropTypes.number.isRequired,
  denominator: PropTypes.number.isRequired,
  isHorizontal: PropTypes.bool
};

StackedBarChart.defaultProps = {
  isHorizontal: false
};

export default StackedBarChart;
