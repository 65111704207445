import React, { useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getSelectedOptions,
  getSelectedQuestions,
  canToggleOptions
} from "../../../../../../store/option-grid/selectors";
import { useOptionGridContext } from "../OptionGridContext";
import OptionGridTable from "./option-grid-table";
import OptionGridList from "./option-grid-list";
import { setSelected } from "../../../../../../store/option-grid/actions";
import { OPTION_GRID_GRID_VIEW } from "../../../../../../helpers/aliases";
import "./_index.css";

export const OptionGridView = props => {
  const { viewType } = useOptionGridContext();
  const { setSelectedOptions, selected } = props;

  const hideOption = useCallback(
    optionKey => {
      setSelectedOptions(selected.filter(key => key !== optionKey));
    },
    [selected, setSelectedOptions]
  );

  const childProps = { ...props, hideOption };

  return (
    <div className="OptionGridView">
      {viewType === OPTION_GRID_GRID_VIEW ? (
        <OptionGridTable {...childProps} />
      ) : (
        <OptionGridList {...childProps} />
      )}
    </div>
  );
};

OptionGridView.propTypes = {
  questions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  fields: PropTypes.object
};

OptionGridView.defaultProps = {
  selected: [],
  fields: {}
};

const mapStateToProps = state => {
  return {
    fields: state.fields,
    questions: getSelectedQuestions(state),
    options: getSelectedOptions(state),
    selected: state.optionGrid.selected,
    totalOptions: state.optionGrid.options.length,
    showCloseButton: canToggleOptions(state),
    isSingleColumn: state.optionGrid.singleColumn,
    windowSize: state.sharedData.windowSize
  };
};

const mapDispatchToProps = {
  setSelectedOptions: setSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionGridView);
