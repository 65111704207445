export const backgroundTextChecker = (questions, optionColKey) => {
  const optionWithText = question =>
    question.answers.find(
      ({ optionKey, backgroundText }) =>
        optionKey === optionColKey &&
        backgroundText &&
        backgroundText[0].content
    );
  return questions.some(optionWithText);
};

export const backgroundTextCheckerList = (questions, options) => {
  for (let i = 0; i < options.length; i++) {
    let optionKey = options[i].optionKey;
    if (backgroundTextChecker(questions, optionKey)) {
      return true;
    }
  }
  return false;
};

export const backgroundTextCheckerSingleColumn = questions => {
  const optionWithText = question =>
    question.answers.find(
      ({ backgroundText }) => backgroundText && backgroundText[0].content
    );
  return questions.some(optionWithText);
};
