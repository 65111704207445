import React from "react";
import BasicText from "../basic-text";
import componentStateMapper from "../componentsStateMapper";
import { getElementsByTagName } from "domutils";
import "./_index.css";
import { Content } from "discover.medical.react-component-library";
import ReactDOMServer from "react-dom/server";

export class Reference extends BasicText {
  constructor(props) {
    super(props);
    this.contentBodyRef = React.createRef();
  }

  renderCompositeComponent = () => {
    const { view } = this.props;

    const getBaseContentHtml = ReactDOMServer.renderToString(
      this.getBaseContent()
    );

    let contentBlock;

    if (getBaseContentHtml && getBaseContentHtml.length > 0) {
      contentBlock = (
        <Content.Block
          ref={this.contentBodyRef}
          preProcessors={[this.processLinks()]}
        >
          {getBaseContentHtml}
        </Content.Block>
      );
    }

    return (
      <div className="Reference">
        <span className="Reference_Index">{view.attributes.index}.</span>
        <div className="Reference_Text">{contentBlock}</div>
      </div>
    );
  };

  //pre-processor for identifying nodes with anchor tag and adding target attribute with _blank value,
  //so we can open external links in References in external window in EPIC or new tab outside EPIC.
  processLinks() {
    return {
      replaceChildren: true,
      shouldProcessNode: node => {
        const isLinkWithClass = node.name === "a";
        return isLinkWithClass;
      },
      processNode: node => {
        if (
          node.attribs &&
          node.attribs.href &&
          node.attribs.href.indexOf("target") == -1
        ) {
          node.attribs.target = "_blank";
        }

        const linkText = this.getLinkText(node);
        return <u>{linkText}</u>;
      }
    };
  }

  getLinkText(node) {
    const tagName = "a";
    const tagElement = getElementsByTagName(tagName, node)[0];

    if (tagElement && tagElement.firstChild) {
      if (
        tagElement.firstChild.children &&
        tagElement.firstChild.children.length > 0
      ) {
        return this.extractNestedLinkText(tagElement);
      } else {
        return this.extractSimpleLinkText(tagElement);
      }
    }
  }

  extractSimpleLinkText(tagElement) {
    if (tagElement.firstChild.type === "text") {
      return tagElement.firstChild.data;
    }
  }

  extractNestedLinkText(tagElement) {
    let linkText = "";
    tagElement.firstChild.children.forEach(child => {
      if (child.type === "text") {
        linkText = child.data;
      }
    });
    return linkText;
  }
}

export default componentStateMapper(Reference);
