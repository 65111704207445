import React from "react";
import "./_index.css";
import PropTypes from "prop-types";
import { Type } from "discover.medical.react-component-library";

export const BackgroundQuestion = ({ question }) => {
  return (
    <Type element="p" className={"BackgroundQuestion"}>
      {question}
    </Type>
  );
};

BackgroundQuestion.propTypes = {
  question: PropTypes.string.isRequired
};

export default BackgroundQuestion;
