import { log } from "../../analytics";
import { events } from "../index";

export default (appState, payload, type) => {
  const { tool = {} } = appState;
  const { metadata = {} } = tool;
  const { aggregateId } = appState.snapshot.events;

  const logObject = {
    type: events.GENERATE_LINK_COPY_CLICKED,
    data: {
      tool_type: metadata.toolType,
      tool_slug: metadata.toolSlug,
      aggregate_id: aggregateId,
      affordance: payload.affordance
    }
  };

  log(logObject.type, logObject.data);
};
