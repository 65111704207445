import React from "react";

import "./_index.css";
import BasicText from "../basic-text";
import InfoButton from "../shared/infobutton";
import InfoLink from "../shared/infolink";
import componentStateMapper from "../componentsStateMapper";

export class GuidelineReference extends BasicText {
  renderCompositeComponent = () => {
    const backgroundProps = this.getBackgroundProps();

    return backgroundProps ? (
      <div>
        <span className="Text_reference" data-auto="reference">
          REFERENCE:{" "}
        </span>
        <span className="Text_baseContent">{this.getBaseContent()}</span>
        <InfoLink
          text={this.getBackgroundText()}
          anchorText={backgroundProps}
        />
      </div>
    ) : (
      <div className="Text_block">
        <span className="Text_reference" data-auto="reference">
          REFERENCE:{" "}
        </span>
        <span className="Text_baseContent">{this.getBaseContent()}</span>
        <InfoButton text={this.getBackgroundText()} />
      </div>
    );
  };
}

export default componentStateMapper(GuidelineReference);
