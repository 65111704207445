import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import ReactHtmlParser from "react-html-parser";
import { Type, Anchor } from "discover.medical.react-component-library";
import "./_index.css";

export const ArticleList = ({
  articles,
  listClassName,
  articleClassName,
  dataAuto
}) => (
  <ul className={listClassName} data-auto={dataAuto}>
    {articles.map(({ name, description, link, id }) => (
      <li key={id} className={cn("Article", articleClassName)}>
        <Type
          className="Article_name"
          element="h3"
          color="primary"
          fontFamily="secondary"
        >
          <span>{ReactHtmlParser(name)}</span>
        </Type>
        <div className="Article_description">
          {ReactHtmlParser(description)}
        </div>
        {link && (
          <Anchor className="Article_link" href={link}>
            &lt;HYPERLINK&gt;
          </Anchor>
        )}
      </li>
    ))}
  </ul>
);

ArticleList.propTypes = {
  articles: PropTypes.array.isRequired,
  listClassName: PropTypes.string,
  articleClassName: PropTypes.string,
  dataAuto: PropTypes.string
};

ArticleList.defaultProps = {
  listClassName: "",
  articleClassName: "",
  dataAuto: ""
};

export default ArticleList;
