import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import isNil from "lodash/isNil";
import {
  Anchor,
  Type,
  Icon,
  Flex
} from "discover.medical.react-component-library";

import {
  ToolsListWrapper,
  ToolsItemDeleteBtn,
  ToolsItemInfoBtn,
  StyledBadge
} from "./index.styled";
import { setToolInfo } from "../../../store/tool-info/actions";
import { removeFavoriteTool } from "../../../store/favorite-tools/actions";

const ToolsItem = ({
  toolInfo,
  onInfoModalOpen,
  isForbidden,
  onSubscriptionModalOpen
}) => {
  const dispatch = useDispatch();
  const sessionNumber = useSelector(state => state.sharedData?.sessionNumber);
  const toolId = toolInfo?.delete?.functionArgs?.apiPath?.split("/").pop();

  return (
    <ToolsListWrapper
      container
      justify={{ content: "space-between" }}
      data-auto="tools-list-item"
      data-testid="tools-list-item"
    >
      <Flex cell column span={10}>
        <Flex
          container
          marginBottom="xs"
          data-auto="tool-item-title"
          align={{ item: "center" }}
        >
          <Anchor
            linkComponent={Link}
            marginRight="sm"
            fontSize="lg"
            fontWeight="bold"
            data-auto="tools-list-item-title"
            data-paid={!toolInfo.isFree}
            href={`${toolInfo.title.route}${
              !isNil(sessionNumber) ? `?session-number=${sessionNumber}` : ""
            }`}
            onClick={e => {
              if (isForbidden) {
                e.preventDefault();
                onSubscriptionModalOpen();
              }
            }}
          >
            {toolInfo.title.label}
          </Anchor>
          {toolInfo.badgeLabel && (
            <StyledBadge
              height="100%"
              size="sm"
              color="green"
              data-testid="tools-free-badge"
            >
              {toolInfo.badgeLabel.toUpperCase()}
            </StyledBadge>
          )}
        </Flex>
        <Type data-auto="tool-item-description" element="p">
          {toolInfo.shortDescription}
        </Type>
      </Flex>
      <Flex
        cell
        span={2}
        justify={{ content: "flex-end" }}
        align={{ item: "flex-start" }}
      >
        <Flex
          container
          justify={{ content: "flex-end" }}
          align={{ item: "center" }}
        >
          <ToolsItemInfoBtn
            data-auto="tool-item-info-button"
            data-testid="tool-item-info-button"
            variant="primary"
            label="info"
            aria-label={toolInfo.info?.altText}
            onClick={() => {
              dispatch(
                setToolInfo(
                  toolInfo.title.label,
                  toolInfo.info.functionArgs.apiPath
                )
              );
              onInfoModalOpen();
            }}
          >
            <Icon icon={{ glyph: toolInfo.info?.glyph, color: "white" }} />
          </ToolsItemInfoBtn>
          {toolInfo.delete && (
            <ToolsItemDeleteBtn
              data-testid="tool-item-delete-button"
              data-auto="tool-item-delete-button"
              aria-label={toolInfo.delete?.altText}
              kind="anchor"
              onClick={() => {
                dispatch(removeFavoriteTool({ favoriteToolId: toolId }));
              }}
              padded={false}
              size="iconOnly"
            >
              <Icon
                icon={{
                  glyph: toolInfo.delete?.glyph,
                  color: "gray",
                  size: "md"
                }}
              />
            </ToolsItemDeleteBtn>
          )}
        </Flex>
      </Flex>
    </ToolsListWrapper>
  );
};

ToolsItem.propTypes = {
  toolInfo: PropTypes.objectOf({
    title: PropTypes.objectOf({
      label: PropTypes.string,
      route: PropTypes.string
    }),
    shortDescription: PropTypes.string,
    icons: PropTypes.arrayOf(
      PropTypes.objectOf({
        glyph: PropTypes.string,
        altText: PropTypes.string
      })
    ),
    info: PropTypes.objectOf({
      glyph: PropTypes.string,
      altText: PropTypes.string,
      function: PropTypes.string,
      functionArgs: PropTypes.objectOf({
        apiPath: PropTypes.string
      })
    }),
    badgeLabel: PropTypes.string
  }),
  onInfoModalOpen: PropTypes.func.isRequired,
  isForbidden: PropTypes.bool,
  onSubscriptionModalOpen: PropTypes.func
};

ToolsItem.displayName = "ToolsItem";

export default ToolsItem;
