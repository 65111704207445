import parser from "../../../helpers/parser";

export const isOptionVisible = (state, option) => {
  const ui = state.fields.ui;
  if (!option) {
    return false;
  }
  try {
    if (option.visibility) {
      return parser.evaluate(option.visibility, { ui });
    }

    return true;
  } catch (e) {
    return false;
  }
};

// Uses visibility rules
export const getVisibleOptions = state => {
  const { questions, options } = state.optionGrid;

  const visibilityFlags = {};

  options.forEach(option => {
    visibilityFlags[option.optionKey] = isOptionVisible(state, option);
  });

  // Get only the visible options
  const visibleOptions = options.filter(
    option => visibilityFlags[option.optionKey]
  );

  const copyOfQuestions = JSON.parse(JSON.stringify(questions));

  // Get only the visible answers
  copyOfQuestions.forEach(question => {
    question.answers = question.answers.filter(
      answer => visibilityFlags[answer.optionKey]
    );
  });

  return {
    questions: copyOfQuestions,
    options: visibleOptions
  };
};

export const getSelectedQuestions = state => {
  const { questions, selected, singleColumn } = state.optionGrid;

  // Get only the visible answers
  const filteredQuestions = JSON.parse(JSON.stringify(questions));
  filteredQuestions.forEach(question => {
    question.answers = question.answers.filter(answer =>
      selected.includes(answer.optionKey)
    );
    // for split column views, filter out the blank answers
    if (singleColumn) {
      question.answers = question.answers.filter(answer => {
        const content = answer.answer.trim();
        return content !== "<p/>" && content !== "";
      });
    }
  });

  return filteredQuestions;
};

export const getSelectedOptions = state => {
  const { options, selected } = state.optionGrid;

  return options.filter(option => selected.includes(option.optionKey));
};

export const canToggleOptions = state => {
  const { options } = state.optionGrid;
  return options && options.length > 2;
};
