export default function useStepsSwitchingHandlers({
  moveStepForward,
  moveToStep,
  submitForm
}) {
  const handleCalculateClick = async () => {
    const isFormSubmitted = await submitForm();

    if (isFormSubmitted) {
      moveStepForward();
      return;
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleStepperItemClick = async index => {
    if (index) {
      const isFormSubmitted = await submitForm();

      if (isFormSubmitted) {
        moveToStep(index);
      }
    }
  };

  return {
    handleCalculateClick,
    handleStepperItemClick
  };
}
