import React, { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import tabComponentsRetriever from "../../../components/tabComponentsRetriever";
import DynamicForm from "../../../../content-components";
import { getIconArrayControlName, splitControls, splitHeader } from "./utils";
import {
  PopulationView,
  PopulationViewHeader,
  PopulationViewContent,
  PopulationSection,
  IconGrid,
  LegendsWrapper,
  HeaderControls,
  TreatmentOptions,
  StyledLabel
} from "./index.styled";
import parser from "../../../../../helpers/parser";
import { keyDataViewed } from "../../../../../store/telemetry/actions";

const PopulationViewTab = ({
  populationHeader,
  population,
  fields,
  controls
}) => {
  const { iconArray, iconControls, iconHeading, legends } = splitControls(
    population
  );
  const { treatmentOptions, headerControls, warning_note } = splitHeader(
    populationHeader
  );
  const iconArrayControlName = getIconArrayControlName(population);
  const populationExpression = get(controls, [
    "_all",
    iconArrayControlName,
    "attributes",
    "parameters",
    "population"
  ]);
  const denominator = populationExpression
    ? parser.evaluate(populationExpression, fields)
    : 100;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(keyDataViewed());
  }, []);

  return (
    <Fragment>
      <PopulationView data-auto="tool-template-implementation-tabs-populationview">
        <PopulationViewHeader>
          <TreatmentOptions
            componentsList={treatmentOptions}
            recalculate={true}
          />
          <HeaderControls componentsList={headerControls} recalculate={true} />
        </PopulationViewHeader>

        <PopulationViewContent>
          <DynamicForm componentsList={iconHeading} />
          <DynamicForm componentsList={warning_note} />
          <DynamicForm componentsList={iconControls} recalculate={true} />
          <PopulationSection>
            <IconGrid denominator={`${denominator}`}>
              <DynamicForm componentsList={iconArray} />
            </IconGrid>
            <LegendsWrapper denominator={`${denominator}`}>
              <DynamicForm componentsList={legends.column_one} />
              <DynamicForm componentsList={legends.column_two} />
            </LegendsWrapper>
          </PopulationSection>
        </PopulationViewContent>
      </PopulationView>
    </Fragment>
  );
};

PopulationViewTab.propTypes = {
  populationHeader: PropTypes.array.isRequired,
  population: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  populationHeader: tabComponentsRetriever(state, "populationHeader"),
  population: tabComponentsRetriever(state, "population"),
  fields: get(state, "fields"),
  controls: get(state, ["tool", "controls"])
});

export default connect(mapStateToProps)(PopulationViewTab);
