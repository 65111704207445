import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import axiosInstance from "../../services/axios-instance";
import { getSuggestions } from "../../store/search-autosuggest/actions";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import {
  StyledAutocomplete,
  AutoSuggestMenuItemMatchWrapper,
  SearchWrapper
} from "./styled";
import redirectTo from "../../helpers/url-manager";
const debounceTimeout = 250;

const SearchAutoSuggest = ({
  hasInitialFocus,
  getSuggestionValue,
  getSectionSuggestions,
  placeholder,
  renderSectionTitle,
  ...searchProps
}) => {
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState([]);
  const data = useSelector(({ searchAutoSuggest }) => searchAutoSuggest?.data);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const getSearchFieldValue = () => {
    const queryParams = new URLSearchParams(decodeURI(location.search || ""));
    const searchQuery = queryParams.get("q");
    if (searchQuery && searchQuery.length > 0) {
      return searchQuery;
    }
    return "";
  };

  const [searchText, setSearchText] = useState(getSearchFieldValue);

  // Autosuggest will call this function every time you need to clear suggestions.
  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSuggestionsFetchRequested = debounce(({ value }) => {
    if (value && value.length > 0) {
      dispatch(getSuggestions(value));
    }
  }, debounceTimeout);

  const shouldRenderSuggestions = value => {
    return value && value.trim().length > 0;
  };

  const processSuggestions = (response = []) => {
    const gotoTitle = "Go to";
    const autoCompleteTitle = "title";
    const autoCompleteTerm = "term";
    const searchForTitile = "Search for";
    const suggestionListLength = 5;
    response.topics =
      response.topics && response.topics.length > suggestionListLength
        ? response.topics.splice(0, suggestionListLength)
        : response.topics;
    response.terms =
      response.terms && response.terms.length > suggestionListLength
        ? response.terms.splice(0, suggestionListLength)
        : response.terms;
    return [
      {
        title: gotoTitle,
        autoCompleteSection: autoCompleteTitle,
        items: response.topics
          ? response.topics.filter(item => item.suggestion)
          : []
      },
      {
        title: searchForTitile,
        autoCompleteSection: autoCompleteTerm,
        items: response.terms
          ? response.terms.filter(item => item.suggestion)
          : []
      }
    ].filter(section => section.items.length > 0);
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = ({ suggestion }, { query }) => {
    const matches = match(suggestion, query);
    const parts = parse(suggestion, matches);

    return (
      <AutoSuggestMenuItemMatchWrapper data-auto="auto-complete-suggestion">
        {parts.map((part, index) => {
          const className = part.highlight ? "match" : "noMatch";
          return (
            <span className={className} key={index}>
              {part.text}
            </span>
          );
        })}
      </AutoSuggestMenuItemMatchWrapper>
    );
  };

  const getSlug = toolData => {
    if (toolData && toolData[0]?.slug) {
      const slug = toolData[0].slug;
      // We have a special case (i.e some slug have different format pubType/articleName and tools/{slug}
      // is just for backward compatibility with the older one)
      if (!slug.includes("/")) {
        return `tool/${slug}`;
      }
      return slug;
    } else {
      return null;
    }
  };

  const handleSuggestionSelected = async (e, { suggestion }) => {
    try {
      setSearchText(suggestion.suggestion);
      const calculatorUrl = `/api-tool-experience/tool/content/slug/byANs`;
      const body = {
        listAN: [suggestion.id]
      };
      const { data } = await axiosInstance.post(calculatorUrl, body);
      const slug = getSlug(data);
      if (slug) {
        redirectToTool(slug);
      } else {
        redirectToSearch();
      }
    } catch {
      setSearchText("");
    }
  };

  const redirectToTool = slug => {
    redirectTo(slug, history);
  };

  const redirectToSearch = () => {
    if (searchText) {
      const spaceReplacedTerm = searchText.trim().replace(/\s{1,}/g, "+");
      const query = `?q=${encodeURI(spaceReplacedTerm)}`;
      redirectTo("search", history, query);
      document.activeElement.blur();
    }
  };

  const onSearchSubmit = evt => {
    evt.preventDefault();
    redirectToSearch();
  };

  useEffect(() => {
    setSuggestions(processSuggestions(data));
  }, [data]);

  const inputProps = {
    placeholder: placeholder,
    value: searchText || "",
    onCancel: () => setSearchText(""),
    onChange: ({ target }) => {
      setSearchText(target.value);
    },
    hasInitialFocus: hasInitialFocus,
    cancelable: true,
    spellCheck: true,
    size: "md",
    name: "search",
    id: "autosuggest",
    "aria-label": "search",
    style: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      fontSize: theme.font.size.md
    }
  };

  return (
    <div data-auto="search-bar" {...searchProps}>
      <SearchWrapper
        role="search"
        data-auto="main-search"
        onSubmit={onSearchSubmit}
      >
        <StyledAutocomplete
          onSearchClick={onSearchSubmit}
          suggestions={suggestions}
          onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={handleSuggestionsClearRequested}
          onSuggestionSelected={handleSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          shouldRenderSuggestions={shouldRenderSuggestions}
          focusInputOnSuggestionClick={false}
          inputProps={inputProps}
          search={true}
          multiSection={true}
        />
      </SearchWrapper>
    </div>
  );
};

SearchAutoSuggest.displayName = "SearchAutoSuggest";

SearchAutoSuggest.defaultProps = {
  hasInitialFocus: false,
  renderSectionTitle: section => (
    <strong data-auto={`auto-complete-${section.autoCompleteSection}`}>
      {section.title}
    </strong>
  ),
  getSectionSuggestions: section => section.items,
  getSuggestionValue: suggestion => suggestion.suggestion,
  placeholder: "Search DynaMed Decisions"
};

SearchAutoSuggest.propTypes = {
  getSuggestionValue: PropTypes.func,
  getSectionSuggestions: PropTypes.func,
  renderSectionTitle: PropTypes.func,
  hasInitialFocus: PropTypes.bool,
  placeholder: PropTypes.string
};

export default SearchAutoSuggest;
