import { useState, useEffect } from "react";
import throttle from "lodash/throttle";
import { isScrolled } from "../util/isScrolled";

export default function useScroll(defaultIsPageScrolled = false) {
  const [isPageScrolled, setPageScrolled] = useState(defaultIsPageScrolled);

  const handleScroll = () => {
    setPageScrolled(isScrolled());
  };

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 200);
    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  });

  return [isPageScrolled];
}
