export const titles = {
  dynaMedDecisionsTools: {
    toolsTitle: "Tools"
  },
  sharedTools: {
    toolsTitle: "Our Tools"
  },
  clinicalTools: {
    toolsTitle: "By Specialty"
  }
};

export const cdhTypes = {
  tools: "dynaMedDecisionsTools",
  "shared-decision-making": "sharedTools",
  "clinical-calculators": "clinicalTools"
};

export const CdhType = Object.freeze({
  CLINICAL_TOOLS: "clinicalTools",
  SHARED_TOOLS: "sharedTools",
  DYNAMED_DECISIONS_TOOLS: "dynaMedDecisionsTools"
});
