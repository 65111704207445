import {
  CIGS_PER_PACK,
  SMOKING_STATUS_CURRENT,
  SMOKING_STATUS_NEVER
} from "../constants";

export const calculatePkyrs = ({
  cigs_day = 0,
  smoking_status = SMOKING_STATUS_NEVER,
  yrs_quit_smoking = 0,
  yrs_start_smoking = 0
}) => {
  let quit_smoking = yrs_quit_smoking;
  if (smoking_status === SMOKING_STATUS_CURRENT) {
    quit_smoking = 0;
  }
  const pkyrs = Math.round(
    (cigs_day / CIGS_PER_PACK) * (yrs_start_smoking - quit_smoking)
  );
  return {
    unit: "",
    value: pkyrs
  };
};
