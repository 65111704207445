import styled from "styled-components";

export const MainBlock = styled.div`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.gray400};
  margin: 0;
  padding: ${({ theme }) => theme.space.md} 0;
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const List = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 0;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.25rem;
  font-size: 0.875rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-right: 0.75rem;
  }

  ::after {
    content: none;
    color: ${({ theme }) => theme.colors.gray800};
    font-size: 0.875rem;
    line-height: 1.5;
    margin-left: 0.75rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      content: "|";
    }
  }
`;

export const Date = styled.div`
  vertical-align: middle;
  font-size: 0.875rem;
`;

export const Disclaimer = styled.div`
  margin-top: 1rem;
  font-size: 0.85rem;
  font-style: italic;
  text-align: left;
`;
