import React from "react";
import { useSelector } from "react-redux";
import {
  BannerWrapper,
  BannerDescription,
  FlexImage,
  BannerTitle,
  TextHolder
} from "./index.styled";

const Banner = () => {
  const {
    title = "Welcome to DynaMed Decisions",
    description = "We provide a growing collection of <strong>evidence-based clinical support and shared decision-making tools</strong>, created by our <strong>team of clinical experts</strong>, to enable busy clinicians to <strong>deliver highly personalized and patient-centered care</strong> in the context of an individual's risks, comorbidities, and preferences."
  } = useSelector(state => state.home.banner || {});

  return (
    <BannerWrapper container data-testid="banner-container">
      <FlexImage />
      <TextHolder>
        <BannerTitle
          element="h2"
          data-auto="banner-title"
          color="headingprimary"
          fontFamily="secondary"
        >
          {title}
        </BannerTitle>
        <BannerDescription>
          <span
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />
        </BannerDescription>
      </TextHolder>
    </BannerWrapper>
  );
};

Banner.displayName = "Banner";

export default Banner;
