import styled from "styled-components";
import {
  Type,
  Spinner,
  Button
} from "discover.medical.react-component-library";

export const Block = styled.div`
  text-align: center;
`;

export const SpinnerElement = styled(Spinner)`
  margin: ${({ theme }) => theme.space.md};
`;

export const SpinnerText = styled(Type)`
  color: ${({ theme }) => theme.colors.gray800};
  margin: 0;
  text-align: center;
`;

export const ButtonElement = styled(Button)`
  margin-top: ${({ theme }) => theme.space.sm};
`;
