import get from "lodash/get";
import { USER_GET_SUCCESS } from "../action-types";

const initialState = { isLoading: true };

//prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  if (type === USER_GET_SUCCESS) {
    return {
      ...state,
      isAuthorized: get(payload, "userState.subscriptionActive") || false
    };
  } else {
    return state;
  }
};
