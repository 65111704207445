import React, { useEffect } from "react";
import "discover.medical.react-component-library/lib/index.css";
import "core-js/modules/esnext.promise.all-settled";
import { Route, Switch, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { connect, useSelector } from "react-redux";
import "react-notifications/lib/notifications.css";
import "./_index.css";
import "../_index.css";

import RouteStatus from "../common/route-status";
import { MyHealthDecisionsHome, DynamedDecisionsHome } from "../pages/home";
import Tools from "../pages/tools";
import PatientEncounterPage from "../pages/patient-encounter-page";
import Tool from "../pages/tool";
import ProtectedPage from "../pages/protectedPage";
import NotFound from "../pages/notFound";
import EngineError from "../pages/engineError";
import AuthError from "../pages/authError";
import ScreenSizeController from "../../components/screen-size-controller";
import { Theme } from "discover.medical.react-component-library";
import { useFeature } from "../../helpers/amplitude";
import { getSessionNumber } from "../../store/shared-data/actions";
import { isMHDDomain } from "../../helpers/domain-manager";
import Search from "../pages/search";
import ForbiddenModal from "../../components/forbidden-modal";
import SystemCheckWrapper from "../system_check_wrapper/components";
import { useOsano } from "../../shared/hooks/useOsano";
import EditorialLeadership from "../pages/editorial-leadership";

export const App = ({ onGetSessionNumber }) => {
  const [isSystemCheckEnabled] = useFeature("f45679_system_check");
  const { location = {} } = useSelector(state => state.app);
  const user = useSelector(state => state.user);

  useEffect(() => {
    onGetSessionNumber();
  }, [onGetSessionNumber]);

  const isDynamedDecisions = !isMHDDomain(location);

  const userData = {
    puaId: user.puaId,
    user: {
      affiliationId: user.affiliationId,
      authMethodPrimary: user.auth_method_primary,
      authMethodSecondary: user.auth_method_secondary,
      ehrVendor: user.ehr_vendor
    },
    interfaceId: isMHDDomain(location) ? "dsd" : "dcd"
  };

  useOsano(userData);

  const theHomePage = isDynamedDecisions
    ? DynamedDecisionsHome
    : MyHealthDecisionsHome;

  return (
    <Theme ui={"dsd"}>
      <Switch>
        <Route exact path="/" component={theHomePage} />
        {isSystemCheckEnabled && (
          <Route exact path="/systemcheck" component={SystemCheckWrapper} />
        )}
        <Route exact path="/shared-decision-making">
          <Redirect exact to="/tools" />
        </Route>
        <Route exact path="/clinical-calculators">
          <Redirect exact to="/tools" />
        </Route>
        <Route exact path="/tools" component={Tools} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/tool/:toolSlug" component={Tool} />
        <Route
          exact
          path="/tool/:toolSlug/version/:toolVersion"
          component={Tool}
        />
        <Route exact path="/:pubType/:toolSlug" component={Tool} />
        <Route
          exact
          path="/:pubType/:toolSlug/version/:toolVersion"
          component={Tool}
        />
        <Route
          exact
          path="/patient-encounter-reports"
          component={PatientEncounterPage}
        />
        <Route exact path="/authentication-error" component={AuthError} />
        <Route exact path="/unavailable" component={EngineError} />
        <Route exact path="/access-denied" component={ProtectedPage} />
        <Route
          exact
          path="/editorial-leadership"
          component={EditorialLeadership}
        />
        <RouteStatus statusCode={404}>
          <NotFound />
        </RouteStatus>
      </Switch>
      <NotificationContainer />
      <ScreenSizeController />
      <ForbiddenModal />
    </Theme>
  );
};

export default connect(null, {
  onGetSessionNumber: getSessionNumber
})(App);
