import styled from "styled-components";
import { Type, List } from "discover.medical.react-component-library";

export const AboutBody = styled(Type)`
  margin-top: ${({ theme }) => theme.space.sm};
`;

export const StyledList = styled(List)`
  margin-top: ${({ theme }) => theme.space.md};
  margin-left: ${({ theme }) => theme.space.md};
`;

export const StyledListItem = styled(List.Item)`
  margin-left: ${({ theme }) => theme.space.md};
  margin-bottom: ${({ theme }) => theme.space.xs};
  &:last-child {
    margin-bottom: ${({ theme }) => theme.space.md};
  }
  &::marker {
    color: ${({ theme }) => theme.colors.gray600};
  }
`;
