import React, { Component } from "react";
import PropTypes from "prop-types";
import { Spinner } from "discover.medical.react-component-library";
import cn from "classnames";
import "./_index.css";

class Preloader extends Component {
  render() {
    const { isLoading } = this.props;

    return (
      <div className={cn("Preloader", { Preloader__visible: isLoading })}>
        <div className="Preloader_overlay" />
        <Spinner className="Preloader_spinner" size="xl" />
      </div>
    );
  }
}

Preloader.propTypes = {
  isLoading: PropTypes.bool
};

Preloader.defaultProps = {
  isLoading: false
};

export default Preloader;
