import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Flex, useMediaQuery } from "discover.medical.react-component-library";
import useScroll from "../../../../../shared/hooks/useScroll";

import {
  StyledDivider,
  StyledContentContainer,
  StyledPatientChartNoteButton,
  StyledRow
} from "./index.styled";
import ToolHeader from "../tool-header";
import PatientDataSummary from "./patientDataSummary";
import tabComponentsRetriever from "../../../components/tabComponentsRetriever";
import ClinicalGuidance from "./ClinicalGuidance";
import PatientAssessment from "./PatientAssessment";
import ToolStickyFooter from "../tool-sticky-footer";
import StickyFooterBtn from "./stickyFooterBtn";
import ToolHeaderButton from "../tool-header-button";
import { riskAndGuidelineViewed } from "../../../../../store/telemetry/actions/index";
import { useDispatch } from "react-redux";
import { isMHDDomain } from "../../../../../helpers/domain-manager";
import get from "lodash/get";

export default function AssessmentStep({
  moveStepBack,
  moveStepForward,
  moveToStep,
  activeStepIndex,
  openPatientChartNoteModal,
  moveToFirstStep,
  componentRef
}) {
  const [isPageScrolled] = useScroll();
  const state = useSelector(_state => _state);
  const location = get(state, "app.location", "");
  const { toolName, toolType } = state.tool.metadata;
  const riskComponentsList = tabComponentsRetriever(state, "risk");
  const guidelineComponentsList = tabComponentsRetriever(state, "guideline");

  const riskGuidanceTitles = tabComponentsRetriever(
    state,
    "riskGuidanceTitles"
  );

  const guidanceSectionTitle = riskGuidanceTitles.find(
    title => title.style === "guidance_section_title"
  );

  const riskSectionTitle = riskGuidanceTitles.find(
    title => title.style === "risk_section_title"
  );

  const patientDataSummaryTitle = riskGuidanceTitles.find(
    title => title.style === "patient_data_summary_title"
  );

  const patientDataControls = riskComponentsList.filter(
    ({ style }) => style === "patient_data_summary"
  );
  const patientAssessmentControls = riskComponentsList.filter(
    ({ style }) => style === "assessment_result"
  );
  const isMobile = useMediaQuery("max", "sm");
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(riskAndGuidelineViewed());
  }, [dispatch]);

  return (
    <Fragment>
      <ToolHeader
        activeStepIndex={activeStepIndex}
        moveToStep={moveToStep}
        title="Assessment & Guidance"
        subTitle={
          <ToolHeaderButton
            dataAuto="header-subTitle-link"
            onClick={moveToFirstStep}
          >
            {toolName}
          </ToolHeaderButton>
        }
        isChartNoteVisible={!isMHDDomain(location)}
        openPatientChartNoteModal={openPatientChartNoteModal}
        isStepsVisible={true}
      />
      <StyledDivider />
      <section data-auto="assessment-step-content">
        <Flex container column={isMobile}>
          {!!patientDataControls.length && (
            <PatientDataSummary
              cell
              componentsList={patientDataControls}
              moveStepBack={moveStepBack}
              title={patientDataSummaryTitle}
            />
          )}
          <StyledContentContainer>
            {!!patientAssessmentControls.length && (
              <PatientAssessment
                title={riskSectionTitle}
                componentsList={patientAssessmentControls}
              />
            )}
            {!!guidelineComponentsList.length && (
              <ClinicalGuidance
                title={guidanceSectionTitle}
                componentsList={guidelineComponentsList}
              />
            )}
          </StyledContentContainer>
        </Flex>
      </section>
      {toolType === 2 && (
        <ToolStickyFooter
          children={
            <StyledRow>
              {isPageScrolled && !isMHDDomain(location) && (
                <StyledPatientChartNoteButton
                  isMobile={isMobile}
                  data-auto="button-patient-chart-note"
                  onClick={openPatientChartNoteModal}
                />
              )}
              <StickyFooterBtn moveStepForward={moveStepForward} />
            </StyledRow>
          }
          stepComponentRef={componentRef}
        />
      )}
    </Fragment>
  );
}

AssessmentStep.propTypes = {
  moveStepBack: PropTypes.func.isRequired,
  moveStepForward: PropTypes.func.isRequired,
  moveToStep: PropTypes.func.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  openPatientChartNoteModal: PropTypes.func.isRequired
};
