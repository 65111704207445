import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PageContainer,
  ContentContainer,
  MainContentRail,
  RightRail,
  StyledAbout
} from "./index.styled";
import PageTemplate from "../../../templates/page";
import Loading from "./components/loading";
import Banner from "./components/banner";
import ToolsList from "./components/tools-list";
import { getHomeData } from "../../../../store/home/actions";

import CallToAction from "./components/call-to-action";

const DynamedDecisionsHome = props => {
  const dispatch = useDispatch();
  const { isLoading, hasLoaded, tools, myTools, isStale } = useSelector(
    state => state.home
  );
  useEffect(() => {
    if (isStale) {
      dispatch(getHomeData());
    }
  }, [dispatch, isStale]);

  return (
    <PageTemplate
      data-auto="home-page-content"
      isNavigationExpanded={true}
      {...props}
    >
      <PageContainer
        container
        column
        maxWidth="none"
        data-testid="home-page-content-container"
        data-auto="home-page-content"
      >
        <ContentContainer container data-auto="content-container">
          <MainContentRail>
            <Banner />
            {isLoading && !hasLoaded ? (
              <Loading />
            ) : (
              <>
                <ToolsList tools={myTools} listType={"favorite"} />
                <ToolsList tools={tools} listType={"featured"} />
              </>
            )}
          </MainContentRail>
          <RightRail>
            <StyledAbout />
            <CallToAction />
          </RightRail>
        </ContentContainer>
      </PageContainer>
    </PageTemplate>
  );
};

DynamedDecisionsHome.displayName = "DynamedDecisionsHome";

export default DynamedDecisionsHome;
