import Basic from "../basic";

export class BasicText extends Basic {
  handleGetValueE2ECommand = () => this._getText("baseContent");
  handleGetContentValueE2ECommand = () => {
    return this.handleGetValueE2ECommand();
  };
  handleGetDiscreteValueE2ECommand = () => this.getFieldValue();
}

export default BasicText;
