import styled from "styled-components";

export const Note = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray600};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  padding: 1rem;
  margin: ${({ theme }) => theme.space.md} 0;
  overflow-y: auto;
  max-height: 27rem;

  && p {
    display: block;
  }

  ul {
    list-style: disc;
    margin-left: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  @media (max-height: ${({ theme }) => theme.breakpoints.md}) {
    max-height: 24rem;
  }

  @media (max-height: ${({ theme }) => theme.breakpoints.sm}) {
    max-height: 18rem;
  }
`;
