import React from "react";
import { Type } from "discover.medical.react-component-library";
import PageLayout from "../../templates/page";
import "./_index.css";

const NotFound = () => (
  <PageLayout>
    <Type element="h2" color="primary" children="Page not found" />
    <Type
      element="p"
      className="NotFoundMsg"
      children="We're sorry, the page you are looking for could not be found."
    />
  </PageLayout>
);

export default NotFound;
