import { getSessionNumberFromURL } from "../../helpers/url-manager";

const getLoginUrl = () => {
  const isWindowDef = typeof window !== "undefined";
  const sessionNumber = isWindowDef ? getSessionNumberFromURL() : undefined;
  const returnToUrl = isWindowDef
    ? encodeURIComponent(window.location.href)
    : "/";
  return `/login?returnToUrl=${returnToUrl}${
    sessionNumber ? "&session-number=" + sessionNumber : ""
  }`;
};

export default getLoginUrl;
