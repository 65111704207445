import styled from "styled-components";
import { Type, Divider } from "discover.medical.react-component-library";
import PatientChartNoteButton from "../patient-chart-note-button";

export const HeaderTitle = styled(Type)`
  margin-top: 0.375rem;
`;

export const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.space.sm} 0;
`;

export const Anchor = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray700};
  border: 0;
  background: transparent;
  outline: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledContentContainer = styled.div`
  width: auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 53%;
  }
`;

export const StyledPatientChartNoteButton = styled(PatientChartNoteButton)`
  margin-right: ${({ theme }) => theme.space.md};
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  justify-content: flex-start;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: center;
  }
`;
