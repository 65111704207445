import { useLocation } from "react-router-dom";

export default function useIndicatorIndex(links) {
  const { pathname } = useLocation();
  const splittedPathname = pathname.split("/");
  if (splittedPathname.length > 2) {
    return -1;
  }
  return links.findIndex(link => link.route.slice(1) === splittedPathname[1]);
}
