import React from "react";
import { useSelector } from "react-redux";

import { Type } from "discover.medical.react-component-library";
import { AboutBody, StyledList, StyledListItem } from "./index.styled";

const About = ({ className }) => {
  const {
    title = "About",
    description = "Our ever-growing collection of clinical decision-making tools:",
    descriptionItems = [
      "Saves you valuable time.",
      "Enables you to deliver personalized and patient-centered care.",
      "Increases patient engagement and satisfaction.",
      "Is created by a team of clinical experts.",
      "Is updated continually to reflect the best evidence."
    ]
  } = useSelector(state => state.home.about || {});

  return (
    <div className={className}>
      <Type element="h2" fontFamily="secondary" color="senary">
        {title}
      </Type>
      <AboutBody element="p" fontSize="sm">
        {description}
        <StyledList type="ul">
          {descriptionItems.map(item => (
            <StyledListItem>{item}</StyledListItem>
          ))}
        </StyledList>
      </AboutBody>
    </div>
  );
};

About.displayName = "About";

export default About;
