import get from "lodash/get";
import {
  FIELDS_DATA_REQUEST,
  FIELDS_DATA_REQUEST_SUCCESS,
  FIELDS_DATA_REQUEST_ERROR,
  CHANGE_FIELD_UI_VALUE,
  RESET_FIELDS_DATA,
  FIELDS_DATA_OUTCOME_SUCCESS,
  OPTION_GRID_DATA_REQUEST,
  OPTION_GRID_DATA_REQUEST_ERROR,
  OPTION_GRID_DATA_OUTCOME_SUCCESS,
  FIELD_VALUE_IS_VALID,
  FIELD_VALUE_IS_INVALID,
  FIELD_IS_HIDDEN,
  FIELD_IS_VISIBLE,
  FIELDS_DATA_EHR_REQUEST,
  FIELDS_DATA_EHR_REQUEST_SUCCESS,
  FIELDS_DATA_EHR_REQUEST_ERROR,
  FIELD_SET_IS_MOUNTED,
  SET_MEASURE_VALUE,
  SET_ERRORS_NUMBER,
  CHANGE_USER_PREFERRED_MEASURE,
  TOGGLE_LAB_DATE_INFO
} from "../../action-types";
import axiosInstance from "../../../services/axios-instance";
import { getLabelByValue } from "../../../helpers/labels";
import getValidator, {
  TYPE_ALL,
  TYPE_CONSTRAINTS_ONLY,
  TYPE_ERROR,
  TYPE_RULES_ONLY
} from "../validators";

import { isNumber } from "../../../shared/util/numberUtils";
import { getBatchId } from "../../../shared/util/idUtils";
import convertObjectPropertiesTypes from "../../../helpers/convertors/object-properties-types-converter";
import { showForbidden } from "../../shared-data/actions";

export const getFieldsData = () => ({ type: FIELDS_DATA_REQUEST });
export const getOptionGridData = () => ({ type: OPTION_GRID_DATA_REQUEST });
export const getFieldsDataError = () => ({ type: FIELDS_DATA_REQUEST_ERROR });
export const getOptionGridDataError = () => ({
  type: OPTION_GRID_DATA_REQUEST_ERROR
});
export const getFieldsDataSuccess = payload => ({
  type: FIELDS_DATA_REQUEST_SUCCESS,
  payload
});
export const retrieveFieldsDataOutcomeSuccess = payload => ({
  type: FIELDS_DATA_OUTCOME_SUCCESS,
  payload
});
export const retrieveOptionGridOutcomeSuccess = payload => ({
  type: OPTION_GRID_DATA_OUTCOME_SUCCESS,
  payload
});
export const changeFieldUiValue = (name, data) => ({
  type: CHANGE_FIELD_UI_VALUE,
  payload: { name, value: data.value, label: data.label }
});
export const fieldIsValid = name => ({
  type: FIELD_VALUE_IS_VALID,
  payload: {
    name
  }
});
export const fieldIsInvalid = (name, errors) => ({
  type: FIELD_VALUE_IS_INVALID,
  payload: {
    name,
    errors
  }
});
export const fieldIsHidden = name => ({
  type: FIELD_IS_HIDDEN,
  payload: { name }
});
export const fieldIsVisible = name => ({
  type: FIELD_IS_VISIBLE,
  payload: { name }
});
export const setIsVisible = (name, isVisible) => dispatch => {
  if (isVisible) {
    dispatch(fieldIsVisible(name));
  } else {
    dispatch(fieldIsHidden(name));
  }
};
export const updateFieldUiLabelAndValue = (name, value) => (
  dispatch,
  getState
) => {
  const view = get(getState(), ["tool", "controls", "_all", name], {});
  const label = getLabelByValue(view, value);
  dispatch(changeFieldUiValue(name, { value, label }));
};
export const changeFieldsUiValues = (compName, value) => async (
  dispatch,
  getState
) => {
  const field = get(getState(), ["fields", "ui", compName], {});
  await dispatch(updateFieldUiLabelAndValue(compName, value));
  await field.associatedFields.forEach(associatedField =>
    dispatch(updateFieldUiLabelAndValue(associatedField, value))
  );
};
export const changeFieldMeasureValue = (name, measureInfo) => async (
  dispatch,
  getState
) => {
  const field = get(getState(), ["fields", "ui", name], {});
  await dispatch(setMeasureValue({ name, measureInfo }));
  await field.associatedFields?.forEach(associatedField =>
    dispatch(updateFieldUiLabelAndValue(associatedField, field.value))
  );
};
export const setMeasureValue = payload => ({
  type: SET_MEASURE_VALUE,
  payload
});
export const resetFieldsData = () => ({ type: RESET_FIELDS_DATA });
const getPatientData = state => {
  const ui = get(state, ["fields", "ui"], {});
  let body = {};
  Object.keys(ui).forEach(key => {
    const cpdn = ui[key]["cpdn"];
    const isHidden = ui[key]["isHidden"];
    if (cpdn && !isHidden) {
      const value = ui[key]["value"];
      body[cpdn] = isNumber(value) ? parseFloat(value) : value;
    }
  });
  return body;
};
const getCalculatorsApiUrl = (executorName, state) => {
  const version = get(state, ["tool", "metadata", "version"], "");
  const toolSlug = get(state, ["tool", "metadata", "toolSlug"], "");
  const pubType = get(state, ["tool", "metadata", "pubType"], "");
  const contentName = get(state, ["tool", "metadata", "contentName"], "");
  const contentVersion = get(state, ["tool", "metadata", "contentVersion"], "");
  const aggregateId = state.snapshot.events.aggregateId;
  const batchId = getBatchId();
  const pubTypeValidated = pubType ? `${pubType}/` : "";
  return `/api-tool-experience/calculators/${pubTypeValidated}${toolSlug}/versions/${version}/${executorName}?language=en-US&aggregateId=${aggregateId}&batchId=${batchId}&contentName=${contentName}&contentVersion=${contentVersion}`;
};
export const setFieldsOutcomeData = () => async (dispatch, getState) => {
  dispatch(getFieldsData());
  try {
    const ui = get(getState(), ["fields", "ui"], {});
    let body = {};
    Object.keys(ui).forEach(key => {
      const cpdn = ui[key]["cpdn"];
      const isHidden = ui[key]["isHidden"];
      if (cpdn && !isHidden) {
        const value = ui[key]["value"];
        body[cpdn] = isNumber(value) ? parseFloat(value) : value;
      }
    });
    const toolSlug = get(getState(), ["tool", "metadata", "toolSlug"], "");
    const version = get(getState(), ["tool", "metadata", "version"], "");
    const pubType = get(getState(), ["tool", "metadata", "pubType"], "");
    const contentName = get(
      getState(),
      ["tool", "metadata", "contentName"],
      {}
    );
    const contentVersion = get(
      getState(),
      ["tool", "metadata", "contentVersion"],
      {}
    );
    const { aggregateId } = getState().snapshot.events;

    const params = {
      contentName,
      contentVersion,
      aggregateId,
      batchId: getBatchId(true),
      language: "en-US"
    };
    const pubTypeValidated = pubType ? `${pubType}/` : "";
    const calculatorUrl = `/api-tool-experience/calculators/${pubTypeValidated}${toolSlug}/versions/${version}/executor`;
    const { data } = await axiosInstance.post(calculatorUrl, body, {
      params
    });
    const convertedData = convertObjectPropertiesTypes(data);
    dispatch(retrieveFieldsDataOutcomeSuccess(convertedData));
  } catch (e) {
    dispatch(showForbidden(e));
    dispatch(getFieldsDataError());
  }
};

export const setOptionGridOutcomeData = () => async (dispatch, getState) => {
  dispatch(getOptionGridData());
  try {
    const state = getState();
    const optionGridReq = {};
    const requestParams = getPatientData(state);
    optionGridReq.requestParams = requestParams;
    const options = get(state, ["optionGrid", "options"], "");
    const cpdnDefaults = get(state, ["optionGrid", "cpdnDefaults"], "");
    const overrideOptions = {};
    options.forEach(op => {
      overrideOptions[op.optionKey] = { ...cpdnDefaults, ...op.cpdnOverrides };
    });
    optionGridReq.overrideOptions = overrideOptions;
    const { data } = await axiosInstance.post(
      getCalculatorsApiUrl("batchExecutorWithOverrides", state),
      optionGridReq
    );
    dispatch(retrieveOptionGridOutcomeSuccess(data));
  } catch (e) {
    dispatch(showForbidden(e));
    dispatch(getOptionGridDataError());
  }
};

export const validateAll = () => (dispatch, getState) => {
  const state = getState();
  const fields = state.fields;

  Object.keys(fields.ui).forEach(name => {
    const { isMounted, value } = fields.ui[name];
    if (isMounted) {
      const view = get(state, ["tool", "controls", "_all", name], {});
      dispatch(validate(name, value, view, fields, TYPE_ALL));
    }
  });
};

export const calculateErrorsNumber = () => (dispatch, getState) => {
  const state = getState();
  const fields = state.fields.ui;

  const errorsNumber = Object.keys(fields).reduce((accumulator, name) => {
    const { isMounted, isHidden, validation } = fields[name];
    const isError =
      isMounted &&
      !isHidden &&
      validation?.some(({ type }) => type === TYPE_ERROR);
    return isError ? accumulator + 1 : accumulator;
  }, 0);
  dispatch(setErrorsNumber(errorsNumber));

  return errorsNumber;
};

export const setErrorsNumber = payload => ({
  type: SET_ERRORS_NUMBER,
  payload
});

export const changeUserPreferredMeasure = (name, userPreferredMeasure) => ({
  type: CHANGE_USER_PREFERRED_MEASURE,
  payload: { name, userPreferredMeasure }
});

export const validate = (name, value, view, fields, type) => async dispatch => {
  let errors = [];
  if (type === TYPE_CONSTRAINTS_ONLY || type === TYPE_ALL) {
    errors = errors.concat(validateConstraints(value, view, fields, name));
  }

  if (type === TYPE_RULES_ONLY || type === TYPE_ALL) {
    errors = errors.concat(validateRules(value, view, fields, name));
  }

  if (errors.length) {
    return dispatch(fieldIsInvalid(name, errors));
  } else {
    return dispatch(fieldIsValid(name));
  }
};

export const validateConstraints = (value, view, fields, name) => {
  const constraints = get(view, "attributes.fieldInfo.constraints", null);
  if (!constraints) {
    return [];
  }
  return Object.keys(constraints)
    .map(key => {
      const validator = getValidator(key);
      const constraintValue = constraints[key];
      return validator(value, constraintValue, fields, name);
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

export const validateRules = (value, view, fields, name) => {
  if (!value && value !== 0) {
    return [];
  }
  const validationRules = get(view, "attributes.fieldInfo.validations", []);
  return validationRules
    .map(rule => {
      const validator = getValidator("expression");
      return validator(value, rule, fields, name);
    })
    .reduce((prev, curr) => prev.concat(curr), []);
};

export const getFieldsDataEhr = () => ({ type: FIELDS_DATA_EHR_REQUEST });

export const getFieldsDataEhrSuccess = payload => ({
  type: FIELDS_DATA_EHR_REQUEST_SUCCESS,
  payload
});

export const getFieldsDataEhrError = () => ({
  type: FIELDS_DATA_EHR_REQUEST_ERROR
});

export const setIsFieldMounted = payload => ({
  type: FIELD_SET_IS_MOUNTED,
  payload
});
export const toggleLabDateInfo = payload => ({
  type: TOGGLE_LAB_DATE_INFO,
  payload
});
