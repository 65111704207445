import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const SectionTitle = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.md};
  }
`;

export const PageRoot = styled.div`
  text-align: center;
  padding-top: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: ${({ theme }) => theme.space.xl};
  }
`;
