import styled from "styled-components";
import { StepAnchor, Stepper } from "discover.medical.react-component-library";

export const StepAnchorStyled = styled(StepAnchor)`
  z-index: 1;
`;

export const StepperStyled = styled(Stepper)`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
  li {
    min-width: 5.75rem;
  }
`;
