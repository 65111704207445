import React from "react";

const AmplitudeContext = React.createContext();

const AmplitudeProvider = ({ ...props }) => {
  return <AmplitudeContext.Provider {...props} value={props.useFeature} />;
};
const AmplitudeConsumer = AmplitudeContext.Consumer;

export { AmplitudeProvider, AmplitudeConsumer, AmplitudeContext };
