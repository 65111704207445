import { log } from "../../analytics";
import { events } from "../index";

export default (appState, payload, type) => {
  const { tool = {} } = appState;

  const { aggregateId } = appState.snapshot.events;

  const { metadata = {} } = tool;

  const logObject = {
    type: events.KEY_DATA_VIEWED,
    data: {
      tool_type: metadata.toolType,
      tool_slug: metadata.toolSlug,
      aggregate_id: aggregateId
    }
  };

  log(logObject.type, logObject.data);
};
