import React, { Component } from "react";
import PropTypes from "prop-types";
import getTemplate from "./getTemplate";

export class DynamicTemplate extends Component {
  render() {
    const { templateName, isLoading, toolTabs } = this.props;
    const Template = getTemplate(templateName); // NOSONAR (Seems sonar does not understand jsx syntax)
    return <Template isLoading={isLoading} toolTabs={toolTabs} />;
  }
}

DynamicTemplate.propTypes = {
  templateName: PropTypes.string,
  toolTabs: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
};

DynamicTemplate.defaultProps = {
  templateName: null,
  isLoading: false
};

export default DynamicTemplate;
