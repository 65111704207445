import {
  HOME_DATA_NOTIFY_STALE,
  HOME_DATA_REQUEST,
  HOME_DATA_REQUEST_ERROR,
  HOME_DATA_REQUEST_SUCCESS
} from "../action-types";

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  banner: undefined,
  about: undefined,
  tools: undefined,
  callToAction: undefined,
  isStale: true,
  seamlessAuthUrl: undefined
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case HOME_DATA_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case HOME_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        myTools: undefined,
        hasLoaded: true,
        isStale: false,
        ...payload
      };
    case HOME_DATA_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        isStale: false,
      };
    case HOME_DATA_NOTIFY_STALE:
      return {
        ...state,
        isStale: true,
      };  
    default:
      return state;
  }
};
