import React from "react";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import componentStateMapper from "../componentsStateMapper";
import getCalculator from "../../../calculators/getCalculator";
import { getComponentParams } from "../../../helpers/component-params";
import BasicNumeric from "../basic-numeric";
import CalculatedValue from "../shared/calculated-value";
import ExtraLabel from "../shared/extra-label";
import { FieldName, Root } from "./index.styled";
import cropDecimalPlaces from "./helpers/cropDecimalPlaces";

export class CalculatedText extends BasicNumeric {
  state = { unit: "" };

  _didMountExtension = () => {
    this.setUnit();
  };

  _didUpdateExtension = prevProps => {
    if (!isEqual(prevProps.fields.ui, this.props.fields.ui)) {
      this.setValue();
    }
  };

  setUnit = () => {
    const { fields, type, view } = this.props;
    const params = get(view, "attributes.parameters", {});
    const paramsValues = getComponentParams(params, fields);
    const calculatorResult = getCalculator(type, paramsValues);
    if (calculatorResult && calculatorResult.unit) {
      this.setState({ unit: calculatorResult.unit });
    }
  };

  setValue = () => {
    const { name, fields, type, view } = this.props;
    const params = get(view, "attributes.parameters", {});
    const paramsValues = getComponentParams(params, fields);
    const calculatorResult = getCalculator(type, paramsValues);

    if (
      calculatorResult &&
      (calculatorResult.value || calculatorResult.value === 0) &&
      calculatorResult.value !== fields.ui[name].value &&
      !(fields.ui[name].value === "" && calculatorResult.value === 0)
    ) {
      this.setState({ unit: calculatorResult.unit });
      this.changeValue(calculatorResult.value, { isTrusted: true });
    }
  };

  renderLabel = () => null;

  renderComponent = () => {
    const { value, name, type } = this.props;
    const { unit } = this.state;
    return (
      <Root cell align={{ item: "center" }} data-testid={`${name}-component`}>
        <FieldName>{this.getBaseContent()}:</FieldName>
        <CalculatedValue
          dataAuto={`${name}-component-value`}
          value={cropDecimalPlaces(type, value)}
        />
        {!!value && (
          <ExtraLabel data-auto="calculated-text-unit">&nbsp;{unit}</ExtraLabel>
        )}
      </Root>
    );
  };
}

export default componentStateMapper(CalculatedText);
