import Basic from "../basic";
import get from "lodash/get";

export class BasicMultipleSelect extends Basic {
  handleSetValueE2ECommand = (_, value) => {
    if (value === undefined) {
      return this.changeValue([]);
    }
    return this.changeValue(value.toString().split(","));
  };

  handleGetValueE2ECommand = () => {
    const { value } = this.props;
    if (!value) {
      return "";
    }
    return value.join(",");
  };

  handleSetLabelE2ECommand = (_, value = "") => {
    const { view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    let arr = value.toString().split(",");
    let res = labels.filter(label => arr.includes(label.name)) || [];
    this.changeValue(res.map(label => label.value));
  };

  handleGetLabelE2ECommand = () => {
    const { view, value } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    let res = labels.filter(label => value.includes(label.value)) || [];
    return res.map(label => label.name).join(",");
  };
}

export default BasicMultipleSelect;
