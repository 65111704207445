export const errorMappings = {
  default: {
    errorMsgTitle:
      "We’re sorry but we cannot complete this request. Please try again.",
    errorMsgDescription:
      "If this issue persists, please contact your system administrator.",
    actionMsg: "Visit the website without authentication >"
  },
  MISSING_COOKIES: {
    errorMsgTitle: "We’re sorry but we cannot complete this request.",
    errorMsgDescription:
      "Please make sure that third-party cookies are enabled. If this issue persists, please contact your system administrator or EBSCO representative.",
    actionMsg: "Visit the website without authentication >"
  },
  INVALID_TOKENID: {
    errorMsgTitle: "We’re sorry but we cannot complete this request.",
    errorMsgDescription:
      "Please make sure the used TOKEN ID is valid. If this issue persists, please contact your system administrator or EBSCO representative.",
    actionMsg: "Visit the website without authentication >"
  },
  SEAMLESS_AUTH_ERROR: {
    errorMsgTitle: "Authentication Error",
    errorMsgDescription:
      "We apologize, but we are unable to process your request due to an authentication issue. Please verify that your login credentials are accurate before attempting again. If the problem persists, kindly contact your system administrator, EBSCO representative, or email csteam@ebsco.com for further assistance.",
    actionMsg: "Proceed to the website without authentication >"
  }
};
