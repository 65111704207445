import styled from "styled-components";

export const MainBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-top: ${({ theme }) => theme.space.md};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex-basis: calc(50% - 1.25rem);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  .Subheading {
    margin: ${({ theme }) => theme.space.sm} 0 0.25rem 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-basis: auto;
  }
`;

export const Section = styled.div`
  &:not(:last-child) {
    padding-bottom: ${({ theme }) => theme.space.sm};
  }

  .pb-sm {
    padding-bottom: ${({ theme }) => theme.space.sm};
  }

  .Text_baseContent {
    max-width: 100%;
    padding-right: 0;
    margin-top: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .Text_baseContent p {
    display: block;
  }

  a {
    word-break: break-all;
  }
`;

export const Contributors = styled(Section)`
  max-width: 100%;
  padding-right: 0;
  margin-bottom: 0.75rem;
  .Title {
    padding-bottom: 1rem;
  }
  .Subheading {
    color: ${({ theme }) => theme.colors.gray700};
    font-size: ${({ theme }) => theme.font.size.md};
    line-height: 1.25rem;
  }
`;

export const Abstract = styled.p`
  margin: 0 0 10px;
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
