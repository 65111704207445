import React, { useState } from "react";
import { Icon, Flex } from "discover.medical.react-component-library";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateUserPreference } from "../../../../../../../universal/store/user-preference/actions";
import { isMHDDomain } from "../../../../../../../universal/helpers/domain-manager";
import {
  StyledButton,
  StyledInputCheckbox,
  StyledInfoLayer,
  StyledInfoLabel,
  StyledFlexBanner
} from "./index.styled";

const PatientInfoBanner = () => {
  const dispatch = useDispatch();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const { location } = useSelector(state => state.app);
  const { contentName } = useSelector(({ tool }) => tool.metadata);
  const productType = isMHDDomain(location) ? "DSD" : "DCD";
  const userPreferenceData = useSelector(state => state.userPreference);
  let productCodeObject = {};
  try {
    productCodeObject = JSON.parse(userPreferenceData.productCode);
  } catch (e) {}
  let contentNameArray = productCodeObject?.[productType] || [];
  const [showBanner, setShowBanner] = useState(
    !contentNameArray.includes(contentName)
  );
  const [isDismissed, setIsDismissed] = useState(false);

  function showInfoBannerToggle() {
    if (doNotShowAgain) {
      contentNameArray.push(contentName);
      const productCodeObj = { [productType]: contentNameArray };
      const data = {
        productCode: JSON.stringify(productCodeObj)
      };
      dispatch(createOrUpdateUserPreference(data));
      setShowBanner(!showBanner);
    } else {
      if (contentNameArray.includes(contentName)) contentNameArray.pop();
      setIsDismissed(true);
    }
  }

  return (
    showBanner &&
    !isDismissed && (
      <StyledInfoLayer>
        <StyledFlexBanner container justify={{ content: "space-between" }}>
          <Flex cell>
            <Icon icon={{ glyph: "error", size: "lg", color: "green" }} />
            <StyledInfoLabel data-auto="patient-info-label">
              <span>
                Some data have been supplied by the EHR. Lab dates are the
                collection date or, if unavailable, the result date.
              </span>
            </StyledInfoLabel>
          </Flex>
          <Flex cell align={{ item: "center" }}>
            <StyledInputCheckbox
              name="patientDataBanner"
              label="Don't show again"
              data-auto="patient-info-checkbox"
              id="left"
              checked={doNotShowAgain}
              onChange={() => setDoNotShowAgain(!doNotShowAgain)}
            />
            <StyledButton
              variant="primary"
              data-auto="patient-info-dismiss-button"
              onClick={showInfoBannerToggle}
            >
              Dismiss
            </StyledButton>
          </Flex>
        </StyledFlexBanner>
      </StyledInfoLayer>
    )
  );
};

export default PatientInfoBanner;
