import React, { Fragment } from "react";
import get from "lodash/get";
import componentStateMapper from "../componentsStateMapper";
import BasicSingleSelect from "../basic-single-select";
import { RadioInputGroup, RadioInput } from "./index.styled";

export class RadioGroup extends BasicSingleSelect {
  renderComponent = () => {
    const { name, value, view, isRequired } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    return (
      <Fragment>
        <RadioInputGroup
          name={`${name}-group`}
          selectedValue={value.toString()}
          onChange={this.handleChange}
          required={isRequired}
          hasError={this.hasErrors()}
          hasWarning={this.hasWarnings()}
        >
          {labels.map(radio => (
            <RadioInput
              id={`${name}-${radio.name}-radio`}
              name={`${name}-group`}
              label={radio.name}
              key={`${radio.name}-radio`}
              value={radio.value}
            />
          ))}
        </RadioInputGroup>
      </Fragment>
    );
  };
}

export default componentStateMapper(RadioGroup);
