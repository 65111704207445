import styled from "styled-components";
import { Flex, Type } from "discover.medical.react-component-library";
import { ButtonLink } from "../../tools-browse-shared-components/styled-components/index.styled";

export const MainTitle = styled(Type)`
  padding-bottom: ${({ theme }) => theme.space.md};
`;

export const MainDescription = styled.div`
  margin-bottom: ${({ theme }) => theme.space.lg};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.md};
  }
`;

export const SubscribeButton = styled(ButtonLink)`
  display: block;
  width: 7.5rem;
  margin-top: ${({ theme }) => theme.space.md};
  font-size: ${({ theme }) => theme.font.size.xs};
  padding: 0.125rem ${({ theme }) => theme.space.sm};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const RightRail = styled(Flex)`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
  }
`;
