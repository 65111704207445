import React, { useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import {
  Modal,
  Type,
  Button,
  Tooltip
} from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import Mustache from "mustache";
import ReactHtmlParser from "react-html-parser";
import { VIEWPORT_LARGE } from "../../../../../../helpers/aliases";
import optionGridLogo from "../../../../../../shared/images/option-grid-logo.svg";
import { chartNoteText } from "../../../../../../shared/constants/chartnote.js";
import {
  chartNoteClicked,
  chartNoteCopyClicked
} from "../../../../../../store/telemetry/actions/index";
import { epochConverter } from "../../../../../../shared/util/epochConverter";
import "./_index.css";
import useCopy from "../hooks/useCopy";

export const OptionGridChartNote = ({ toolname, windowSize, modified }) => {
  const { closeChartNoteModal, isChartNoteOpen } = useOptionGridContext();

  const chartnoteRef = useRef(null);
  const { copy } = useCopy();
  const dispatch = useDispatch();

  const handleCopyButtonClick = () => {
    copy(chartnoteRef.current);
    dispatch(chartNoteCopyClicked("options"));
  };

  useEffect(() => {
    if (isChartNoteOpen) {
      dispatch(chartNoteClicked("options"));
    }
  }, [isChartNoteOpen]);

  return (
    <Modal
      v2
      data-auto="optiongrid-chart-note-modal"
      onRequestClose={closeChartNoteModal}
      isOpen={isChartNoteOpen}
    >
      <img
        className="OptionGridChartNote_logo"
        src={optionGridLogo}
        alt="Option Grid"
      />
      <Type
        className="OptionGridChartNote_heading"
        element="h2"
        color="primary"
        fontFamily="secondary"
      >
        Patient Chart Note
      </Type>
      <Type element="p" styledAs="em">
        This note is provided for clinicians to document use of this decision
        aid.
      </Type>
      <div
        className="OptionGridChartNote_container"
        ref={chartnoteRef}
        data-auto="check_chart_note-content-component"
      >
        <Type element="p">
          {ReactHtmlParser(
            Mustache.render(chartNoteText, {
              toolname,
              date: epochConverter(modified)
            })
          )}
        </Type>
      </div>
      <Tooltip
        placement={windowSize === VIEWPORT_LARGE ? "bottom" : "right"}
        content="Note copied to clipboard"
        hasArrow={true}
        toggle="click"
        trigger={
          <Button
            data-auto="chartnote-copy-button"
            onClick={handleCopyButtonClick}
            size="md"
          >
            Copy
          </Button>
        }
      />
    </Modal>
  );
};

OptionGridChartNote.propTypes = {
  selectedOptions: PropTypes.array.isRequired,
  toolname: PropTypes.string.isRequired,
  windowSize: PropTypes.string.isRequired,
  modified: PropTypes.string
};

const mapStateToProps = state => ({
  toolname: get(state, "tool.metadata.name", ""),
  selectedOptions: state.optionGrid.selected,
  windowSize: state.sharedData.windowSize,
  modified: state.tool.metadata.modified
});

export default connect(mapStateToProps)(OptionGridChartNote);
