import { log } from "../../analytics";
import { events } from "../index";

export default (appState, payload, type) => {
  const { tool = {} } = appState;
  const { aggregateId } = appState.snapshot.events;
  const { metadata = {} } = tool;

  const logObject = {
    type: events.TOOL_LAUNCHED,
    data: {
      tool_name: metadata.toolName,
      tool_an: metadata.contentName,
      tool_slug: metadata.toolSlug,
      aggregate_id: aggregateId
    }
  };

  log(logObject.type, logObject.data);
};
