import React from "react";
import PropTypes from "prop-types";
import { TYPE_ERROR, TYPE_WARNING } from "../../../../store/fields/validators";
import { Message } from "./index.styled";

function ValidationErrors({ validationResult, fieldName }) {
  return (
    <>
      {validationResult?.map(
        (error, index) =>
          !!error.message && (
            <Message
              key={error.message}
              hasError={TYPE_ERROR === error.type}
              hasWarning={TYPE_WARNING === error.type}
              data-auto={`validation-error-message-${fieldName}-${index}`}
              children={
                <span dangerouslySetInnerHTML={{ __html: error.message }} />
              }
            />
          )
      )}
    </>
  );
}

ValidationErrors.propTypes = {
  validationResult: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string
    })
  ),
  fieldName: PropTypes.string.isRequired
};

ValidationErrors.defaultProps = {
  validationResult: []
};

export default ValidationErrors;
