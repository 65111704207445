import styled from "styled-components";
import { Type, Button } from "discover.medical.react-component-library";

export const Root = styled.div`
  text-align: center;
`;

export const Text = styled(Type)`
  color: ${({ theme }) => theme.colors.gray800};
  margin: ${({ theme }) => `${theme.space.sm} 0`};
`;

export const ButtonElement = styled(Button)`
  margin-top: ${({ theme }) => theme.space.sm};
`;
