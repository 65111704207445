import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ToolHeader from "../../tool-header";
import { useFeature } from "../../../../../../helpers/amplitude";
import { StyledDivider } from "./index.styled";
import DynamicForm from "../../../../../content-components";
import PatientInfoBanner from "../patient-info-banner";
import EhrEnabledFieldsBanner from "../ehr-enabled-fields-banner";
import useEhrpdnsExist, {
  useMissingEhrpdnsAndMetadata
} from "../hooks/useEhrpdnsExist";
import PatientInfoPopup from "../patient-info-popup/PatientInfoPopup";

const PatientStepHeader = ({ activeStepIndex, moveToStep, topControls }) => {
  const { toolName } = useSelector(({ tool }) => tool.metadata);
  const { showInfoPopup } = useSelector(state => state.userPreference);
  const { puaReminderModalShowing } = useSelector(state => state.sharedData);
  const [isInfoBannerEnabled] = useFeature("f55614_display_ehrpdn_info");
  const [isPopulatingNoFeatureEnabled] = useFeature(
    "f65288_prepopulating_no_for_high_usage_ehr_tools"
  );
  const isEhrpdnsExist = useEhrpdnsExist();
  const controlsMissingEhrpdns = useMissingEhrpdnsAndMetadata();
  const showDialog = showInfoPopup && !puaReminderModalShowing;

  return (
    <>
      <ToolHeader
        activeStepIndex={activeStepIndex}
        moveToStep={moveToStep}
        title={toolName}
        isChartNoteVisible={false}
        isStepsVisible={true}
      />
      <DynamicForm componentsList={topControls} />
      <StyledDivider thickness="sm" noMargin={true} />
      {isPopulatingNoFeatureEnabled && isEhrpdnsExist && (
        <EhrEnabledFieldsBanner />
      )}
      {!isPopulatingNoFeatureEnabled &&
        isInfoBannerEnabled &&
        isEhrpdnsExist && <PatientInfoBanner />}
      {isInfoBannerEnabled &&
        controlsMissingEhrpdns &&
        Object.keys(controlsMissingEhrpdns).length > 0 && (
          <PatientInfoPopup
            showPopup={showDialog}
            controlsMissingEhrpdns={controlsMissingEhrpdns}
          />
        )}
    </>
  );
};

PatientStepHeader.propTypes = {
  moveToStep: PropTypes.func.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  topControls: PropTypes.array
};

PatientStepHeader.defaultProps = {
  topControls: []
};

export default PatientStepHeader;
