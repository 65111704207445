import styled from "styled-components";

export const TermsContent = styled.div`
   display: block;
   white-space: pre-wrap;
   & p {
    margin-bottom: 0rem;
   }
   & ul {
     margin-top: -1em;
     list-style: disc outside none;
   }
   & ul li {
     margin-left: 2em;
     display: list-item;
     text-align: -webkit-match-parent;
   }
   & ul li:last-child {
     margin-bottom: -1em;
   }
}
`;
