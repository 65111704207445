import styled from "styled-components";
import { Anchor, Flex, Modal } from "discover.medical.react-component-library";

export const Root = styled(Modal)`
  && {
    align-items: center;
  }
`;

export const Main = styled.div`
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
`;

export const MainContent = styled(Flex)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: calc((100 - (((12 / 8) - 1) * 4.6)) / (12 / 8) * 1%);
  }
`;

export const ButtonLink = styled(Anchor)`
  &:hover {
    text-decoration: none;
  }
`;
