import React from "react";

import ProvidersWithoutRouter from "./providers-without-router";
import { BrowserRouter } from "react-router-dom";

const Providers = ({ store, children, amplitude }) => (
  <ProvidersWithoutRouter store={store} amplitude={amplitude}>
    <BrowserRouter>{children}</BrowserRouter>
  </ProvidersWithoutRouter>
);

Providers.propTypes = {};

export default Providers;
