const mhdDomains = [
  "myhealthdecisions.com", // Live production domain
  "www.myhealthdecisions.com", // Live production domain
  "mhd.devqa.eismedi.com", // Dev env ingress domain for development use
  "mhd.live.eismedi.com", // Live env ingress domain for development use
  "healthdecisions.devqa.eismedi.com"
];

const internalDDDomains = [
  "dd.devqa.eismedi.com", // Dev env ingress domain for development use
  "dd.live.eismedi.com" // Live env ingress domain for development use
];

// The last one is confusing and temporary and will be removed later

export const isMHDDomain = (location = {}) => {
  return mhdDomains.includes(location.hostname);
};

export const isInternalDDDomain = (location = {}) => {
  return internalDDDomains.includes(location.hostname);
};
