import React from "react";
import get from "lodash/get";
import { useMediaQuery } from "discover.medical.react-component-library";
import { componentStateMapper } from "../componentsStateMapper";
import BasicSingleSelect from "../basic-single-select";
import { StyledButton } from "./index.styled";
import DecisionInput from "../decision-input";

export class ChoiceInputComp extends BasicSingleSelect {
  renderCompositeComponent = () => {
    const { value, view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    return (
      <>
        {Array.isArray(labels) &&
          labels.map(label => (
            <StyledButton
              key={label.value}
              kind="text"
              onClick={evt => this.handleToggleChange(evt, label.value)}
              label={label.name}
              selected={value === label.value}
            >
              {label.name}
            </StyledButton>
          ))}
      </>
    );
  };
}

export const ChoiceInput = props => {
  const baseMq = useMediaQuery("max", "sm");
  if (baseMq) {
    return <DecisionInput {...props}>{props.children}</DecisionInput>;
  }
  return <ChoiceInputComp {...props}>{props.children}</ChoiceInputComp>;
};

export default componentStateMapper(ChoiceInput);
