import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledType } from "./index.styled";

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogTitleActions,
  Spinner,
  Type
} from "discover.medical.react-component-library";

import {
  REPORT_REQUESTING,
  REPORT_REQUESTED,
  REPORT_PENDING,
  REPORT_NO_DATA,
  REPORT_READY,
  REPORT_FAILED,
  REPORT_ERROR,
  REPORT_TIMEOUT,
  REPORT_DONE,
  REPORT_GENERATING
} from "../../../store/patient-encounter-report/constants";

const PatientEncounterDashboardModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [allowModalDismissal, setAllowModalDismissal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const status = useSelector(state => state.patientEncounterReport?.status);

  useEffect(() => {
    switch (status) {
      case REPORT_REQUESTING:
      case REPORT_REQUESTED:
      case REPORT_PENDING:
      case REPORT_GENERATING:
      case REPORT_READY:
        setShowModal(true);
        setAllowModalDismissal(false);
        setModalMessage(
          "Please wait while your CSV report is being generated."
        );
        setShowSpinner(true);
        break;
      case REPORT_NO_DATA:
        setShowModal(true);
        setAllowModalDismissal(true);
        setModalMessage(
          "There is no reportable data for the date range selected and so no CSV was generated."
        );
        setShowSpinner(false);
        break;
      case REPORT_FAILED:
      case REPORT_ERROR:
      case REPORT_TIMEOUT:
        setShowModal(true);
        setAllowModalDismissal(true);
        setModalMessage(
          "We're sorry. There was an error in generating the CSV report. Please try again later."
        );
        setShowSpinner(false);
        break;
      case REPORT_DONE:
      default:
        setTimeout(() => setShowModal(false), 500);
    }
  }, [status]);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Dialog
      data-testid="patient-encounter-modal"
      isOpen={showModal}
      maxWidth="xl"
      aria-label="Report Status"
    >
      <DialogTitle padding="sm">
        {allowModalDismissal && (
          <>
            <Type></Type>
            <DialogTitleActions
              data-testid="patient-encounter-modal-dismiss"
              onRequestClose={closeModal}
            />
          </>
        )}
      </DialogTitle>
      <Card>
        <CardContent padding="sm">
          <StyledType element="p">{modalMessage}</StyledType>
          {allowModalDismissal && (
            <DialogActions justify="center">
              <Button
                data-testid="patient-encounter-modal-button"
                variant="primary"
                onClick={closeModal}
              >
                Okay
              </Button>
            </DialogActions>
          )}
          {showSpinner && (
            <Spinner data-testid="patient-encounter-modal-spinner" size="sm" />
          )}
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default PatientEncounterDashboardModal;
