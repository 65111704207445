import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { HiddenDiv, StyledDiv, StickyContent } from "./styled";

/**
 * Checks if the given element is in the viewport.
 *
 * @param {HTMLElement} element The element.
 *
 * @return {boolean} Returns `true` if it is, or `false` if not.
 */
const isStuck = element => {
  return window.innerHeight < element.getBoundingClientRect().bottom;
};

export const Sticky = ({
  children,
  className,
  classNameNotVisible,
  classNameVisible,
  ...props
}) => {
  const hiddenRef = useRef(null);
  const footerRef = useRef(null);
  const [sticky, setSticky] = useState(false);
  const [shadowHeight, setShadowHeight] = useState(0);

  const update = () => {
    setShadowHeight(footerRef.current.offsetHeight);
    setSticky(isStuck(hiddenRef.current));
  };

  useEffect(() => {
    update();

    window.addEventListener("scroll", update);
    window.addEventListener("resize", update);
    return () => {
      window.removeEventListener("scroll", update);
      window.removeEventListener("resize", update);
    };
  }, [hiddenRef.current]);

  return (
    <>
      <StyledDiv
        {...props}
        sticky={sticky}
        shadowHeight={shadowHeight}
        ref={footerRef}
      >
        <StickyContent sticky={sticky}>{children}</StickyContent>
      </StyledDiv>
      <HiddenDiv ref={hiddenRef} />
    </>
  );
};

Sticky.propTypes = {
  /**
   * The child elements, if any.
   */
  children: PropTypes.node.isRequired,

  /**
   * The CSS class for the element.
   */
  className: PropTypes.string,

  /**
   * The class for the element when not visible in the viewport.
   */
  classNameNotVisible: PropTypes.string,

  /**
   * The class for the element when visible in the viewport.
   */
  classNameVisible: PropTypes.string,

  /**
   * Remove the background for the element?
   */
  noBg: PropTypes.bool,

  /**
   * Allow content to bump up against the edge of the element?
   */
  wide: PropTypes.bool
};

export default Sticky;
