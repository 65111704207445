import isNil from "lodash/isNil";

export const redirectTo = (path, history, query = null) => {
  const sessionNumber = getSessionNumberFromURL();
  let queryParams = "";
  if (!isNil(query)) {
    queryParams = query;
  } else if (!isNil(sessionNumber)) {
    queryParams = `?session-number=${sessionNumber}`;
  }
  const nextPath = `/${path}${queryParams}`;
  history.replace(nextPath);
};

export const getSessionNumberFromURL = () => {
  return new URLSearchParams(window.location.search).get("session-number");
};

export const getParamFromUrl = (param, location) => {
  return new URLSearchParams(location.search).get(param);
};

export default redirectTo;
