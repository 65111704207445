import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";

export const Container = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.space.md};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }

  ${({ $containerStyles }) => $containerStyles};
`;

export const LabelColumn = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.sm};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: flex-end;
    flex-basis: 41.66666%;
    flex-shrink: 0;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-basis: 33.33333%;
  }
`;

export const ControlColumn = styled(Flex)`
  flex-wrap: wrap;
  align-content: flex-start;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex: 1 1 auto;
    padding-left: ${({ theme }) => theme.space.sm};
  }

  ${({ $controlColumnStyles }) => $controlColumnStyles};
`;

export const InfoWrapper = styled(Flex)`
  align-self: stretch;
  padding: 0.25rem 0;
  margin-left: 0.75rem;
`;

export const ErrorWrapper = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
`;

export const LabDateInfoWrapper = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
`;

export const DefaultMessageLinkWrapper = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.colors.info};
  cursor: pointer;
`;
