import React from "react";
import InfoButton from "../shared/infobutton";
import BasicText from "../basic-text";
import componentStateMapper from "../componentsStateMapper";
import { EligibilityText, InfoButtonWrapper } from "./index.styled";

export class EligibilityStatement extends BasicText {
  renderCompositeComponent = () => {
    return (
      <EligibilityText fontSize="sm">
        {this.getBaseContent()}
        <InfoButtonWrapper>
          <InfoButton text={this.getBackgroundText()} />
        </InfoButtonWrapper>
      </EligibilityText>
    );
  };
}

export default componentStateMapper(EligibilityStatement);
