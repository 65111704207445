import React from "react";
import { Anchor, Button, Type } from "discover.medical.react-component-library";
import {
  SectionContentImage,
  SectionContentTextWrapper,
  SectionContentText,
  SectionTitle,
  SectionContent,
  SectionWrapper
} from "./index.styled";
import getLoginUrl from "../../../../../shared/util/getLoginUrl";
import { ButtonLink } from "../../../../tools-browse-shared-components/styled-components/index.styled";
import { DD_MARKETING_PAGE_URL } from "../../../../../shared/constants/externalUrls";

const CallToAction = () => (
  <SectionWrapper data-auto="call-to-action">
    <SectionTitle
      data-auto="call-to-action-title"
      element="h2"
      color="senary"
      fontFamily="secondary"
    >
      Want full access?
    </SectionTitle>

    <SectionContent>
      <SectionContentTextWrapper>
        <SectionContentText
          data-auto="call-to-action-text"
          element="p"
          fontSize="sm"
          fontFamily="primary"
        >
          Bring our ever growing collection of
          <Type element="strong" fontSize="sm">
            &ensp;evidence-based decision making tools
          </Type>
          &ensp;into your daily practice…
        </SectionContentText>

        <ButtonLink
          as={Button}
          data-auto="learn-button"
          href={DD_MARKETING_PAGE_URL}
          target="_blank"
        >
          Learn More
        </ButtonLink>
      </SectionContentTextWrapper>

      <SectionContentImage />
    </SectionContent>

    <Type element="i" fontSize="sm" fontFamily="primary">
      Already a subscriber?&nbsp;
    </Type>

    <Anchor data-auto="sign-in-link" fontSize="sm" href={getLoginUrl()}>
      Sign In
    </Anchor>
  </SectionWrapper>
);

export default CallToAction;
