import React from "react";
import axios from "axios";
import getApiClient from "./get-api-client";

const Context = React.createContext();

const EdgeApiProvider = ({ baseUrl, headers, ...props }) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers
  });
  const apiClient = getApiClient(axiosInstance);
  return <Context.Provider {...props} value={apiClient} />;
};
const EdgeApiConsumer = Context.Consumer;

export { EdgeApiProvider, EdgeApiConsumer };
