import React from "react";
import { SystemCheck } from "lib-discover-medical-uisharedwidgets";
import { connect } from "react-redux";
import { DSD_DCD_URLS, DSD, DCD } from "../constants";
import PageLayout from "../../templates/page";
import PropTypes from "prop-types";
import { isMHDDomain } from "../../../helpers/domain-manager";

const SystemCheckWrapper = ({ userDetails }) => {
  return (
    <PageLayout>
      <SystemCheck userDetails={userDetails} urls={DSD_DCD_URLS} isDsd={true} />
    </PageLayout>
  );
};

SystemCheckWrapper.propTypes = {
  userDetails: PropTypes.shape({
    puaId: PropTypes.string,
    aapidr: PropTypes.string,
    email: PropTypes.string,
    profileId: PropTypes.string,
    trafficSource: PropTypes.shape({
      authMethodPrimary: PropTypes.string
    })
  })
};

SystemCheckWrapper.defaultProps = {
  userDetails: {
    puaId: "",
    email: "",
    trafficSource: {
      authMethodPrimary: ""
    }
  }
};

export const mapStateToProps = state => {
  const { location = {} } = state.app;
  const profileId = isMHDDomain(location) ? DSD : DCD;
  let userDetailObjs = {
    puaId: state.user.puaId,
    aapidr: state.user.aapidr,
    email: state.user.email,
    trafficSource: {
      authMethodPrimary: state.user.auth_method_primary
    },
    profileId
  };
  return { userDetails: userDetailObjs };
};
SystemCheckWrapper.displayName = "SystemCheckWrapper";
export default connect(mapStateToProps)(SystemCheckWrapper);
