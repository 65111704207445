import React, { Component } from "react";
import PropTypes from "prop-types";
import { Flex, Popover } from "discover.medical.react-component-library";
import {
  Container,
  InfoWrapper,
  ErrorWrapper,
  ControlColumn,
  DefaultMessageLinkWrapper,
  LabelColumn,
  LabDateInfoWrapper
} from "./index.styled";
import { FeatureComponent } from "../../../../helpers/amplitude";

export class ControlField extends Component {
  render() {
    const {
      type,
      renderLabel,
      renderComponent,
      renderInfo,
      renderErrors,
      isLabelDisplayed,
      containerStyles,
      controlColumnStyles,
      renderLabDateInfo,
      renderDefaultMessage,
      renderDefaultMessageLink
    } = this.props;
    const capitalizedType = type.charAt(0).toUpperCase() + type.slice(1);
    const typeClass = capitalizedType.replace(".", "-");

    return (
      <Container
        container
        className={`${typeClass}-field-wrapper`}
        $containerStyles={containerStyles}
      >
        {isLabelDisplayed && (
          <LabelColumn cell data-auto="control-field-label">
            {renderLabel()}
          </LabelColumn>
        )}

        <ControlColumn cell $controlColumnStyles={controlColumnStyles}>
          <Flex align={{ item: "flex-start" }} cell>
            {renderComponent()}
            <InfoWrapper cell>{renderInfo()}</InfoWrapper>
          </Flex>
          <FeatureComponent feature="f55610_display_lab_dates_for_ehr">
            {enabled =>
              enabled && (
                <LabDateInfoWrapper>{renderLabDateInfo()}</LabDateInfoWrapper>
              )
            }
          </FeatureComponent>
          <ErrorWrapper>{renderErrors()}</ErrorWrapper>
          <FeatureComponent feature="f65288_prepopulating_no_for_high_usage_ehr_tools">
            {enabled =>
              enabled && (
                <Popover
                  placement="top"
                  trigger={
                    <DefaultMessageLinkWrapper data-testid="default-message-link-wrapper">
                      {renderDefaultMessageLink()}
                    </DefaultMessageLinkWrapper>
                  }
                  popperComponentProps={{
                    variant: "primary",
                    width: "medium"
                  }}
                >
                  <span data-testid="default-message">
                    {renderDefaultMessage()}
                  </span>
                </Popover>
              )
            }
          </FeatureComponent>
        </ControlColumn>
      </Container>
    );
  }
}

ControlField.propTypes = {
  type: PropTypes.string.isRequired,
  renderLabel: PropTypes.func.isRequired,
  renderComponent: PropTypes.func.isRequired,
  renderInfo: PropTypes.func.isRequired,
  renderErrors: PropTypes.func.isRequired,
  containerStyles: PropTypes.array,
  controlColumnStyles: PropTypes.array,
  isLabelDisplayed: PropTypes.bool,
  renderLabDateInfo: PropTypes.func.isRequired,
  renderDefaultMessageLink: PropTypes.func.isRequired
};

ControlField.defaultProps = {
  containerStyles: null,
  controlColumnStyles: null,
  isLabelDisplayed: true
};

export default ControlField;
