import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ToolsListItem from "../tools-list-item/ToolsListItem";
import ToolsItem from "../tools-item";
import {
  Spinner,
  useMediaQuery
} from "discover.medical.react-component-library";
import {
  ToolsListAccordion,
  ToolsListButton,
  SingleToolsListButtonsRoot,
  ToolsListToolbar
} from "./index.styled";
import { useTheme } from "styled-components";
import InfoModal from "../info-modal";
import InfoModalv2 from "../info-modal-v2";
import SubscriptionModal from "../../pages/search/components/subscription-modal";
import { getTools, getMHDTools } from "../../../store/tool-browse/actions";
import useModal from "../../../shared/hooks/useModal";

const getCdhType = (isMHD, cdhTypeProp) =>
  isMHD ? "sharedTools" : cdhTypeProp;

const getToolsActionForProduct = isMHD => (isMHD ? getMHDTools : getTools);

const renderToolsListItems = (
  category,
  tools,
  isMobile,
  openInfoModal,
  openSubscriptionModal,
  isSDM,
  isMHD
) => {
  if (!isMHD) {
    return tools[category.nestedElementsPath].map(tool => {
      const toolInfo = {
        title: {
          label: tool.title,
          route: tool.slug
        },
        shortDescription: tool.shortDescription,
        info: {
          glyph: "information",
          altText: "More Information",
          function: "getToolInfo",
          functionArgs: {
            apiPath: `/api-tool-experience/content/${tool.an}/${tool.version}/info`
          }
        },
        badgeLabel: tool.isFree ? "free" : undefined,
        isFree: tool.isFree
      };

      return (
        <ToolsItem
          key={tool.title}
          toolInfo={toolInfo}
          onInfoModalOpen={openInfoModal}
          isForbidden={tool.isForbidden}
          onSubscriptionModalOpen={openSubscriptionModal}
        />
      );
    });
  } else {
    return tools[category.nestedElementsPath].map(tool => (
      <ToolsListItem
        {...tool}
        category={category.nestedElementsPath}
        isMobile={isMobile}
        key={tool.an}
        isSDM={isSDM}
        onInfoModalOpen={openInfoModal}
        onSubscriptionModalOpen={openSubscriptionModal}
      />
    ));
  }
};

const renderInfoModal = (
  isMHD,
  isSDM,
  isInfoModal,
  isMobile,
  isDesktop,
  closeInfoModal
) => {
  if (!isMHD) {
    return (
      <InfoModalv2
        isOpen={isInfoModal}
        isMobile={isMobile}
        isDesktop={isDesktop}
        onClose={closeInfoModal}
      />
    );
  } else {
    return (
      <InfoModal
        isOpen={isInfoModal}
        isMobile={isMobile}
        isDesktop={isDesktop}
        onClose={closeInfoModal}
        isSDM={isSDM}
      />
    );
  }
};

const ToolsList = ({ isMHD, cdhType: cdhTypeProp }) => {
  const isDesktop = useMediaQuery("min", "md");
  const isMobile = useMediaQuery("max", "sm");
  const categories = useSelector(state => state.toolBrowse.categories);
  const tools = useSelector(state => state.toolBrowse.tools);
  const isLoading = useSelector(state => state.toolBrowse.isLoading);
  const pendingCategories = useSelector(
    state => state.toolBrowse.pendingCategories
  );
  const [accordions, setAccordionsState] = useState([]);
  const [isInfoModal, openInfoModal, closeInfoModal] = useModal();
  const [
    isSubscriptionModal,
    openSubscriptionModal,
    closeSubscriptionModal
  ] = useModal();
  const {
    breakpoints: { sm }
  } = useTheme();

  const dispatch = useDispatch();

  const updateAccordion = useCallback(
    (idx, state) => {
      const newState = [...accordions];
      newState[idx] = state;
      setAccordionsState(newState);
    },
    [accordions]
  );

  const getToolsAction = getToolsActionForProduct(isMHD);
  const cdhType = getCdhType(isMHD, cdhTypeProp);
  const isSDM = cdhType === "sharedTools";
  useEffect(() => {
    const initialAccordionsState = categories.map(() => false);
    setAccordionsState(initialAccordionsState);
  }, [categories, sm]);

  const updateAccordionTools = category => {
    if (!tools[category.nestedElementsPath].length) {
      dispatch(getToolsAction(cdhType, category.nestedElementsPath));
    }
  };

  const updateAccordions = useCallback(
    state => {
      setAccordionsState(accordions.map(() => state));
      if (state) {
        categories.forEach(category => updateAccordionTools(category));
      }
    },
    [accordions, categories, cdhType, dispatch, tools]
  );

  const handleToggleAccordion = useCallback(
    idx => {
      updateAccordion(idx, !accordions[idx]);
    },
    [accordions, updateAccordion]
  );

  const handleToolsListAccordionClick = (category, idx) => {
    if (!tools[category.nestedElementsPath].length && !accordions[idx]) {
      dispatch(getToolsAction(cdhType, category.nestedElementsPath));
    }
    handleToggleAccordion(idx);
  };

  return (
    <section data-auto="tools-list">
      <ToolsListToolbar
        cell
        align={{ item: "center" }}
        justify={{ content: "space-between" }}
      >
        <SingleToolsListButtonsRoot>
          <ToolsListButton
            data-auto="tools-list-expand-button"
            kind="text"
            onClick={() => updateAccordions(true)}
            disabled={accordions.every(accordion => accordion)}
          >
            Expand All
          </ToolsListButton>

          <ToolsListButton
            data-auto="tools-list-collapse-button"
            kind="text"
            onClick={() => updateAccordions(false)}
            disabled={accordions.every(
              accordion => !categories.length || !accordion
            )}
          >
            Collapse All
          </ToolsListButton>
        </SingleToolsListButtonsRoot>
      </ToolsListToolbar>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {categories.map((category, idx) => (
            <ToolsListAccordion
              animation={false}
              data-auto="tools-list-category"
              key={category.title}
              isOpen={accordions[idx]}
              toggleText={category.title}
              variant="quinary"
              onClick={() => handleToolsListAccordionClick(category, idx)}
            >
              {pendingCategories.includes(category.nestedElementsPath) ? (
                <Spinner />
              ) : (
                renderToolsListItems(
                  category,
                  tools,
                  isMobile,
                  openInfoModal,
                  openSubscriptionModal,
                  isSDM,
                  isMHD
                )
              )}
            </ToolsListAccordion>
          ))}
        </>
      )}
      {renderInfoModal(
        isMHD,
        isSDM,
        isInfoModal,
        isMobile,
        isDesktop,
        closeInfoModal
      )}

      <SubscriptionModal
        isOpen={isSubscriptionModal}
        isMobile={isMobile}
        onClose={closeSubscriptionModal}
      />
    </section>
  );
};

ToolsList.defaultProps = {
  isMHD: false
};

ToolsList.propTypes = {
  isMHD: PropTypes.bool
};

export default ToolsList;
