import styled from "styled-components";
import {
  Flex,
  ContentFormatter
} from "discover.medical.react-component-library";

export const PillContainer = styled(Flex)`
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  margin-bottom: ${({ theme }) => theme.space.sm};
  display: flex;
  width: 100%;
  max-width: 43.75rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;

export const PillLabel = styled(Flex)`
  padding: 0.75rem ${({ theme }) => theme.space.lg};
  background-color: ${({ theme }) => theme.colors.gray300};
  border-radius: 4px 0 0 4px;
  flex: 1 1 auto;

  p {
    margin: 0;
  }
`;

export const PillValueWrapper = styled(Flex)`
  color: ${({ theme }) => theme.colors.white};
  padding: 0.75rem ${({ theme }) => theme.space.lg};
  background-color: ${({ theme }) => theme.colors.secondary.base};
  border-radius: 0 4px 4px 0;
  width: 50%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 30%;
    max-width: 40%;
  }

  p {
    margin: 0;
  }
`;

export const PillValue = styled(ContentFormatter)`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;

export const TooltipContainer = styled.div`
  width: 28px;
  margin: 0 0 ${({ theme }) => theme.space.sm} 0.2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0 0 ${({ theme }) => theme.space.sm}
      ${({ theme }) => theme.space.md};
  }
`;
