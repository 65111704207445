import React from "react";
import get from "lodash/get";
import { Toggle as RCLToggle } from "discover.medical.react-component-library";
import { componentStateMapper } from "../componentsStateMapper";
import HtmlParser from "react-html-parser";
import { Wrapper } from "./index.styled";
import BasicSingleSelect from "../basic-single-select";

export class SingleSelectToggleWithLabels extends BasicSingleSelect {
  renderComponent = () => {
    const { name, value, view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    const constraints = get(view, "attributes.fieldInfo.constraints", []);
    const minLabel = get(view, "attributes.fieldInfo.minText", "");
    const maxLabel = get(view, "attributes.fieldInfo.maxText", "");
    return (
      <Wrapper>
        {Array.isArray(labels) && (
          <RCLToggle
            id={name}
            exclusive={true}
            value={value.toString()}
            required={constraints && constraints.required}
            onChange={this.handleToggleChange}
            firstLabel={HtmlParser(minLabel)}
            lastLabel={HtmlParser(maxLabel)}
          >
            {labels.map(item => (
              <RCLToggle.Button
                data-auto="single-select-with-label"
                key={item.value}
                value={item.value}
                hasError={this.hasErrors()}
                hasWarning={this.hasWarnings()}
              >
                {item.name}
              </RCLToggle.Button>
            ))}
          </RCLToggle>
        )}
      </Wrapper>
    );
  };
}

export default componentStateMapper(SingleSelectToggleWithLabels);
