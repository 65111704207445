// pkyrs
export const CIGS_PER_PACK = 20;
export const SMOKING_STATUS_CURRENT = "current";
export const SMOKING_STATUS_NEVER = "never";

//bmi
export const BMI_COEFFICIENT = 703.07;
export const BMI_UNIT = "kg/m2";

//ehrpdns metadata
export const EHR_VARIABLE_PREFFIX = "ehr.";
export const RESOURCE_TYPE_OBSERVATION = "Observation";
