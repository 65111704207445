import React, { useState } from "react";
import { connect } from "react-redux";
import { Icon, List } from "discover.medical.react-component-library";
import OptionGridDropdown from "../option-grid-dropdown";
import { useOptionGridContext } from "../OptionGridContext";
import {
  OPTION_GRID_GRID_VIEW,
  OPTION_GRID_LIST_VIEW,
  VIEWPORT_SMALL
} from "../../../../../../helpers/aliases";
import "./_index.css";

export const OptionGridViewSelector = ({ windowSize }) => {
  const [open, setOpen] = useState(false);
  const { viewType, setViewType, isSingleColumn } = useOptionGridContext();

  const toggleMenu = React.useCallback(() => {
    setOpen(!open);
  }, [open]);

  const changeView = React.useCallback(
    event => {
      setViewType(event.target.dataset.value);
      setOpen(false);
    },
    [setViewType]
  );

  const text = viewType === OPTION_GRID_GRID_VIEW ? "Grid View" : "List View";
  const glyph = viewType === OPTION_GRID_GRID_VIEW ? "apps" : "list";

  if (windowSize === VIEWPORT_SMALL || isSingleColumn) {
    return null;
  }
  return (
    <OptionGridDropdown
      text={text}
      glyph={glyph}
      open={open}
      toggleMenu={toggleMenu}
      dataAuto="viewselector"
      buttonClassName="OptionGridViewSelector_button"
      windowSize={windowSize}
    >
      <List
        className="OptionGridViewSelector_contents"
        type="ul"
        noIndicator={true}
      >
        <List.Item
          onClick={changeView}
          data-value={OPTION_GRID_GRID_VIEW}
          className="OptionGridViewSelector_row"
          data-auto="optiongrid-viewselector-item-table"
        >
          <Icon
            icon={{ glyph: "apps", size: "sm" }}
            className="OptionGridViewSelector_icon"
          />
          Grid View
        </List.Item>
        <List.Item
          onClick={changeView}
          data-value={OPTION_GRID_LIST_VIEW}
          className="OptionGridViewSelector_row"
          data-auto="optiongrid-viewselector-item-list"
        >
          <Icon
            icon={{ glyph: "list", size: "sm" }}
            className="OptionGridViewSelector_icon"
          />
          List View
        </List.Item>
      </List>
    </OptionGridDropdown>
  );
};

const mapStateToProps = state => {
  return {
    windowSize: state.sharedData.windowSize
  };
};

export default connect(mapStateToProps)(OptionGridViewSelector);
