import React, { Fragment, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PatientStep from "../patient-step";
import AssessmentStep from "../assessment-step";
import OptionsStep from "../options-step";
import useModal from "../../../../../shared/hooks/useModal";
import ModalPatientChartNote from "../modal-patient-chartnote";
import { changeActiveTabIndex } from "../../../../../store/tool/actions";
import { toolLaunched } from "../../../../../store/telemetry/actions";

const stepToComponentMapping = {
  patient: PatientStep,
  assessment: AssessmentStep,
  options: OptionsStep
};

function getStepComponent(step) {
  return stepToComponentMapping[step] || Fragment;
}

export default function Wizard() {
  const dispatch = useDispatch();
  const activeStepIndex = useSelector(({ tool }) => tool.activeStepIndex);
  const steps = useSelector(({ tool }) => tool.controls.steps);
  const StepComponent = getStepComponent(steps[activeStepIndex]); // NOSONAR (Seems sonar does not understand jsx syntax)
  const [
    isPatientChartNoteOpen,
    openPatientChartNoteModal,
    closePatientChartNoteModal
  ] = useModal();

  const activeStep = steps[activeStepIndex];

  const isNotFirstStep = !!activeStepIndex;
  const isNotLastStep = activeStepIndex < steps.length - 1;
  const stepComponentRef = useRef(null);
  const toolSlug = useSelector(({ tool }) => tool?.metadata?.toolSlug);
  const aggregateId = useSelector(
    ({ snapshot }) => snapshot?.events?.aggregateId
  );

  useEffect(() => {
    if (aggregateId && toolSlug) {
      dispatch(toolLaunched());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregateId, toolSlug]);

  const moveStepBack = () => {
    if (isNotFirstStep) {
      dispatch(
        changeActiveTabIndex({
          activeStepIndex: activeStepIndex - 1
        })
      );
    }
  };
  const moveStepForward = () => {
    if (isNotLastStep) {
      dispatch(
        changeActiveTabIndex({
          activeStepIndex: activeStepIndex + 1
        })
      );
    }
  };
  const moveToStep = stepIndex => {
    dispatch(
      changeActiveTabIndex({
        activeStepIndex: stepIndex
      })
    );
  };

  const moveToFirstStep = useCallback(() => {
    moveToStep(0);
  }, []);

  return (
    <>
      <div ref={stepComponentRef}>
        <StepComponent
          moveToFirstStep={moveToFirstStep}
          moveStepBack={moveStepBack}
          moveStepForward={moveStepForward}
          moveToStep={moveToStep}
          activeStepIndex={activeStepIndex}
          openPatientChartNoteModal={openPatientChartNoteModal}
          componentRef={stepComponentRef}
        />
      </div>
      <ModalPatientChartNote
        closePatientChartNoteModal={closePatientChartNoteModal}
        isPatientChartNoteOpen={isPatientChartNoteOpen}
        activeStep={activeStep}
      />
    </>
  );
}
