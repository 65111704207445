import React, { Fragment } from "react";
import { TYPE_ALL } from "../../../store/fields/validators";
import BasicNumeric from "../basic-numeric";
import componentStateMapper from "../componentsStateMapper";
import { Input, Label } from "./index.styled";

export class NumericMeasureWithLabel extends BasicNumeric {
  state = {
    value: ""
  };

  _didMountExtension = () => {
    const initialStateValue =
      this.props.value !== "" ? Number(this.props.value) : "";
    this.setState({ value: initialStateValue });
    if (initialStateValue !== "") {
      this._validate(initialStateValue, TYPE_ALL);
    }
  };

  handleInputChange = event => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    this.changeValue(value, event);
    this.setState({ value });
  };

  handleSetValueE2ECommand = (_, value) => {
    this.handleInputChange({ target: { value } });
  };

  renderComponent = () => {
    const { name, measure } = this.props;
    const { value } = this.state;
    return (
      <Fragment>
        <Input
          autoComplete="off"
          id={name}
          name={name}
          data-auto={`${name}-numeric-measure-label`}
          data-testid={`${name}-component`}
          value={value}
          onChange={this.handleInputChange}
          hasError={this.hasErrors()}
          hasWarning={this.hasWarnings()}
        />
        <Label htmlFor={name} data-auto={`${name}-numeric-measure-label-unit`}>
          {measure.value}
        </Label>
      </Fragment>
    );
  };
}

export default componentStateMapper(NumericMeasureWithLabel);
