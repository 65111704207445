import styled from "styled-components";
import {
  Anchor,
  ButtonAction,
  Flex,
  Type
} from "discover.medical.react-component-library";

export const ToolsListItemRoot = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray400};

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ToolsListItemContent = styled(Flex)`
  border-right: ${({ theme, isSDM }) =>
    isSDM ? `1px solid ${theme.colors.gray400}` : "none"};
  padding: 0.75rem
    ${({ theme, pubTypePretty }) =>
      pubTypePretty ? `${theme.space.md} 0.75rem 0` : theme.space.md};
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 0.75rem 0.75rem 0.75rem
      ${({ theme, pubTypePretty }) => (pubTypePretty ? 0 : theme.space.md)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0.875rem 0.75rem 0.875rem
      ${({ theme, pubTypePretty }) => (pubTypePretty ? 0 : theme.space.md)};
  }
`;

export const ToolsListItemLink = styled(Anchor)`
  font-weight: ${({ theme }) => theme.font.weight.semibold};

  && {
    display: inline;
  }

  &:hover {
    text-decoration: none;
  }
`;

export const ToolsListItemInfo = styled(Flex)`
  padding-right: ${({ pubTypePretty }) =>
    pubTypePretty ? "0.25rem" : "0.75rem"};
  padding-left: 0.75rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-right: ${({ theme, pubTypePretty }) =>
      pubTypePretty ? theme.space.sm : "0.875rem"};
    padding-left: 0.875rem;
  }
`;

export const ToolsListItemInfoBtn = styled(ButtonAction)`
  width: 1.25rem;
  height: 1.25rem;
`;

export const ToolsListItemTitle = styled.span`
  vertical-align: middle;

  &:hover {
    text-decoration: underline;
  }
`;

export const PubTypeTitle = styled(Type)`
  margin: 0;
`;
