import styled, { css } from "styled-components";

export const HiddenDiv = styled.div`
  font-size: 1px;
  line-height: 1px;
  visibility: hidden;
`;
export const StickyContent = styled.div`
  position: relative;
  width: 100%;
  ${({ sticky }) =>
    sticky &&
    css`
      @media (min-width: ${({ theme }) =>
          theme.breakpoints.sm}) and (max-width: 1439px) {
        padding: 0 1rem;
      }
    `};
`;

export const StyledDiv = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.375rem 0;
  margin: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.gray400};
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ css }) => css}
  ${({ noBg, theme }) =>
    !noBg &&
    css`
      background: ${theme.colors.white};
    `};
  ${({ wide }) =>
    !wide &&
    css`
      padding-right: 1rem;
      padding-left: 1rem;
    `}
  position: sticky;
  z-index: ${({ theme }) => theme.zIndex.bar};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme }) => theme.space.sm} 0;
    font-size: ${({ theme }) => theme.font.size.sm};
    align-items: center;
    ${props =>
      props.isCentered &&
      `justify-content: center;
    `};
    z-index: auto;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    position: relative;
  }

  ${({ sticky, shadow }) =>
    sticky &&
    shadow &&
    css`
      box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.15);
      border: none;
      margin: 0 -1rem;
      padding: 0.375rem 1rem;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        position: fixed;
        z-index: 1;
      }
      @media (min-width: 1440px) {
        box-shadow: none;
        margin: 0;
        border: none;
        width: 100%;
        &:before {
          display: block;
          content: "";
          width: 200vw;
          left: -50%;
          position: fixed;
          left: -1rem;
          bottom: 0;
          background: #fff;
          z-index: -1;
          box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.15);
          height: ${({ shadowHeight }) => shadowHeight}px;
        }
      }
    `}
`;
