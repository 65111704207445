import {
  OPTION_GRID_REQUEST,
  OPTION_GRID_REQUEST_SUCCESS,
  OPTION_GRID_REQUEST_ERROR,
  RESET_OPTION_GRID,
  OPTION_GRID_SET_SELECTED,
  OPTION_GRID_SELECT_VIEW
} from "../action-types";
import { OPTION_GRID_GRID_VIEW } from "../../helpers/aliases";

export const initialState = {
  loading: false,
  options: [],
  questions: [],
  selected: [],
  viewType: OPTION_GRID_GRID_VIEW,
  singleColumn: false,
  popUpComponents: []
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case OPTION_GRID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case OPTION_GRID_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: payload.options.map(option => option.optionKey),// set all options selected by default
        ...payload
      };
    case OPTION_GRID_REQUEST_ERROR:
      return {
        ...state,
        loading: false
      };
    case OPTION_GRID_SET_SELECTED:
      return {
        ...state,
        selected: payload
      };
    case OPTION_GRID_SELECT_VIEW:
      return {
        ...state,
        viewType: payload
      };
    case RESET_OPTION_GRID:
      return initialState;
    default:
      return state;
  }
};
