import React from "react";

import "./_index.css";
import componentStateMapper from "../componentsStateMapper";
import InfoButton from "../shared/infobutton";

import { Icon } from "discover.medical.react-component-library";
import iconMap from "../shared/icon-map";
import get from "lodash/get";
import BasicText from "../basic-text";

export class Legend extends BasicText {
  renderCompositeComponent = () => {
    const icon = this.getIconName();
    return (
      <div className="Legend">
        {icon && (
          <Icon
            className="Legend_icon"
            icon={{ glyph: this.getIconName(), size: "xl" }}
          />
        )}
        <span className="Legend_text">{this.getBaseContent()} </span>
        <InfoButton text={this.getBackgroundText()} />
      </div>
    );
  };

  getIconName = () => {
    const { view } = this.props;
    const iconType = get(view, "attributes.fieldInfo.iconType", "");
    return get(iconMap, iconType, "");
  };
}

export default componentStateMapper(Legend);
