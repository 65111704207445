import styled from "styled-components";
import {
  Layer,
  Label,
  Flex,
  Divider
} from "discover.medical.react-component-library";

export const StyledInfoLayer = styled(Layer)`
  padding: 0.5rem;
`;

export const StyledFlexBanner = styled(Flex)`
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const StyledInfoLabel = styled(Label)`
  margin-top: 4px;
  margin-left: 4.5px;
  font-size: 0.875rem;
  text-align: center;
`;

export const HighlightedSpan = styled.span`
  display: inline;
  background-color: ${({ theme }) => theme.colors.yellow};
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;

export const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.space.sm} 0;
`;
