import { DD_MARKETING_PAGE_URL } from "./externalUrls";

export const newLinks = [
  {
    isExternal: false,
    route: "/tools",
    label: "Tools",
    id: "tools"
  },
  {
    isExternal: true,
    route: DD_MARKETING_PAGE_URL,
    label: "About",
    id: "about"
  }
];
