import React from "react";
import { useSelector } from "react-redux";

import { Anchor, Button, Type } from "discover.medical.react-component-library";
import {
  SectionContentImage,
  SectionContentTextWrapper,
  SectionContentBody,
  SectionTitle,
  SectionContent,
  SectionWrapper
} from "./index.styled";
import getLoginUrl from "../../../../../../shared/util/getLoginUrl";
import { ButtonLink } from "../../../../../tools-browse-shared-components/styled-components/index.styled";

const CallToAction = () => {
  const callToAction = useSelector(state => state.home.callToAction);
  const { title, description, button, signIn } = callToAction || {};
  return callToAction ? (
    <SectionWrapper data-auto="call-to-action">
      <SectionContent>
        <SectionContentTextWrapper>
          <SectionTitle
            data-auto="call-to-action-title"
            element="h2"
            color="senary"
            fontFamily="secondary"
          >
            {title}
          </SectionTitle>

          <SectionContentBody
            dangerouslySetInnerHTML={{
              __html: description
            }}
          />

          <ButtonLink
            as={Button}
            data-auto="learn-button"
            href={button.route}
            target="_blank"
          >
            {button.label}
          </ButtonLink>
        </SectionContentTextWrapper>

        <SectionContentImage />
      </SectionContent>

      <Type element="i" fontSize="sm" fontFamily="primary">
        {signIn.message}&nbsp;
      </Type>

      <Anchor data-auto="sign-in-link" fontSize="sm" href={getLoginUrl()}>
        {signIn.link.label}
      </Anchor>
    </SectionWrapper>
  ) : (
    <></>
  );
};

CallToAction.displayName = "CallToAction";

export default CallToAction;
