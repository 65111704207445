import axiosInstance from "../../../services/axios-instance";

import {
  TOOL_BROWSE_GET_CATEGORIES,
  TOOL_BROWSE_GET_CATEGORIES_SUCCESS,
  TOOL_BROWSE_GET_CATEGORIES_ERROR,
  TOOL_BROWSE_GET_TOOLS,
  TOOL_BROWSE_GET_TOOLS_ERROR,
  TOOL_BROWSE_GET_TOOLS_SUCCESS,
  TOOL_BROWSE_GET_TOOL_INFO,
  TOOL_BROWSE_GET_TOOL_INFO_ERROR,
  TOOL_BROWSE_GET_TOOL_INFO_SUCCESS,
  TOOL_BROWSE_SET_TOOL_INFO_DATA,
  TOOL_BROWSE_RESET
} from "../../action-types";
import { showForbidden } from "../../shared-data/actions";
export const getCategoriesRequest = () => ({
  type: TOOL_BROWSE_GET_CATEGORIES
});

export const getCategoriesError = () => ({
  type: TOOL_BROWSE_GET_CATEGORIES_ERROR
});

export const getCategoriesSuccess = payload => ({
  type: TOOL_BROWSE_GET_CATEGORIES_SUCCESS,
  payload
});

export const getCategories = (cdhType, isMHD) => async dispatch => {
  const url = `/api-tool-experience/browse/${cdhType}`;
  dispatch(reset());
  dispatch(getCategoriesRequest());
  try {
    const { data } = await axiosInstance.get(url);
    dispatch(getCategoriesSuccess(data));
    dispatch(
      isMHD
        ? getMHDTools(cdhType, data.cdh[0].nestedElementsPath)
        : getTools(cdhType, data.cdh[0].nestedElementsPath)
    );
  } catch (e) {
    dispatch(showForbidden(e));
    dispatch(getCategoriesError());
  }
};

export const getToolsRequest = nestedElementsPath => ({
  type: TOOL_BROWSE_GET_TOOLS,
  payload: nestedElementsPath
});

export const getToolsError = () => ({
  type: TOOL_BROWSE_GET_TOOLS_ERROR
});

export const getToolsSuccess = (tools, nestedElementsPath) => ({
  type: TOOL_BROWSE_GET_TOOLS_SUCCESS,
  payload: { tools, nestedElementsPath }
});

export const getTools = (cdhType, nestedElementsPath) => async dispatch => {
  const url = `/api-tool-experience/cdh/${cdhType}?path=${encodeURIComponent(
    nestedElementsPath
  )}`;
  dispatch(getToolsRequest(nestedElementsPath));
  try {
    const { data } = await axiosInstance.get(url);
    dispatch(getToolsSuccess(data, nestedElementsPath));
  } catch (e) {
    dispatch(showForbidden(e));
    dispatch(getToolsError());
  }
};

export const getMHDTools = (cdhType, nestedElementsPath) => async dispatch => {
  const url = `/api-tool-experience/cdh/${cdhType}?path=${encodeURIComponent(
    nestedElementsPath
  )}`;
  dispatch(getToolsRequest(nestedElementsPath));
  try {
    let { data } = await axiosInstance.get(url);
    data = data.filter(
      ({ isPatientData, isSharedDecision }) =>
        !isPatientData && !isSharedDecision
    );
    dispatch(getToolsSuccess(data, nestedElementsPath));
  } catch (e) {
    dispatch(showForbidden(e));
    dispatch(getToolsError());
  }
};

export const getToolInfoRequest = () => ({
  type: TOOL_BROWSE_GET_TOOL_INFO
});

export const getToolInfoError = () => ({
  type: TOOL_BROWSE_GET_TOOL_INFO_ERROR
});

export const getToolInfoSuccess = (an, info) => ({
  type: TOOL_BROWSE_GET_TOOL_INFO_SUCCESS,
  payload: { an, info }
});

export const getToolInfo = (an, version) => async dispatch => {
  const toolsUrl = `/api-tool-experience/content/${an}/${version}/info`;
  dispatch(getToolInfoRequest());
  try {
    const { data } = await axiosInstance.get(toolsUrl);
    dispatch(getToolInfoSuccess(an, data));
  } catch (e) {
    dispatch(showForbidden(e));
    dispatch(getToolInfoError());
  }
};

export const setToolInfoData = (category, an, version) => ({
  type: TOOL_BROWSE_SET_TOOL_INFO_DATA,
  payload: { category, an, version }
});

export const reset = () => ({
  type: TOOL_BROWSE_RESET
});
