import { useLocation } from "react-router-dom";

export default function useIsSnapshotId(staticContext) {
  const location = useLocation();
  if (staticContext) {
    return !!staticContext.queryParams.snapshotId;
  } else {
    const queryParams = new URLSearchParams(location.search);
    return !!queryParams.get("snapshotId");
  }
}
