import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Footer,
  FooterLink,
  FooterSocialLink,
  useMediaQuery
} from "discover.medical.react-component-library";

import logo from "../../shared/images/EbscoLogo.svg";
import { FeedbackWidget } from "lib-discover-medical-uisharedwidgets";
import { useFeature } from "../../helpers/amplitude";
import isNil from "lodash/isNil";
import "./_index.css";
import { isMHDDomain } from "../../helpers/domain-manager";
import { useSelector } from "react-redux";
import {
  DD_MARKETING_PAGE_URL,
  MHD_MARKETING_PAGE_URL,
  TERMS_OF_USE_URL
} from "../../shared/constants/externalUrls";
import TermsOfUseDialog from "../../components/TermsOfUse";
import { useOsano, hasOsano } from "../../shared/hooks/useOsano.js";
import { handleFooterLinkClick } from "./util/footerLinkClick";

const getProductType = location => (isMHDDomain(location) ? "DSD" : "DCD");

const getFooterLink = (
  link,
  onManageCookies,
  handleOnClick,
  hasOsano,
  showOsanoDrawer
) => {
  return !link.cookiesLink || onManageCookies ? (
    <FooterLink
      key={link.url}
      data-testid="footer-links"
      href={link.url}
      target={link.target}
      onClick={handleFooterLinkClick(
        link,
        () => onManageCookies(hasOsano, showOsanoDrawer),
        handleOnClick
      )}
    >
      {link.text}
    </FooterLink>
  ) : null;
};

export const AppFooter = ({ userDetails, sessionNumber }) => {
  const [isSystemCheckEnabled] = useFeature("f45679_system_check");
  const currentYear = new Date().getFullYear();
  const { location } = useSelector(state => state.app);
  const productType = getProductType(location);
  const feedbackUrl = "/api-feedback/api/feedbacks/v2/messages";
  const [isTermsDlgOpen, setIsTermsDlgOpen] = useState(false);

  const dialogConfig = {
    right: 0,
    buttonsRight: "0.5rem",
    bottom: 0,
    zIndex: 1
  }; // NOSONAR
  const feedbackButtonConfig = {
    position: "fixed"
  }; // NOSONAR

  const profileInfo = {
    customerId: userDetails?.affiliationId ? userDetails.affiliationId : "NA",
    userId: userDetails?.puaId ? userDetails.puaId : "NA",
    authenticationType: userDetails?.auth_method_primary
      ? userDetails.auth_method_primary
      : "unauthenticated"
  };

  const handleOnClick = event => {
    // If Terms & Condition link (which is at TERMS_OF_USE_URL pop up dialog to display
    const target =
      event.target && event.target.href
        ? event.target.href
        : window.event.srcElement.href;
    if (target.indexOf("ebsco-policies") >= 0) {
      event.preventDefault();
      event.stopPropagation();
      setIsTermsDlgOpen(true);
    }
  };

  const handleCloseDlg = () => {
    setIsTermsDlgOpen(false);
  };

  const userData = {
    puaId: userDetails?.puaId,
    user: {
      affiliationId: userDetails?.affiliationId,
      authMethodPrimary: userDetails?.auth_method_primary,
      authMethodSecondary: userDetails?.auth_method_secondary,
      ehrVendor: userDetails?.ehr_vendor
    },
    interfaceId: isMHDDomain(location) ? "dsd" : "dcd"
  };

  const { showOsanoDrawer } = useOsano(userData);
  const onManageCookies = hasOsano() ? showOsanoDrawer : null;

  const footerLinks = [
    ...[
      {
        url: `/editorial-leadership${
          !isNil(sessionNumber) ? `?session-number=${sessionNumber}` : ""
        }`, // NOSONAR
        text: "Editorial Leadership",
        target: "_self"
      }
    ],
    ...[
      isMHDDomain(location)
        ? {
            url: MHD_MARKETING_PAGE_URL,
            text: "About My Health Decisions"
          }
        : {
            url: DD_MARKETING_PAGE_URL,
            text: "About DynaMed Decisions"
          } // NOSONAR
    ],
    {
      url: "https://connect.ebsco.com/s/article/DynaMed-Decisions-User-Guide", // NOSONAR
      text: "Help"
    },
    ...[
      isSystemCheckEnabled && {
        url: `/systemcheck${
          !isNil(sessionNumber) ? `?session-number=${sessionNumber}` : ""
        }`, // NOSONAR
        text: "System Check",
        target: "_self"
      }
    ],
    {
      url: "http://support.ebscohost.com/ehost/privacy.html", // NOSONAR
      text: "Privacy Policy"
    },
    {
      url: TERMS_OF_USE_URL,
      text: "Terms of Use"
    },
    {
      url: "http://support.ebscohost.com/ehost/terms.html#copyright", // NOSONAR
      text: "Copyright"
    },
    {
      url: "",
      text: "Manage Cookies",
      cookiesLink: true
    }
  ];

  const socialLinks = [
    {
      name: "twitter",
      url: "https://twitter.com/EBSCO_Health"
    }, // NOSONAR
    {
      name: "linkedin",
      url: "https://www.linkedin.com/showcase/ebsco-health" // NOSONAR
    }
  ];

  return (
    <div className="Footer_container" data-auto="footer">
      <Footer
        className="Footer"
        links={footerLinks
          ?.filter(ele => ele?.url || ele?.cookiesLink)
          .map(link =>
            getFooterLink(
              link,
              onManageCookies,
              handleOnClick,
              hasOsano,
              showOsanoDrawer
            )
          )}
        socialLinks={socialLinks?.map(link => (
          <FooterSocialLink
            key={link.url}
            data-testid="footer-social-links"
            glyph={link.name}
            href={link.url}
          />
        ))}
        logo={<img className="Footer_logo" alt="Dynamed" src={logo} />}
        copyright={`© ${currentYear} EBSCO Industries, Inc. All rights reserved.`}
      />
      <TermsOfUseDialog
        isTermsDlgOpen={isTermsDlgOpen}
        handleCloseDlg={handleCloseDlg}
        termsUrl={TERMS_OF_USE_URL}
      />
      <FeedbackWidget
        productType={productType}
        feedbackUrl={feedbackUrl}
        isMobile={useMediaQuery("max", "sm")}
        dialogConfig={dialogConfig}
        feedbackButtonConfig={feedbackButtonConfig}
        profileInfo={profileInfo}
      />
    </div>
  );
};

const mapStateToProps = ({ sharedData: { sessionNumber }, user }) => ({
  userDetails: user,
  sessionNumber
});

export default connect(mapStateToProps)(AppFooter);
