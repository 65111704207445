export const round1 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    return Math.round(value);
  }
};

export const round01 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    return Math.round(value * 10) / 10;
  }
};

export const round001 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    return Math.round(value * 100) / 100;
  }
};

export const round0001 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    return Math.round(value * 1000) / 1000;
  }
};

export const roundmin1 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    const result = Math.round(value);
    return result < 1 ? "< 1" : result;
  }
};

export const percentage1 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    return Math.round(value * 100);
  }
};

export const percentage01 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    return Math.round(value * 1000) / 10;
  }
};

export const percentage001 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    return Math.round(value * 10000) / 100;
  }
};

export const percentage0001 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    return Math.round(value * 100000) / 1000;
  }
};

export const percentmin05 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    const result = Math.round(value * 1000) / 10;
    return result < 0.5 ? "< 0.5" : result;
  }
};

export const percentmin1 = () => (text, render) => {
  const value = render(text);
  if (value && (Number(value) || Number(value) === 0)) {
    const result = Math.round(value * 1000) / 10;
    return result < 1 ? "< 1" : result;
  }
};
