import Mustache from "mustache";
import * as rounders from "../rounders";

const MustacheExtender = {
  render: (template, params) =>
    Mustache.render(template, {
      ...params,
      ...rounders
    })
};

export default MustacheExtender;
