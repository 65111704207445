import React from "react";
import { Type } from "discover.medical.react-component-library";
import componentStateMapper from "../componentsStateMapper";
import "./_index.css";
import { DefinitionWrapper } from "./index.styled";
import BasicText from "../basic-text";

export class Definition extends BasicText {
  renderCompositeComponent = () => {
    const inlineText = this.getInlineText();

    return (
      <DefinitionWrapper>
        <Type element="h6" fontWeight="semibold">
          {this.getBaseContent()}
        </Type>
        {inlineText && (
          <div className="Definition_Description">{inlineText}</div>
        )}
      </DefinitionWrapper>
    );
  };
}

export default componentStateMapper(Definition);
