import { calculateBmi } from "./bmi";
import { calculateEgfr } from "./egfr";
import { calculatePkyrs } from "./pkyrs";

export const calculatorsMap = {
  defaultType: () => null,
  "numeric.bmi": calculateBmi,
  "numeric.egfr": calculateEgfr,
  "numeric.pkyrs": calculatePkyrs
};

export const getCalculator = (type, ...args) =>
  calculatorsMap[type]
    ? calculatorsMap[type](...args)
    : calculatorsMap["defaultType"]();

export default getCalculator;
