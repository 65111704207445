import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isMHDDomain } from "../../helpers/domain-manager";

const DomainSpecific = ({ MHD = false, DSD = false, children }) => {
  const { location } = useSelector(state => state.app);
  if (MHD && isMHDDomain(location)) {
    return children;
  }
  if (DSD && !isMHDDomain(location)) {
    return children;
  }

  return null;
};

DomainSpecific.propTypes = {
  MHD: PropTypes.bool,
  DSD: PropTypes.bool
};

export default DomainSpecific;
