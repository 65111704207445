import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./_index.css";

const OptionGridDataCell = ({
  className,
  children,
  isQuestion,
  width,
  isSingleColumn
}) => {
  return (
    <td
      className={cn("OptionGridDataCell", className, {
        OptionGridDataCell__widthSm: width === "small",
        OptionGridDataCell__widthMd: width === "medium",
        OptionGridDataCell__widthLg: width === "large",
        OptionGridDataCell__question: isQuestion,
        OptionGridDataCell__singleColumn: isSingleColumn
      })}
    >
      {children}
    </td>
  );
};

OptionGridDataCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isQuestion: PropTypes.bool,
  width: PropTypes.oneOf(["small", "medium", "large"]),
  isSingleColumn: PropTypes.bool
};

OptionGridDataCell.defaultProps = {
  className: "",
  children: null,
  isQuestion: false,
  width: "small",
  isSingleColumn: false
};

export default OptionGridDataCell;
