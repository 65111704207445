import React from "react";
import { Anchor, Type } from "discover.medical.react-component-library";
import { AccessInfoContent, AccessInfoFooter } from "./index.styled";
import getLoginUrl from "../../../shared/util/getLoginUrl";

const AccessInfo = () => (
  <AccessInfoContent>
    <Type
      data-auto="access-modal-text"
      element="p"
      fontSize="sm"
      fontFamily="primary"
    >
      We're sorry! You do not have permission to access this page.
    </Type>

    <AccessInfoFooter element="p">
      <Type element="i" fontSize="sm" fontFamily="primary">
        Have an account with access?&nbsp;
      </Type>

      <Anchor data-auto="sign-in-link" fontSize="sm" href={getLoginUrl()}>
        Sign In
      </Anchor>
    </AccessInfoFooter>
  </AccessInfoContent>
);

export default AccessInfo;
