import months from "../constants/months.js";

//A function to convert epoch time to a format like "Apr 5, 2004"
export const epochConverter = epochTime => {
  if (!epochTime) {
    return "";
  }

  let currentDatetime = new Date(epochTime);
  let month = months[currentDatetime.getMonth()];
  let day = currentDatetime.getDate();
  let year = currentDatetime.getFullYear();

  return `${month} ${day}, ${year}`;
};
