export function isNumber(value, supportComma = true) {
  // !isNaN(parseFloat(value)) --> filters objects, null and undefined values
  // isNaN(Number(value)) --> filters any problematic stings like: 5,1
  // !isNaN(Number(value.replace(",", "."))) --> the last attempt to handle problematic number: replace ',' with '.'
  return (
    !isNaN(parseFloat(value)) &&
    (!isNaN(Number(value)) ||
      (supportComma && !isNaN(Number(value.replace(",", ".")))))
  );
}
