import React from "react";
import PropTypes from "prop-types";
import {
  InfoModalCaption,
  InfoModalIcon,
  InfoModalLegendItemRoot
} from "./index.styled";

const InfoModalLegendItem = ({ glyph, size, text }) => {
  return (
    <InfoModalLegendItemRoot data-auto="info-modal-legend-item">
      <InfoModalIcon icon={{ glyph, size }} />
      <InfoModalCaption element="p" display="inline" fontSize="sm">
        {text}
      </InfoModalCaption>
    </InfoModalLegendItemRoot>
  );
};

InfoModalLegendItem.prototype = {
  glyph: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default InfoModalLegendItem;
