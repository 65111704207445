import React from "react";

const infographicBlock1 = {
  icon: (
    <g>
      <rect fill="#faa61a" width="14" height="14" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock2 = {
  icon: (
    <g>
      <rect fill="#d42026" width="14" height="14" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock3 = {
  icon: (
    <g>
      <rect fill="#d42026" width="14" height="14" />
      <rect fill="#fff" x="2" y="2" width="10" height="10" />
      <rect fill="#faa61a" x="4" y="4" width="6" height="6" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock4 = {
  icon: (
    <g>
      <rect fill="#a8aaac" width="14" height="14" />
      <rect fill="#fff" x="2" y="2" width="10" height="10" />
      <rect fill="#faa61a" x="4" y="4" width="6" height="6" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock5 = {
  icon: (
    <g>
      <rect fill="#a8aaac" width="14" height="14" />
      <rect fill="#fff" x="2" y="2" width="10" height="10" />
      <rect fill="#3988af" x="4" y="4" width="6" height="6" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock6 = {
  icon: (
    <g>
      <rect fill="#3988af" width="14" height="14" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock7 = {
  icon: (
    <g>
      <rect fill="#a6a8ab" width="14" height="14" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock8 = {
  icon: (
    <g>
      <rect fill="#d42026" width="14" height="14" />
      <rect fill="#fff" x="2" y="2" width="10" height="10" />
      <rect fill="#a8aaac" x="4" y="4" width="6" height="6" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock9 = {
  icon: (
    <g>
      <rect fill="#faa61b" width="14" height="14" />
      <rect fill="#fff" x="2" y="2" width="10" height="10" />
      <rect fill="#a8aaac" x="4" y="4" width="6" height="6" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock10 = {
  icon: (
    <g>
      <rect fill="#96cdf0" width="14" height="14" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock11 = {
  icon: (
    <g>
      <rect fill="#a8aaac" width="14" height="14" />
      <rect fill="#fff" x="2" y="2" width="10" height="10" />
      <rect fill="#94CEF3" x="4" y="4" width="6" height="6" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicBlock12 = {
  icon: (
    <g>
      <rect fill="#a8aaac" width="14" height="14" />
      <rect fill="#fff" x="2" y="2" width="10" height="10" />
      <rect fill="#163341" x="4" y="4" width="6" height="6" />
    </g>
  ),
  viewBox: "0 0 14 14"
};

const infographicPerson1 = {
  icon: (
    <g>
      <path
        fill="#faa61a"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#faa61a"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson2 = {
  icon: (
    <g>
      <path
        fill="#d42026"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#d42026"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson3 = {
  icon: (
    <g>
      <path
        d="M15.7,1.1V32.49H1.1V1.1H15.7M16.8,0H0V33.59H16.8V0Z"
        fill="#d42026"
      />
      <path
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
        fill="#faa51a"
        fillRule="evenodd"
      />
      <path
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
        fill="#faa51a"
        fillRule="evenodd"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson4 = {
  icon: (
    <g>
      <path
        fill="#a8aaac"
        d="M15.7,1.1V32.49H1.1V1.1H15.7M16.8,0H0V33.59H16.8V0Z"
      />
      <path
        fill="#faa61a"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#faa61a"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson5 = {
  icon: (
    <g>
      <path
        fill="#A6A8AB"
        d="M15.7,1.1V32.49H1.1V1.1H15.7M16.8,0H0V33.59H16.8V0Z"
      />
      <path
        fill="#94CEF3"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#94CEF3"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson6 = {
  icon: (
    <g>
      <path
        fill="#a8aaac"
        d="M15.7,1.1V32.49H1.1V1.1H15.7M16.8,0H0V33.59H16.8V0Z"
      />
      <path
        fill="#3988af"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#3988af"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson7 = {
  icon: (
    <g
      id="icon-/-7"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-6" transform="translate(5.500000, 6.000000)" fill="#3988AE">
        <path
          d="M10.0075672,9.42065742 C11.6269355,9.44599359 12.9560112,10.7664808 12.9757649,12.3652366 C13.0088307,15.0912375 13.0045364,17.8176679 12.983924,20.5432394 C12.9744766,21.7769824 12.1280765,22.3927804 10.6160649,22.3051773 C10.5503626,23.3027356 10.4790778,24.3007234 10.4202463,25.2995699 C10.2467579,28.2647616 10.0762755,31.2308121 9.90794023,34.1964331 C9.8576973,35.0814816 9.48753145,35.4645303 8.6050595,35.47226 C7.23218222,35.4847133 5.85844608,35.4801308 4.4855688,35.4744071 C3.47727481,35.4709717 3.11140323,35.1489016 3.04698922,34.1345957 C2.93705598,32.4173182 2.8619063,30.6974641 2.76829127,28.9793277 C2.6467635,26.7510323 2.52180032,24.522737 2.39511943,22.2407633 C1.00120024,22.4915485 0.0431491801,21.8813331 0.026830964,20.6570375 C-0.00967030878,17.8503043 -0.0173999901,15.041424 0.058179116,12.2364085 C0.100692363,10.6591241 1.42075016,9.44384646 2.99803457,9.42022799 C5.33411603,9.38544442 7.67148577,9.38415614 10.0075672,9.42065742 Z M6.57716935,0.00137761788 C8.70841425,0.0597796544 10.397779,1.81699387 10.3269236,3.90186069 C10.2543505,6.0348233 8.47652381,7.74608886 6.42085801,7.66149179 C4.3359912,7.57603587 2.6384673,5.81109197 2.68484538,3.77732694 C2.73294118,1.67055935 4.51205616,-0.0557361384 6.57716935,0.00137761788 Z"
          id="Combined-Shape"
        ></path>
      </g>
      <ellipse
        id="Oval"
        stroke="#A6A8AB"
        strokeWidth="1.5"
        cx="12"
        cy="24"
        rx="11.25"
        ry="23.25"
      ></ellipse>
    </g>
  ),
  viewBox: "0 0 24 48"
};

const infographicPerson8 = {
  icon: (
    <g>
      <path
        fill="#3988af"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#3988af"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson9 = {
  icon: (
    <g>
      <path
        fill="#163341"
        d="M15.7,1.1V32.49H1.1V1.1H15.7M16.8,0H0V33.59H16.8V0Z"
      />
      <path
        fill="#163341"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#163341"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson10 = {
  icon: (
    <g>
      <path
        fill="#a6a8ab"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#a6a8ab"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson11 = {
  icon: (
    <g>
      <path
        fill="#d42026"
        d="M15.7,1.1V32.49H1.1V1.1H15.7M16.8,0H0V33.59H16.8V0Z"
      />
      <path
        fill="#a8aaac"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#a8aaac"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson12 = {
  icon: (
    <g
      id="icon-/-12"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-6" transform="translate(5.500000, 6.000000)" fill="#A6A8AB">
        <path
          d="M10.0075672,9.42065742 C11.6269355,9.44599359 12.9560112,10.7664808 12.9757649,12.3652366 C13.0088307,15.0912375 13.0045364,17.8176679 12.983924,20.5432394 C12.9744766,21.7769824 12.1280765,22.3927804 10.6160649,22.3051773 C10.5503626,23.3027356 10.4790778,24.3007234 10.4202463,25.2995699 C10.2467579,28.2647616 10.0762755,31.2308121 9.90794023,34.1964331 C9.8576973,35.0814816 9.48753145,35.4645303 8.6050595,35.47226 C7.23218222,35.4847133 5.85844608,35.4801308 4.4855688,35.4744071 C3.47727481,35.4709717 3.11140323,35.1489016 3.04698922,34.1345957 C2.93705598,32.4173182 2.8619063,30.6974641 2.76829127,28.9793277 C2.6467635,26.7510323 2.52180032,24.522737 2.39511943,22.2407633 C1.00120024,22.4915485 0.0431491801,21.8813331 0.026830964,20.6570375 C-0.00967030878,17.8503043 -0.0173999901,15.041424 0.058179116,12.2364085 C0.100692363,10.6591241 1.42075016,9.44384646 2.99803457,9.42022799 C5.33411603,9.38544442 7.67148577,9.38415614 10.0075672,9.42065742 Z M6.57716935,0.00137761788 C8.70841425,0.0597796544 10.397779,1.81699387 10.3269236,3.90186069 C10.2543505,6.0348233 8.47652381,7.74608886 6.42085801,7.66149179 C4.3359912,7.57603587 2.6384673,5.81109197 2.68484538,3.77732694 C2.73294118,1.67055935 4.51205616,-0.0557361384 6.57716935,0.00137761788 Z"
          id="Combined-Shape"
        ></path>
      </g>
      <ellipse
        id="Oval"
        stroke="#FFA60D"
        strokeWidth="1.5"
        cx="12"
        cy="24"
        rx="11.25"
        ry="23.25"
      ></ellipse>
    </g>
  ),
  viewBox: "0 0 24 48"
};

const infographicPerson13 = {
  icon: (
    <g>
      <path
        fill="#faa61b"
        d="M15.7,1.1V32.49H1.1V1.1H15.7M16.8,0H0V33.59H16.8V0Z"
      />
      <path
        fill="#a8aaac"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#a8aaac"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson14 = {
  icon: (
    <g>
      <path
        fill="#96cdf0"
        fillRule="evenodd"
        d="M8.45,2.7a3,3,0,1,1-.12,6,3,3,0,0,1-2.92-3A3,3,0,0,1,8.45,2.7Z"
      />
      <path
        fill="#96cdf0"
        fillRule="evenodd"
        d="M11.63,10.07a2.6,2.6,0,0,1,2.73,2.3q0,3.21,0,6.4c0,1-.79,1.45-2.18,1.38-.06.78-.13,1.56-.18,2.34l-.48,7c0,.7-.38,1-1.2,1-1.26,0-2.53,0-3.79,0-.93,0-1.27-.25-1.33-1.05-.1-1.34-.17-2.68-.26-4-.11-1.74-.22-3.48-.34-5.27-1.28.2-2.17-.28-2.18-1.24,0-2.19,0-4.39,0-6.59a2.55,2.55,0,0,1,2.71-2.2Q8.4,10,11.63,10.07Z"
      />
    </g>
  ),
  viewBox: "0 0 16.8 33.59"
};

const infographicPerson15 = {
  icon: (
    <g>
      <g id="Group-6" transform="translate(5.000000, 6.000000)" fill="#94CEF3">
        <path
          d="M10.5075672,9.42065742 C12.1269355,9.44599359 13.4560112,10.7664808 13.4757649,12.3652366 C13.5088307,15.0912375 13.5045364,17.8176679 13.483924,20.5432394 C13.4744766,21.7769824 12.6280765,22.3927804 11.1160649,22.3051773 C11.0503626,23.3027356 10.9790778,24.3007234 10.9202463,25.2995699 C10.7467579,28.2647616 10.5762755,31.2308121 10.4079402,34.1964331 C10.3576973,35.0814816 9.98753145,35.4645303 9.1050595,35.47226 C7.73218222,35.4847133 6.35844608,35.4801308 4.9855688,35.4744071 C3.97727481,35.4709717 3.61140323,35.1489016 3.54698922,34.1345957 C3.43705598,32.4173182 3.3619063,30.6974641 3.26829127,28.9793277 C3.1467635,26.7510323 3.02180032,24.522737 2.89511943,22.2407633 C1.50120024,22.4915485 0.54314918,21.8813331 0.526830964,20.6570375 C0.490329691,17.8503043 0.48260001,15.041424 0.558179116,12.2364085 C0.600692363,10.6591241 1.92075016,9.44384646 3.49803457,9.42022799 C5.83411603,9.38544442 8.17148577,9.38415614 10.5075672,9.42065742 Z M7.07716935,0.00137761788 C9.20841425,0.0597796544 10.897779,1.81699387 10.8269236,3.90186069 C10.7543505,6.0348233 8.97652381,7.74608886 6.92085801,7.66149179 C4.8359912,7.57603587 3.1384673,5.81109197 3.18484538,3.77732694 C3.23294118,1.67055935 5.01205616,-0.0557361384 7.07716935,0.00137761788 Z"
          id="Combined-Shape"
        ></path>
      </g>
      <rect
        id="Rectangle"
        stroke="#94CEF3"
        strokeWidth="1.5"
        x="0.75"
        y="0.75"
        width="22.5"
        height="46.5"
      ></rect>
    </g>
  ),
  viewBox: "0 0 24 48"
};

const helpOutline = {
  icon: (
    <g>
      <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14a4 4 0 0 0-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5a4 4 0 0 0-4-4z" />
    </g>
  )
};

export default {
  infographicBlock1,
  infographicBlock2,
  infographicBlock3,
  infographicBlock4,
  infographicBlock5,
  infographicBlock6,
  infographicBlock7,
  infographicBlock8,
  infographicBlock9,
  infographicBlock10,
  infographicBlock11,
  infographicBlock12,
  infographicPerson1,
  infographicPerson2,
  infographicPerson3,
  infographicPerson4,
  infographicPerson5,
  infographicPerson6,
  infographicPerson7,
  infographicPerson8,
  infographicPerson9,
  infographicPerson10,
  infographicPerson11,
  infographicPerson12,
  infographicPerson13,
  infographicPerson14,
  infographicPerson15,
  help_outline: helpOutline
};
