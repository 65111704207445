import { BMI_COEFFICIENT, BMI_UNIT } from "../constants";

export const calculateBmi = ({ weight_lbs = 0, height_in = 0 }) => {
  if (Number(weight_lbs) && Number(height_in)) {
    const value = (
      (weight_lbs / (height_in * height_in)) *
      BMI_COEFFICIENT
    ).toFixed(1);
    return {
      unit: BMI_UNIT,
      value
    };
  }
  return {
    unit: BMI_UNIT,
    value: 0
  };
};
