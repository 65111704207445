import {
  TOOL_DATA_REQUEST,
  TOOL_DATA_REQUEST_SUCCESS,
  TOOL_DATA_REQUEST_ERROR,
  RESET_TOOL_DATA,
  TOOL_METADATA_REQUEST,
  TOOL_METADATA_REQUEST_ERROR,
  TOOL_METADATA_REQUEST_SUCCESS,
  TOOL_TABS_REQUEST,
  TOOL_TABS_REQUEST_SUCCESS,
  TOOL_TABS_REQUEST_ERROR,
  TOOL_ACTIVE_TAB_INDEX_CHANGE
} from "../../action-types";

export const getToolData = () => ({ type: TOOL_DATA_REQUEST });
export const getToolDataError = () => ({ type: TOOL_DATA_REQUEST_ERROR });
export const getToolDataSuccess = payload => ({
  type: TOOL_DATA_REQUEST_SUCCESS,
  payload
});
export const resetToolData = () => ({ type: RESET_TOOL_DATA });

export const getToolMetadata = () => ({ type: TOOL_METADATA_REQUEST });
export const getToolMetadataError = () => ({
  type: TOOL_METADATA_REQUEST_ERROR
});
export const getToolMetadataSuccess = payload => ({
  type: TOOL_METADATA_REQUEST_SUCCESS,
  payload
});

export const getToolTabs = () => ({ type: TOOL_TABS_REQUEST });
export const getToolTabsSuccess = payload => ({
  type: TOOL_TABS_REQUEST_SUCCESS,
  payload
});
export const getToolTabsErrors = () => ({ type: TOOL_TABS_REQUEST_ERROR });

export const changeActiveTabIndex = payload => ({
  type: TOOL_ACTIVE_TAB_INDEX_CHANGE,
  payload
});
