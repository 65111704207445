import styled from "styled-components";

export const Main = styled.main`
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
