import {
  FAVORITE_TOOLS_REQUEST,
  FAVORITE_TOOLS_REQUEST_SUCCESS,
  FAVORITE_TOOLS_REQUEST_ERROR,
  FAVORITE_TOOL_MODAL_TOGGLE,
  FAVORITE_TOOL_ADD_ERROR,
  FAVORITE_TOOL_RESET_TOAST,
  FAVORITE_TOOL_RESET
} from "../action-types";

export const initialState = {
  isLoading: false,
  sharedTools: [],
  clinicalTools: [],
  showToast: false,
  modalStatus: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FAVORITE_TOOLS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case FAVORITE_TOOLS_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...payload
      };

    case FAVORITE_TOOLS_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        sharedTools: [],
        clinicalTools: []
      };
    case FAVORITE_TOOL_ADD_ERROR:
    case FAVORITE_TOOL_MODAL_TOGGLE:
      return {
        ...state,
        ...payload
      };
    case FAVORITE_TOOL_RESET:
      return {
        ...initialState
      };
    case FAVORITE_TOOL_RESET_TOAST:
      return {
        ...state,
        showToast: false
      };
    default:
      return state;
  }
};
