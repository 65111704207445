import BasicText from "../basic-text";
import MustacheExtender from "../../../helpers/mustache-override";
import get from "lodash/get";

export class BasicUnit extends BasicText {
  getUnits() {
    const { view, fields } = this.props;
    const units = get(view, ["attributes", "fieldInfo", "units"], "");
    return units ? MustacheExtender.render(units, fields) : "";
  }
  handleGetContentValueE2ECommand = () => this.handleGetValueE2ECommand();
  handleGetDiscreteValueE2ECommand = () =>
    `${this.getFieldValue()} ${this.getUnits()}`;
}

export default BasicUnit;
