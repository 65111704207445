import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";

export const StyledContainer = styled(Flex)`
  position: relative;
  padding: 1rem 0.625rem 1rem 1.875rem;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1.5rem 1rem 1.5rem 4.375rem;
    ${({ mhd = false }) =>
      mhd &&
      `
    display: flex;
    justify-content: flex-end;
    padding-right: 3rem;
  `}
  }
`;
