import React from "react";
import { Anchor, Button, Type } from "discover.medical.react-component-library";
import {
  LearnButton,
  SubscriptionInfoContent,
  SubscriptionInfoFooter
} from "./index.styled";
import getLoginUrl from "../../../shared/util/getLoginUrl";
import { DD_MARKETING_PAGE_URL } from "../../../shared/constants/externalUrls";

const SubscriptionInfo = () => (
  <SubscriptionInfoContent>
    <Type
      data-auto="subscription-modal-text"
      element="p"
      fontSize="sm"
      fontFamily="primary"
    >
      Sorry! This tool is available to subscribers only.
    </Type>

    <LearnButton
      as={Button}
      data-auto="learn-button"
      href={DD_MARKETING_PAGE_URL}
      target="_blank"
    >
      Learn about Subscriptions
    </LearnButton>

    <SubscriptionInfoFooter element="p">
      <Type element="i" fontSize="sm" fontFamily="primary">
        Already a subscriber?&nbsp;
      </Type>

      <Anchor data-auto="sign-in-link" fontSize="sm" href={getLoginUrl()}>
        Sign In
      </Anchor>
    </SubscriptionInfoFooter>
  </SubscriptionInfoContent>
);

export default SubscriptionInfo;
