import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Modal } from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import DynamicForm from "../../../../../content-components/";
import optionGridLogo from "../../../../../../shared/images/option-grid-logo.svg";
import tabComponentsRetriever from "../../../tabComponentsRetriever";
import "./_index.css";

export function OptionGridAgreeModal({ popUpComponents, slug }) {
  const agreedList = JSON.parse(sessionStorage.getItem("agreedList")) || [];
  const isAgreed = agreedList.includes(slug);
  const {
    openAgreeModal,
    closeAgreeModal,
    isAgreeOpen
  } = useOptionGridContext();

  useEffect(() => {
    if (popUpComponents.length !== 0 && !isAgreed && !isAgreeOpen) {
      openAgreeModal();
    }
  }, [isAgreeOpen, isAgreed, popUpComponents.length, openAgreeModal]);

  const setUpAgreement = useCallback(() => {
    sessionStorage.setItem("agreedList", JSON.stringify([...agreedList, slug]));
  }, [agreedList, slug]);

  const closeModalAndAgree = useCallback(() => {
    closeAgreeModal();
    setUpAgreement();
  }, [setUpAgreement, closeAgreeModal]);

  return (
    <Modal v2 isOpen={isAgreeOpen} closeable={false}>
      <div className="OptionGridAgreeModal_container">
        <img className="OptionGridAgreeModal_logo" src={optionGridLogo} />
        <DynamicForm componentsList={popUpComponents} />
      </div>
      <div className="OptionGridAgreeModal_footer">
        <Button
          data-auto="optiongrid-agree-modal-btn"
          className="OptionGridAgreeModal_button"
          onClick={closeModalAndAgree}
        >
          I understand
        </Button>
      </div>
    </Modal>
  );
}

OptionGridAgreeModal.propTypes = {
  popUpComponents: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  popUpComponents: tabComponentsRetriever(state, "popUpComponents"),
  slug: state.tool.metadata.slug
});

export default connect(mapStateToProps)(OptionGridAgreeModal);
