import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Type } from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import DynamicForm from "../../../../../content-components/";
import OptionGridModal from "../option-grid-modal";
import tabComponentsRetriever from "../../../tabComponentsRetriever";
import "./_index.css";

export function OptionGridAbout({
  contributors,
  certifications,
  competingInterests
}) {
  const { closeAboutModal, isAboutOpen } = useOptionGridContext();

  return (
    <OptionGridModal
      className="OptionGridModal"
      onRequestClose={closeAboutModal}
      isOpen={isAboutOpen}
      header="About"
      size="large"
    >
      <div className="OptionGridAbout">
        <div className="OptionGridAbout_Column">
          <section className="OptionGridAbout_Section OptionGridAbout_Contributors">
            <Type
              element="h4"
              color="primary"
              fontFamily="secondary"
              className="OptionGridAbout_Title"
            >
              Contributors
            </Type>

            <DynamicForm componentsList={contributors} />
          </section>
        </div>

        <div className="OptionGridAbout_Column">
          {certifications && !!certifications.length && (
            <section className="OptionGridAbout_Section OptionGridAbout_Certification">
              <Type
                element="h4"
                color="primary"
                fontFamily="secondary"
                className="OptionGridAbout_Title"
              >
                Certification
              </Type>
              <DynamicForm componentsList={certifications} />
            </section>
          )}

          <section className="OptionGridAbout_Section">
            <Type
              element="h4"
              color="primary"
              fontFamily="secondary"
              className="OptionGridAbout_Title"
            >
              Disclosure/Conflict of Interest
            </Type>
            <DynamicForm componentsList={competingInterests} />
          </section>

          <section className="OptionGridAbout_Section">
            <Type
              element="h4"
              color="primary"
              fontFamily="secondary"
              className="OptionGridAbout_Title"
            >
              Funding
            </Type>
            <p className="OptionGridAbout_Abstract">
              The development of this decision aid was fully supported by EBSCO
              Health with no external funding.
            </p>
          </section>
        </div>
      </div>
    </OptionGridModal>
  );
}

OptionGridAbout.propTypes = {
  contributors: PropTypes.array.isRequired,
  certifications: PropTypes.array.isRequired,
  competingInterests: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  contributors: tabComponentsRetriever(state, "contributors"),
  certifications: tabComponentsRetriever(state, "certifications"),
  competingInterests: tabComponentsRetriever(state, "competingInterests")
});

export default connect(mapStateToProps)(OptionGridAbout);
