import React from "react";
import { connect } from "react-redux";
import OptionGridViewSelector from "./option-grid-view-selector";
import OptionGridFooter from "./option-grid-footer";
import OptionGridReferences from "./option-grid-references";
import OptionGridAbout from "./option-grid-about";
import OptionGridInfoPanel from "./option-grid-info-panel";
import OptionGridChartNote from "./option-grid-chartnote";
import { OptionGridProvider } from "./OptionGridContext";
import OptionGridActionPanel from "./option-grid-action-panel";
import OptionGridView from "./option-grid-view";
import OptionGridOptionSelector from "./option-grid-option-selector";
import OptionGridHeader from "./option-grid-header";
import OptionGridGlossary from "./option-grid-glossary";
import OptionGridAgreeModal from "./option-grid-agree-modal";
import OptionGridLinkModal from "./option-grid-link-modal";
import OptionGridQrCodeModal from "./option-grid-qr-code-modal";
import OptionGridWarningModal from "./option-grid-warning-modal";
import OptionGridPdfViewer from "./option-grid-pdf-viewer";

import "./_index.css";

import {
  selectOptionGridViewType,
  pdfViewOpen
} from "../../../../../store/option-grid/actions";
import {
  createSnapshot,
  createSnapshotRequest,
  sendOpenAccessGeneratedEvent,
  resetSnapshotData,
  openLink,
  openQRCode
} from "../../../../../store/snapshot/actions";
import {
  canSnapshotSelector,
  snapshotProcessedSelector
} from "../../../../../store/snapshot/selectors";
import { triggerInterfaceChange } from "../../../../../store/snapshot/actions";

import useModal from "../../../../../shared/hooks/useModal";
import useSnapshot from "./hooks/useSnapshot";
import usePdfGeneration from "./hooks/usePdfGeneration";
import useLinkGeneration from "./hooks/useLinkGeneration";
import useQrCodeGeneration from "./hooks/useQrCodeGeneration";
import useViewType from "./hooks/useViewType";
import useInfoPanel from "./hooks/useInfoPanel";
import useInterfaceChangeEvent from "./hooks/useInterfaceChangeEvent";

export const OptionGrid = ({
  options,
  setViewType,
  viewType,
  windowSize,
  toolSlug,
  contentName,
  header,
  content,
  modified,
  singleColumn,
  selected,
  createSnapshotAction,
  aggregateId,
  snapshotProcessingInfo,
  snapshotData,
  resetSnapshotDataAction,
  triggerInterfaceChangeAction,
  sendOpenAccessGeneratedEventAction,
  isSnapshotAvailable,
  optionGridLoaded,
  openLinkAction,
  openQRCodeAction,
  canSnapshot
}) => {
  const [
    isReferencesOpen,
    openReferencesModal,
    closeReferencesModal
  ] = useModal();
  const [isChartNoteOpen, openChartNoteModal, closeChartNoteModal] = useModal();
  const [isAboutOpen, openAboutModal, closeAboutModal] = useModal();
  const [isAgreeOpen, openAgreeModal, closeAgreeModal] = useModal();

  const {
    openInfoPanel,
    closeInfoPanel,
    isInfoPanelOpen,
    selectedOption,
    getOptionName
  } = useInfoPanel({ options });

  useInterfaceChangeEvent({
    aggregateId,
    isSnapshotAvailable,
    canSnapshot,
    optionGridLoaded,
    selected,
    triggerInterfaceChangeAction,
    viewType
  });

  useViewType({
    windowSize,
    viewType,
    setViewType,
    singleColumn
  });

  const {
    isSpinnerShown,
    setIsSpinnerShown,
    createSnapshot: createSnapshotHelper
  } = useSnapshot({
    createSnapshotAction,
    aggregateId,
    snapshotProcessingInfo
  });

  const {
    isWarningModalOpen,
    closeWarningModal,
    printPDF,
    pdfUrl,
    closePdfViewer
  } = usePdfGeneration({
    toolSlug,
    contentName,
    options,
    selected,
    setIsSpinnerShown,
    resetSnapshotDataAction,
    aggregateId,
    snapshotProcessingInfo
  });

  const { isLinkOpen, openLinkModal, closeLinkModal } = useLinkGeneration({
    setIsSpinnerShown,
    sendOpenAccessGeneratedEventAction,
    resetSnapshotDataAction,
    openLinkAction,
    snapshotProcessingInfo,
    aggregateId
  });

  const {
    isQrCodeOpen,
    openQrCodeModal,
    closeQrCodeModal
  } = useQrCodeGeneration({
    setIsSpinnerShown,
    sendOpenAccessGeneratedEventAction,
    resetSnapshotDataAction,
    openQRCodeAction,
    snapshotProcessingInfo,
    aggregateId
  });

  return (
    <OptionGridProvider
      value={{
        viewType: viewType,
        isSingleColumn: singleColumn,
        setViewType,
        printPDF,
        openChartNoteModal,
        closeChartNoteModal,
        isReferencesOpen,
        openReferencesModal,
        closeReferencesModal,
        isWarningModalOpen,
        closeWarningModal,
        isAboutOpen,
        openAboutModal,
        closeAboutModal,
        isInfoPanelOpen,
        openInfoPanel,
        closeInfoPanel,
        isChartNoteOpen,
        openAgreeModal,
        closeAgreeModal,
        isAgreeOpen,
        modified,
        selectedOption,
        selectedOptionsList: selected,
        isLinkOpen,
        openLinkModal,
        closeLinkModal,
        isQrCodeOpen,
        openQrCodeModal,
        closeQrCodeModal,
        getOptionName,
        snapshotId: snapshotProcessingInfo.snapshotId,
        createSnapshot: createSnapshotHelper,
        isSpinnerShown,
        setIsSpinnerShown,
        snapshotProcessingInfo,
        isSnapshotAvailable: snapshotData.isSnapshotAvailable,
        snapshotCreatedAt: snapshotData.createdAt,
        canSnapshot,
        pdfUrl,
        contentName,
        closePdfViewer,
        pdfViewOpen
      }}
    >
      <div className="OptionGrid">
        {header}
        <div className="OptionGrid_content"> {content}</div>
      </div>
    </OptionGridProvider>
  );
};

OptionGrid.Header = OptionGridHeader;
OptionGrid.ActionPanel = OptionGridActionPanel;
OptionGrid.View = OptionGridView;
OptionGrid.OptionSelector = OptionGridOptionSelector;
OptionGrid.ViewSelector = OptionGridViewSelector;
OptionGrid.Footer = OptionGridFooter;
OptionGrid.References = OptionGridReferences;
OptionGrid.AgreeModal = OptionGridAgreeModal;
OptionGrid.About = OptionGridAbout;
OptionGrid.InfoPanel = OptionGridInfoPanel;
OptionGrid.Glossary = OptionGridGlossary;
OptionGrid.ChartNote = OptionGridChartNote;
OptionGrid.LinkModal = OptionGridLinkModal;
OptionGrid.QrCodeModal = OptionGridQrCodeModal;
OptionGrid.WarningModal = OptionGridWarningModal;
OptionGrid.PdfViewer = OptionGridPdfViewer;

const mapStateToProps = state => ({
  singleColumn: state.optionGrid.singleColumn,
  viewType: state.optionGrid.viewType,
  selected: state.optionGrid.selected,
  options: state.optionGrid.options,
  windowSize: state.sharedData.windowSize,
  toolSlug: state.tool.metadata.slug,
  contentName: state.tool.metadata.contentName,
  modified: state.tool.metadata.modified,
  aggregateId: state.snapshot.events.aggregateId,
  snapshotProcessingInfo: snapshotProcessedSelector(state),
  snapshotId: state.snapshot.snapshotId,
  snapshotData: state.snapshot.snapshotData,
  isSnapshotAvailable: state.snapshot.snapshotData.isSnapshotAvailable,
  optionGridLoaded: state.optionGrid.questions.length > 0,
  canSnapshot: canSnapshotSelector(state)
});

const mapDispatchToProps = {
  setViewType: selectOptionGridViewType,
  createSnapshotAction: createSnapshot,
  createSnapshotRequestAction: createSnapshotRequest,
  resetSnapshotDataAction: resetSnapshotData,
  openLinkAction: openLink,
  openQRCodeAction: openQRCode,
  triggerInterfaceChangeAction: triggerInterfaceChange,
  sendOpenAccessGeneratedEventAction: sendOpenAccessGeneratedEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionGrid);
