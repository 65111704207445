import { log } from "../../analytics";
import { events } from "../index";

export default (appState, payload, type) => {
  const { aggregateId } = appState.snapshot.events;

  const logObject = {
    type: events.NAVIGATION_ITEM_CLICKED,
    data: {
      aggregate_id: aggregateId,
      navigate_to: payload.navigate_to,
      affordance: payload.affordance
    }
  };

  log(logObject.type, logObject.data);
};
