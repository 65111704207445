import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { CIGS_PER_PACK } from "../../../calculators/constants";
import {
  mapStateToProps as mapStateToPropsBase,
  mapDispatchToProps
} from "../componentsStateMapper";
import "./_index.css";
import BasicNumeric from "../basic-numeric";
import { infoButtonStyles, labelStyles, Label } from "./index.styled";
import CalculatedValue from "../shared/calculated-value";
import NumericControl from "../shared/numeric-control";

export class NumericPackYears extends BasicNumeric {
  handleChangePacks = event => {
    const { value } = event.target;
    const cigs = Number(value) ? value * CIGS_PER_PACK : "";
    this.handleChange({ target: { value: cigs }, isTrusted: event.isTrusted });
  };

  constructor(props) {
    super(props);

    const parentsRenderLabel = this.renderLabel;
    this.renderLabel = () => parentsRenderLabel(labelStyles);

    const parentsRenderInfoButton = this.renderInfoButton;
    this.renderInfoButton = () => parentsRenderInfoButton(infoButtonStyles);
  }

  _shouldComponentUpdateExtension = nextProps => {
    const { pkyrs } = nextProps;
    if (this.props.pkyrs !== pkyrs) {
      return true;
    }
    return false;
  };

  renderComponent = () => {
    const { name, isRequired, value, autocomplete, pkyrs } = this.props;
    const packs = value / CIGS_PER_PACK || "";
    return (
      <div className="NumericPackYears">
        <div className="NumericPackYears_segment">
          <Label htmlFor={name}>Packs</Label>
          <NumericControl
            className="NumericPackYears_input"
            id={name}
            name="packs"
            step={0.05}
            value={`${packs}`}
            onChange={this.handleChangePacks}
            required={isRequired}
            hasError={this.hasErrors()}
            hasWarning={this.hasWarnings()}
            data-auto={`${name}-component-packs-field`}
            autocomplete={autocomplete}
          />
        </div>
        <div className="NumericPackYears_segment">
          <Label htmlFor="cigs">Cigarettes</Label>
          <NumericControl
            className="NumericPackYears_input"
            id="cigs"
            name="cigs"
            value={`${value}`}
            onChange={this.handleChange}
            required={isRequired}
            hasError={this.hasErrors()}
            hasWarning={this.hasWarnings()}
            data-auto={`${name}-component-cigs-field`}
            autocomplete={autocomplete}
          />
        </div>
        <div className="NumericPackYears_segment NumericPackYears_segment--last">
          <Label>Pack years</Label>
          <CalculatedValue
            dataAuto={`${name}-component-pack-years-value`}
            value={pkyrs}
          />
        </div>
      </div>
    );
  };
}

export const mapStateToProps = (state, ownProps) => {
  const baseProps = mapStateToPropsBase(state, ownProps);
  const pkYrsValidation = get(
    state,
    ["fields", "ui", "pk_yrs", "validation"],
    []
  );
  const pkyrs = get(state, ["fields", "ui", "pk_yrs", "value"], "");
  return {
    ...baseProps,
    pkyrs,
    validationResult: baseProps.validationResult.concat(pkYrsValidation)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NumericPackYears);
