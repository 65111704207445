import {
  CHANGE_SCREEN_TYPE_REQUEST,
  GET_SESSION_NUMBER,
  SHOW_FORBIDDEN_MODAL,
  RESET_FORBIDDEN_MODAL,
  PUA_REMINDER_MODAL_SHOWING
} from "../action-types";

export const initialState = {
  windowSize: null,
  showForbiddenModal: false,
  forbiddenErrorMsg: "",
  puaReminderModalShowing: false
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case CHANGE_SCREEN_TYPE_REQUEST:
      return {
        ...state,
        windowSize: payload
      };
      case GET_SESSION_NUMBER: 
      return {
        ...state,
        sessionNumber: payload
      }
      case SHOW_FORBIDDEN_MODAL:
        if(!state.showForbiddenModal) {
        return {
          ...state,
          showForbiddenModal: true,
          forbiddenErrorMsg: payload
        };
      }
        else {
          return state;
        }
      case RESET_FORBIDDEN_MODAL:
        return {
          ...state,
          showForbiddenModal: false,
          forbiddenErrorMsg: ""
        };
      case PUA_REMINDER_MODAL_SHOWING:
        return {
          ...state,
          puaReminderModalShowing: payload
        };
    default:
      return state;
  }
};
