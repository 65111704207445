import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Modal, Type } from "discover.medical.react-component-library";
import "./_index.css";

export function OptionGridModal({
  header,
  toolName,
  showToolName,
  children,
  onRequestClose,
  isOpen,
  size
}) {
  return (
    <Modal
      v2
      borderRadius="sm"
      padding={false}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      className={`OptionGridModal OptionGridModal__${size}`}
    >
      <div className="OptionGridModal_Header">
        <Type
          element="h2"
          color="primary"
          fontFamily="secondary"
          className="OptionGridModal_Title"
        >
          {header}
        </Type>
      </div>

      <div data-auto="optiongrid-modal-body" className="OptionGridModal_Body">
        <div className="OptionGridModal_Scrollbar">
          <div className="OptionGridModal_Content">
            {showToolName && (
              <Type
                element="h3"
                styledAs="h2"
                fontFamily={null}
                className="OptionGridModal_Tool_name"
              >
                {toolName}
              </Type>
            )}
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
}

OptionGridModal.propTypes = {
  header: PropTypes.string.isRequired,
  headerClass: PropTypes.string,
  toolName: PropTypes.string.isRequired,
  showToolName: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(["small", "large"])
};

OptionGridModal.defaultProps = {
  showToolName: true,
  size: "small"
};

const mapStateToProps = state => ({
  toolName: get(state, ["tool", "metadata", "name"], [])
});

export default connect(mapStateToProps)(OptionGridModal);
