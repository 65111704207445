import { Fragment } from "react";
import PatientDataTab from "./patientdatatab";
import RiskGuidelinesTab from "./riskguidelinestab";
import PopulationViewTab from "./populationviewtab";
import OptionGridTab from "./optiongridtab";

const componentsMap = {
  patientData: {
    component: PatientDataTab,
    name: "PATIENT DATA"
  },
  riskAndGuidelines: {
    component: RiskGuidelinesTab,
    name: "RISK & GUIDELINES"
  },
  populationView: {
    component: PopulationViewTab,
    name: "POPULATION VIEW"
  },
  optionGrid: {
    component: OptionGridTab,
    name: "OPTION GRID"
  }
};

export const getComponent = type => {
  return (
    componentsMap[type] || {
      component: Fragment
    }
  );
};

export const getComponentKeys = () => {
  return Object.keys(componentsMap);
};

export default getComponent;
