import { getSessionNumberFromURL } from "../../helpers/url-manager";

const getRegisterUrl = () => {
  const isWindowDef = typeof window !== "undefined";
  const baseUrl = isWindowDef ? encodeURIComponent(window.location.href) : "/";
  const sessionNumber = isWindowDef ? getSessionNumberFromURL() : undefined;
  return sessionNumber
    ? `/login?session-number=${sessionNumber}&action=register&returnToUrl=${baseUrl}`
    : `/login?action=register&returnToUrl=${baseUrl}`;
};

export default getRegisterUrl;
