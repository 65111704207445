import { useEffect, useCallback, useRef } from "react";

import { updateAmplitudeUser } from "../util/amplitudeHelper";
import * as amplitude from "../../middleware/telemetry/analytics";

const noop = () => null;

const isAnalyticsAccepted = consentConfig =>
  consentConfig?.ANALYTICS === "ACCEPT";

export const hasOsano = () => typeof window !== "undefined" && !!window?.Osano;

export const isOsanoAnalyticsAllowed = isOsanoEnabled => {
  //If Osano is enabled, but fails to load, default to no analytics tracking. If Osano is not enabled, default to allow tracking. Otherwise, get the current consent state from Osano.
  if (!hasOsano() && isOsanoEnabled) {
    return false;
  } else if (!isOsanoEnabled) {
    return true;
  }

  return isAnalyticsAccepted(window.Osano.cm.getConsent());
};
const showOsanoDrawer = () => hasOsano() && window.Osano.cm.showDrawer();

export const useOsano = user => {
  const userData = useRef(user);

  const onConfigUpdated = useCallback(consentConfig => {
    const canDoAnalytics = isAnalyticsAccepted(consentConfig);
    amplitude.optOut(!canDoAnalytics);

    if (canDoAnalytics) {
      updateAmplitudeUser(userData.current);
    }
  }, []);

  useEffect(() => {
    if (!hasOsano()) {
      return noop;
    }

    window.Osano.cm.addEventListener("osano-cm-consent-saved", onConfigUpdated);

    return () => {
      window.Osano.cm.removeEventListener(
        "osano-cm-consent-saved",
        onConfigUpdated
      );
    };
  }, [onConfigUpdated]);

  return {
    showOsanoDrawer
  };
};
