import React from "react";
import PropTypes from "prop-types";
import { Icon } from "discover.medical.react-component-library";
import "./_index.css";

export const BackgroundInfoButton = ({ onClick, iconColor }) => {
  return (
    <Icon
      className={"BackgroundInfoButton"}
      onClick={onClick}
      data-auto="optiongrid-background-info-button"
      icon={{
        glyph: "info_outline",
        size: "sm",
        color: iconColor
      }}
      background={{ clickable: true }}
    />
  );
};

BackgroundInfoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconColor: PropTypes.string.isRequired
};

BackgroundInfoButton.defaultProps = {
  iconColor: "link"
};

export default BackgroundInfoButton;
