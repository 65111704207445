import axios from "axios";
import { NotificationManager } from "react-notifications";
import { getSessionNumberFromURL } from "../../helpers/url-manager";
import showErrorNotification from "../../helpers/showErrorNotification";

const axiosInstance = axios.create({
  baseURL: "/"
});

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    let title = "Error";
    let message = "Some problems with network connection";
    if (error.response) {
      const { status, statusText } = error.response;

      title = `Error ${status}`;
      message = `${statusText}`;
    }
    if (!showErrorNotification(error)) {
      NotificationManager.error(message, title);
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  config => {
    const sessionNumber =
      typeof window !== "undefined" ? getSessionNumberFromURL() : null;
    if (sessionNumber && config.url.startsWith("/api")) {
      config.params = { ...config.params, "session-number": sessionNumber };
    }
    return config;
  },
  error => Promise.reject(error)
);

export default axiosInstance;
