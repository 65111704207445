import React from "react";
import { Anchor } from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import "./_index.css";
import { epochConverter } from "../../../../../../shared/util/epochConverter";

function OptionGridFooter() {
  const optionGridContext = useOptionGridContext();
  const {
    isSnapshotAvailable,
    snapshotCreatedAt,
    modified,
    openReferencesModal,
    openAboutModal
  } = optionGridContext;

  const handleReferencesClick = React.useCallback(
    e => {
      e.preventDefault();
      openReferencesModal();
    },
    [openReferencesModal]
  );

  const handleAboutClick = React.useCallback(
    e => {
      e.preventDefault();
      openAboutModal();
    },
    [openAboutModal]
  );

  return (
    <div className="OptionGridFooter">
      <ul className="OptionGridFooter_List">
        <li className="OptionGridFooter_Item">
          <Anchor
            onClick={handleReferencesClick}
            fontSize="sm"
            data-auto="option-grid-footer-references"
          >
            References
          </Anchor>
        </li>

        <li className="OptionGridFooter_Item">
          <Anchor
            onClick={handleAboutClick}
            fontSize="sm"
            data-auto="option-grid-footer-about"
          >
            More About this Option Grid Decision Aid
          </Anchor>
        </li>

        <li
          className="OptionGridFooter_Item"
          data-auto="option-grid-footer-date"
        >
          {isSnapshotAvailable
            ? `Generated on: ${epochConverter(snapshotCreatedAt)}`
            : `Last Update: ${epochConverter(modified)}`}
        </li>
      </ul>
      <div
        className="OptionGridFooter_Disclaimer"
        data-auto="option-grid-footer-disclaimer"
      >
        {isSnapshotAvailable
          ? "If you have concerns that information might be out of date, please contact your healthcare provider."
          : "Option Grid™ Decision Aids are reviewed on an ongoing basis and updated to reflect the latest evidence."}
      </div>
    </div>
  );
}

export default OptionGridFooter;
