import React, { useEffect } from "react";
import {
  Flex,
  Spinner,
  Type,
  useMediaQuery
} from "discover.medical.react-component-library";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../templates/page";
import {
  Main,
  MainContent
} from "../../tools-browse-shared-components/styled-components/index.styled";
import { FoundToolsList, SearchTitle } from "./index.styled";
import ToolsItem from "../../tools-browse-shared-components/tools-item";
import { getParamFromUrl } from "../../../helpers/url-manager";
import { searchTools } from "../../../store/search/actions";
import { useLocation } from "react-router-dom";
import useModal from "../../../shared/hooks/useModal";
import InfoModalv2 from "../../tools-browse-shared-components/info-modal-v2";
import SubscriptionModal from "./components/subscription-modal";

const Search = () => {
  const isDesktop = useMediaQuery("min", "md");
  const isMobile = useMediaQuery("max", "sm");
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoading, foundTools } = useSelector(({ search }) => search);
  const [isInfoModal, openInfoModal, closeInfoModal] = useModal();
  const [
    isSubscriptionModal,
    openSubscriptionModal,
    closeSubscriptionModal
  ] = useModal();
  const { isAuthorized } = useSelector(state => state.ssr);
  const searchWord = getParamFromUrl("q", location);
  useEffect(() => {
    dispatch(searchTools(searchWord));
  }, [searchWord, dispatch]);

  const checkIsForbidden = isFree => !isFree && !isAuthorized;

  const renderSearchResultTool = tool => {
    const toolInfo = {
      title: {
        label: tool.title,
        route: tool.slug
      },
      shortDescription: tool.shortDescription,
      info: {
        glyph: "information",
        altText: "More Information",
        function: "getToolInfo",
        functionArgs: {
          apiPath: `/api-tool-experience/content/${tool.an}/${tool.version}/info`
        }
      },
      badgeLabel: tool.isFree && !isAuthorized ? "free" : undefined
    };
    return (
      <ToolsItem
        key={tool.title}
        toolInfo={toolInfo}
        onInfoModalOpen={openInfoModal}
        isForbidden={checkIsForbidden(tool.isFree)}
        onSubscriptionModalOpen={openSubscriptionModal}
      />
    );
  };

  const renderSearchResultTools = () => {
    if (foundTools.length) {
      return (
        <MainContent cell column>
          <FoundToolsList>
            {foundTools.map(renderSearchResultTool)}
          </FoundToolsList>
        </MainContent>
      );
    }
  };

  const getSearchTitle = () => {
    return foundTools.length ? "SHOWING: " : "There are no search results for ";
  };

  return (
    <PageLayout dataAuto="search-page" isNavigationExpanded={true}>
      <Main id="main">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <SearchTitle>
              <Type element="h6" display={"inline"}>
                {getSearchTitle()}
              </Type>
              <Type element="strong">{searchWord}</Type>
            </SearchTitle>
            <Flex
              container
              justify={{ content: "space-between" }}
              align={{ item: "flex-start" }}
            >
              {renderSearchResultTools()}
            </Flex>
          </>
        )}
      </Main>
      <InfoModalv2
        isOpen={isInfoModal}
        isMobile={isMobile}
        isDesktop={isDesktop}
        onClose={closeInfoModal}
      />
      <SubscriptionModal
        isOpen={isSubscriptionModal}
        isMobile={isMobile}
        onClose={closeSubscriptionModal}
      />
    </PageLayout>
  );
};

export default Search;
