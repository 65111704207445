import styled, { css } from "styled-components";
import {
  Anchor,
  AppBar,
  Flex,
  Button
} from "discover.medical.react-component-library";
import SearchAutoSuggest from "../search-autosuggest";

export const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  flex: none;

  @media print {
    display: none;
  }
`;

export const StyledAppBar = styled(AppBar.Container)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 4rem;
  }
`;

export const StyledFlex = styled(Flex)`
  height: 100%;
`;

// Show Hamburger on Mobile/Tablet and Desktop (only when scrolled)
export const StyledHamburgerWrapper = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.space.md};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

export const StyledNavigationWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.base};
  display: none;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

export const StyledNavigationFlex = styled(Flex)`
  height: 100%;
  margin: 0 auto;
`;

export const StyledMaskingContainer = styled.div`
  display: block;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: ${({ $isNavigationExpanded }) =>
      $isNavigationExpanded ? "none" : "block"};
  }
`;

export const StyledLogo = styled.img`
  ${props =>
    props.brand === "MHD"
      ? css`
          width: 11.25rem;

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.sm}) {
            width: 20.25rem;
          }

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.md}) {
            width: 23.25rem;
          }
        `
      : css`
          width: 11.25rem;

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.sm}) {
            width: 16.5rem;
          }

          @media screen and (min-width: ${({ theme }) =>
              theme.breakpoints.md}) {
            width: 20.125rem;
          }
        `}
`;

export const StyledSearch = styled(SearchAutoSuggest)`
  width: auto;
  max-width: 20.5rem;
  margin-left: 0.75rem;
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 18rem;
    margin-left: 1.5rem;
    display: block;
  }
`;

export const StyledSearchButton = styled(Button.Action)`
  padding: 0;
  border-color: ${({ theme }) => theme.colors.quaternary.base};
  background: none;
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;

  > svg {
    fill: ${({ theme }) => theme.colors.white};
    width: 1.25rem;
    height: 1.25rem;
  }
  &:hover {
    border-color: ${({ isActiveForStyle, theme }) =>
      `${theme.colors.white}${isActiveForStyle && " !important"}`};
    ${({ isActiveForStyle, theme }) =>
      isActiveForStyle
        ? `background-color: ${theme.colors.senary.base}; !important;`
        : `background: none !important;`}

    & > svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none !important;
  }
`;

export const SearchTrayWrapper = styled(SearchAutoSuggest)`
  width: auto;
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.colors.senary.base};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none !important;
  }
`;

export const StyledCustomUserArea = styled(Flex)`
  padding: 0.5rem 1rem 0;
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 1.5;
`;

export const StyledUserLink = styled(Anchor)`
  font-size: ${({ theme }) => theme.font.size.sm};
  padding: 0 0 0.5rem;
`;
