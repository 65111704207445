import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Icon } from "discover.medical.react-component-library";
import PatientDataSummaryLabelMobile from "./patient-data-summary-label-mobile";
import {
  PatientDataSummaryContainer,
  PatientDataSummaryLabel,
  PatientDataSummaryLabelContainer,
  StyledDivider,
  StyledDynamicFormDataSummary
} from "./index.styled";
import {
  VIEWPORT_SMALL,
  VIEWPORT_LARGE
} from "../../../../../../helpers/aliases";
import DynamicForm from "../../../../../content-components";

const PatientDataSummary = ({ title, componentsList, moveStepBack }) => {
  const state = useSelector(storageState => storageState);
  const {
    sharedData: { windowSize }
  } = state;
  const [statementsVisible, setStatementsVisibility] = useState(false);
  const toggleStatementsVisibility = () => {
    setStatementsVisibility(!statementsVisible);
  };
  const span = windowSize === VIEWPORT_LARGE ? 4 : undefined;
  return (
    <PatientDataSummaryContainer cell column span={span}>
      <PatientDataSummaryLabelContainer container>
        {windowSize === VIEWPORT_SMALL ? (
          <>
            {statementsVisible ? (
              <PatientDataSummaryLabelMobile
                onClick={toggleStatementsVisibility}
                iconGlyph="keyboard_arrow_down"
                label="Hide Patient Information"
              />
            ) : (
              <PatientDataSummaryLabelMobile
                onClick={toggleStatementsVisibility}
                iconGlyph="keyboard_arrow_right"
                label="Show Patient Information"
              />
            )}
          </>
        ) : title ? (
          <DynamicForm componentsList={[title]} />
        ) : (
          <PatientDataSummaryLabel
            element="p"
            textTransform="uppercase"
            fontSize="md"
          >
            Patient Information
          </PatientDataSummaryLabel>
        )}
        <Button
          variant="primary"
          kind="text"
          padded={false}
          size="iconOnly"
          onClick={moveStepBack}
          data-auto="edit-btn"
        >
          <Icon
            icon={{
              glyph: "create",
              size: "md"
            }}
          />
        </Button>
      </PatientDataSummaryLabelContainer>
      {windowSize === VIEWPORT_SMALL && !statementsVisible && (
        <StyledDivider thickness="sm" />
      )}
      {((windowSize === VIEWPORT_SMALL && statementsVisible) ||
        windowSize !== VIEWPORT_SMALL) && (
        <StyledDynamicFormDataSummary componentsList={componentsList} />
      )}
    </PatientDataSummaryContainer>
  );
};

PatientDataSummary.propTypes = {
  componentsList: PropTypes.array.isRequired,
  moveStepBack: PropTypes.func,
  title: PropTypes.shape({
    cpdn: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    style: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  })
};

export default PatientDataSummary;
