import React from "react";
import BasicUnit from "../basic-unit";
import componentStateMapper from "../componentsStateMapper";

import { Flex } from "discover.medical.react-component-library";

import {
  PillContainer,
  PillLabel,
  PillValueWrapper,
  PillValue,
  TooltipContainer
} from "./index.styled";

export class Pill extends BasicUnit {
  renderCompositeComponent = () => {
    return (
      <Flex container noGap align={{ item: "center" }}>
        <PillContainer noGap>
          <PillLabel align={{ item: "center" }} cell>
            {this.getBaseContent()}
          </PillLabel>
          <PillValueWrapper
            cell
            justify={{ content: "left" }}
            align={{ item: "center" }}
          >
            <PillValue className="text-wrapper">
              {this.getFieldValue() + " " + this.getUnits()}
            </PillValue>
          </PillValueWrapper>
        </PillContainer>
        <TooltipContainer>{this.renderInfoButton()}</TooltipContainer>
      </Flex>
    );
  };
}

export default componentStateMapper(Pill);
