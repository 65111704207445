import React, { useRef, useEffect } from "react";
import {
  Modal,
  Type,
  Flex,
  useMediaQuery,
  Tooltip
} from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import {
  Text,
  ListItem,
  ListStyled,
  Root,
  QRCodeBlock,
  QRCodeBlockMobile,
  QRWrapper,
  CopyButton
} from "./index.styled";
import SpinnerWithText from "../../../../../spinner-with-text/SpinnerWithText";
import OptionGridSnapshotError from "../option-grid-snapshot-error/OptionGridSnapshotError";
import {
  generateQrCodeClicked,
  generateQrCodeCopyClicked
} from "../../../../../../store/telemetry/actions/index";
import { useDispatch } from "react-redux";
import useLinkCopy from "../hooks/useLinkCopy";
import QRCode from "qrcode.react";

function OptionGridQrCodeModal() {
  const {
    closeQrCodeModal,
    isQrCodeOpen,
    snapshotId,
    isSpinnerShown,
    snapshotProcessingInfo
  } = useOptionGridContext();
  const qrCodeRef = useRef(null);
  const { copy } = useLinkCopy();
  const isMobile = useMediaQuery("max", "sm");
  const { isError } = snapshotProcessingInfo;
  const sharedLink = `${window.location.href}${
    window.location.search ? "&" : "?"
  }snapshotId=${snapshotId}`;

  const handleCopyButtonClick = () => {
    const qrCodeElement = qrCodeRef.current.querySelector("#qr-code");
    copy(qrCodeElement);
    dispatch(generateQrCodeCopyClicked({ affordance: "patient_options" }));
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isQrCodeOpen) {
      dispatch(generateQrCodeClicked({ affordance: "patient_options" }));
    }
  }, [dispatch, isQrCodeOpen]);

  return (
    <Modal
      v2
      borderRadius="sm"
      padding={true}
      onRequestClose={closeQrCodeModal}
      isOpen={isQrCodeOpen}
      className="OptionGridQrCodeModal"
      style={{ maxWidth: "41rem" }}
      data-auto="option-grid-qr-code-modal"
    >
      {isSpinnerShown && !isError && (
        <SpinnerWithText>
          <b>Your QR Code is being generated.</b> This may take a moment...
        </SpinnerWithText>
      )}

      {isSpinnerShown || isError || (
        <Root data-auto="option-grid-qr-code-modal-root">
          <Type
            element="h3"
            styledAs="h2"
            color="primary"
            fontFamily="secondary"
          >
            QR Code
          </Type>

          {isMobile ? (
            <div data-auto="option-grid-qr-code-mobile-view">
              <QRCodeBlockMobile cell span={3}>
                <QRWrapper forwardedRef={qrCodeRef}>
                  <QRCode
                    data-auto="option-grid-qr-code-modal-field"
                    value={sharedLink}
                    id="qr-code"
                  />
                </QRWrapper>
              </QRCodeBlockMobile>
              <div>
                <Text element="i">The page linked to:</Text>

                <ListStyled>
                  <ListItem>
                    <Type element="strong" display="inline">
                      Will show content as is&nbsp;
                    </Type>
                    and not include any updates or changes available on the live
                    site.
                  </ListItem>
                  <ListItem>
                    <Type element="strong" display="inline">
                      Will not show any options that you have hidden&nbsp;
                    </Type>
                    (although these will be available for recipients to
                    “unhide”).
                  </ListItem>
                </ListStyled>
              </div>
            </div>
          ) : (
            <Flex
              container
              justify={{ content: "space-between" }}
              data-auto="option-grid-qr-code-desktop-view"
            >
              <QRCodeBlock cell span={3}>
                <QRWrapper forwardedRef={qrCodeRef}>
                  <QRCode
                    data-auto="option-grid-qr-code-modal-field"
                    value={sharedLink}
                    id="qr-code"
                  />
                  <Tooltip
                    border={false}
                    content="QR Code copied to clipboard"
                    hasArrow={true}
                    position="center"
                    variant="dark"
                    toggle="click"
                    contentSize="xs"
                    placement="right"
                    trigger={
                      <CopyButton
                        variant="primary"
                        onClick={handleCopyButtonClick}
                        data-auto="option-grid-qr-code-modal-copy"
                        size="sm"
                      >
                        Copy QR Code
                      </CopyButton>
                    }
                  />
                </QRWrapper>
              </QRCodeBlock>
              <Flex cell span={9}>
                <div>
                  <Text element="i">The page linked to:</Text>

                  <ListStyled>
                    <ListItem>
                      <Type element="strong" display="inline">
                        Will show content as is&nbsp;
                      </Type>
                      and not include any updates or changes available on the
                      live site.
                    </ListItem>
                    <ListItem>
                      <Type element="strong" display="inline">
                        Will not show any options that you have hidden&nbsp;
                      </Type>
                      (although these will be available for recipients to
                      “unhide”).
                    </ListItem>
                  </ListStyled>
                </div>
              </Flex>
            </Flex>
          )}
        </Root>
      )}

      {isError && (
        <OptionGridSnapshotError
          onClick={closeQrCodeModal}
          message="We're sorry! We were unable to generate a qr code. Please try again later."
        />
      )}
    </Modal>
  );
}

export default OptionGridQrCodeModal;
