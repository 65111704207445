import React, { useEffect, useState } from "react";
import { checkIsIE } from "../../helpers/browser-checker";
import {
  Card,
  Dialog,
  DialogTitle,
  DialogTitleActions,
  Type
} from "discover.medical.react-component-library";
import { Content } from "./BrowserNoticeDialog.styled";

const BrowserNoticeDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isIE = checkIsIE();

  useEffect(() => {
    try {
      const isDismissed = window.sessionStorage.getItem("ie-warning-dismissed");
      setIsOpen(!isDismissed);
    } catch {} //eslint-disable-line
  }, []);

  const handleClose = () => {
    try {
      window.sessionStorage.setItem("ie-warning-dismissed", "true");
      setIsOpen(false);
    } catch {} //eslint-disable-line
  };

  return isIE && isOpen ? (
    <Dialog
      aria-labelledby="ie-warning-title"
      aria-describedby="ie-warning-content"
      isOpen={isOpen}
      maxWidth="sm"
      onRequestClose={handleClose}
    >
      <DialogTitle
        borderBottomColor="gray300"
        borderBottomStyle="solid"
        borderBottomWidth="1px"
        paddingLeft="lg"
      >
        <Type
          element="h2"
          fontFamily="secondary"
          id="ie-warning-title"
          styledAs="h3"
        >
          Dear Internet Explorer User
        </Type>
        <DialogTitleActions onRequestClose={handleClose} />
      </DialogTitle>
      <Card padding="lg">
        <Content id="ie-warning-content">
          {`<p><strong>We are no longer supporting use of Microsoft's Internet Explorer (IE) browser</strong> to access DynaMed, DynaMedex, Dynamic Health, and DynaMed Decisions. (Please note that Microsoft ceased development and support of Internet Explorer in June 2022.)</p><p><strong>For optimal and safe access to our products</strong>, please use one of the many other supported browsers, including <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>, <a href="https://www.microsoft.com/edge" target="_blank">Microsoft Edge</a>, and <a href="https://www.mozilla.org/firefox/" target="_blank">Firefox.</a></p><p>We apologize for any inconvenience that this causes.</p><hr /><p>Need additional help? Contact <a href="https://connect.ebsco.com/s/article/How-do-I-contact-EBSCO-Support?language=en_US" target="_blank">Customer Support</a></p><p><small>&copy;2024 EBSCO Industries, Inc. All rights reserved</small></p>`}
        </Content>
      </Card>
    </Dialog>
  ) : null;
};

export default BrowserNoticeDialog;
