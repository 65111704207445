import { useCallback } from "react";

export default function useLinkCopy() {
  const copy = useCallback(node => {
    window.getSelection().removeAllRanges();
    const range = document.createRange();

    if (node?.textContent) {
      const a = document.createElement("a");
      const linkText = document.createTextNode(node.textContent);
      a.appendChild(linkText);
      a.title = "Link for Patient";
      a.href = node.textContent;
      document.body.appendChild(a);

      range.selectNode(a);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      document.body.removeChild(a);
    } else if (node?.nodeName.toUpperCase() === "CANVAS") {
      const dataUrl = node.toDataURL("image/png");
      const img = document.createElement("img");
      img.src = dataUrl;
      document.body.appendChild(img);

      range.selectNode(img);
      window.getSelection().addRange(range);
      document.execCommand("copy");
      document.body.removeChild(img);
    }
  }, []);

  return { copy };
}
