import styled from "styled-components";
import img from "../../../../../../shared/images/welcome-banner.jpg";
import { Type, Flex } from "discover.medical.react-component-library";

export const BannerWrapper = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.xl};
  background-color: ${({ theme }) => theme.colors.quinary.base};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.xxl};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

export const FlexImage = styled.div`
  display: none;
  background: url(${img}) no-repeat;
  background-size: cover;
  min-width: 13rem;
  min-height: 7.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

export const BannerTitle = styled(Type)`
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.xl};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.space.sm};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.xxl};
  }
`;

export const BannerDescription = styled(Type)`
  display: inline-block;
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-bottom: ${({ theme }) => theme.space.sm};
`;

export const TextHolder = styled.div`
  padding: 1.25rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem 1.875rem;
  }
`;
