import React from "react";
import Sticky from "../../../../sticky";

export const ToolStickyFooter = ({ children, isCentered, ...props }) => (
  <Sticky
    {...props}
    wide={true}
    shadow={true}
    isCentered={isCentered}
    classNameNotVisible="visible"
  >
    {children}
  </Sticky>
);

export default ToolStickyFooter;
