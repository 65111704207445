import {
  TOOL_DATA_REQUEST,
  TOOL_DATA_REQUEST_SUCCESS,
  TOOL_DATA_REQUEST_ERROR,
  RESET_TOOL_DATA,
  TOOL_METADATA_REQUEST,
  TOOL_METADATA_REQUEST_SUCCESS,
  TOOL_METADATA_REQUEST_ERROR,
  TOOL_TABS_REQUEST,
  TOOL_TABS_REQUEST_ERROR,
  TOOL_TABS_REQUEST_SUCCESS,
  TOOL_ACTIVE_TAB_INDEX_CHANGE
} from "../action-types";

export const initialState = {
  loading: false,
  metadata: {
    slug: "",
    name: "",
    calcId: "",
    status: ""
  },
  controls: {
    _all: {},
    data: {},
    dataHeader: {},
    risk: {},
    guideline: {},
    populationHeader: {},
    population: {},
    optiongridHeader: {},
    optiongrid: {},
    toolTabs: [],
    references: {},
    contributors: {},
    certifications: {},
    competingInterests: {},
    definitions: {},
    steps: [],
    chartNote: {}
  },
  activeStepIndex: 0
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case TOOL_DATA_REQUEST:
    case TOOL_METADATA_REQUEST:
    case TOOL_TABS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case TOOL_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        controls: {
          ...state.controls,
          ...payload,
          _all: {
            ...state.controls._all,
            ...payload._all
          }
        }
      };
    case TOOL_TABS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        controls: {
          ...state.controls,
          ...payload
        }
      };
    case TOOL_TABS_REQUEST_ERROR:
    case TOOL_DATA_REQUEST_ERROR:
    case TOOL_METADATA_REQUEST_ERROR:
      return {
        ...state,
        loading: false
      };
    case RESET_TOOL_DATA:
      return initialState;
    case TOOL_METADATA_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        metadata: {
          ...state.metadata,
          ...payload
        }
      };
    case TOOL_ACTIVE_TAB_INDEX_CHANGE:
      return {
        ...state,
        ...payload
      }
    default:
      return state;
  }
};
