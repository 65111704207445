import React from "react";
import {
  ButtonStyled,
  PatientChartNoteTextStyled,
  PatientChartNoteIconStyled
} from "./index.styled";

import { Tooltip } from "discover.medical.react-component-library";

export default function PatientChartNoteButton(props) {
  return (
    <>
      {props.isMobile ? (
        <Tooltip
          isLabel={true}
          hasArrow={true}
          placement="bottom"
          variant="light"
          contentSize="md"
          trigger={
            <ButtonStyled kind="outlined" {...props}>
              <PatientChartNoteIconStyled
                icon={{ glyph: "assignment", size: "md" }}
              />
            </ButtonStyled>
          }
        >
          Patient Chart Note
        </Tooltip>
      ) : (
        <ButtonStyled
          kind="outlined"
          aria-label="Patient Chart Note"
          {...props}
        >
          <PatientChartNoteIconStyled
            icon={{ glyph: "assignment", size: "md" }}
          />
          <PatientChartNoteTextStyled>
            Patient Chart Note
          </PatientChartNoteTextStyled>
        </ButtonStyled>
      )}
    </>
  );
}
