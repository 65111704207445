import { createSelector } from "reselect";
const selectSnapshotAggregateId = state => state.snapshot.events.aggregateId;

export const canSnapshotSelector = createSelector(
  selectSnapshotAggregateId,
  aggregateId => !!aggregateId
);

const selectSnapshotId = state => state.snapshot.snapshotId;
const selectIsSnapshotLoading = state => state.snapshot.loading;
const selectPendingRequestsAmount = state => state.snapshot.events.pending;
const selectIsError = state => state.snapshot.isCreationError;
const selectTask = state => state.snapshot.task;

export const snapshotProcessedSelector = createSelector(
  selectSnapshotId,
  selectIsSnapshotLoading,
  selectPendingRequestsAmount,
  selectIsError,
  selectTask,
  (snapshotId, isSnapshotLoading, pendingRequestsAmount, isError, task) => {
    return {
      snapshotId,
      isEventsLoading: pendingRequestsAmount !== 0,
      isSnapshotLoading,
      isError,
      task
    };
  }
);
