/**
 * This object maps the icon code from the content to the icon name in the shared library.
 *
 * Any icon codes that do not have a corresponding icon name have not been created yet (they are
 * not MVP). They may be included in the object to acknowledge that these icons will eventually be
 * used. Unmapped (or code with no icon names) should use a default icon that indicates none is
 * available.
 *
 * @link https://confluence.epnet.com/display/ART/Icons
 * @link https://ebscois.github.io/discover.medical.react-component-library/docs/components/icon/#all-icons
 */
export default {
  ie1: "infographicPerson1",
  ie1_t1_iese0: "infographicPerson12",
  ie1_t2_iese0: "infographicPerson13",
  ie3: "infographicPerson2",
  ie3_t1_ie1: "infographicPerson3",
  ie3_t1_iese0: "infographicPerson11",
  ie3_t2_iese0: "infographicPerson11",
  iese0: "infographicPerson10",
  iese0_t1_ie1: "infographicPerson4",
  iese0_t1_se2: "infographicPerson7",
  iese0_t2_se1: "infographicPerson5",
  iese0_t2_se2: "infographicPerson6",
  iese0_t2_se3: "infographicPerson9",
  se1: "infographicPerson14",
  se2: "infographicPerson8"
};
