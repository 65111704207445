import { isNil } from "lodash";
import getLoginUrl from "../../shared/util/getLoginUrl";

const checkForActiveSubscription = (userProfileData, userState) => {
  if (!userState?.subscriptionActive) {
    const returnData = JSON.parse(JSON.stringify(userProfileData));
    returnData.messaging.italic =
      "Your organization's subscription is inactive";
    return returnData;
  }
  return userProfileData;
};

const userProfileDataGenerator = ({
  user,
  sessionNumber,
  displaySignIn = true
}) => {
  let userProfileData;
  if (user?.userState?.organizational && !user?.userState?.personalized) {
    userProfileData = { ...userConfig.ORGANIZATIONAL_USER };
    if (!displaySignIn) {
      userProfileData.messaging.signIn = null;
      userProfileData.linkURLs.signInURL = null;
    }
    return checkForActiveSubscription(userProfileData, user?.userState);
  } else if (user?.userState?.personalized) {
    userProfileData = { ...userConfig.PERSONALIZED_USER };
    if (user?.access?.patientEncounterReport) {
      const params = !isNil(sessionNumber)
        ? `?session-number=${sessionNumber}`
        : "";
      userProfileData.customLinks = [
        {
          id: "patient-encounter-reports",
          path: `/patient-encounter-reports${params}`,
          text: "Patient Encounter Reports"
        }
      ];
    }
    return checkForActiveSubscription(userProfileData, user?.userState);
  } else {
    return userConfig.UNAUTHORIZED_USER;
  }
};

const userConfig = {
  ORGANIZATIONAL_USER: {
    messaging: {
      signIn: "sign in"
    },
    linkURLs: {
      signInURL: getLoginUrl()
    }
  },
  PERSONALIZED_USER: {
    messaging: {
      signOut: "sign out"
    },
    linkURLs: {
      signOutURL: "/logout"
    }
  },
  UNAUTHORIZED_USER: {
    messaging: {
      content: "Already subscribed?",
      link: "Sign in now"
    },
    linkURLs: {
      registerURL: getLoginUrl()
    }
  }
};

export default userProfileDataGenerator;
