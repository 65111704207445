import { useSelector } from "react-redux";
import tabComponentsRetriever from "../../../../../components/tabComponentsRetriever";

function splitControls(sidesControlsList) {
  const halfLength = Math.ceil(sidesControlsList.length / 2);
  const leftControls = sidesControlsList.splice(0, halfLength);

  return { rightControls: sidesControlsList, leftControls };
}

export default function usePatientControls() {
  const componentsList = useSelector(state =>
    tabComponentsRetriever(state, "data")
  );
  const topControls = useSelector(state =>
    tabComponentsRetriever(state, "dataHeader")
  );
  const { rightControls, leftControls } = splitControls(componentsList);

  return {
    topControls,
    rightControls,
    leftControls
  };
}
