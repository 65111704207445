import {
  SNAPSHOT_CONTEXT_CHANGE,
  SNAPSHOT_DATA_REQUEST,
  SNAPSHOT_DATA_REQUEST_ERROR,
  SNAPSHOT_DATA_REQUEST_SUCCESS,
  SNAPSHOT_CREATE_REQUEST,
  SNAPSHOT_CREATE_REQUEST_ERROR,
  SNAPSHOT_CREATE_REQUEST_SUCCESS,
  SNAPSHOT_RESET_DATA,
  SNAPSHOT_EVENT_REQUEST,
  SNAPSHOT_EVENT_REQUEST_ERROR,
  SNAPSHOT_EVENT_REQUEST_SUCCESS,
  SNAPSHOT_EVENT_CREATE_OPEN_LINK,
  SNAPSHOT_EVENT_CREATE_OPEN_LINK_SUCCESS,
  SNAPSHOT_EVENT_CREATE_OPEN_LINK_ERROR,
  SNAPSHOT_OPEN_LINK,
  SNAPSHOT_QR_CODE
} from "../../action-types";
import { INTERFACE_CHANGED } from "../events";
import axiosInstance from "../../../services/axios-instance";
import { showForbidden } from "../../shared-data/actions";

export const openQRCode = () => ({
  type: SNAPSHOT_QR_CODE
});

export const openLink = () => ({
  type: SNAPSHOT_OPEN_LINK
});

export const resetSnapshotData = () => ({
  type: SNAPSHOT_RESET_DATA
});

export const changeSnapshotContext = () => ({
  type: SNAPSHOT_CONTEXT_CHANGE
});
export const getSnapshotData = () => ({ type: SNAPSHOT_DATA_REQUEST });
export const getSnapshotDataError = () => ({
  type: SNAPSHOT_DATA_REQUEST_ERROR
});
export const getSnapshotDataSuccess = payload => ({
  type: SNAPSHOT_DATA_REQUEST_SUCCESS,
  payload
});

export const createSnapshot = aggregateId => async dispatch => {
  dispatch(createSnapshotRequest());
  const requestPromise = axiosInstance.post(
    "/api-tool-experience/create",
    null,
    {
      params: {
        aggregateId,
        withData: 0
      }
    }
  );
  const timerPromise = new Promise(resolve => setTimeout(resolve, 1500));
  const [response] = await Promise.allSettled([requestPromise, timerPromise]);
  dispatch(
    response.status === "fulfilled"
      ? createSnapshotRequestSuccess(response.value.data.id)
      : createSnapshotRequestError()
  );
};

export const createSnapshotRequest = () => ({
  type: SNAPSHOT_CREATE_REQUEST
});

export const createSnapshotRequestSuccess = payload => ({
  type: SNAPSHOT_CREATE_REQUEST_SUCCESS,
  payload
});

export const createSnapshotRequestError = () => ({
  type: SNAPSHOT_CREATE_REQUEST_ERROR
});

/**
 * The time in milliseconds before a request is considered "timed out".
 */
const NETWORK_TIMEOUT = 1500;

const eventRequestFailed = event => ({
  type: SNAPSHOT_EVENT_REQUEST_ERROR,
  payload: {
    event
  }
});

const eventRequestSucceeded = event => ({
  type: SNAPSHOT_EVENT_REQUEST_SUCCESS,
  payload: {
    event
  }
});

const eventRequested = event => ({
  type: SNAPSHOT_EVENT_REQUEST,
  payload: {
    event
  }
});

/**
 * Handles action dispatching around event triggers.
 *
 * IMPORTANT: This handler should only be used for event requests that mutate
 * or create the snapshot. Please see the Snapshot Confluence page to know
 * which events these are.
 *
 * 1. Dispatch action for pending request.
 * 2. Await race between timeout promise and request promise.
 *   - Each promise resolves into an action.
 * 3. First promise resolved gets dispatched.
 *
 * @param {function} dispatch The action dispatcher.
 * @param {string}   event    The name of the event for the request.
 * @param {Promise}  request  The network request for the event.
 */
const handleEventRequest = async (dispatch, event, request) => {
  dispatch(eventRequested(event));

  const doTimeout = new Promise(resolve =>
    setTimeout(() => resolve(eventRequestFailed(event)), NETWORK_TIMEOUT)
  );

  const doRequest = new Promise(resolve =>
    request
      .then(() => resolve(eventRequestSucceeded(event)))
      .catch(e => {
        dispatch(showForbidden(e));
        resolve(eventRequestFailed(event));
      })
  );

  await Promise.race([doTimeout, doRequest]).then(action => dispatch(action));
};

/**
 * Triggers the INTERFACE_CHANGED event.
 *
 * @param {string} aggregateId The aggregate unique identifier.
 * @param {object} payload     The event payload.
 */
export const triggerInterfaceChange = (
  aggregateId,
  payload
) => async dispatch => {
  const request = axiosInstance.post(
    "/api-tool-experience/event/interface-changed",
    payload,
    {
      params: {
        aggregateId
      }
    }
  );

  await handleEventRequest(dispatch, INTERFACE_CHANGED, request);
};

export const sendOpenAccessGeneratedEvent = (
  snapshotId,
  aggregateId
) => async dispatch => {
  if (snapshotId && aggregateId) {
    dispatch(createOpenAccessGeneratedEventRequest());

    try {
      const response = await axiosInstance.post(
        "/api-tool-experience/event/open-access-url-generated",
        null,
        {
          params: {
            aggregateId,
            snapshotId
          }
        }
      );
      dispatch(
        response.status === 201
          ? createOpenAccessGeneratedEventSuccess()
          : createOpenAccessGeneratedEventError()
      );
    } catch (error) {
      dispatch(showForbidden(error));
      dispatch(createOpenAccessGeneratedEventError());
    }
  } else {
    console.log(`NO snapshotId(${snapshotId}) or aggregateId(${aggregateId})`);
  }
};

export const createOpenAccessGeneratedEventRequest = () => ({
  type: SNAPSHOT_EVENT_CREATE_OPEN_LINK
});

export const createOpenAccessGeneratedEventSuccess = () => ({
  type: SNAPSHOT_EVENT_CREATE_OPEN_LINK_SUCCESS
});

export const createOpenAccessGeneratedEventError = () => ({
  type: SNAPSHOT_EVENT_CREATE_OPEN_LINK_ERROR
});
