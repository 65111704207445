import React from "react";
import { Type, Button, Modal } from "discover.medical.react-component-library";
import PropTypes from "prop-types";

const MessageModal = ({
  isOpen,
  isMobile,
  onClose,
  buttonText,
  children,
  clickAction
}) => (
  <Modal
    v2
    data-auto="fav-tool-modal"
    borderRadius="sm"
    onRequestClose={onClose}
    isOpen={isOpen}
    style={{
      maxWidth: isMobile ? "40rem" : "",
      textAlign: "center"
    }}
  >
    <Type
      data-auto="fav-tool-modal-text"
      element="p"
      fontSize="sm"
      fontFamily="primary"
    >
      {children}
    </Type>
    <Button
      data-auto="modal-action-button"
      style={{
        marginTop: "1rem"
      }}
      onClick={clickAction}
    >
      {buttonText}
    </Button>
  </Modal>
);

MessageModal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  clickAction: PropTypes.func.isRequired
};

export default MessageModal;
