import { events } from "../../../middleware/telemetry/loggers";

export const addSSREvent = payload => ({
  type: events.ADD_SSR_EVENT,
  payload
});

export const clearSSREvents = () => ({
  type: events.CLEAR_SSR_EVENTS
});

export const homePageLoad = payload => ({
  type: events.HOME_VIEWED,
  payload
});

export const patientDataEntryViewed = () => ({
  type: events.PATIENT_DATA_ENTRY_VIEWED
});

export const patientOptionsVisibilityClicked = payload => ({
  type: events.PATIENT_OPTIONS_VISIBILITY_CLICKED,
  payload
});

export const riskAndGuidelineViewed = () => ({
  type: events.ASSESSMENT_GUIDANCE_VIEWED
});

export const patientOptionsViewed = () => ({
  type: events.PATIENT_OPTIONS_VIEWED
});

export const keyDataViewed = () => ({
  type: events.KEY_DATA_VIEWED
});

export const chartNoteClicked = payload => ({
  type: events.CHART_NOTE_CLICKED,
  payload
});

export const chartNoteCopyClicked = payload => ({
  type: events.CHART_NOTE_COPY_CLICKED,
  payload
});

export const generateQrCodeClicked = payload => ({
  type: events.GENERATE_QR_CODE_CLICKED,
  payload
});

export const generateQrCodeCopyClicked = payload => ({
  type: events.GENERATE_QR_CODE_COPY_CLICKED,
  payload
});

export const generateLinkClicked = payload => ({
  type: events.GENERATE_LINK_CLICKED,
  payload
});

export const generateLinkCopyClicked = payload => ({
  type: events.GENERATE_LINK_COPY_CLICKED,
  payload
});

export const navigationItemClicked = payload => ({
  type: events.NAVIGATION_ITEM_CLICKED,
  payload
});

export const toolLaunched = payload => ({
  type: events.TOOL_LAUNCHED,
  payload
});
