import styled from "styled-components";

export const ButtonsBlock = styled.div`
  text-align: center;

  button {
    background-color: ${({ theme }) => theme.colors.green};

    &:hover {
      background-color: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const AccountabilityMessage = styled.div`
  text-align: left;
  display: block;
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: center;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: flex-start;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: center;
  }
`;

export const Mark = styled.span`
  font-size: ${({ theme }) => theme.font.size.xs};
  line-height: ${({ theme }) => theme.space.lg};
  margin-left: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin: 0;
  }
`;

export const RedMark = styled.span`
  color: ${({ theme }) => theme.colors.red.base};
`;
