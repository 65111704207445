import styled from "styled-components";
import { Button } from "discover.medical.react-component-library";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const FlexContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

export const ToolBar = styled.div`
  background-color: ${({ theme }) => theme.colors.gray300};
  padding: ${({ theme }) => theme.space.gutter.base};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme }) => theme.space.gutter.sm};
  }
`;

export const BackButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.green};
  &:hover {
    background-color: ${({ theme }) => theme.colors.green};
  }
`;

export const IFrame = styled.iframe`
  flex: 1;
  border: none;
  background: white;
  width: 100%;
`;
