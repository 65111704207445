import React, { Fragment } from "react";
import { Accordion } from "discover.medical.react-component-library";
import OptionGridAnswer from "../../option-grid-answer";
import "../_index.css";

export const AccordionView = ({
  options,
  questions,
  fields,
  isSingleColumn,
  getOptionName
}) => {
  return (
    <Fragment>
      {questions.map((question, questionIndex) => {
        return (
          <Accordion
            toggleText={question.question}
            key={question.question}
            variant="quaternary"
            className="OptionGridList_question"
          >
            <div className="OptionGridList_optionList">
              {question.answers.map((answer, answerIndex) => (
                <div
                  key={answer.optionKey}
                  className="OptionGridList_option"
                  data-auto={`OP${answerIndex + 1}FAQ${questionIndex +
                    1}-content-component`}
                >
                  {!isSingleColumn && (
                    <div
                      data-auto="optiongrid-accordion-option-title"
                      className="OptionGridList_optionName"
                    >
                      {getOptionName(options, answer.optionKey)}
                    </div>
                  )}
                  <OptionGridAnswer
                    answer={answer.answer}
                    fields={fields}
                    optionKey={answer.optionKey}
                    type="list"
                  />
                </div>
              ))}
            </div>
          </Accordion>
        );
      })}
    </Fragment>
  );
};

export default AccordionView;
