/**
 * Wrapper around Amplitude-js
 * @module
 *
 * @desc
 * This wrapper provides functions that can be used for initializing amplitude and then logging events.
 * @see https://ebscoind.sharepoint.com/sites/BIAnalyticsWiki/Pages/Instrument%20your%20Application.aspx
 *
 */

import {
  INIT_OPTIONS_API_KEY_EXPECTED,
  SET_DEVICEID_STRING_EXPECTED,
  SET_USERID_STRING_EXPECTED,
  SET_USERINFO_OBJECT_EXPECTED,
  SET_GROUPINFO_GROUP_TYPE_STRING_EXPECTED,
  SET_GROUPINFO_GROUP_NAME_STRING_EXPECTED,
  LOG_EVENT_TYPE_STRING_EXPECTED,
  LOG_EVENT_PROPERTIES_OBJECT_EXPECTED
} from "../../shared/constants/analytics";

import amplitude from "amplitude-js";

let hasOptedOut;
const persistentEventsCache = [];

/**
 * Initializes the Amplitude Javascript SDK with your apiKey
 * @see https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference
 * @throws an error if apiKey is missing from the config
 * @example initAnalytics()
 */
export const initAnalytics = (apiKey, opts = {}) => {
  if (typeof window !== "undefined" && apiKey) {
    // If we're not being serverside rendered and we have a proper API key, instantiate analytics
    amplitude.getInstance().init(apiKey, null, opts);
    hasOptedOut = opts.optOut;
  } else {
    throw new Error(INIT_OPTIONS_API_KEY_EXPECTED);
  }
};

/**
 * Sets a custom deviceId for current user
 * @param {string} deviceId - custom deviceId for current user
 * @see https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference
 * @throws an error if deviceId is not available or if it is not a string
 * @example setUserDevice('9f4b7cfc-7fda-4dab-b0bc-dfd34a9505c5')
 */
export const setUserDevice = deviceId => {
  if (typeof deviceId !== "string") {
    throw new Error(SET_DEVICEID_STRING_EXPECTED);
  }
  if (amplitude) {
    amplitude.getInstance().setDeviceId(deviceId);
  }
};

/**
 * Gets the deviceId for current user
 * @example setUserDevice()
 */
export const getUserDevice = () => {
  if (amplitude?.getInstance()) {
    return amplitude.getInstance().options.deviceId;
  }
  return false;
};

/**
 * Sets an identifier for the current user
 * @param {string} userId - identifier to set
 * @see https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference
 * @throws an error if userId is not available or if it is not a string or not null
 * @example setUser('me@ebsco.com')
 */
export const setUser = userId => {
  if (typeof userId !== "string" && userId !== null) {
    throw new Error(SET_USERID_STRING_EXPECTED);
  }

  if (amplitude) {
    amplitude.getInstance().setUserId(userId);
  }
};

/**
 * Sets user properties for the current user
 * @param {object|{}} properties -  object with string keys and values for the user properties to set.
 * @see https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference
 * @throws an error if properties is not available or if it is not a object
 * @example setUserInfo({'gender': 'female', 'sign_up_complete': true})
 */
export const setUserInfo = properties => {
  if (typeof properties !== "object") {
    throw new Error(SET_USERINFO_OBJECT_EXPECTED);
  }

  if (amplitude) {
    const cleaner = new amplitude.Identify();
    if (!properties["auth_method_primary"]) {
      cleaner.unset("auth_method_primary");
    }
    if (!properties["auth_method_secondary"]) {
      cleaner.unset("auth_method_secondary");
    }
    if (!properties["ehr_vendor"]) {
      cleaner.unset("ehr_vendor");
    }
    amplitude.getInstance().identify(cleaner);
    amplitude.getInstance().setUserProperties(properties);
  }
};

/**
 * Add user to a group or groups. You need to specify a groupType and groupName(s).
 * Note: this will also set groupType: groupName as a user property.
 *
 * @param {string} groupType - the group type (ex: orgId)
 * @param {string} groupName - the name of the group (ex: 15)
 * @see https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference
 * @throws an error if groupType or groupname are not valid
 * @example setGroupInfo('orgId', 15); // this adds the current user to orgId 15.
 */
export const setGroupInfo = (groupType, groupName) => {
  if (typeof groupType !== "string") {
    throw new Error(SET_GROUPINFO_GROUP_TYPE_STRING_EXPECTED);
  }

  if (typeof groupName !== "string") {
    throw new Error(SET_GROUPINFO_GROUP_NAME_STRING_EXPECTED);
  }
  if (amplitude) {
    amplitude.getInstance().setGroup(groupType, groupName);
  }
};

/**
 * Log an event with eventType and eventProperties
 * @param {string} eventType - name of event
 * @param {object} eventProperties - (optional) an object with string keys and values
 * for the event properties
 * @see https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference
 * @example log('search_initiated', {'location': 'landing', 'term': 'apples'});
 */
export const log = (eventType, eventProperties) => {
  if (typeof eventType !== "string") {
    throw new Error(LOG_EVENT_TYPE_STRING_EXPECTED);
  }

  if (typeof eventProperties !== "object") {
    throw new Error(LOG_EVENT_PROPERTIES_OBJECT_EXPECTED);
  }

  if (!amplitude) {
    return;
  }

  if (hasOptedOut) {
    persistentEventsCache.push({ eventType, eventProperties });
    return;
  }

  amplitude.getInstance().logEvent(eventType, eventProperties);
};

const flushPersistentEventsCache = () => {
  if (!persistentEventsCache.length) {
    return;
  }

  const amplitudeInstance = amplitude.getInstance();

  persistentEventsCache.forEach(event => {
    const { eventType, eventProperties } = event;

    amplitudeInstance.logEvent(eventType, eventProperties);
  });

  persistentEventsCache.splice(0, persistentEventsCache.length);
};

/**
 * Set Opt Out
 * @param {string} shouldOptOut - wether or not to opt out
 * @see https://amplitude.zendesk.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference
 * @example optOut(false)
 */
export const optOut = shouldOptOut => {
  if (typeof window === "undefined" || !amplitude) {
    return;
  }

  hasOptedOut = shouldOptOut;
  amplitude.getInstance().setOptOut(shouldOptOut);

  if (!shouldOptOut) {
    flushPersistentEventsCache();
  }
};
