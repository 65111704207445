import styled from "styled-components";
import { Select as RCLSelect } from "discover.medical.react-component-library";

export const Select = styled(RCLSelect)`
  && {
    width: 6rem;
  }
`;

export const Label = styled.label`
  margin-left: ${({ theme }) => theme.space.sm};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 1.25rem;
  align-self: center;
`;
