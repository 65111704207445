import React from "react";
import PropTypes from "prop-types";
import { ToolsListItemIconHolder } from "./index.styled";
import { Icon, Tooltip } from "discover.medical.react-component-library";

const ToolsTypeIcon = ({ isActive, glyph, label, content }) => {
  const settings = {
    glyph,
    color: isActive ? "dark" : "inactive",
    size: "md"
  };
  return (
    <ToolsListItemIconHolder cell>
      {isActive ? (
        <Tooltip
          variant="dark"
          hasArrow={true}
          placement="bottom"
          content={content}
          extraMargin={false}
          trigger={<Icon aria-label={label} icon={settings} />}
        />
      ) : (
        <Icon aria-label={`${label}, Disabled`} icon={settings} />
      )}
    </ToolsListItemIconHolder>
  );
};

ToolsTypeIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
  glyph: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default ToolsTypeIcon;
