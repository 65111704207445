import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import { Root } from "./index.styled";

export default function ToolHeaderButton({ children, onClick, dataAuto }) {
  const theme = useContext(ThemeContext);
  return (
    <Root
      color={theme.colors.secondary.base}
      type="button"
      onClick={onClick}
      data-auto={dataAuto}
    >
      {children}
    </Root>
  );
}

ToolHeaderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  dataAuto: PropTypes.string
};
