import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import {
  Flex,
  Type,
  useMediaQuery
} from "discover.medical.react-component-library";
import PatientChartNoteButton from "../patient-chart-note-button";
import StepperComponent from "../stepper-component";
import { Header, HeaderTools } from "./index.styled";
import ToolActions from "../../../components/tool-actions";
export const ToolHeader = ({
  activeStepIndex,
  moveToStep,
  title,
  subTitle,
  isChartNoteVisible,
  openPatientChartNoteModal,
  isStepsVisible
}) => {
  const outcome = useSelector(({ fields }) => fields.outcome);
  const steps = useSelector(({ tool }) => tool.controls.steps);
  const toolType = useSelector(({ tool }) => tool.metadata.toolType);
  const processedSteps = steps.map(step => ({
    label: step.charAt(0).toUpperCase() + step.slice(1),
    disabled: step !== "patient" && isEmpty(outcome)
  }));
  const isMobile = useMediaQuery("max", "sm");
  return (
    <Header container justify={{ content: "space-between" }}>
      <Flex cell column align={{ self: "center" }}>
        {subTitle && (
          <Type element="p" color="gray700" fontSize="xs" marginBottom="xs">
            {subTitle}
          </Type>
        )}
        <Type
          element="h1"
          styledAs="h2"
          color="gray800"
          fontFamily="secondary"
          data-auto="tool-header-title"
          marginBottom="0"
        >
          {title}
        </Type>
      </Flex>
      <HeaderTools cell align={{ item: "flex-start" }}>
        {isChartNoteVisible && (
          <PatientChartNoteButton
            isMobile={isMobile}
            data-auto="button-patient-chart-note"
            onClick={openPatientChartNoteModal}
          />
        )}
        {activeStepIndex === 0 && <ToolActions />}
        {isStepsVisible && [2, 3].includes(toolType) && (
          <StepperComponent
            steps={processedSteps}
            activeStepIndex={activeStepIndex}
            moveToStep={moveToStep}
          />
        )}
      </HeaderTools>
    </Header>
  );
};

export default ToolHeader;

ToolHeader.propTypes = {
  activeStepIndex: PropTypes.number.isRequired,
  moveToStep: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  isChartNoteVisible: PropTypes.bool,
  openPatientChartNoteModal: PropTypes.func,
  isStepsVisible: PropTypes.bool
};
