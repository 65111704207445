import React, { Fragment } from "react";
import { Type } from "discover.medical.react-component-library";
import BackgroundQuestion from "../background-question";
import BackgroundText from "../background-text";
import PropTypes from "prop-types";

import "./_index.css";

export const TableBackgroundInfo = ({ questions, optionKey, title }) => {
  return (
    <Fragment>
      <Type
        element="h1"
        data-auto="option-grid-background-option-name"
        className="TableBackgroundInfo_OptionName"
      >
        {title}
      </Type>

      {questions.map(question => (
        <Fragment key={question.question}>
          {question.answers.map(answer => (
            <Fragment key={answer.optionKey}>
              {answer.backgroundText && answer.optionKey === optionKey && (
                <Fragment>
                  <BackgroundQuestion question={question.question} />
                  <BackgroundText bgText={answer.backgroundText[0].content} />
                </Fragment>
              )}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
};

BackgroundText.propTypes = {
  questions: PropTypes.array,
  optionKey: PropTypes.string,
  title: PropTypes.string
};

export default TableBackgroundInfo;
