import { useCallback, useEffect } from "react";
import useModal from "../../../../../../../shared/hooks/useModal";
import { SNAPSHOT_QR_CODE } from "../../../../../../../store/action-types";

export default function useQrCodeGeneration({
  setIsSpinnerShown,
  sendOpenAccessGeneratedEventAction,
  resetSnapshotDataAction,
  openQRCodeAction,
  snapshotProcessingInfo,
  aggregateId
}) {
  const [isQrCodeOpen, openQrCodeModal, closeQrCodeModal] = useModal();

  const sendOpenAccessGeneratedEvent = useCallback(() => {
    if (
      snapshotProcessingInfo &&
      snapshotProcessingInfo.task === SNAPSHOT_QR_CODE &&
      snapshotProcessingInfo.snapshotId &&
      !snapshotProcessingInfo.isSnapshotLoading
    ) {
      sendOpenAccessGeneratedEventAction(
        snapshotProcessingInfo.snapshotId,
        aggregateId
      );
    }
  }, [sendOpenAccessGeneratedEventAction, snapshotProcessingInfo, aggregateId]);

  useEffect(() => {
    if (isQrCodeOpen) {
      resetSnapshotDataAction();
      openQRCodeAction();
      setIsSpinnerShown(true);
    }
  }, [
    isQrCodeOpen,
    resetSnapshotDataAction,
    setIsSpinnerShown,
    openQRCodeAction
  ]);

  useEffect(() => {
    sendOpenAccessGeneratedEvent();
  }, [aggregateId, sendOpenAccessGeneratedEvent, snapshotProcessingInfo]);

  return { isQrCodeOpen, openQrCodeModal, closeQrCodeModal };
}
