import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Type } from "discover.medical.react-component-library";
import OptionGridDataCell from "./option-grid-data-cell";
import OptionGridHeaderCell from "./option-grid-header-cell";
import OptionGridAnswer from "../option-grid-answer";
import { useOptionGridContext } from "../../OptionGridContext";
import OptionGridDividedDataCell from "./option-grid-divided-data-cell";
import contentParser from "../../../../../../../helpers/content-parser";
import "./_index.css";
import BackgroundInfoButton from "../../option-grid-info-panel/background-info-button/BackgroundInfoButton";
import {
  backgroundTextChecker,
  backgroundTextCheckerSingleColumn
} from "../../option-grid-info-panel/background-util";

export const OptionGridTable = ({
  options,
  questions,
  fields,
  hideOption,
  showCloseButton,
  totalOptions,
  isSingleColumn
}) => {
  const getCellWidth = () => {
    switch (totalOptions) {
      case 1:
        return "large";
      case 2:
        return "medium";
      default:
        return "small";
    }
  };

  const optionGridContext = useOptionGridContext();

  if (isSingleColumn) {
    return (
      <table
        className={cn("OptionGridTable", {
          OptionGridTable_table__isSingleColumn: isSingleColumn
        })}
      >
        <thead>
          <tr className="OptionGridTable_cell__sticky">
            <OptionGridHeaderCell colspan="2">
              <Type
                element="p"
                fontFamily={null}
                fontWeight={null}
                fontSize="lg"
              >
                PATIENT QUESTIONS
              </Type>
              {backgroundTextCheckerSingleColumn(questions) && (
                <BackgroundInfoButton
                  onClick={() => optionGridContext.openInfoPanel()}
                />
              )}
            </OptionGridHeaderCell>
          </tr>
        </thead>
        <tbody>
          {questions.map((question, questionIndex) => (
            <tr key={question.question} data-auto="option-grid-body-row">
              <OptionGridDataCell
                isQuestion={true}
                isSingleColumn={isSingleColumn}
                className="OptionGridTable_cell__sticky"
              >
                {contentParser(question.question)}
              </OptionGridDataCell>
              <OptionGridDataCell width="large" isSingleColumn={isSingleColumn}>
                <div
                  data-auto="option-grid-body-single-column-block"
                  className="OptionGridTable_row"
                >
                  {question.answers.map((answer, answerIndex) => (
                    <OptionGridDividedDataCell key={answer.optionKey}>
                      <div
                        data-auto={`OP${answerIndex + 1}FAQ${questionIndex +
                          1}-content-component`}
                      >
                        <OptionGridAnswer
                          answer={answer.answer}
                          fields={fields}
                          optionKey={answer.optionKey}
                        />
                      </div>
                    </OptionGridDividedDataCell>
                  ))}
                </div>
              </OptionGridDataCell>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <div data-auto="optiongrid-table" className="OptionGridTable">
      <thead>
        <tr data-auto="option-grid-header-row">
          <OptionGridHeaderCell
            isFirst={true}
            className="OptionGridTable_cell__sticky"
          >
            <Type element="p" fontFamily={null} fontWeight={null} fontSize="sm">
              Patient Questions
            </Type>
          </OptionGridHeaderCell>
          {options.map(option => (
            <OptionGridHeaderCell
              key={option.optionKey}
              optionKey={option.optionKey}
              showCloseButton={showCloseButton}
              onCloseButtonClick={hideOption}
            >
              <Type
                element="p"
                fontFamily={null}
                fontWeight={null}
                fontSize="lg"
              >
                {option.name}
              </Type>
              {backgroundTextChecker(questions, option.optionKey) && (
                <BackgroundInfoButton
                  onClick={() =>
                    optionGridContext.openInfoPanel(option.optionKey)
                  }
                />
              )}
            </OptionGridHeaderCell>
          ))}
        </tr>
      </thead>
      <tbody>
        {questions.map((question, questionIndex) => (
          <tr key={question.question} data-auto="option-grid-body-row">
            <OptionGridDataCell
              isQuestion={true}
              className="OptionGridTable_cell__sticky"
            >
              {contentParser(question.question)}
            </OptionGridDataCell>

            {question.answers.map((answer, answerIndex) => (
              <OptionGridDataCell key={answer.optionKey} width={getCellWidth()}>
                <div
                  data-auto={`OP${answerIndex + 1}FAQ${questionIndex +
                    1}-content-component`}
                >
                  <OptionGridAnswer
                    answer={answer.answer}
                    fields={fields}
                    optionKey={answer.optionKey}
                  />
                </div>
              </OptionGridDataCell>
            ))}

            {/* add hidden cell at the end to fill up remaining table space */}
            <td className="OptionGridDataCell__hidden"></td>
          </tr>
        ))}
      </tbody>
    </div>
  );
};

OptionGridTable.propTypes = {
  questions: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  fields: PropTypes.object
};

OptionGridTable.defaultProps = {
  selected: [],
  fields: {}
};

export default OptionGridTable;
