import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { Menu, Overflow } from "discover.medical.react-component-library";
import OptionGridButton from "../option-grid-button";
import "./_index.css";

const OptionGridDropdown = ({
  className,
  children,
  open,
  text,
  glyph,
  toggleMenu,
  dataAuto,
  windowSize
}) => {
  return (
    <div
      data-auto={"optiongrid-dropdown" + (dataAuto ? `-${dataAuto}` : "")}
      className={cn("OptionGridDropdown", className)}
    >
      <Menu
        isOpen={open}
        toggleMenu={toggleMenu}
        triggerAnchor={{
          vertical: "bottom",
          horizontal: "right"
        }}
        menuAnchor={{
          vertical: "top",
          horizontal: "right"
        }}
        trigger={
          <OptionGridButton viewport={windowSize} text={text} glyph={glyph} />
        }
      >
        <Overflow.Menu className="OptionGridDropdown_menu">
          {children}
        </Overflow.Menu>
      </Menu>
    </div>
  );
};

OptionGridDropdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  text: PropTypes.string,
  toggleMenu: PropTypes.func,
  dataAuto: PropTypes.string,
  windowSize: PropTypes.string
};

OptionGridDropdown.defaultProps = {
  className: "",
  open: false,
  text: "Show/Hide",
  dataAuto: "showhide",
  toggleMenu: () => {}
};

export default OptionGridDropdown;
