import React from "react";
import { useSelector } from "react-redux";
import { Type } from "discover.medical.react-component-library";
import DynamicForm from "../../../../content-components/DynamicForm";
import ModalTemplate from "../modal-template";
import tabComponentsRetriever from "../../../../templates/components/tabComponentsRetriever";
import {
  Abstract,
  Column,
  Contributors,
  MainBlock,
  Section
} from "./index.styled";

export const ModalAbout = ({ closeAboutModal, isAboutOpen }) => {
  const state = useSelector(storageState => storageState);
  const contributors = tabComponentsRetriever(state, "contributors");
  const certifications = tabComponentsRetriever(state, "certifications");
  const competingInterests = tabComponentsRetriever(
    state,
    "competingInterests"
  );

  return (
    <ModalTemplate
      onRequestClose={closeAboutModal}
      isOpen={isAboutOpen}
      header="About"
      size="large"
    >
      <MainBlock>
        <Column>
          <Contributors>
            <Type
              element="h4"
              color="primary"
              fontFamily="secondary"
              className="Title"
            >
              Contributors
            </Type>

            <DynamicForm componentsList={contributors} />
          </Contributors>
        </Column>

        <Column>
          {certifications && !!certifications.length && (
            <Section data-auto="modal-about-certification">
              <Type
                element="h4"
                color="primary"
                fontFamily="secondary"
                className="pb-sm"
              >
                Certification
              </Type>
              <DynamicForm componentsList={certifications} />
            </Section>
          )}

          <Section>
            <Type
              element="h4"
              color="primary"
              fontFamily="secondary"
              className="pb-sm"
            >
              Disclosure/Conflict of Interest
            </Type>
            <DynamicForm componentsList={competingInterests} />
          </Section>

          <Section>
            <Type
              helement="h4"
              color="primary"
              fontFamily="secondary"
              className="pb-sm"
            >
              Funding
            </Type>
            <Abstract>
              The development of this decision aid was fully supported by EBSCO
              Health with no external funding.
            </Abstract>
          </Section>
        </Column>
      </MainBlock>
    </ModalTemplate>
  );
};

export default ModalAbout;
