import { log } from "../../analytics";
import { events } from "../index";

export default (appState, payload, type) => {
  const { optionGrid = {} } = appState;
  const { selected = {} } = optionGrid;
  const { tool = {} } = appState;
  const { metadata = {} } = tool;
  const { aggregateId } = appState.snapshot.events;

  const logObject = {
    type: events.PATIENT_OPTIONS_VISIBILITY_CLICKED,
    data: {
      tool_type: metadata.toolType,
      tool_slug: metadata.toolSlug,
      aggregate_id: aggregateId,
      visible: selected.toString(),
      value: payload.value,
      affordance: payload.affordance
    }
  };

  log(logObject.type, logObject.data);
};
