import styled from "styled-components";
import { Divider, Flex } from "discover.medical.react-component-library";
import DynamicForm from "../../../../../content-components";

export const Root = styled.div`
  margin-top: 1rem;
`;

export const ErrorsBanner = styled.div`
  padding: 0.625rem 0 1.125rem;
`;

export const Container = styled(Flex)`
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

export const Column = styled(Flex)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-basis: 50%;
    flex-shrink: 0;
  }
`;

export const StyledDynamicForm = styled(DynamicForm)`
  flex-grow: 1;
`;

export const FooterDivider = styled(Divider)`
  display: none;
  margin-top: 1.125rem;
  margin-bottom: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;
