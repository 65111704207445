import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useOptionGridContext } from "../OptionGridContext";
import { Type } from "discover.medical.react-component-library";
import DynamicForm from "../../../../../content-components/";
import tabComponentsRetriever from "../../../tabComponentsRetriever";
import OptionGridModal from "../option-grid-modal";
import "./_index.css";

export function OptionGridReferences({ references }) {
  const { closeReferencesModal, isReferencesOpen } = useOptionGridContext();

  return (
    <OptionGridModal
      onRequestClose={closeReferencesModal}
      isOpen={isReferencesOpen}
      header="References"
    >
      <div className="OptionGridReferences">
        <Type
          element="p"
          className="OptionGridReferences_Description"
          fontSize="sm"
        >
          Our decision aids are created using the best-available evidence. The
          creation process centers on a systematic approach to evidence search,
          selection, appraisal, and synthesis that leverages the systematic
          literature surveillance process of DynaMed.
        </Type>

        <DynamicForm componentsList={references} />
      </div>
    </OptionGridModal>
  );
}

OptionGridReferences.propTypes = {
  references: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  references: tabComponentsRetriever(state, "references")
});

export default connect(mapStateToProps)(OptionGridReferences);
