import React from "react";
import { Flex, Skeleton } from "discover.medical.react-component-library";

const Loading = () => {
  return (
    <Flex container column marginBottom="lg">
      <Flex
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="gray400"
        marginBottom="md"
        paddingY="sm"
      >
        <Skeleton animate height={34} kind="rect" width={200} />
      </Flex>
      {[1, 2, 3].map(() => (
        <Flex
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="gray400"
          paddingY="sm"
        >
          <Skeleton
            animate
            height={24}
            rag
            kind="rect"
            width={350}
            marginBottom="sm"
          />
          <Skeleton animate kind="text" rag />
          <Skeleton animate kind="text" rag />
        </Flex>
      ))}
    </Flex>
  );
};

Loading.displayName = "Loading";

export default Loading;
