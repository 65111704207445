import {
  PATIENT_ENCOUNTER_REPORT_REQUEST,
  PATIENT_ENCOUNTER_REPORT_REQUEST_SUCCESS,
  PATIENT_ENCOUNTER_REPORT_REQUEST_ERROR,
  PATIENT_ENCOUNTER_REPORT_STATUS_REQUEST,
  PATIENT_ENCOUNTER_REPORT_STATUS_ERROR,
  PATIENT_ENCOUNTER_REPORT_STATUS_TIMEOUT,
  PATIENT_ENCOUNTER_REPORT_STATUS_SUCCESS,
  PATIENT_ENCOUNTER_REPORT_DOWNLOAD_REQUEST,
  PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SUCCESS,
  PATIENT_ENCOUNTER_REPORT_DOWNLOAD_ERROR,
  PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SKIP,
  PATIENT_ENCOUNTER_REPORT_RESET
} from "../action-types";

import {
  REPORT_REQUESTING,
  REPORT_REQUESTED,
  REPORT_ERROR,
  REPORT_TIMEOUT,
  REPORT_DONE
} from "./constants";

const initialState = {
  status: null,
  statusChecks: 0,
  id: null
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case PATIENT_ENCOUNTER_REPORT_RESET:
      return initialState;
    case PATIENT_ENCOUNTER_REPORT_REQUEST:
      return {
        ...state,
        status: REPORT_REQUESTING,
        statusChecks: 0,
        id: null
      };
    case PATIENT_ENCOUNTER_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        id: payload.id,
        status: REPORT_REQUESTED,
        statusChecks: 0
      };
    case PATIENT_ENCOUNTER_REPORT_STATUS_SUCCESS:
      return {
        ...state,
        statusChecks: state.statusChecks += 1,
        status: payload.status ? payload.status.toLowerCase() : payload.status,
      };
    case PATIENT_ENCOUNTER_REPORT_STATUS_ERROR:
      return {
        ...state,
        status: REPORT_ERROR,
        errorCode: payload.code,
        statusChecks: state.statusChecks += 1,
      };
    case PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SUCCESS:
    return {
      ...state,
      status: REPORT_DONE
    };
    case PATIENT_ENCOUNTER_REPORT_REQUEST_ERROR:
    case PATIENT_ENCOUNTER_REPORT_DOWNLOAD_ERROR:
      return {
        ...state,
        status: REPORT_ERROR,
        errorCode: payload.code
      };
    case PATIENT_ENCOUNTER_REPORT_STATUS_TIMEOUT:
      return {
        ...state,
        status: REPORT_TIMEOUT
      };
    case PATIENT_ENCOUNTER_REPORT_STATUS_REQUEST:
    case PATIENT_ENCOUNTER_REPORT_DOWNLOAD_REQUEST:
    case PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SKIP:
    default:
      return state;
  }
};
