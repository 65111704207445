import styled from "styled-components";
import { Input } from "discover.medical.react-component-library";

export const NumericControl = styled(Input.Text).attrs(() => ({
  type: "number"
}))`
  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &,
    &:hover,
    &:focus {
      appearance: none;
      -moz-appearance: textfield;
    }
  }
`;
