import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const Content = styled.div`
  padding: 1.75rem 0.625rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1.75rem;
  }
`;

export const SectionTitle = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};
`;

export const LegendTitle = styled(Type)`
  margin-bottom: 0.625rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
`;
