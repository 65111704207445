import styled from "styled-components";
import { Search, Autocomplete } from "discover.medical.react-component-library";

export const SearchWrapper = styled(Search)`
  width: 100%;
`;

export const AutoSuggestMenuItemMatchWrapper = styled.span`
  .match {
    font-weight: bold;
  }
  .noMatch {
    font-weight: ${({ theme }) => theme.font.weight.thin};
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
`;
