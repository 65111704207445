import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./_index.css";

const OptionGridDividedDataCell = ({ className, children }) => {
  return (
    <td className={cn("OptionGridDividedDataCell", className)}>{children}</td>
  );
};

OptionGridDividedDataCell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

OptionGridDividedDataCell.defaultProps = {
  className: "",
  children: null
};

export default OptionGridDividedDataCell;
