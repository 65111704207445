import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MessageModal from "../message-modal";
import { useMediaQuery } from "discover.medical.react-component-library";
import { resetForbiddenModal } from "../../store/shared-data/actions";

const ForbiddenModal = () => {
  const dispatch = useDispatch();
  const { showForbiddenModal, forbiddenErrorMsg } = useSelector(
    state => state.sharedData
  );
  const isMobile = useMediaQuery("max", "sm");
  const closeModal = () => {
    dispatch(resetForbiddenModal());
  };
  return (
    <MessageModal
      isOpen={showForbiddenModal}
      buttonText={"OK"}
      clickAction={closeModal}
      isMobile={isMobile}
      onClose={closeModal}
    >
      {forbiddenErrorMsg}
    </MessageModal>
  );
};

export default ForbiddenModal;
