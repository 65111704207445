import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const MainBlock = styled.div`
  a {
    word-break: break-all;
    color: ${({ theme }) => theme.colors.secondary.base};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    u {
      text-decoration: none;
    }
  }
`;

export const Description = styled(Type)`
  padding: 0.875rem 0 0.75rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;
