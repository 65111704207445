import React, { Fragment } from "react";
import get from "lodash/get";
import { Toggle as RCLToggle } from "discover.medical.react-component-library";
import { componentStateMapper } from "../componentsStateMapper";
import "./_index.css";
import BasicMultipleSelect from "../basic-multiple-select";

export class MultiselectToggle extends BasicMultipleSelect {
  renderComponent = () => {
    const { name, value, view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    const constraints = get(view, "attributes.fieldInfo.constraints", []);

    return (
      <Fragment>
        {Array.isArray(labels) && (
          <RCLToggle
            id={name}
            deselectAll={true}
            exclusive={false}
            value={value}
            required={constraints && constraints.required}
            onChange={this.handleToggleChange}
          >
            {labels.map(item => (
              <RCLToggle.Button
                key={item.value}
                data-auto="multi-select-button"
                value={item.value}
                hasError={this.hasErrors()}
                hasWarning={this.hasWarnings()}
              >
                {item.name}
              </RCLToggle.Button>
            ))}
          </RCLToggle>
        )}
      </Fragment>
    );
  };
}

export default componentStateMapper(MultiselectToggle);
