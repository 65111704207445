import React from "react";
import SubscriptionInfo from "../../../../tools-browse-shared-components/subscription-info";
import { Root } from "../../../../tools-browse-shared-components/styled-components/index.styled";
import PropTypes from "prop-types";

const SubscriptionModal = ({ isOpen, isMobile, onClose }) => (
  <Root
    v2
    data-auto="subscription-modal"
    borderRadius="sm"
    onRequestClose={onClose}
    isOpen={isOpen}
    style={{
      maxWidth: isMobile || "30rem"
    }}
    fullScreen={isMobile}
  >
    <SubscriptionInfo />
  </Root>
);

SubscriptionModal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default SubscriptionModal;
