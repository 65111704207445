import styled from "styled-components";
import { ContentFormatter } from "discover.medical.react-component-library";

export const Content = styled(ContentFormatter)`
  small {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
  hr {
    height: 1px;
    background: ${({ theme }) => theme.colors.gray400};
    border: none;
  }
`;
