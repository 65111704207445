import {
  TOOL_BROWSE_GET_CATEGORIES,
  TOOL_BROWSE_GET_CATEGORIES_ERROR,
  TOOL_BROWSE_GET_CATEGORIES_SUCCESS,
  TOOL_BROWSE_GET_TOOL_INFO,
  TOOL_BROWSE_GET_TOOL_INFO_ERROR,
  TOOL_BROWSE_GET_TOOL_INFO_SUCCESS,
  TOOL_BROWSE_GET_TOOLS,
  TOOL_BROWSE_GET_TOOLS_ERROR,
  TOOL_BROWSE_GET_TOOLS_SUCCESS,
  TOOL_BROWSE_SET_TOOL_INFO_DATA,
  TOOL_BROWSE_RESET,
  TOOL_BROWSE_GET_FAV_TOOLS_SUCCESS
} from "../action-types";

export const initialState = {
  isLoading: false,
  isInfoLoading: false,
  pendingCategories: [],
  toolInfoData: null,
  featureTool: null,
  isCallToActionVisible: false,
  categories: [],
  tools: null,
  freeTools: { clinicalTools: null, sharedTools: null },
  info: {}
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case TOOL_BROWSE_GET_CATEGORIES:
      return { ...state, isLoading: true };

    case TOOL_BROWSE_GET_CATEGORIES_SUCCESS:
      const tools = payload.cdh.reduce((stateAcc, category) => {
        return { ...stateAcc, [category.nestedElementsPath]: [] };
      }, {});

      return {
        ...state,
        categories: payload.cdh,
        isLoading: false,
        tools,
        featureTool: payload.featureTool,
        isCallToActionVisible: payload.isCallToActionVisible
      };

    case TOOL_BROWSE_GET_CATEGORIES_ERROR:
      return { ...state, isLoading: false };

    case TOOL_BROWSE_GET_TOOLS:
      return {
        ...state,
        pendingCategories: [...state.pendingCategories, payload]
      };

    case TOOL_BROWSE_GET_TOOLS_SUCCESS:
      return {
        ...state,
        tools: { ...state.tools, [payload.nestedElementsPath]: payload.tools },
        info: {
          ...state.info,
          ...payload.tools.reduce((stateAcc, tool) => {
            return { ...stateAcc, [tool.an]: null };
          }, {})
        },
        pendingCategories: [
          ...state.pendingCategories.filter(
            category => category !== payload.nestedElementsPath
          )
        ]
      };

    case TOOL_BROWSE_GET_TOOLS_ERROR:
      return { ...state, pendingCategories: [] };
    case TOOL_BROWSE_GET_FAV_TOOLS_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          ...payload.tools.reduce((state, tool) => {
            return { ...state, [tool.an]: null };
          }, {})
        }
      };
    case TOOL_BROWSE_GET_TOOL_INFO:
      return { ...state, isInfoLoading: true };

    case TOOL_BROWSE_GET_TOOL_INFO_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          [payload.an]: payload.info
        },
        isInfoLoading: false
      };

    case TOOL_BROWSE_GET_TOOL_INFO_ERROR:
      return { ...state, isInfoLoading: false };

    case TOOL_BROWSE_SET_TOOL_INFO_DATA:
      return { ...state, toolInfoData: payload };

    case TOOL_BROWSE_RESET:
      return { ...initialState };

    default:
      return state;
  }
};
