import React, { useEffect } from "react";
import { Button, Flex, Type } from "discover.medical.react-component-library";
import { useDispatch, useSelector } from "react-redux";

import PageLayout from "../../templates/page";
import ToolsList from "../../tools-browse-shared-components/tools-list";
import CallToAction from "./components/call-to-action";
import FeatureTool from "./components/feature-tool";
import {
  MainDescription,
  MainTitle,
  RightRail,
  SubscribeButton
} from "./index.styled";
import {
  Main,
  MainContent
} from "../../tools-browse-shared-components/styled-components/index.styled";
import { getCategories } from "../../../store/tool-browse/actions";
import { CdhType } from "../../../shared/constants/toolsBrowse";

const Tools = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories(CdhType.DYNAMED_DECISIONS_TOOLS));
    // eslint-disable-next-line
  }, []);

  const isCallToActionVisible = useSelector(
    state => state.toolBrowse.isCallToActionVisible
  );

  return (
    <PageLayout dataAuto="tools-page" isNavigationExpanded={true}>
      <Main id="main">
        <MainTitle
          data-auto="tools-page-title"
          element="h1"
          color="senary"
          fontFamily="secondary"
        >
          Tools
        </MainTitle>

        <Flex
          container
          justify={{ content: "space-between" }}
          align={{ item: "flex-start" }}
        >
          <MainContent cell column>
            <MainDescription>
              <Type
                data-auto="tools-page-description"
                element="i"
                fontSize="sm"
                fontFamily="primary"
              >
                Evidence-based, clinical support and shared decision-making
                tools created by physicians.
              </Type>

              {isCallToActionVisible && (
                <SubscribeButton
                  as={Button}
                  kind="outlined"
                  href="https://www.ebsco.com/health-care/products/dynamed-decisions"
                >
                  About Subscribing
                </SubscribeButton>
              )}
            </MainDescription>

            <ToolsList cdhType={CdhType.DYNAMED_DECISIONS_TOOLS} />
          </MainContent>

          <RightRail cell span={4} column>
            <FeatureTool />
            {isCallToActionVisible && <CallToAction />}
          </RightRail>
        </Flex>
      </Main>
    </PageLayout>
  );
};

export default Tools;
