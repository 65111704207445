import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Modal,
  Type,
  Button,
  Tooltip
} from "discover.medical.react-component-library";
import {
  chartNoteClicked,
  chartNoteCopyClicked
} from "../../../../../store/telemetry/actions/index";
import { VIEWPORT_LARGE } from "../../../../../helpers/aliases";
import useCopy from "../../../../../components/templates/components/optiongridtab/option-grid/hooks/useCopy";
import tabComponentsRetriever from "../../../components/tabComponentsRetriever";
import DynamicForm from "../../../../content-components";
import { Note } from "./index.styled";

const stepToChartNoteOutputClassMapping = {
  assessment: "chart_note_cc",
  options: "chart_note_sdm"
};

export const ModalPatientChartNote = ({
  closePatientChartNoteModal,
  isPatientChartNoteOpen,
  activeStep
}) => {
  const chartnoteRef = useRef(null);
  const { copy } = useCopy();
  const dispatch = useDispatch();

  const chartNoteOutputClassTofilter =
    stepToChartNoteOutputClassMapping[activeStep];

  const state = useSelector(storageState => storageState);
  const {
    sharedData: { windowSize }
  } = state;
  // Filter out a list of chartNotes for a given screen preserving the original order.
  // Only screen specific or shared(outputClass="chart_note_all") will be shown
  const chartNotes = tabComponentsRetriever(state, "chartNote").filter(
    chartNote =>
      [chartNoteOutputClassTofilter, "chart_note_all"].indexOf(
        chartNote.style
      ) > -1
  );

  const handleCopyButtonClick = () => {
    copy(chartnoteRef.current);
    dispatch(chartNoteCopyClicked(activeStep));
  };

  useEffect(() => {
    if (isPatientChartNoteOpen) {
      dispatch(chartNoteClicked(activeStep));
    }
  }, [isPatientChartNoteOpen]);

  return (
    <Modal
      v2
      borderRadius="sm"
      padding={false}
      data-auto="patient-chart-note-modal"
      onRequestClose={closePatientChartNoteModal}
      isOpen={isPatientChartNoteOpen}
    >
      <div className="OptionGridModal_Header">
        <Type
          element="h2"
          color="primary"
          fontFamily="secondary"
          className="OptionGridModal_Title"
        >
          Patient Chart Note
        </Type>
      </div>
      <div data-auto="optiongrid-modal-body" className="OptionGridModal_Body">
        <div className="OptionGridModal_Content">
          <Type element="p" styledAs="em">
            This note is provided for clinicians to document use of this
            decision aid.
          </Type>
          <Note
            ref={chartnoteRef}
            data-auto="check_chart_note-content-component"
          >
            <DynamicForm componentsList={chartNotes} />
          </Note>
          <Tooltip
            placement={windowSize === VIEWPORT_LARGE ? "bottom" : "right"}
            content="Note copied to clipboard"
            hasArrow={true}
            toggle="click"
            trigger={
              <Button
                data-auto="chartnote-copy-button"
                onClick={handleCopyButtonClick}
                size="md"
              >
                Copy
              </Button>
            }
          />
        </div>
      </div>
    </Modal>
  );
};

ModalPatientChartNote.propTypes = {
  closePatientChartNoteModal: PropTypes.func.isRequired,
  isPatientChartNoteOpen: PropTypes.bool.isRequired,
  activeStep: PropTypes.string.isRequired
};

export default ModalPatientChartNote;
