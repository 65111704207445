import isNil from "lodash/isNil";
import { Link, useLocation } from "react-router-dom";

// Generate the props to pass to HamburgerLink and Navigation.Link
export const getLinkProps = (link, sessionNumber) => {
  const linkProps = {};

  linkProps.href = `${link.route}${
    !isNil(sessionNumber) && !link.isExternal
      ? `?session-number=${sessionNumber}`
      : ""
  }`;

  if (link.isExternal) {
    linkProps.target = "_blank";
    linkProps.linkComponent = "a";
  } else {
    linkProps.linkComponent = Link;
  }

  return linkProps;
};

export const isActive = link => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return pathname === link.route;
};

// Determine the index in which to display the current
// page indicator for hamburger menu and navigation menu
export const getIndicatorIndex = links => {
  let activeIndex = -1;
  links.forEach((link, index) => {
    if (isActive(link)) {
      activeIndex = index;
    }
  });
  return activeIndex;
};
