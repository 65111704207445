import {
  Toast,
  Type,
  useMediaQuery
} from "discover.medical.react-component-library";
import { get } from "lodash";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useModal from "../../../../shared/hooks/useModal";
import getLoginUrl from "../../../../shared/util/getLoginUrl";
import {
  addFavoriteTool,
  getFavoriteTools,
  handleToastFinish,
  removeFavoriteTool,
  resetTools,
  toggleModal
} from "../../../../store/favorite-tools/actions";
import MessageModal from "../../../message-modal";
import { ActionButton, ActionTooltip, StyledIcon } from "./index.styled";

const actionItems = {
  addFavorite: {
    id: "favorite",
    text: "Add to Favorites",
    icon: "bookmark_border",
    color: false,
    actionName: addFavoriteTool
  },
  removeFavorite: {
    id: "unfavorite",
    text: "Remove from Favorites",
    icon: "bookmark_added",
    color: true,
    actionName: removeFavoriteTool
  }
};
const toolTypes = [
  "SHARED_TOOL_TYPE1",
  "SHARED_TOOL_TYPE2",
  "CLINICAL_TOOL_TYPE"
];

const ToolActions = () => {
  const [isFavoriteTool, setIsFavoriteTool] = useState(false);
  const [favoriteToolId, setFavoriteToolId] = useState(null);
  const dispatch = useDispatch();
  const personalized = useSelector(state =>
    get(state, "user.userState.personalized", false)
  );
  const { toolType, contentName: an } = useSelector(state =>
    get(state, "tool.metadata", {})
  );
  const { sharedTools, clinicalTools, modalStatus, showToast } = useSelector(
    state =>
      get(state, "favoriteTools", {
        sharedTools: [],
        clinicalTools: [],
        modalStatus: false,
        showToast: false
      })
  );
  const isMobile = useMediaQuery("max", "sm");
  const [isModal, openModal, closeModal] = useModal();

  useEffect(() => {
    const favTool =
      [...sharedTools, ...clinicalTools].find(tool => tool.an === an) || {};
    if (favTool.id) {
      setFavoriteToolId(favTool.id);
      setIsFavoriteTool(true);
    } else {
      setIsFavoriteTool(false);
    }
  }, [sharedTools, clinicalTools, an]);

  useEffect(() => {
    modalStatus ? openModal() : closeModal();
  }, [modalStatus, openModal, closeModal]);

  const executeIfAllowed = async userAction => {
    if (!personalized) {
      dispatch(toggleModal(true));
      return;
    }
    dispatch(
      userAction({
        favoriteToolId,
        an,
        toolType: toolTypes[toolType - 1]
      })
    );
  };

  useEffect(() => {
    dispatch(resetTools());
    if (personalized) {
      dispatch(getFavoriteTools());
    }
  }, [dispatch, personalized]);

  const closeModalAction = () => {
    dispatch(toggleModal(false));
  };

  const redirectToLogin = () => {
    window.location.href = getLoginUrl();
  };
  const clickAction = personalized ? closeModalAction : redirectToLogin;
  const modalButtonText = personalized ? "Okay" : "Sign in/Register";
  const ModalContent = () =>
    !personalized ? (
      <>
        Please sign in or register for a <strong>free personal account</strong>{" "}
        and then you can bookmark tools for quicker access.
      </>
    ) : (
      <>
        Your bookmarked tools can be found in <strong>My Tools</strong> on the
        homepage.
      </>
    );

  const actionItem = isFavoriteTool
    ? actionItems.removeFavorite
    : actionItems.addFavorite;

  return (
    <>
      <ActionTooltip
        border={false}
        content={actionItem.text}
        contentSize="md"
        hasArrow={true}
        variant="light"
        trigger={
          <ActionButton
            data-auto={`tool-${actionItem.id}-button`}
            kind="outlined"
            size="iconOnly"
            contentSize="md"
            variant="primary"
            aria-label={actionItem.text}
            onClick={debounce(() => {
              executeIfAllowed(actionItem.actionName);
            }, 200)}
          >
            <StyledIcon
              $inputColor={actionItem.color}
              icon={{ glyph: actionItem.icon, size: "md" }}
            />
          </ActionButton>
        }
      />
      <MessageModal
        isOpen={isModal}
        onClose={closeModalAction}
        buttonText={modalButtonText}
        clickAction={clickAction}
        isMobile={isMobile}
      >
        <ModalContent />
      </MessageModal>
      <Toast
        type="error"
        shouldTransition={true}
        autohideTimeout={3000}
        isCentered={false}
        isOpen={showToast}
        onClose={() => {
          dispatch(handleToastFinish());
        }}
        data-auto="tool-exist-toast"
      >
        <Type>The Tool is already added to your bookmarked tool list</Type>
      </Toast>
    </>
  );
};

export default ToolActions;
