import get from "lodash/get";

export const getComponentParams = (params, fields) => {
  const paramsValues = {};
  Object.keys(params).forEach(key => {
    const paramData = get(fields, params[key].trim(), "");
    if (paramData) {
      paramsValues[key] = paramData;
    }
  });
  return paramsValues;
};
