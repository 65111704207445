import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable"; // For IE 11 support
import "element-closest-polyfill"; // For IE 11 support
import "resize-observer-polyfill/dist/ResizeObserver.global"; // For IE 11 support
import "intl";
import "f-twelve";
/**
 * Main client-side entry-point
 * @module
 *
 * @desc
 * react-scripts expects src/index.js as its entry-point for the SPA application
 */

import index, { initDatadogRum } from "./client";
index();
initDatadogRum();
