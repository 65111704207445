import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ToolHeader from "../tool-header";
import { OptionGridTabPanel } from "./index.styled";
import ToolHeaderButton from "../tool-header-button";
import OptionGridTab from "../../../components/optiongridtab";
import { isMHDDomain } from "../../../../../helpers/domain-manager";
import get from "lodash/get";
import {
  Tab,
  TabContext,
  TabList,
  TabPanel,
  TabPanels,
  Divider
} from "discover.medical.react-component-library";
import PopulationViewTab from "../populationviewtab";
import { navigationItemClicked } from "../../../../../store/telemetry/actions";

export default function OptionsStep({
  moveToStep,
  activeStepIndex,
  openPatientChartNoteModal,
  moveToFirstStep
}) {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const tabKeys = ["key_data", "option_grid"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e, index) => {
    setActiveTab(index);
    dispatch(
      navigationItemClicked({
        affordance: "patient_options",
        navigate_to: tabKeys[index]
      })
    );
  };

  const { toolName, toolType } = useSelector(({ tool }) => tool.metadata);
  const location = useSelector(state => get(state, "app.location", ""));

  return toolType !== 1 ? (
    <Fragment>
      <ToolHeader
        activeStepIndex={activeStepIndex}
        moveToStep={moveToStep}
        title="Patient Options"
        subTitle={
          <ToolHeaderButton onClick={moveToFirstStep}>
            {toolName}
          </ToolHeaderButton>
        }
        isChartNoteVisible={!isMHDDomain(location)}
        openPatientChartNoteModal={openPatientChartNoteModal}
        isStepsVisible={true}
      />
      <TabContext activeTab={activeTab}>
        <TabList
          onChange={handleChange}
          aria-label="patient options view"
          data-auto="tool-option-data-tabs"
        >
          <Tab label="KEY DATA" />
          <Tab label="OPTION GRID" />
        </TabList>
        <Divider noMargin={true} />
        <TabPanels>
          <TabPanel>
            <PopulationViewTab />
          </TabPanel>
          <OptionGridTabPanel>
            <OptionGridTab showChartNote={false} showFavButton={false} />
          </OptionGridTabPanel>
        </TabPanels>
      </TabContext>
    </Fragment>
  ) : (
    <OptionGridTab />
  );
}

OptionsStep.propTyes = {
  moveStepBack: PropTypes.func.isRequired,
  moveStepForward: PropTypes.func.isRequired,
  moveToStep: PropTypes.func.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  openPatientChartNoteModal: PropTypes.func.isRequired
};
