import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";
import About from "./components/about";

export const PageContainer = styled(Flex)`
  min-height: 100%;

  .Footer_container {
    margin-top: auto;
  }
`;
export const ContentContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;
export const MainContentRail = styled.div`
  flex-direction: column;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 65%;
    margin-right: ${({ theme }) => theme.space.md};
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: ${({ theme }) => theme.space.xl};
  }
`;
export const RightRail = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 35%;
  }
`;

export const StyledAbout = styled(About)`
  margin-top: ${({ theme }) => theme.space.lg};
  margin-bottom: ${({ theme }) => theme.space.lg};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.space.lg};
  }
`;
