import styled, { css } from "styled-components";
import {
  Button,
  Field,
  InputCheckbox,
  Type,
  ValidationMessaging
} from "discover.medical.react-component-library";
import NumberFormat from "react-number-format";

const borderWidth = "1px";

export const StyledField = styled(Field)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const StyledButton = styled(Button)`
  display: block;
`;

export const StyledNumberFormat = styled(NumberFormat)`
  appearance: none;
  position: relative;
  max-width: 7.25rem;
  height: 2rem;
  font-family: inherit;
  padding: 0 0.75rem;
  border: ${borderWidth} solid ${({ theme }) => theme.colors.gray600};
  font-size: ${({ theme }) => theme.font.size.sm};
  border-radius: ${({ theme }) => theme.border.radius.sm};
  color: ${({ theme }) => theme.colors.gray800};
  background: ${({ theme }) => theme.colors.white};
  &:hover {
    border: ${borderWidth} solid ${({ theme }) => theme.colors.gray800};
  }
  &:focus {
    border: ${borderWidth} solid ${({ theme }) => theme.colors.accent.base} !important;
    outline: none;
  }
  ${props =>
    props.hasError &&
    css`
      /* stylelint-disable declaration-no-important */
      color: ${props.theme.colors.red};
      border: ${borderWidth} solid ${props.theme.colors.error} !important;
      /* stylelint-enable */
    `}
`;

export const StyledInputCheckbox = styled(InputCheckbox)`
  padding: 1rem 0;
`;

export const StyledSectionTitle = styled(Type)`
  margin-bottom: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.space.md};
  }
`;

export const StyledType = styled(Type)`
  font-style: italic;
`;

export const StyledValidationMessaging = styled(ValidationMessaging)`
  padding: 0 0 1rem 0;
`;
