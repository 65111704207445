import { USER_PREFERENCE, USER_PREFERENCE_ERROR } from "../../action-types";
import axiosInstance from "../../../services/axios-instance";

export const userPreference = payload => ({
  type: USER_PREFERENCE,
  payload
});
export const userPreferenceError = payload => ({
  type: USER_PREFERENCE_ERROR,
  payload
});
export const getUserPreference = () => async dispatch => {
  const userPreferenceUrl = "/api-tool-experience/users/preference";
  try {
    const { data } = await axiosInstance.get(userPreferenceUrl, {
      headers: { Pragma: "no-cache" } // disable cache so ie11 will get latest content
    });
    dispatch(userPreference({ data }));
  } catch (e) {
    dispatch(userPreferenceError({ message: e.message }));
  }
};

export const createOrUpdateUserPreference = body => async dispatch => {
  const userPreferenceUrl = "/api-tool-experience/users/preference";
  try {
    const { data } = await axiosInstance.post(userPreferenceUrl, body, {
      headers: { Pragma: "no-cache" } // disable cache so ie11 will get latest content
    });
    dispatch(userPreference({ data }));
  } catch (e) {
    dispatch(userPreferenceError({ message: e.message }));
  }
};
