import React from "react";
import "./_index.css";
import PropTypes from "prop-types";
import { Type } from "discover.medical.react-component-library";
import { StyledContainer } from "./styled";
import { isMHDDomain } from "../../helpers/domain-manager";
import { useSelector } from "react-redux";

export const Banner = ({ dataAuto }) => {
  const { location } = useSelector(state => state.app);
  const isMHD = isMHDDomain(location);
  return (
    <section
      className={`Banner ${isMHD ? "Banner__mhd" : ""}`}
      data-auto={dataAuto}
    >
      <StyledContainer mhd={isMHD}>
        <Type className="Banner_description" element="h1">
          Informed decisions.
          <br />
          Better outcomes.
        </Type>
      </StyledContainer>
    </section>
  );
};

Banner.propTypes = {
  dataAuto: PropTypes.string.isRequired
};
