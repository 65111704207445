import React from "react";

import "./_index.css";
import componentStateMapper from "../componentsStateMapper";
import InfoButton from "../shared/infobutton";
import InfoLink from "../shared/infolink";
import BasicText from "../basic-text";

export class Text extends BasicText {
  renderCompositeComponent = () => {
    const backgroundProps = this.getBackgroundProps();
    if (backgroundProps) {
      return (
        <div>
          <span className="Text_baseContent">{this.getBaseContent()}</span>
          <InfoLink
            text={this.getBackgroundText()}
            anchorText={backgroundProps}
          />
        </div>
      );
    }
    return (
      <div className="Text_block">
        <span className="Text_baseContent">{this.getBaseContent()}</span>
        <InfoButton text={this.getBackgroundText()} />
      </div>
    );
  };
}

export default componentStateMapper(Text);
