import axiosInstance from "../../../services/axios-instance";

import {
  HOME_DATA_REQUEST,
  HOME_DATA_REQUEST_ERROR,
  HOME_DATA_REQUEST_SUCCESS,
  HOME_DATA_NOTIFY_STALE
} from "../../action-types";

const homeDataUrl = "/api-tool-experience/experiences/home";

export const getHomeData = () => async dispatch => {
  dispatch({
    type: HOME_DATA_REQUEST
  });
  try {
    const { data } = await axiosInstance.get(homeDataUrl, {
      headers: { Pragma: "no-cache" } // disable cache so ie11 will get latest content
    });
    dispatch({
      type: HOME_DATA_REQUEST_SUCCESS,
      payload: data
    });
  } catch {
    dispatch({
      type: HOME_DATA_REQUEST_ERROR
    });
  }
};

export const notifyStateStale = () => dispatch => {
  dispatch({
    type: HOME_DATA_NOTIFY_STALE
  });
};
