import styled from "styled-components";
import { ButtonLink } from "../styled-components/index.styled";
import { Type } from "discover.medical.react-component-library";

export const LearnButton = styled(ButtonLink)`
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.md}`};
  margin: 0.75rem 0 0.875rem;
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: ${({ theme }) => theme.space.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: ${({ theme }) => `0.75rem ${theme.space.md}`};
    margin: ${({ theme }) => `${theme.space.md} 0 ${theme.space.sm}`};
    font-size: ${({ theme }) => theme.font.size.md};
  }
`;

export const SubscriptionInfoContent = styled.div`
  text-align: center;
`;

export const SubscriptionInfoFooter = styled(Type)`
  margin: 0;
`;
