import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PageTemplate from "../../templates/page";
import {
  getToolDataRequest,
  cleanUpTool
} from "../../../store/shared-actions/tool-data";
import SubscriptionInfo from "../../tools-browse-shared-components/subscription-info";
import DynamicTemplate from "../../templates";
import { redirectTo } from "../../../helpers/url-manager";
import NotAvailable from "../not-available";

export default function Tool({ match, location, staticContext }) {
  const dispatch = useDispatch();
  const toolVersion = match.params.toolVersion;
  const isLoading = useSelector(
    state =>
      state.tool.loading ||
      state.fields.loading ||
      state.fields.loadingOptionGrid
  );
  const shouldRedirect = useSelector(
    ({ snapshot }) => !snapshot.snapshotData.isSnapshotAvailable
  );
  const toolTabs = useSelector(({ tool }) => tool.controls.toolTabs);
  const queryParams = new URLSearchParams(location.search || "");
  const snapshotId = queryParams.get("snapshotId");
  const { pubType, toolSlug } = useParams();
  const [isForbidden, setIsForbidden] = useState(false);
  const history = useHistory();
  const errorHandler = statusCode => {
    switch (statusCode) {
      case 401:
      case 403:
        setIsForbidden(true);
        break;
      default:
        redirectTo("unavailable", history);
    }
  };
  const redirectHandler = (path, query = null) => {
    redirectTo(path, history, query);
  };
  useEffect(() => {
    dispatch(
      getToolDataRequest(
        {
          pubType,
          toolSlug,
          snapshotId,
          version: toolVersion,
          history
        },
        errorHandler,
        redirectHandler
      )
    );
    return () => dispatch(cleanUpTool());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    match,
    location,
    dispatch,
    snapshotId,
    toolVersion,
    pubType,
    toolSlug,
    history
  ]);

  return (
    <PageTemplate
      dataAuto="tool-page"
      shouldRedirect={shouldRedirect}
      staticContext={staticContext}
      isBottomPadding={false}
    >
      {isForbidden ? (
        <NotAvailable>
          <SubscriptionInfo />
        </NotAvailable>
      ) : (
        <DynamicTemplate
          templateName={queryParams.get("template")}
          toolVersion={toolVersion}
          isLoading={isLoading}
          toolTabs={toolTabs}
        />
      )}
    </PageTemplate>
  );
}

Tool.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.object
};

Tool.defaultProps = {
  staticContext: null
};
