import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./_index.css";
import PropTypes from "prop-types";

export const BackgroundText = ({ bgText }) => {
  return <div className={"BackgroundText"}>{ReactHtmlParser(bgText)}</div>;
};

BackgroundText.propTypes = {
  bgText: PropTypes.string.isRequired
};

export default BackgroundText;
