import styled from "styled-components";
import NumericControl from "../shared/numeric-control";

export const Input = styled(NumericControl)`
  width: 6rem;
  & input {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }
`;

export const Label = styled.label`
  margin-left: ${({ theme }) => theme.space.sm};
  font-size: ${({ theme }) => theme.font.size.sm};
  line-height: 1.25rem;
  align-self: center;
`;
