import _, { get } from "lodash";
import { useSelector } from "react-redux";
import { RESOURCE_TYPE_OBSERVATION } from "../../../../../../../calculators/constants";

export default function useEhrpdnsExist() {
  const ehr = useSelector(({ fields }) => fields.ehr);

  return !_.isEmpty(ehr);
}

/**
 * This will change while integrating with api call
 * @returns
 */
export function useMissingEhrpdnsAndMetadata() {
  const missing_ehr = useSelector(({ fields }) => fields.missingEhr);
  const metadata = useSelector(({ fields }) => fields.ehrMetadata);

  const tool = useSelector(state => state.tool);
  const ehrpdns = get(tool, ["controls", "ehrpdns"], {});

  const uiFields = useSelector(({ fields }) => fields.ui);
  const controlsBasedOnMissingEhrpdns = {};
  const addToControlsBasedOnMissingEhrpdns = (controlName, ehrData) => {
    if (!controlsBasedOnMissingEhrpdns.hasOwnProperty(controlName)) {
      controlsBasedOnMissingEhrpdns[controlName] = {
        ehrpdns: []
      };
    }
    controlsBasedOnMissingEhrpdns[controlName].ehrpdns.push(ehrData);
  };
  const formatDisplayName = string => {
    return string ? string.replace(/_unit_.*/, "").replace(/_/g, " ") : "";
  };

  const getControlsAssociatedWithPdn = pdn => {
    return ehrpdns[pdn] ? ehrpdns[pdn].controls : [];
  };

  const getControlDisplayName = control => {
    // A control should never be missing baseContent, but if it does
    // we'll use the control name, formatted for display
    return get(
      tool,
      ["controls", "_all", control, "attributes", "fieldInfo", "baseContent"],
      formatDisplayName(control)
    );
  };

  if (missing_ehr && metadata) {
    missing_ehr.forEach(pdn => {
      const resourceType = get(metadata, [pdn, "resourceType"], "");
      const timeWindow = get(metadata, [pdn, "timeWindow"], "");
      if (
        resourceType === RESOURCE_TYPE_OBSERVATION &&
        !_.isEmpty(timeWindow)
      ) {
        const ehrpdnDisplayName = formatDisplayName(pdn);
        const ehrData = {
          pdn,
          ehrpdnDisplayName,
          timeWindow
        };

        const controls = getControlsAssociatedWithPdn(pdn);
        if (controls === undefined || controls.length < 1) {
          // At least one control should be associated with every PDN in the ehrpdns list,
          // but if the ehrpdns list itself is not found, or it does not contain a list of
          // controls associated with the given PDN, we'll use the PDN itself,
          // formatted for display
          addToControlsBasedOnMissingEhrpdns(ehrpdnDisplayName, ehrData);
        } else {
          controls.forEach(control => {
            const controlDisplayName = getControlDisplayName(control);
            const value = get(uiFields, [control, "value"], "");
            // If the control has a default value, the PDNs returned were sufficient
            // to preload the control
            if (value === "") {
              addToControlsBasedOnMissingEhrpdns(controlDisplayName, ehrData);
            }
          });
        }
      }
    });
  }

  return controlsBasedOnMissingEhrpdns;
}
