import React from "react";
import get from "lodash/get";

import "./_index.css";
import BasicNumeric from "../basic-numeric";
import { componentStateMapper } from "../componentsStateMapper";
import NumericControl from "../shared/numeric-control";

export class NumericInput extends BasicNumeric {
  handleNumericChange = event => {
    const { value } = event.target;
    this.changeValue(value === "" ? "" : Number(value), event);
  };
  renderComponent = () => {
    const { name, isRequired, value, view, autocomplete } = this.props;
    const step = Number(
      get(view, ["attributes", "fieldInfo", "constraints", "increment"], 1)
    );
    return (
      <NumericControl
        className="NumericInput"
        id={name}
        name={name}
        value={`${value}`}
        step={step}
        onChange={this.handleNumericChange}
        hasError={this.hasErrors()}
        hasWarning={this.hasWarnings()}
        required={isRequired}
        data-auto={`${name}-numeric-input-component`}
        autocomplete={autocomplete}
      />
    );
  };
}

export default componentStateMapper(NumericInput);
