import React from "react";
import PropTypes from "prop-types";
import { Type } from "discover.medical.react-component-library";
import { InfoModalContentItemRoot, InfoModalContentText } from "./index.styled";

const InfoModalContentItem = ({ title, description, isSDM }) => (
  <InfoModalContentItemRoot data-auto="info-modal-content" $isSDM={isSDM}>
    <Type element="strong">{title}: </Type>
    <Type element="p" display="inline">
      <InfoModalContentText dangerouslySetInnerHTML={{ __html: description }} />
    </Type>
  </InfoModalContentItemRoot>
);

InfoModalContentItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isSDM: PropTypes.bool.isRequired
};

InfoModalContentItem.displayName = "InfoModalContentItem";

export default InfoModalContentItem;
