import {
  FIELDS_DATA_REQUEST,
  FIELDS_DATA_REQUEST_SUCCESS,
  FIELDS_DATA_REQUEST_ERROR,
  CHANGE_FIELD_UI_VALUE,
  RESET_FIELDS_DATA,
  FIELDS_DATA_OUTCOME_SUCCESS,
  OPTION_GRID_DATA_REQUEST,
  OPTION_GRID_DATA_REQUEST_ERROR,
  OPTION_GRID_DATA_OUTCOME_SUCCESS,
  FIELD_VALUE_IS_VALID,
  FIELD_VALUE_IS_INVALID,
  FIELD_IS_HIDDEN,
  FIELD_IS_VISIBLE,
  FIELDS_DATA_EHR_REQUEST,
  FIELDS_DATA_EHR_REQUEST_SUCCESS,
  FIELDS_DATA_EHR_REQUEST_ERROR,
  FIELD_SET_IS_MOUNTED,
  SET_MEASURE_VALUE,
  SET_ERRORS_NUMBER,
  CHANGE_USER_PREFERRED_MEASURE,
  TOGGLE_LAB_DATE_INFO
} from "../action-types";

export const initialState = {
  loading: false,
  loadingOptionGrid: false,
  ui: {},
  outcome: {},
  optionGridOutcome: {},
  ehr: {},
  ehrMetadata: {},
  missingEhr: [],
  validationErrorsNumber: 0
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case CHANGE_FIELD_UI_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.name]: {
            ...state.ui[payload.name],
            value: payload.value,
            label: payload.label
          }
        }
      };
    case SET_MEASURE_VALUE:
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.name]: {
            ...state.ui[payload.name],
            measure: { ...payload.measureInfo },
          }
        }
      };
    case TOGGLE_LAB_DATE_INFO:
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.name]: {
            ...state.ui[payload.name],
            labDateInfo: {
              ...state.ui[payload.name].labDateInfo,
              isHidden: payload.isHidden
            }
          }
        }
      };
      // ui.creatinie.measure.value
    case FIELDS_DATA_REQUEST:
    case FIELDS_DATA_EHR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FIELDS_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        ui: {
          ...state.ui,
          ...payload.ui
        }
      };
    case FIELDS_DATA_REQUEST_ERROR:
    case FIELDS_DATA_EHR_REQUEST_ERROR:
      return {
        ...state,
        loading: false
      };
    case RESET_FIELDS_DATA:
      return initialState;
    case FIELDS_DATA_OUTCOME_SUCCESS:
      return {
        ...state,
        outcome: payload,
        loading: false
      };
    case OPTION_GRID_DATA_REQUEST:
      return {
        ...state,
        loadingOptionGrid: true
      };
    case OPTION_GRID_DATA_REQUEST_ERROR:
      return {
        ...state,
        loadingOptionGrid: false
      };
    case OPTION_GRID_DATA_OUTCOME_SUCCESS:
      return {
        ...state,
        optionGridOutcome: payload,
        loadingOptionGrid: false
      };
    case FIELD_VALUE_IS_VALID:
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.name]: {
            ...state.ui[payload.name],
            validation: []
          }
        }
      };
    case FIELD_VALUE_IS_INVALID:
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.name]: {
            ...state.ui[payload.name],
            validation: payload.errors
          }
        }
      };
    case FIELD_IS_HIDDEN:
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.name]: {
            ...state.ui[payload.name],
            isHidden: true
          }
        }
      };
    case FIELD_IS_VISIBLE:
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.name]: {
            ...state.ui[payload.name],
            isHidden: false
          }
        }
      };
    case FIELDS_DATA_EHR_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        ehr: { ...payload.ehr },
        ehrMetadata: { ...payload.ehrMetadata },
        missingEhr: [...payload.missingEhr],
      };
    case FIELD_SET_IS_MOUNTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          [payload.name]: {
            ...state.ui[payload.name],
            isMounted: payload.isMounted
          }
        }
      }
    case SET_ERRORS_NUMBER:
      return {
        ...state,
        validationErrorsNumber: payload
      }
    case CHANGE_USER_PREFERRED_MEASURE:
        return {
          ...state,
          ui: {
            ...state.ui,
            [payload.name]: {
              ...state.ui[payload.name],
              userPreferredMeasure: payload.userPreferredMeasure
            }
          }
        }
    default:
      return state;
  }
};
