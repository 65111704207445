import styled from "styled-components";
import NumericControl from "../shared/numeric-control";

export const Input = styled(NumericControl)`
  width: 6rem;
  & input {
    border-top-right-radius: ${({ isMeasureSingle }) =>
      isMeasureSingle ? "3px !important" : 0};
    border-bottom-right-radius: ${({ isMeasureSingle }) =>
      isMeasureSingle ? "3px !important" : 0};
  }
`;
