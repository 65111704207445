const uiVariablePatterns = /ui\.(.*)\.(value|label)/;

export default function getUiVariablesNames(expressionVariables) {
  if (!expressionVariables || !Array.isArray(expressionVariables)) {
    return [];
  }

  return expressionVariables
    .filter(variable => uiVariablePatterns.test(variable))
    .map(variable => uiVariablePatterns.exec(variable)[1]);
}
