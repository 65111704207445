import React from "react";
import ddLogo from "../../shared/images/dd-logo.svg";
import mHDLogo from "../../shared/images/mhd-logo.svg";
import { StyledLogo } from "./styled";
import DomainSpecific from "../domain-specific";

export const Logo = ({ className }) => {
  return (
    <>
      <DomainSpecific MHD>
        <StyledLogo
          className={className}
          brand="MHD"
          src={mHDLogo}
          alt="My Health Decisions by DynaMed"
        />
      </DomainSpecific>
      <DomainSpecific DSD>
        <StyledLogo
          className={className}
          src={ddLogo}
          alt={"DynaMed Decisions"}
        />
      </DomainSpecific>
    </>
  );
};

export default Logo;
