import { useEffect } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import { changeScreenSize } from "../../store/shared-data/actions";
import {
  VIEWPORT_SMALL,
  VIEWPORT_LARGE,
  VIEWPORT_MEDIUM
} from "../../helpers/aliases";

export const ScreenSizeController = ({ onChangeScreenSize, windowSize }) => {
  const sizer = debounce(() => {
    // these values should match the values added in the css file src/universal/components/_index.css
    const small = 640;
    const medium = 1040;

    if (window.innerWidth < small && windowSize !== VIEWPORT_SMALL) {
      onChangeScreenSize(VIEWPORT_SMALL);
    } else if (
      window.innerWidth < medium &&
      window.innerWidth >= small &&
      windowSize !== VIEWPORT_MEDIUM
    ) {
      onChangeScreenSize(VIEWPORT_MEDIUM);
    } else {
      // adding else to avoid sonar complaints
      if (window.innerWidth >= medium && windowSize !== VIEWPORT_LARGE) {
        onChangeScreenSize(VIEWPORT_LARGE);
      }
    }
  }, 300);

  useEffect(() => {
    sizer();
    window.addEventListener("resize", sizer);
    return () => {
      window.removeEventListener("resize", sizer);
    };
  });
  return null;
};

ScreenSizeController.propTypes = {
  windowSize: PropTypes.string
};

const mapStateToProps = state => ({
  windowSize: state.sharedData.windowSize
});

const mapDispatchToProps = {
  onChangeScreenSize: changeScreenSize
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenSizeController);
