import React from "react";
import SpinnerWithText from "../../../../../spinner-with-text/SpinnerWithText";
import OptionGridSnapshotError from "../option-grid-snapshot-error/OptionGridSnapshotError";
import { useOptionGridContext } from "../OptionGridContext";
import { Modal } from "discover.medical.react-component-library";
import { ButtonElement, Text, Title, ButtonsBlock } from "./index.styled";

export const OptionGridWarningModal = () => {
  const {
    isWarningModalOpen,
    closeWarningModal,
    isSpinnerShown,
    setIsSpinnerShown,
    snapshotProcessingInfo
  } = useOptionGridContext();
  const { isError } = snapshotProcessingInfo;

  return (
    <Modal
      v2
      data-auto="warning-modal"
      onRequestClose={closeWarningModal}
      isOpen={isWarningModalOpen}
      closeable={!isSpinnerShown}
    >
      {isSpinnerShown || isError || (
        <>
          <Title element="h2" color="primary">
            Please Note
          </Title>
          <Text data-auto="warning-modal-text">
            <b>You have one or more options hidden</b> and these will not be
            included in the PDF. Okay to proceed?
          </Text>
          <ButtonsBlock>
            <ButtonElement
              data-auto="warning-modal-agree-button"
              onClick={() => setIsSpinnerShown(true)}
              size="md"
            >
              Yes, Generate PDF
            </ButtonElement>
            <ButtonElement
              data-auto="warning-modal-cancel-button"
              onClick={closeWarningModal}
              size="md"
              kind="text"
            >
              Cancel
            </ButtonElement>
          </ButtonsBlock>
        </>
      )}
      {isSpinnerShown && !isError && (
        <SpinnerWithText>
          <b>Your PDF is being generated.</b> This may take a moment...
        </SpinnerWithText>
      )}
      {isError && (
        <OptionGridSnapshotError
          onClick={closeWarningModal}
          message="We're sorry! We were unable to generate a PDF. Please try again later."
        />
      )}
    </Modal>
  );
};

export default OptionGridWarningModal;
