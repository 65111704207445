import axios from "axios";
import React from "react";
import { AmplitudeContext } from "../../../universal/helpers/amplitude/context";

const getAmplitudeExperimentVariants = async (
  hostname,
  sessionNumber,
  customer_id
) => {
  try {
    const baseUrl =
      hostname === "localhost"
        ? "http://localhost:3030"
        : `https://${hostname}`;
    const { data } = await axios.get(`${baseUrl}/experimentVariants`, {
      headers: {
        "cache-control": "no-cache",
        "x-should-proxy-api": true
      },
      params: {
        customer_id,
        "session-number": sessionNumber,
        location: hostname
      }
    });
    return data;
  } catch (err) {
    console.error("Unable to retrieve amplitude experiments");
    console.error(err);
    return {};
  }
};

export default async function configureAmplitude(
  hostname,
  sessionNumber,
  customerId
) {
  const variants = await getAmplitudeExperimentVariants(
    hostname,
    sessionNumber,
    customerId
  );

  return {
    useFeature: (feature, withPayload) => {
      if (withPayload) {
        return variants[feature];
      }

      return !!variants[feature];
    }
  };
}

export const useFeature = feature => {
  const amplitudeUseFeature = React.useContext(AmplitudeContext);
  if (!amplitudeUseFeature) {
    console.warn("Amplitude Experiments provider is not set up");
  }
  return [amplitudeUseFeature && amplitudeUseFeature(feature)];
};

export const useFeaturePayload = feature => {
  const amplitudeUseFeature = React.useContext(AmplitudeContext);
  if (!amplitudeUseFeature) {
    console.warn("Amplitude Experiments provider is not set up");
    return null;
  }
  return amplitudeUseFeature(feature, true);
};

export const FeatureComponent = ({ feature, children }) => {
  const [isEnabled] = useFeature(feature);
  return React.createElement(React.Fragment, null, children(isEnabled));
};
