import axiosInstance from "../../../services/axios-instance";
import get from "lodash/get";
import {
  PATIENT_ENCOUNTER_REPORT_REQUEST,
  PATIENT_ENCOUNTER_REPORT_REQUEST_SUCCESS,
  PATIENT_ENCOUNTER_REPORT_REQUEST_ERROR,
  PATIENT_ENCOUNTER_REPORT_STATUS_REQUEST,
  PATIENT_ENCOUNTER_REPORT_STATUS_ERROR,
  PATIENT_ENCOUNTER_REPORT_STATUS_TIMEOUT,
  PATIENT_ENCOUNTER_REPORT_STATUS_SUCCESS,
  PATIENT_ENCOUNTER_REPORT_DOWNLOAD_REQUEST,
  PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SUCCESS,
  PATIENT_ENCOUNTER_REPORT_DOWNLOAD_ERROR,
  PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SKIP,
  PATIENT_ENCOUNTER_REPORT_RESET
} from "../../action-types";

import Epic from ".././../../../client/epic";

export const resetPatientEncounterReport = () => ({
  type: PATIENT_ENCOUNTER_REPORT_RESET
});

export const initiatePatientEncounterReportRequest = () => ({
  type: PATIENT_ENCOUNTER_REPORT_REQUEST
});
export const initiatePatientEncounterReportRequestError = payload => ({
  type: PATIENT_ENCOUNTER_REPORT_REQUEST_ERROR,
  payload
});
export const initiatePatientEncounterReportRequestSuccess = payload => ({
  type: PATIENT_ENCOUNTER_REPORT_REQUEST_SUCCESS,
  payload
});

export const getPatientEncounterReportStatus = () => ({
  type: PATIENT_ENCOUNTER_REPORT_STATUS_REQUEST
});
export const getPatientEncounterReportStatusError = payload => ({
  type: PATIENT_ENCOUNTER_REPORT_STATUS_ERROR,
  payload
});
export const getPatientEncounterReportStatusTimeout = () => ({
  type: PATIENT_ENCOUNTER_REPORT_STATUS_TIMEOUT
});
export const getPatientEncounterReportStatusSuccess = payload => ({
  type: PATIENT_ENCOUNTER_REPORT_STATUS_SUCCESS,
  payload
});

export const downloadPatientEncounterReport = () => ({
  type: PATIENT_ENCOUNTER_REPORT_DOWNLOAD_REQUEST
});
export const downloadPatientEncounterReportSuccess = payload => ({
  type: PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SUCCESS,
  payload
});
export const downloadPatientEncounterReportError = payload => ({
  type: PATIENT_ENCOUNTER_REPORT_DOWNLOAD_ERROR,
  payload
});
export const skipPatientEncounterReportDownload = () => ({
  type: PATIENT_ENCOUNTER_REPORT_DOWNLOAD_SKIP
});

const createErrorPayload = error => {
  return {
    code:
      get(error, "response.status", 0) || get(error, "response.data.code", 0)
  };
};

const formatDateForCsv = isoDate => {
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC"
  ];
  const [date] = isoDate.split("T");
  const [year, month, day] = date.split("-");
  const monthName = monthNames[Number(month) - 1];
  return `${monthName}.${day}.${year}`;
};
const formatCsvFilename = (startDate, endDate) => {
  const start = formatDateForCsv(startDate);
  const end = formatDateForCsv(endDate);
  return `DynaMed-Decisions-Patient-Encounter-Report-${start}-${end}.csv`;
};

export const requestPatientEncounterReportDownload = (
  id,
  startDate,
  endDate
) => async (dispatch, getState) => {
  const sessionNumber = get(getState(), ["sharedData", "sessionNumber"]);
  const location = get(getState(), ["app", "location"]);
  const hostname = location.hostname;

  const patientEncounterReportDownloadUrl = `https://${hostname}/api-tool-experience/report/download?id=${id}&session-number=${sessionNumber}`;

  const userAgent = window.navigator.userAgent;

  dispatch(downloadPatientEncounterReport());

  try {
    const epic = new Epic();
    if (userAgent && userAgent.indexOf("Hyperdrive") !== -1) {
      //inside hyperdrive
      window.open(patientEncounterReportDownloadUrl);
    } else if (epic.checkIsEpic()) {
      //inside hyperspace
      if (window.navigator.msSaveOrOpenBlob) {
        // for IE and Edge
        const { data } = await axiosInstance.get(
          `/api-tool-experience/report/download?id=${id}&session-number=${sessionNumber}`
        );
        window.navigator.msSaveBlob(
          new Blob([data]),
          formatCsvFilename(startDate, endDate)
        );
      } else if (console.log) {
        console.log(
          "Unable to download: window.navigator.msSaveOrOpenBlog not available"
        );
      }
    } else {
      //in all other browsers outside Epic
      window.location.assign(patientEncounterReportDownloadUrl);
    }

    dispatch(downloadPatientEncounterReportSuccess());
  } catch (error) {
    const payload = createErrorPayload(error);
    dispatch(downloadPatientEncounterReportError(payload));
  }
};

export const requestPatientEncounterReportStatus = () => async (
  dispatch,
  getState
) => {
  let currentId = get(getState(), ["patientEncounterReport", "id"]);
  if (currentId) {
    const patientEncounterReportStatusUrl = `/api-tool-experience/report/status?id=${currentId}`;
    dispatch(getPatientEncounterReportStatus());
    try {
      const { data } = await axiosInstance.get(patientEncounterReportStatusUrl);
      currentId = get(getState(), ["patientEncounterReport", "id"]);
      if (currentId) {
        dispatch(getPatientEncounterReportStatusSuccess(data));
      }
    } catch (error) {
      currentId = get(getState(), ["patientEncounterReport", "id"]);
      if (currentId) {
        const payload = createErrorPayload(error);
        dispatch(getPatientEncounterReportStatusError(payload));
      }
    }
  }
};

export const requestPatientEncounterReport = (
  startDate,
  endDate
) => async dispatch => {
  const patientEncounterReportRequestUrl = `/api-tool-experience/report/request?startDate=${startDate}&endDate=${endDate}`;
  dispatch(initiatePatientEncounterReportRequest());
  try {
    const { data } = await axiosInstance.post(patientEncounterReportRequestUrl);
    dispatch(initiatePatientEncounterReportRequestSuccess(data));
  } catch (error) {
    const payload = createErrorPayload(error);
    dispatch(initiatePatientEncounterReportRequestError(payload));
  }
};
