import cloneDeep from "lodash/cloneDeep";
import parser from "../../parser";

/*
 * If a condition in the visibility expression depends on a HIDDEN ui field, then the field should be
 * treated as though it is empty.  The value of the field should not be used.  In an attempt to achieve this
 * behavior, this method was created to substitute hidden ui fields with a NULL value in the visibility expression.
 * For example, the expression "ui.test.value == 'yes'" would be transformed into "NULL == 'yes'" if
 * "ui.test" field is hidden.  In practice what happens is that the value is actually turned to 0, which
 * is not ideal, especially since it results in hidden fields behaving differently than other empty fields.
 * However, this can be worked around in the expressions.  A comprehensive solution to the various issues with
 * way the UI evaluates expressions has been planned but involves content work as well as development work and
 * has not been high enough priority to implement.
 */

/**
 * The parsed expressions cache.
 */
const parsed = {};

/**
 * Evaluates a visibility expression and returns the result.
 *
 * @param {string} expression The visibility expression.
 * @param {object} fields     The state of the fields in the UI.
 *
 * @return {boolean} Returns `true` if visible, or `false` if not.
 */
const evaluate = (expression, fields) => {
  try {
    if (!parsed[expression]) {
      parsed[expression] = parser.parse(expression);
    }

    const ui = cloneDeep(fields.ui);

    parsed[expression]
      // Get all of the referenced variables in the expression.
      .variables({ withMembers: true })

      // Only process the ones that use the `ui` slice of the state.
      .filter(path => path.indexOf("ui.") === 0)

      // Split the variable paths into iterable arrays.
      .map(path => path.split("."))

      // Extract the field name.
      .map(path => path[1])

      // If the field is hidden, swap values with `null`.
      .forEach(name => {
        if (ui[name].isHidden) {
          ui[name].label = null;
          ui[name].value = null;
        }
      });

    return (
      parsed[expression]
        // It is unclear why simplify needs to be called since tests show it will always simplify
        // the expression into the actual boolean result (e.g. "true" or "false"). Since the
        // expression is completely evaluated, you'd think we'd be able to just call evaluate
        // directly.
        .simplify({ ui })

        // Since the expression is already evaluated, we're simply evaluate a "true" or "false"
        // keyword into the JavaScript equivalent. There has to be some way we can save a few CPU
        // cycles by skipping the partial evaluation done by "simplify".
        .evaluate({ outcome: fields.outcome, ui })
    );
  } catch (e) {
    throw new Error(`Incorrect visibility rule "${expression}"`);
  }
};

export default evaluate;
