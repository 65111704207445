import React from "react";
import { hydrate } from "react-dom";
import "./index.css";
import Providers from "./components/providers";
import App from "../universal/components/app";
import createStore from "../universal/store/create-store";
import loadReactIntlData from "../universal/locales/load-react-intl-data";
import configureAmplitude from "../universal/helpers/amplitude";
import { log } from "../universal/middleware/telemetry/analytics";
import { clearSSREvents } from "../universal/store/telemetry/actions";
import { getUser } from "../universal/store/user/actions";
import { getUserPreference } from "../universal/store/user-preference/actions";
import { isMHDDomain } from "../universal/helpers/domain-manager";
import Epic from "./epic";
import { datadogRum } from "@datadog/browser-rum";
import { instantiateAmplitude } from "../universal/shared/util/amplitudeHelper";
import { isOsanoAnalyticsAllowed } from "../universal/shared/hooks/useOsano";

export const initDatadogRum = () => {
  const baseUrl = new URL(`https://${window.location.hostname}`);

  const isClientLive = [
    "dd.live.eismedi.com",
    "myhealthdecisions.com",
    "decisions.dynamed.com"
  ].includes(window.location.hostname);

  let env = isClientLive ? "live" : "dev";

  const proxyUrl = new URL(`${baseUrl}client-rum`);

  datadogRum.init({
    applicationId: "ef51bb7d-282a-46bf-ad1c-d5a99c3511a0",
    clientToken: "pub11bf073aea63526d4957c5dbc8bd1b5e",
    site: "datadoghq.com",
    service: "dynamed-decisions",
    env,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    sampleRate: 100,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    proxyUrl: proxyUrl,
    allowedTracingOrigins: [baseUrl.origin],
    traceSampleRate: 100,
    useCrossSiteSessionCookie: true,
    defaultPrivacyLevel: "mask-user-input"
  });
};
const getInterfaceId = location => (isMHDDomain(location) ? "dsd" : "dcd");

const renderClient = () => {
  const { locale } = window.__INITIAL_STATE__.intl;
  const setupLocaleData =
    locale === "en" ? Promise.resolve() : loadReactIntlData(locale);

  // f-twelve requires some time to get init
  const promise = new Promise(resolve => setTimeout(resolve, 100));
  const isClientLive = [
    "hippocrates-ui.chewie-live.eks.ehost-live.eislz.com",
    "dd.live.eismedi.com",
    "myhealthdecisions.com",
    "decisions.dynamed.com"
  ].includes(window.location.hostname);
  const snapshotId = new URLSearchParams(window.location.search).get(
    "snapshotId"
  );
  return Promise.all([setupLocaleData, promise]).then(async () => {
    const epic = new Epic();
    epic.initialHandshakeWithEpic();
    const store = createStore(window.__INITIAL_STATE__);
    const { telemetry = { ssrQueue: [] } } = store.getState();

    await store.dispatch(getUser());
    const { user = {}, sharedData = {} } = store.getState();
    if (user.puaId) {
      await store.dispatch(getUserPreference());
    }

    const amplitude = await configureAmplitude(
      window.location?.hostname,
      sharedData.sessionNumber,
      user.affiliationId
    );

    //If url has snapshotId, then do not initialize the amplitude.
    //This is done to avoid event logging to the amplitude when link for patient is accessed
    if (!snapshotId) {
      const { location = {}, isOsanoEnabled } = store.getState().app;

      //Initialize amplitude only when user is authorized
      const analyticsApi = `${
        isClientLive ? "" : "staging-"
      }telemetry.ebsco.com/public/2/form-data`;

      telemetry.ssrQueue.forEach(logObject => {
        log(logObject.type, logObject.data);
      });

      const amplitudeInfo = {
        amplitudeApiKey: window.__AMPLITUDE_API_KEY__,
        analyticsApi
      };

      const userData = {
        puaId: user.puaId,
        user: {
          affiliationId: user.affiliationId,
          authMethodPrimary: user.auth_method_primary,
          authMethodSecondary: user.auth_method_secondary,
          ehrVendor: user.ehr_vendor
        },
        interfaceId: getInterfaceId(location)
      };

      const isAnalyticsAllowed = isOsanoAnalyticsAllowed(isOsanoEnabled);

      instantiateAmplitude({
        amplitudeInfo,
        userData: isAnalyticsAllowed ? userData : null,
        optOut: !isAnalyticsAllowed
      });

      store.dispatch(clearSSREvents());
    }

    if (process.env.NODE_ENV === "development") {
      // A trick for hiding expected flash-of-unstyled-content
      // during dev resulting from combo of SSR + style-loader
      window.document.body.removeAttribute("style");
    }

    hydrate(
      <Providers store={store} amplitude={amplitude}>
        <App />
      </Providers>,
      document.getElementById("root")
    );
  });
};

export default renderClient;
