import React, { useEffect } from "react";
import { Icon } from "discover.medical.react-component-library";
import { useOptionGridContext } from "../OptionGridContext";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./_index.css";

import {
  BackButton,
  FlexContainer,
  GlobalStyle,
  ToolBar
} from "./index.styled";
import { useDispatch } from "react-redux";

const OptionGridPdfViewer = () => {
  const dispatch = useDispatch();
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: defaultTabs => [
      defaultTabs[0] // Thumbnails tab
    ]
  });
  const { pdfUrl, closePdfViewer, pdfViewOpen } = useOptionGridContext();
  useEffect(() => {
    if (pdfUrl) {
      dispatch(pdfViewOpen());
    }
  }, [pdfUrl, pdfViewOpen, dispatch]);

  if (!pdfUrl) {
    return null;
  }

  const handleDocumentLoad = e => {
    const { activateTab } = defaultLayoutPluginInstance;

    // Activate the Thumbnails tab
    activateTab(0);
  };

  return (
    <FlexContainer data-auto="pdf-viewer-page" column={true} container={true}>
      <GlobalStyle />
      <ToolBar>
        <BackButton
          onClick={closePdfViewer}
          data-auto="pdf-viewer-page-back-button"
        >
          <Icon icon={{ glyph: "navigate_before" }} />
          Back to Tool
        </BackButton>
      </ToolBar>
      <Worker workerUrl="https://resources.dynamed.com/discover.medical.hippocratesui/assets/js/pdf.worker.js">
        <Viewer
          theme="dark"
          fileUrl={pdfUrl}
          plugins={[defaultLayoutPluginInstance]}
          defaultScale={1.35}
          onDocumentLoad={handleDocumentLoad}
        />
      </Worker>
    </FlexContainer>
  );
};

export default OptionGridPdfViewer;
