import React from "react";
import PropTypes from "prop-types";
import "./_index.css";

/**
 * The children will be rendered by row first, wrapping into the next row (left to right).
 *
 * @example
 *
 *     1 2 3 4
 *     5 6 - -
 *     - - - -
 */
const HORIZONTAL = "h";

/**
 * The children will be rendered by column first, wrapping into the next column (top to bottom).
 *
 * This is the default behavior.
 *
 * @example
 *
 *     1 4 - -
 *     2 5 - -
 *     3 6 - -
 */
const VERTICAL = "v";

/**
 * Renders children in a table-based grid.
 *
 * @example
 *
 *     import { default as IconGrid, HORIZONTAL, VERTICAL } from "./icon-grid";
 *
 * @example
 *
 *     <IconGrid flow={VERTICAL} columns={2} rows={2}>
 *       <Icon icon={{ glyph: 'infographicPerson1' }}/>
 *       <Icon icon={{ glyph: 'infographicPerson1' }}/>
 *       <Icon icon={{ glyph: 'infographicPerson1' }}/>
 *       <Icon icon={{ glyph: 'infographicPerson1' }}/>
 *     </IconGrid>
 */
class IconGrid extends React.Component {
  /**
   * Processes the children to be used in the grid.
   *
   * @param {String}        flow     The direction to render the children.
   * @param {Number}        columns  The number of columns in the grid.
   * @param {Number}        rows     The number of rows in the grid.
   * @param {Array<Object>} children The children to render in the grid.
   *
   * @return {Array<Array<Object>>} The children to render.
   */
  processChildren(flow, columns, rows, children) {
    const grid = [];
    const gridSize = columns * rows;

    for (let i = 0; i < gridSize; i++) {
      let column = 0;
      let row = 0;

      if (flow === HORIZONTAL) {
        column = i % columns;

        if (i > 0) {
          row = Math.floor(i / columns);
        }
      } else {
        if (i > 0) {
          column = Math.floor(i / rows);
        }

        row = i % rows;
      }

      // Initialize the group if its new.
      if (!grid[row]) {
        grid[row] = [];
      }

      // Use the child, or a placeholder if not available.
      if (!children || i >= children.length) {
        grid[row][column] = <React.Fragment />;
      } else {
        grid[row][column] = children[i];
      }
    }

    return grid;
  }

  /**
   * Renders the children inside of a table grid.
   *
   * @return {Object} The table.
   */
  render() {
    const { children, columns, flow, rows } = this.props;
    const grid = this.processChildren(flow, columns, rows, children);

    return (
      <div className="IconGrid">
        {grid.map((row, rowIndex) => (
          <div className="IconGrid_row" key={"r" + rowIndex}>
            {row.map((column, columnIndex) => (
              <div key={"c" + columnIndex}>{column}</div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

// Default to a 10 by 10 grid with vertical flow.
IconGrid.defaultProps = {
  flow: VERTICAL,
  columns: 10,
  rows: 10
};

// Define required component properties.
IconGrid.propTypes = {
  columns: PropTypes.number.isRequired,
  flow: PropTypes.oneOf([HORIZONTAL, VERTICAL]).isRequired,
  rows: PropTypes.number.isRequired
};

// Export the component by default.
export default IconGrid;

// Export the flow constants.
export { HORIZONTAL, VERTICAL };
