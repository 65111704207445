import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Root, Container, Star, EhrEnabledSpan } from "./index.styled";
import { FeatureComponent } from "../../../../helpers/amplitude";

export class InputLabel extends Component {
  getText() {
    const { ehrConnected, ehrEnabledField, text } = this.props;
    return ehrConnected && ehrEnabledField ? (
      <EhrEnabledSpan data-testid="input-label-ehr-enabled">
        {text}
      </EhrEnabledSpan>
    ) : (
      <Fragment>{text}</Fragment>
    );
  }
  render() {
    const {
      name,
      isRequired,
      stylesExtension,
      isEmpty,
      text,
      type
    } = this.props;

    return (
      isEmpty || (
        <Root
          htmlFor={name}
          $stylesExtension={stylesExtension}
          data-auto="input-label"
          type={type}
        >
          {
            <FeatureComponent feature="f65288_prepopulating_no_for_high_usage_ehr_tools">
              {enabled =>
                enabled ? (
                  <Container>
                    {this.getText()}
                    {isRequired && <Star data-auto="input-label-star">*</Star>}
                  </Container>
                ) : (
                  <Container>
                    {text}
                    {isRequired && <Star data-auto="input-label-star">*</Star>}
                  </Container>
                )
              }
            </FeatureComponent>
          }
        </Root>
      )
    );
  }
}

InputLabel.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  stylesExtension: PropTypes.array,
  isEmpty: PropTypes.bool,
  ehrEnabledField: PropTypes.bool,
  ehrConnected: PropTypes.bool
};

InputLabel.defaultProps = {
  isRequired: false,
  stylesExtension: null,
  isEmpty: false
};

export default InputLabel;
