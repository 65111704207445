import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, Icon } from "discover.medical.react-component-library";

import { StyledButton } from "./index.styled";

export default function StickyFooterBtn({ moveStepForward }) {
  const btnSize = useMediaQuery("min", "sm") ? "md" : "sm";

  return (
    <StyledButton
      kind="contained"
      borderRadius="md"
      size={btnSize}
      onClick={moveStepForward}
      data-auto="view-patient-options-button"
    >
      View Patient Options
      <Icon icon={{ glyph: "navigate_next" }} />
    </StyledButton>
  );
}

StickyFooterBtn.propTypes = {
  moveStepForward: PropTypes.func.isRequired
};
