import React, { useState } from "react";
import {
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Type,
  List,
  Flex,
  DialogTitleActions
} from "discover.medical.react-component-library";
import { useDispatch } from "react-redux";
import { userPreference } from "../../../../../../../universal/store/user-preference/actions";
import ReactHtmlParser from "react-html-parser";
import { StyledItem } from "./index.styled";

const PatientInfoPopup = ({ showPopup, controlsMissingEhrpdns }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(showPopup);

  const closeModal = event => {
    setIsOpen(false);
    const data = {
      showInfoPopup: false
    };
    dispatch(userPreference({ data }));
  };

  return (
    <Dialog
      aria-labelledby="text-dialog-title"
      isOpen={isOpen}
      maxWidth="sm"
      onRequestClose={closeModal}
      data-auto="missing-ehr-patient-info-popup"
    >
      <DialogTitle>
        <Type id="text-dialog-title" fontSize="sm" element="p">
          The following patient data was <b>not preloaded</b> from this
          patient’s record:
        </Type>
        <DialogTitleActions onRequestClose={closeModal} />
      </DialogTitle>

      <Card borderYColor="gray300" borderYStyle="solid" borderYWidth="1px">
        <CardContent>
          <List type="ul">
            {Object.keys(controlsMissingEhrpdns).map(displayName => {
              const ehrpdns = controlsMissingEhrpdns[displayName].ehrpdns;
              return (
                <StyledItem key={displayName}>
                  <Flex container wrap>
                    <Flex cell>
                      <Type fontSize="sm" fontWeight="bold">
                        {ReactHtmlParser(displayName)}
                      </Type>
                    </Flex>
                    {ehrpdns && ehrpdns.length >= 2 && (
                      <Flex cell>
                        <Type element="i" fontSize="sm" fontFamily="primary">
                          &nbsp;(Data available are outdated and/or incomplete:{" "}
                          {ehrpdns
                            .map(ehrData => {
                              return ehrData?.ehrpdnDisplayName;
                            })
                            .join(", ")}
                          )
                        </Type>
                      </Flex>
                    )}
                    {ehrpdns && ehrpdns.length === 1 && (
                      <Flex cell>
                        <Type element="i" fontSize="sm" fontFamily="primary">
                          &nbsp;(No data available from within the last{" "}
                          {ehrpdns[0]?.timeWindow?.toLowerCase()})
                        </Type>
                      </Flex>
                    )}
                  </Flex>
                </StyledItem>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default PatientInfoPopup;
