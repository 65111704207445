import React from "react";
import BasicUnit from "../basic-unit";
import componentStateMapper from "../componentsStateMapper";
import Statement from "../basic-statement";
import { NestedContainer } from "./index.styled";

export class PatientDataSummaryStatementNested extends BasicUnit {
  renderCompositeComponent = () => {
    return (
      <NestedContainer>
        <Statement
          content={this.getBaseContent()}
          value={this.getFieldValue()}
          units={this.getUnits()}
        />
      </NestedContainer>
    );
  };
}

export default componentStateMapper(PatientDataSummaryStatementNested);
