import React, { Fragment } from "react";
import get from "lodash/get";
import { Toggle as RCLToggle } from "discover.medical.react-component-library";
import { StyledToggleButton } from "./styled";
import componentStateMapper from "../componentsStateMapper";
import { PREPOPULATION_MESSAGE_NO_VALUE } from "../shared/constants";
import BasicSingleSelect from "../basic-single-select";
import "./_index.css";

export class Toggle extends BasicSingleSelect {
  renderComponent = () => {
    const { name, value, view } = this.props;
    const labels = get(view, "attributes.fieldInfo.labels", []);
    const constraints = get(view, "attributes.fieldInfo.constraints", []);
    return (
      <Fragment>
        {Array.isArray(labels) && (
          <RCLToggle
            className={"Toggle"}
            id={name}
            exclusive={true}
            value={value.toString()}
            name={name}
            required={constraints && constraints.required}
            onChange={this.handleToggleChange}
            data-auto={`${name}-toggle-component`}
          >
            {labels.map(item => (
              <StyledToggleButton
                key={item.value}
                value={item.value}
                hasError={this.hasErrors()}
                hasWarning={this.hasWarnings()}
                data-auto={`${name}-toggle-component-button-${item.name}`}
              >
                {item.name}
              </StyledToggleButton>
            ))}
          </RCLToggle>
        )}
      </Fragment>
    );
  };

  getLabDateInfoMsg = () => {
    const { labDateInfo, measure, ehrPrepopulationMessage } = this.props;
    if (labDateInfo) {
      const { value, effectiveDateTime } = labDateInfo;
      if (value && effectiveDateTime) {
        if (ehrPrepopulationMessage === PREPOPULATION_MESSAGE_NO_VALUE) {
          return this.formatLabDateInfoMsg(effectiveDateTime);
        }
        return this.formatLabDateInfoMsg(
          effectiveDateTime,
          value,
          measure?.label
        );
      }
      return "";
    }
  };
}

export default componentStateMapper(Toggle);
