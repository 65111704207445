import { createContext, useContext } from "react";

const OptionGridContext = createContext();
const OptionGridProvider = OptionGridContext.Provider;
const OptionGridConsumer = OptionGridContext.Consumer;

const useOptionGridContext = () => {
  const context = useContext(OptionGridContext);
  if (!context) {
    throw new Error(
      "OptionGrid compound components can't be rendered outside OptionGrid component"
    );
  }

  return context;
};

export {
  OptionGridContext,
  OptionGridProvider,
  OptionGridConsumer,
  useOptionGridContext
};
