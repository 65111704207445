import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, useMediaQuery } from "discover.medical.react-component-library";
import dmxLogo from "../../../shared/images/dmx-tab-lg-md.svg";
import dmxLogoSm from "../../../shared/images/dmx-tab-sm.svg";
import dmpLogo from "../../../shared/images/dmp-tab-lg-md.svg";
import dmpLogoSm from "../../../shared/images/dmp-tab-sm.svg";
import dcdLogo from "../../../shared/images/dcd-tab-lg-md.svg";
import dcdLogoSm from "../../../shared/images/dcd-tab-sm.svg";

import "./_index.css";

const logos = {
  sm: {
    dmp: dmpLogoSm,
    dmx: dmxLogoSm,
    dcd: dcdLogoSm
  },
  lg: {
    dmp: dmpLogo,
    dmx: dmxLogo,
    dcd: dcdLogo
  }
};

const altTexts = {
  dmp: "DynaMed",
  dmx: "DynaMedex",
  dcd: "DynaMed Decisions"
};

const InterfaceSwitcher = ({ referrer }) => {
  const { interfaceId, path, host } = referrer;
  const [hasMounted, setHasMounted] = useState(false);
  const logoSize = useMediaQuery("min", "md") ? "lg" : "sm";

  // forces the component to re-render after mounting
  // had to add this to fix SSR hydration issues
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted || !logoSize) {
    return null;
  }

  const returnUrl = path ? `${host}${path}` : host;

  return (
    <Tabs
      data-testid="interface-switcher"
      variant="secondary"
      left="senary"
      right="primary"
    >
      <a
        className="InterfaceSwitcher_link"
        data-auto="interface-switcher-link"
        href={returnUrl}
      >
        <Tabs.Tab className="InterfaceSwitcher_tab" sidePadding={false}>
          <img src={logos[logoSize][interfaceId]} alt={altTexts[interfaceId]} />
        </Tabs.Tab>
      </a>
      <Tabs.Tab
        className="InterfaceSwitcher_tab"
        isActive={true}
        sidePadding={false}
      >
        <img src={logos[logoSize]["dcd"]} alt={altTexts["dcd"]} />
      </Tabs.Tab>
    </Tabs>
  );
};

InterfaceSwitcher.propTypes = {
  referrer: PropTypes.shape({
    path: PropTypes.string,
    interfaceId: PropTypes.string.isRequired,
    host: PropTypes.string.isRequired
  }).isRequired
};

export default InterfaceSwitcher;
