import {
  TOOL_INFO_REQUEST,
  TOOL_INFO_REQUEST_ERROR,
  TOOL_INFO_REQUEST_SUCCESS,
  SET_TOOL_INFO
} from "../action-types";

export const initialState = {
  currentInfo: { toolUrl: "", title: "" },
  info: [],
  isInfoLoading: false
};

// prettier-ignore
export default (state = initialState, { type, payload }) => { // NOSONAR (Redux implementation)
  switch (type) {
    case TOOL_INFO_REQUEST:
      return {
        ...state,
        isInfoLoading: true
      };
    case TOOL_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        isInfoLoading: false,
        info:{...state.info, [payload.toolInfoUrl]: payload.info }
      };
    case TOOL_INFO_REQUEST_ERROR:
      return {
        ...state,
        isInfoLoading: false,
      };
    case SET_TOOL_INFO:
      return {
        ...state,
        currentInfo: payload
      };
    default:
      return state;
  }
};
