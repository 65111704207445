import { useCallback, useState } from "react";

export default function useModal(defaultIsModalOpened = false) {
  const [isModalOpened, setIsModalOpened] = useState(defaultIsModalOpened);

  const toggleModal = useCallback(() => {
    setIsModalOpened(!isModalOpened);
  }, [setIsModalOpened, isModalOpened]);

  const openModal = useCallback(() => {
    setIsModalOpened(true);
  }, [setIsModalOpened]);

  const closeModal = useCallback(() => {
    setIsModalOpened(false);
  }, [setIsModalOpened]);

  return [isModalOpened, openModal, closeModal, toggleModal];
}
