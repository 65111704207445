import React from "react";
import PageLayout from "../../templates/page";
import Banner from "../../banner";
import "./style.css";
import { Type } from "discover.medical.react-component-library";

export const ProtectedPage = () => {
  return (
    <PageLayout
      dataAuto="protected-page"
      shouldRedirect={false}
      className="ProtectedPage"
    >
      <Banner dataAuto="error-page-banner" />
      <div className="ProtectedPage_title">
        <Type
          element="p"
          fontSize="xl"
          children="We're sorry! You do not have permission to access this page."
        />
      </div>
    </PageLayout>
  );
};
