import React from "react";
import { useFeaturePayload } from "../../helpers/amplitude";
import PersistentAlert from "../persistent-alert";

const MAINTENANCE_FEATURE_FLAG = "dd-maintenance-alert";

export const MaintenanceAlert = () => {
  const feature = useFeaturePayload(MAINTENANCE_FEATURE_FLAG);

  if (!feature) {
    return <></>;
  }

  return (
    <PersistentAlert
      alert={
        feature.payload?.alert ||
        "This is a site-wide alert for Dynamed Decisions."
      }
      heading={feature.payload?.heading || "Site-Wide Alert"}
      message={
        feature.payload?.message || "A message has not been set for this alert."
      }
    />
  );
};

export default MaintenanceAlert;
