import React, { Component, Fragment } from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import get from "lodash/get";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import MustacheExtender from "../../../helpers/mustache-override";
import Blank from "./blank";
import "./_index.css";
import { BasicContent } from "./index.styled";
import evaluateVisibilityExpression from "../../../helpers/inline-expressions/evaluateVisibilityExpression";
import InputLabel from "../shared/inputlabel";
import InfoButton from "../shared/infobutton";
import ValidationErrors from "./validation";
import ControlField from "./field";
import {
  TYPE_ERROR,
  TYPE_WARNING,
  TYPE_ALL
} from "../../../store/fields/validators";
import { PREPOPULATION_MESSAGE_HIDDEN } from "../shared/constants";
import convertType from "../../../helpers/convertors/types-converter";
import LabDateInfo from "../lab-date-info";

/**
 * Components that are known to be used for patient data input.
 */
const inputTypes = [
  "list.radiogroup",
  "multiselect.filter",
  "multiselect.toggle",
  "numeric.basic",
  "numeric.height",
  "numeric.heightweight",
  "numeric.measure",
  "numeric.packsperday",
  "numeric.quitsmoking",
  "numeric.startsmoking",
  "numeric.weight",
  "singleselect.dropdown",
  "singleselect.filter",
  "singleselect.radiogroup",
  "singleselect.scale",
  "singleselect.toggle"
];

export class Basic extends Component {
  componentWrapperRef = React.createRef();

  componentDidMount() {
    this._checkHideRule();
    this._didMountExtension();
    this.changeMountedState(true);
    this.attachE2ECommandHandler();
  }

  componentWillUnmount() {
    this.changeMountedState(false);
  }
  changeMountedState(isMounted) {
    const { name, setIsFieldMounted } = this.props;
    setIsFieldMounted({ name, isMounted });
  }

  attachE2ECommandHandler = () => {
    if (this.componentWrapperRef.current) {
      this.componentWrapperRef.current.executeE2ECommand = this.executeE2ECommand.bind(
        this
      );
    }
  };

  executeE2ECommand = (command, value) => {
    switch (command) {
      case "getDiscreteValue":
        return this.handleGetDiscreteValueE2ECommand(command);
      case "getContentValue":
        return this.handleGetContentValueE2ECommand(command);
      case "setValue":
        return this.handleSetValueE2ECommand(command, value);
      case "getValue":
        return this.handleGetValueE2ECommand(command);
      case "isDisplayed":
        return !this.isHidden();
      case "setLabel":
        this.handleSetLabelE2ECommand(command, value);
        break;
      case "getLabel":
        return this.handleGetLabelE2ECommand(command);
      case "isWarningDisplayed":
        return this.hasWarnings();
      case "isErrorDisplayed":
        return this.hasErrors();
      case "getWarningValue":
        return (
          this.hasWarnings() && this._getValidationMessageByType(TYPE_WARNING)
        );
      case "getErrorValue":
        return this.hasErrors() && this._getValidationMessageByType(TYPE_ERROR);
      default:
        throw new Error(`command ${command} not supported`);
    }
  };

  handleGetContentValueE2ECommand = command => {
    throw new Error(`command ${command} has not been implemented`);
  };

  handleGetDiscreteValueE2ECommand = command => {
    throw new Error(`command ${command} has not bneen implemented`);
  };

  handleSetValueE2ECommand = command => {
    throw new Error(`command ${command} has not been implemented`);
  };

  handleGetValueE2ECommand = command => {
    throw new Error(`command ${command} has not been implemented`);
  };

  handleSetLabelE2ECommand = command => {
    throw new Error(`command ${command} has not been implemented`);
  };

  handleGetLabelE2ECommand = command => {
    throw new Error(`command ${command} has not been implemented`);
  };

  _didMountExtension = () => {};

  _shouldComponentUpdateExtension = () => {
    return false;
  };

  shouldComponentUpdate(nextProps, nextState) {
    // If the child component is not used for patient data input, skip our custom update filter
    // and continue doing simple props and state comparison to determine if the component should
    // be updated.
    if (inputTypes.indexOf(this.props.type) === -1) {
      return !(
        isEqual(this.props, nextProps) && isEqual(this.state, nextState)
      );
    }

    if (this._shouldComponentUpdateExtension(nextProps, nextState)) {
      return true;
    }

    const prevIsHidden = this.props.isHidden;
    const prevPropsValue = this.props?.value;
    const prevState = JSON.parse(JSON.stringify(this.state));
    const prevValidation = this.props?.validationResult;

    const visibilityExpression = get(
      this.props.view,
      "attributes.visibility",
      null
    );

    let nextIsHidden = false;
    let nextPropsValue = nextProps?.value;
    let nextValidation = nextProps?.validationResult;

    if (visibilityExpression) {
      try {
        nextIsHidden = !evaluateVisibilityExpression(
          visibilityExpression,
          nextProps.fields
        );
      } catch (e) {
        this._visibilityError = e.toString();

        return true;
      }
    }

    const isSameValidationError =
      JSON.stringify(prevValidation) === JSON.stringify(nextValidation);

    const shouldUpdate =
      prevIsHidden !== nextIsHidden ||
      !isSameValidationError ||
      !isEqual(prevState, nextState) ||
      prevPropsValue !== nextPropsValue;

    return shouldUpdate;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this._checkHideRule();
    this._didUpdateExtension(prevProps, prevState, snapshot);
    if (
      prevProps.isHidden === true &&
      this.props.isHidden === false &&
      this.componentWrapperRef.current
    ) {
      this.attachE2ECommandHandler();
    }
  }

  _didUpdateExtension = () => {};

  _validate = async (newValue, type) => {
    const { view, name, validateValue, fields } = this.props;
    return await validateValue(name, newValue, view, fields, type);
  };

  handleToggleChange = (event, value) => this.changeValue(value, event);

  handleChange = event => this.changeValue(event.target.value, event);

  changeValue(value, event = { isTrusted: false }) {
    const { onValueChange, name, recalculate, onRecalculate } = this.props;
    const convertedValue = convertType(value);
    onValueChange(name, convertedValue).then(
      debounce(() => {
        this._validate(convertedValue, TYPE_ALL);
        if (recalculate) {
          onRecalculate();
        }
      }, 200)
    );
    if (!!event?.isTrusted) {
      this.hideLabDateInfoOnUserAction(event.isTrusted); //isTrusted is true for user performed actions / events and false for script generated events
    }
  }

  hideLabDateInfoOnUserAction(isUserAction) {
    const isHidden = get(this.props, "labDateInfo.isHidden", true);
    if (isUserAction && this.props.labDateInfo && !isHidden) {
      this.props.hideLabDateInfo(this.props.name);
    }
  }

  handleChangeMeasureValue = measureInfo =>
    this.changeMeasureValue(measureInfo);

  changeMeasureValue(measureInfo) {
    const {
      onMeasureValueChange,
      name,
      recalculate,
      onRecalculate
    } = this.props;
    onMeasureValueChange(name, measureInfo).then(
      debounce(() => {
        this._validate(measureInfo.value, TYPE_ALL);

        if (recalculate) {
          onRecalculate();
        }
      }, 200)
    );
  }

  _getText = type => {
    const { view, fields } = this.props;
    const templateStr = get(view, ["attributes", "fieldInfo", type], "");
    return MustacheExtender.render(templateStr, fields);
  };

  _getHtmlTransform = () => {
    // If there is a sessionNumber, return a function that modifies internal links to
    // include the `session-number` query parameter
    const { sessionNumber } = this.props;
    if (!sessionNumber) {
      return null;
    }
    return node => {
      if (node.type === "tag" && node.name === "a") {
        if (
          // We want to add the session number to internal URLs only
          node.attribs.href.charAt(0) === "/"
        ) {
          // Use a question mark if no query string exists, otherwise use an ampersand
          const connector = node.attribs.href.indexOf("?") === -1 ? "?" : "&";
          node.attribs.href = `${node.attribs.href}${connector}session-number=${sessionNumber}`;
        }
      }
    };
  };

  getBaseContent = () => {
    const transform = this._getHtmlTransform();
    return ReactHtmlParser(this._getText("baseContent"), { transform });
  };

  getInlineText = () => {
    return ReactHtmlParser(this._getText("inlineText"));
  };

  getBackgroundItem = () => {
    const { fields, view } = this.props;
    const backgroundText = get(
      view,
      ["attributes", "fieldInfo", "backgroundText"],
      []
    );
    //TODO Remove this solution when e2e will be fixed
    if (typeof backgroundText === "string") {
      return backgroundText;
    }
    if (!Array.isArray(backgroundText)) {
      throw new Error(
        `Incorrect background text "${backgroundText}". Background text always should be an array.`
      );
    }
    for (const item of backgroundText) {
      try {
        if (
          !item.visibility ||
          evaluateVisibilityExpression(item.visibility, fields)
        ) {
          return item;
        }
      } catch (e) {
        throw new Error(
          `Incorrect condition "${item.visibility}" inside background text`
        );
      }
    }
    return null;
  };

  getBackgroundText() {
    const { fields } = this.props;
    const backgroundItem = this.getBackgroundItem();
    //TODO Remove this solution when e2e will be fixed
    if (typeof backgroundItem === "string") {
      return MustacheExtender.render(backgroundItem, fields);
    }
    return backgroundItem
      ? MustacheExtender.render(backgroundItem.content, fields)
      : "";
  }

  getBackgroundProps() {
    const { fields } = this.props;
    const backgroundItem = this.getBackgroundItem();
    return backgroundItem && backgroundItem.props
      ? MustacheExtender.render(backgroundItem.props, fields)
      : "";
  }

  // TODO For getFieldValue() and getUnits(): Replace view with relevant field when new fields will be added to store

  getFieldValue() {
    const { view, fields } = this.props;
    const value = get(view, ["attributes", "fieldInfo", "value"], "");
    return value ? MustacheExtender.render(value, fields) : "";
  }

  _checkHideRule = () => {
    const { view, fields, name, isHidden, changeVisibility } = this.props;
    const visibilityExpression = get(view, "attributes.visibility", false);
    try {
      if (visibilityExpression) {
        const expressionResult = !!evaluateVisibilityExpression(
          visibilityExpression,
          fields
        );
        if (isHidden === expressionResult) {
          changeVisibility(name, expressionResult);
        }
      }
    } catch (e) {
      this._visibilityError = e.toString();
    }
  };

  isHidden = () => {
    return this.props.isHidden;
  };

  hasErrors = () => {
    return this._containsValidationResultByType(TYPE_ERROR);
  };

  hasWarnings = () => {
    return this._containsValidationResultByType(TYPE_WARNING);
  };

  _containsValidationResultByType = validationType => {
    const { validationResult } = this.props;
    const errorsResult =
      validationResult &&
      validationResult.filter(result => validationType === result.type);
    return Boolean(errorsResult && errorsResult.length);
  };

  _getValidationMessageByType = validationType => {
    const { validationResult } = this.props;
    const errorsResult =
      validationResult &&
      validationResult.filter(
        result => validationType === result.type && result.message
      );
    return (
      errorsResult &&
      errorsResult.length &&
      errorsResult[0].message.replace(/(<([^>]+)>)/gi, "")
    );
  };

  isBaseContentEmpty = () => {
    return !this._getText("baseContent");
  };

  isLabelDisplayed() {
    const { view } = this.props;
    return !view?.attributes?.isChild;
  }

  renderComponent = () => {
    const { name, type } = this.props;
    return (
      <Blank name={name} type={type} message={"Component doesn't exist"} />
    );
  };

  renderCompositeComponent = () => {
    const { type } = this.props;
    return (
      <ControlField
        type={type}
        renderLabel={this.renderLabel}
        renderComponent={this.renderComponent}
        renderInfo={this.renderInfoButton}
        renderErrors={this.renderValidationError}
        isLabelDisplayed={this.isLabelDisplayed()}
        renderLabDateInfo={this.renderLabDateInfo}
        renderDefaultMessage={this.renderDefaultMessage}
        renderDefaultMessageLink={this.renderDefaultMessageLink}
      />
    );
  };

  renderLabel = stylesExtension => {
    const { ehrEnabledField, fields, name, isRequired, type } = this.props;
    const ehrConnected = !isEmpty(fields.ehr);
    return (
      <InputLabel
        text={this.getBaseContent()}
        name={name}
        isRequired={isRequired}
        stylesExtension={stylesExtension}
        isEmpty={this.isBaseContentEmpty()}
        ehrEnabledField={ehrEnabledField}
        ehrConnected={ehrConnected}
        type={type}
      />
    );
  };

  renderInfoButton = stylesExtension => {
    return (
      <InfoButton
        text={this.getBackgroundText()}
        stylesExtension={stylesExtension}
      />
    );
  };

  renderValidationError = () => {
    const { validationResult, name } = this.props;
    return (
      <ValidationErrors
        className="Validation-error"
        validationResult={validationResult}
        fieldName={name}
      />
    );
  };

  renderLabDateInfo = () => {
    const { ehrPrepopulationMessage, labDateInfo } = this.props;
    if (
      labDateInfo &&
      labDateInfo.isHidden === false &&
      ehrPrepopulationMessage !== PREPOPULATION_MESSAGE_HIDDEN
    ) {
      const msg = this.getLabDateInfoMsg();
      return <LabDateInfo message={msg} />;
    }
  };

  renderDefaultMessageLink = () => {
    const { defaultMessageLink } = this.props;
    if (defaultMessageLink) {
      return ReactHtmlParser(defaultMessageLink);
    }
  };

  renderDefaultMessage = () => {
    const { defaultMessage } = this.props;
    if (defaultMessage) {
      return ReactHtmlParser(defaultMessage);
    }
  };

  //Added code to get info message in a seperate method.
  //This method is overriden in Toggle.
  getLabDateInfoMsg = () => {
    const { labDateInfo } = this.props;
    if (labDateInfo) {
      const { effectiveDateTime } = labDateInfo;
      if (effectiveDateTime) {
        return this.formatLabDateInfoMsg(effectiveDateTime);
      }
    }
  };

  formatLabDateInfoMsg = (date, value, unit) => {
    const msgPrefix = value
      ? (unit ? `${value} ${unit}` : value) + " as"
      : "As";
    const options = { year: "numeric", month: "short", day: "numeric" };
    const dateStr = new Date(date).toLocaleDateString("en-US", options);
    return `${msgPrefix} of ${dateStr}`;
  };

  render = () => {
    const {
      componentWrapperRef,
      props: { type, name }
    } = this;
    return this.isHidden() ? (
      <Fragment />
    ) : this._visibilityError ? (
      <Blank type={type} name={name} message={this._visibilityError} />
    ) : (
      <BasicContent
        data-auto={`${name}-content-component`}
        className="General-content-component-wrapper"
        ref={componentWrapperRef}
      >
        {this.renderCompositeComponent()}
      </BasicContent>
    );
  };
}

Basic.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  cpdn: PropTypes.string,
  isRequired: PropTypes.bool,
  fields: PropTypes.shape({ ui: PropTypes.object }),
  validationResult: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      message: PropTypes.string
    })
  ),
  onValueChange: PropTypes.func,
  validateValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autocomplete: PropTypes.string,
  recalculate: PropTypes.bool,
  onRecalculate: PropTypes.func,
  onMeasureValueChange: PropTypes.func,
  setIsFieldMounted: PropTypes.func,
  hideLabDateInfo: PropTypes.func
};

Basic.defaultProps = {
  validationResult: [],
  onValueChange: () => Promise.resolve(),
  onMeasureValueChange: () => Promise.resolve(),
  validateValue: () => Promise.resolve(),
  setIsFieldMounted: () => {},
  fields: {},
  cpdn: null,
  isRequired: false,
  value: "",
  type: "basic",
  autocomplete: "off",
  recalculate: false,
  onRecalculate: () => {}
};

export default Basic;
