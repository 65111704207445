import React from "react";
import { Type } from "discover.medical.react-component-library";
import PageLayout from "../../templates/page";
import "./_index.css";

const EngineError = () => (
  <PageLayout>
    <div className="EngineError">
      <Type
        element="h2"
        color="primary"
        children="We're sorry! This page is temporarily unavailable."
      />
      <Type
        element="p"
        className="EngineError_msg"
        children="We are working to solve this problem. Please try again shortly."
      />
    </div>
  </PageLayout>
);

export default EngineError;
