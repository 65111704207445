import {
  logHomePageViewed,
  logPatientDataEntryViewed,
  logPdfGenerated,
  logPatientOptionsVisibilityClicked,
  logPatientOptionsViewed,
  logKeyDataViewed,
  logAssessmentAndGuidanceViewed,
  logChartNoteClicked,
  logChartNoteCopyClicked,
  logGenerateLinkClicked,
  logGenerateQRCodeClicked,
  logGenerateLinkCopyClicked,
  logGenerateQrCodeCopyClicked,
  logNavigationItemClicked,
  logPatientOptionsViewType,
  logToolLaunched,
  events
} from "./loggers";
import { addSSREvent } from "../../store/telemetry/actions/index";
import {
  OPTION_GRID_PDF_VIEW_OPEN,
  OPTION_GRID_SELECT_VIEW
} from "../../../universal/store/action-types";

export let isBrowser = typeof window !== "undefined";
export const setIsNotBrowser = () => (isBrowser = false);

// MAP ACTIONS TO SPECIFIC LOGGERS
export const loggersMap = {
  [events.HOME_VIEWED]: [logHomePageViewed],
  [events.PATIENT_DATA_ENTRY_VIEWED]: [logPatientDataEntryViewed],
  [events.PATIENT_OPTIONS_VISIBILITY_CLICKED]: [
    logPatientOptionsVisibilityClicked
  ],
  [events.PATIENT_OPTIONS_VIEWED]: [logPatientOptionsViewed],
  [events.KEY_DATA_VIEWED]: [logKeyDataViewed],
  [events.ASSESSMENT_GUIDANCE_VIEWED]: [logAssessmentAndGuidanceViewed],
  [events.CHART_NOTE_CLICKED]: [logChartNoteClicked],
  [events.CHART_NOTE_COPY_CLICKED]: [logChartNoteCopyClicked],
  [events.GENERATE_LINK_CLICKED]: [logGenerateLinkClicked],
  [events.GENERATE_QR_CODE_CLICKED]: [logGenerateQRCodeClicked],
  [events.GENERATE_LINK_COPY_CLICKED]: [logGenerateLinkCopyClicked],
  [events.GENERATE_QR_CODE_COPY_CLICKED]: [logGenerateQrCodeCopyClicked],
  [OPTION_GRID_PDF_VIEW_OPEN]: [logPdfGenerated],
  [events.NAVIGATION_ITEM_CLICKED]: [logNavigationItemClicked],
  [OPTION_GRID_SELECT_VIEW]: [logPatientOptionsViewType],
  [events.TOOL_LAUNCHED]: [logToolLaunched]
};

// INTERCEPT ACTIONS IF THEY ARE MAPPED TO A LOGGER
export default store => next => action => {
  const result = next(action);
  const { type, payload } = action;
  const loggers = loggersMap[type];
  if (loggers && loggers.length) {
    loggers.forEach(logger => {
      if (typeof logger !== "function") {
        console.error(
          "Telemtry Error: logger for " + type + " is not a function."
        );
        return;
      }
      const logObject = logger(store.getState(), payload, type);
      if (!isBrowser && logObject) {
        store.dispatch(addSSREvent(logObject));
      }
    });
  }
  return result;
};
