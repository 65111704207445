import { useDispatch, useSelector } from "react-redux";
import {
  calculateErrorsNumber,
  setFieldsOutcomeData,
  validateAll
} from "../../../../../../../store/fields/actions";

export default function useForm() {
  const dispatch = useDispatch();
  const errorsNumber = useSelector(
    ({ fields }) => fields.validationErrorsNumber
  );

  const submitForm = async () => {
    dispatch(validateAll());
    const isErrors = dispatch(calculateErrorsNumber());

    if (!isErrors) {
      await dispatch(setFieldsOutcomeData());
    }

    return !isErrors;
  };

  return {
    errorsNumber,
    submitForm
  };
}
