import React from "react";
import { Type } from "discover.medical.react-component-library";
import "./_index.css";
import componentStateMapper from "../componentsStateMapper";
import BasicText from "../basic-text";

export class Subheading extends BasicText {
  renderCompositeComponent = () => {
    return (
      <Type
        className="Subheading"
        element="h2"
        styledAs="h4"
        color="gray800"
        fontFamily="primary"
        marginTop="0"
        marginBottom="xs"
      >
        {this.getBaseContent()}
      </Type>
    );
  };
}

export default componentStateMapper(Subheading);
