import styled from "styled-components";

export const BasicContent = styled.div`
  width: auto;

  h3 {
    margin-bottom: 0.5rem;
  }

  a {
    word-break: break-all;
    color: ${({ theme }) => theme.colors.secondary.base};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
