import styled from "styled-components";
import {
  Layer,
  Label,
  InputCheckbox,
  Flex,
  Button
} from "discover.medical.react-component-library";

export const StyledButton = styled(Button)`
  margin-left: 28px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 0px;
  }
`;
export const StyledInfoLayer = styled(Layer)`
  background-color: #f4f4f4;
  padding: 0.5rem;
`;

export const StyledFlexBanner = styled(Flex)`
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

export const StyledInfoLabel = styled(Label)`
  margin-top: 4px;
  margin-left: 4.5px;
  font-weight: bold;
  font-size: 0.875rem;
`;

export const StyledInputCheckbox = styled(InputCheckbox)`
  margin-left: 28px;
  margin-right: 17.5px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 0px;
  }
`;
