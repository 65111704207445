import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ToolGroup from "./components/toolGroup";
import PageTemplate from "../../../templates/page";
import ArticleList from "../../../article-list";
import Banner from "../../../banner";
//temporary solution
import articles from "../../../../static/articles.json";
import mHDArticles from "../../../../static/mHDArticles.json";
import { reset } from "../../../../store/tool-browse/actions";
import { homePageLoad } from "../../../../store/telemetry/actions/index";
import { StyledHeading, StyledDescription } from "./index.styled";
import "./_index.css";
import DomainSpecific from "../../../domain-specific";

export class MyHealthDecisionsHome extends Component {
  componentWillUnmount() {
    const { onResetTools } = this.props;
    onResetTools();
  }
  componentDidMount() {
    const { onHomePageLoad } = this.props;
    onHomePageLoad();
  }

  render() {
    return (
      <PageTemplate
        dataAuto="home-page-content"
        {...this.props}
        hero={<Banner role="banner" dataAuto="home-page-banner" />}
      >
        <section className="HomePage_content">
          <section className="HomePage_tools" data-auto="home-page-tools-list">
            <StyledHeading
              className="HomePage_toolsHeader"
              element="h2"
              color="primary"
              fontFamily="secondary"
            >
              Our Decision Aids
            </StyledHeading>
            <StyledDescription styledAs="em">
              Evidence-based, shared decision-making aids created by physicians
              to facilitate patient decision-making.
            </StyledDescription>
            <ToolGroup />
          </section>
          <DomainSpecific MHD>
            <ArticleList
              dataAuto="home-page-article-list"
              articles={mHDArticles}
              listClassName="HomePage_articlesList"
              articleClassName="HomePage_article"
            />
          </DomainSpecific>
          <DomainSpecific DSD>
            <ArticleList
              dataAuto="home-page-article-list"
              articles={articles}
              listClassName="HomePage_articlesList"
              articleClassName="HomePage_article"
            />
          </DomainSpecific>
        </section>
      </PageTemplate>
    );
  }
}

MyHealthDecisionsHome.propTypes = {
  onResetTools: PropTypes.func.isRequired,
  onHomePageLoad: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onResetTools: reset,
  onHomePageLoad: homePageLoad
};

export default connect(null, mapDispatchToProps)(MyHealthDecisionsHome);
