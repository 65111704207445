import React from "react";
import PropTypes from "prop-types";
import { Alert } from "discover.medical.react-component-library";
import {
  Root,
  ErrorsBanner,
  Column,
  StyledDynamicForm,
  Container
} from "./index.styled";
import ToolStickyFooter from "../../tool-sticky-footer";
import PatientStepStickyFooterContent from "../patient-step-sticky-footer-content";

const PatientStepForm = ({
  leftControls,
  rightControls,
  errorsNumber,
  handleSubmit,
  componentRef
}) => {
  return (
    <Root data-auto="patient-step-form">
      {!!errorsNumber && (
        <ErrorsBanner data-auto="patient-step-errors-banner">
          <Alert type="error">
            {errorsNumber === 1
              ? "There is 1 error. Please correct it to run the calculation."
              : `There are ${errorsNumber} errors. Please correct them to run the calculation.`}
          </Alert>
        </ErrorsBanner>
      )}

      <Container container justify={{ content: "space-between" }} noGap>
        <Column cell>
          <StyledDynamicForm componentsList={leftControls} />
        </Column>

        <Column cell>
          <StyledDynamicForm componentsList={rightControls} />
        </Column>
      </Container>

      <ToolStickyFooter
        isCentered={true}
        children={
          <PatientStepStickyFooterContent handleSubmit={handleSubmit} />
        }
        stepComponentRef={componentRef}
      />
    </Root>
  );
};

PatientStepForm.propTypes = {
  leftControls: PropTypes.array.isRequired,
  rightControls: PropTypes.array.isRequired,
  errorsNumber: PropTypes.number
};

PatientStepForm.defaultProps = {
  errorsNumber: 0
};

export default PatientStepForm;
