import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestPatientEncounterReport,
  requestPatientEncounterReportStatus,
  requestPatientEncounterReportDownload,
  resetPatientEncounterReport,
  skipPatientEncounterReportDownload,
  getPatientEncounterReportStatusTimeout
} from "../../store/patient-encounter-report/actions";
import { Label, Type } from "discover.medical.react-component-library";
import { statusChecksHaveTimedOut } from "./helpers/index";

import {
  StyledButton,
  StyledSectionTitle,
  StyledField,
  StyledInputCheckbox,
  StyledNumberFormat,
  StyledType,
  StyledValidationMessaging
} from "./index.styled";
import {
  REPORT_NO_DATA,
  REPORT_READY,
  REPORT_FAILED,
  REPORT_ERROR,
  REPORT_TIMEOUT,
  REPORT_DONE,
  REPORT_REQUESTED,
  REPORT_GENERATING,
  REPORT_PENDING
} from "../../store/patient-encounter-report/constants";
import { uniqueId } from "lodash";
import PatientEncounterDashboardModal from "./modal";

import {
  TEN_SECONDS_IN_MILLISECONDS,
  DATE_FORMAT,
  DATE_FORMAT_TEMPLATE,
  DATE_INPUT_MASK
} from "./constants";

const PatientEncounterDashboard = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [validDateRange, setValidDateRange] = useState(false);
  const [statusCheckStart, setStatusCheckStart] = useState(null);
  const { status, id, statusChecks } = useSelector(
    state => state.patientEncounterReport || {}
  );
  const dateFormatRegex = /^(\d\d)\/(\d\d)\/(\d\d\d\d)$/;
  const { organization } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const interval = useRef(null);

  useEffect(() => {
    dispatch(resetPatientEncounterReport());
    return function cleanup() {
      clearInterval(interval.current);
      dispatch(resetPatientEncounterReport());
    };
  }, []);

  useEffect(() => {
    if (statusChecksHaveTimedOut(interval, statusCheckStart)) {
      clearInterval(interval.current);
      dispatch(getPatientEncounterReportStatusTimeout());
    }
  }, [statusChecks, dispatch]);

  useEffect(() => {
    if (
      statusChecks === 1 &&
      [REPORT_GENERATING, REPORT_PENDING].includes(status)
    ) {
      interval.current = setInterval(() => {
        dispatch(requestPatientEncounterReportStatus());
      }, TEN_SECONDS_IN_MILLISECONDS);
    }
  }, [statusChecks, status, dispatch]);

  useEffect(() => {
    switch (status) {
      case REPORT_REQUESTED:
        clearInterval(interval.current);
        setTimeout(() => {
          setStatusCheckStart(new Date(Date.now()));
          dispatch(requestPatientEncounterReportStatus());
        }, 1000);
        break;
      case REPORT_READY:
        clearInterval(interval.current);
        const isoStartDate = getIsoDateInUtc(startDate, true);
        const isoEndDate = getIsoDateInUtc(endDate, true);
        dispatch(
          requestPatientEncounterReportDownload(id, isoStartDate, isoEndDate)
        );
        break;
      case REPORT_DONE:
        clearInterval(interval.current);
        break;
      case REPORT_NO_DATA:
      case REPORT_FAILED:
      case REPORT_ERROR:
      case REPORT_TIMEOUT:
        clearInterval(interval.current);
        dispatch(skipPatientEncounterReportDownload());
        break;
      default:
        return;
    }
  }, [status]);

  const formatDateMonthDayYear = date => {
    const day = ("0" + date.getUTCDate()).slice(-2);
    const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  };

  const getYesterday = () => {
    const today = new Date(Date.now());
    let yesterday = new Date(today);
    yesterday.setUTCDate(today.getUTCDate() - 1);
    const monthDayYear = formatDateMonthDayYear(yesterday);
    return {
      monthDayYear,
      isoDate: getIsoDateInUtc(monthDayYear)
    };
  };

  const getDateElements = dateString => {
    const dateElements = dateString.match(dateFormatRegex);
    const [, month, day, year] = dateElements || [];
    return dateElements ? { month, day, year } : null;
  };

  const getIsoDateInUtc = (dateString, includeTime) => {
    const dateElements = getDateElements(dateString);
    if (dateElements) {
      const { month, day, year } = dateElements;
      const isoDate = `${year}-${month}-${day}`;
      return includeTime ? `${isoDate}T00:00:00.000Z` : isoDate;
    }
  };

  const validateDate = (dateString, setError) => {
    setError("");
    if (dateString === "") {
      return;
    }
    const invalidDateMessage = "Please enter a valid date (MM/DD/YYYY)";

    const isoDate = getIsoDateInUtc(dateString, true);
    if (!isoDate) {
      setError(invalidDateMessage);
      return;
    }

    const date = new Date(isoDate);
    if (isNaN(date)) {
      setError(invalidDateMessage);
      return;
    }

    const dateElements = getDateElements(dateString);
    if (!dateElements) {
      setError(invalidDateMessage);
      return;
    }

    const monthIndex = dateElements.month - 1;
    if (monthIndex !== date.getUTCMonth()) {
      setError(invalidDateMessage);
      return;
    }

    const yesterday = getYesterday();
    if (isoDate >= yesterday.isoDate) {
      setError(`Please enter a date prior to ${yesterday.monthDayYear}.`);
      return;
    }

    return date;
  };

  const validateDateRange = () => {
    setValidDateRange(false);
    const validStartDate = validateDate(startDate, setStartDateError);
    const validEndDate = validateDate(endDate, setEndDateError);
    if (validStartDate && validEndDate) {
      if (validEndDate < validStartDate) {
        setEndDateError(
          "Please enter an end date that is later than or the same as the start date."
        );
      } else {
        setEndDateError("");
        setValidDateRange(true);
      }
    }
  };

  const handleDateChange = (value, setDate, setError) => {
    setValidDateRange(false);
    setError("");
    setDate(value);
  };

  const onStartChange = event => {
    handleDateChange(event.target.value, setStartDate, setStartDateError);
  };

  const onEndChange = event => {
    handleDateChange(event.target.value, setEndDate, setEndDateError);
  };

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      validateDateRange();
    }
  };

  const handleConfirmationChange = event => {
    setConfirmed(event.target.checked);
  };

  const handleSubmit = () => {
    const isoStartDate = getIsoDateInUtc(startDate, false);
    const isoEndDate = getIsoDateInUtc(endDate, false);
    dispatch(requestPatientEncounterReport(isoStartDate, isoEndDate));
  };

  const fieldId1 = uniqueId("date-range-");
  const fieldId2 = uniqueId("date-range-");

  return (
    <>
      <StyledSectionTitle element="h1" color="senary" fontFamily="secondary">
        Patient Encounter Reports
      </StyledSectionTitle>
      <StyledType fontSize="md">
        Generate and download a Patient Encounter CSV report
        {organization && (
          <StyledType display="inline">
            {" "}
            for{" "}
            <Type fontWeight="bold" display="inline">
              {organization}
            </Type>
          </StyledType>
        )}
        :
      </StyledType>
      <StyledField
        id={fieldId1}
        data-testid="start-date-input"
        hasError={!!startDateError}
        label={""}
        message={
          startDateError && (
            <StyledValidationMessaging hasError>
              {startDateError}
            </StyledValidationMessaging>
          )
        }
      >
        <Label htmlFor="start-date">Start Date</Label>
        <StyledNumberFormat
          format={DATE_FORMAT_TEMPLATE}
          mask={DATE_INPUT_MASK}
          name="start-date"
          onChange={onStartChange}
          onBlur={validateDateRange}
          onKeyPress={handleKeyPress}
          hasError={!!startDateError}
          value={startDate}
          id="start-date"
          placeholder={DATE_FORMAT}
        />
      </StyledField>
      <StyledField
        id={fieldId2}
        data-testid="end-date-input"
        label={""}
        hasError={!!endDateError}
        message={
          endDateError && (
            <StyledValidationMessaging hasError>
              {endDateError}
            </StyledValidationMessaging>
          )
        }
      >
        <Label htmlFor="end-date">End Date</Label>
        <StyledNumberFormat
          format={DATE_FORMAT_TEMPLATE}
          mask={DATE_INPUT_MASK}
          name="end-date"
          onChange={onEndChange}
          onBlur={validateDateRange}
          onKeyPress={handleKeyPress}
          hasError={!!endDateError}
          value={endDate}
          id="end-date"
          placeholder={DATE_FORMAT}
        />
      </StyledField>
      <StyledType element="p" fontSize="sm">
        Please note that UTC (Universal Coordinated Time) will be used in all
        Patient Encounter Reports.
      </StyledType>
      <StyledInputCheckbox
        data-testid="confirmation-checkbox"
        label="I certify that I am authorized to download this report and that this data will be used in accordance with HIPAA rules."
        name="confirmation"
        id="confirmation"
        checked={confirmed}
        onChange={handleConfirmationChange}
      />
      <StyledButton
        data-testid="report-request-button"
        variant="primary"
        disabled={!confirmed || !validDateRange}
        onClick={handleSubmit}
      >
        Get CSV Report
      </StyledButton>
      <PatientEncounterDashboardModal />
    </>
  );
};

export default PatientEncounterDashboard;
