import DefaultTemplate from "./default";
import DefaultNewTemplate from "./default-new";

const templatesMap = {
  basic: DefaultNewTemplate,
  defaultOld: DefaultTemplate
};

export const getTemplate = name => {
  return templatesMap[name] || templatesMap["basic"];
};

export default getTemplate;
