import React, { Component } from "react";
import PropTypes from "prop-types";
import { Tabs } from "discover.medical.react-component-library";
import { getComponent, getComponentKeys } from "../components/tabs";
import Preloader from "../components/preloader";
import "./_index.css";

//This class is not used by any tool on live DD. It is depricated

export class DefaultTemplate extends Component {
  myRef = React.createRef();

  changeTab = index => {
    this.myRef.current.showContent(index);
  };

  render() {
    const { isLoading, toolTabs } = this.props;
    const componentKeys = getComponentKeys();
    const isHiddenTab =
      toolTabs &&
      toolTabs.length >= 1 &&
      toolTabs.filter(tabName => componentKeys.includes(tabName)).length === 1;

    // For content with just one tab, don't bother displaying the tab
    let SingleContentComponent = null;
    if (isHiddenTab) {
      SingleContentComponent = getComponent(toolTabs[0]).component;
    }

    return (
      <div
        classNames="DefaultTemplate_main"
        data-auto="tool-template-implementation"
      >
        <Preloader isLoading={isLoading} />

        {isHiddenTab && (
          <div data-auto="tool-template-implementation-hide-tabs">
            <SingleContentComponent
              key={getComponent(toolTabs[0]).name}
              isHiddenBtn={true}
            />
          </div>
        )}

        {!isHiddenTab && (
          <Tabs.WithContent
            ref={this.myRef}
            classNames="DefaultTemplate_tabContent"
            data-auto="tool-template-implementation-tabs"
            tabItems={toolTabs.map((comp, index) => {
              const { component: ContentComponent, name } = getComponent(comp); // NOSONAR (Seems sonar does not understand jsx syntax)
              const tabLabel = isHiddenTab ? "" : getComponent(comp).name;
              const isHiddenBtn = isHiddenTab || toolTabs.length === index + 1;
              return {
                tabLabel,
                tabContent: (
                  <ContentComponent
                    key={name}
                    isHiddenBtn={isHiddenBtn}
                    index={index + 2} // count of tabs start from 1
                    changeTab={this.changeTab}
                  />
                )
              };
            })}
          />
        )}
      </div>
    );
  }
}

DefaultTemplate.propTypes = {
  isLoading: PropTypes.bool,
  toolTabs: PropTypes.array
};

DefaultTemplate.defaultProps = {
  isLoading: false,
  toolTabs: []
};

export default DefaultTemplate;
