import React, { useState, useEffect } from "react";
import {
  Anchor,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Type
} from "discover.medical.react-component-library";
import getLoginUrl from "../../shared/util/getLoginUrl";
import getRegisterUrl from "../../shared/util/getRegisterUrl";
import { useSelector, useDispatch } from "react-redux";
import { setPuaReminderShowing } from "../../store/shared-data/actions";

function shouldNotShowPuaDialog() {
  return !!document.cookie
    .split("; ")
    .find(item => item.indexOf("puaWarningModalShown") === 0);
}

export const PuaWarning = () => {
  const user = useSelector(state => state.user);
  const [showPuaWarning, setShowPuaWarning] = useState(false);
  const dialogLabel = "pua-register-warn";
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.hasFetched && user?.showLoginPrompt) {
      if (!shouldNotShowPuaDialog()) {
        document.cookie = `puaWarningModalShown=true; SameSite=None; Secure`;
        dispatch(setPuaReminderShowing(true));
        setShowPuaWarning(true);
      }
    }
  }, [showPuaWarning, user]);

  const handleClosePuaWarning = () => {
    setShowPuaWarning(false);
    dispatch(setPuaReminderShowing(false));
  };

  const puaDialog = {
    bestExperience: "For the best experience,",
    pleaseSignIn: " please sign in or create a personal user account.",
    justOnce: "You'll only need to do this once.",
    createAccount: "Create Account",
    signin: "Sign In",
    noThanks: "No, thanks. Skip this step and remind me later."
  };

  return (
    <>
      <Dialog
        background="black"
        data-testid={dialogLabel}
        id={dialogLabel}
        maxWidth="sm"
        isOpen={showPuaWarning}
        onRequestClose={handleClosePuaWarning}
      >
        <Card>
          <CardContent>
            <Type element="p">
              {puaDialog.bestExperience}
              <Type element="span" fontWeight="semibold">
                {puaDialog.pleaseSignIn}
              </Type>
              <Type element="p" marginTop="md">
                <Type element="i">{puaDialog.justOnce}</Type>
              </Type>
            </Type>
          </CardContent>
        </Card>
        <DialogActions justify="left">
          <Button
            kind="outlined"
            variant="primary"
            href={getLoginUrl()}
            data-testid="pua-signin"
          >
            {puaDialog.signin}
          </Button>
          <Button
            kind="contained"
            variant="primary"
            style={{ marginLeft: "1rem" }}
            href={getRegisterUrl()}
            data-testid="pua-register"
          >
            {puaDialog.createAccount}
          </Button>
        </DialogActions>
        <Card>
          <CardContent>
            <Anchor
              variant="primary"
              kind="anchor"
              onClick={handleClosePuaWarning}
              data-testid="pua-skip"
            >
              {puaDialog.noThanks}
            </Anchor>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
};
