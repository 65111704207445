import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { SectionTitle, Content, LegendTitle } from "./index.styled";
import { Root } from "../styled-components/index.styled";
import InfoModalContentItem from "../info-modal-content-item";
import InfoModalLegendItem from "../info-modal-legend-item";
import { useDispatch, useSelector } from "react-redux";
import { getToolInfo } from "../../../store/tool-browse/actions";
import { Spinner } from "discover.medical.react-component-library";

const InfoModal = ({
  isMobile,
  isDesktop,
  isOpen,
  isSDM,
  onClose,
  toolsList
}) => {
  const dispatch = useDispatch();
  const toolInfoData = useSelector(state => state.toolBrowse.toolInfoData);
  const info = useSelector(state => state.toolBrowse.info);
  const tools = useSelector(state => state.toolBrowse.tools);
  const isInfoLoading = useSelector(state => state.toolBrowse.isInfoLoading);

  useEffect(() => {
    if (isOpen && toolInfoData && !info[toolInfoData.an]) {
      dispatch(getToolInfo(toolInfoData.an, toolInfoData.version));
    }
  }, [dispatch, info, isOpen, toolInfoData]);

  let tool;
  let toolInfo;

  if (toolInfoData && isOpen) {
    const providedTools = toolsList || tools[toolInfoData.category];
    tool = providedTools.find(
      currentTool => currentTool.an === toolInfoData.an
    );
    toolInfo = info[toolInfoData.an];
  }

  return (
    <Root
      v2
      borderRadius="sm"
      padding={false}
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overflow: "auto",
        maxWidth: !isMobile && !isDesktop ? "40rem" : "47rem",
        maxHeight: "100%"
      }}
      fullScreen={isMobile}
    >
      {isInfoLoading ? (
        <Spinner />
      ) : (
        <Content data-auto="info-modal">
          <SectionTitle
            data-auto="info-modal-title"
            element="h2"
            fontFamily="secondary"
          >
            {tool?.title}
          </SectionTitle>

          {toolInfo?.eligibility && (
            <InfoModalContentItem
              title="Eligibility"
              description={toolInfo?.eligibility}
              isSDM={isSDM}
            />
          )}

          {isSDM &&
            (tool?.isPatientData ||
              tool?.isSharedDecision ||
              tool?.isPatientFAQ) && (
              <div>
                <LegendTitle data-auto="info-modal-legend-title" element="h6">
                  This Tool Includes:
                </LegendTitle>

                {tool?.isPatientData && (
                  <InfoModalLegendItem
                    glyph="how_to_reg"
                    size="md"
                    text="Content customized for each patient"
                  />
                )}

                {tool?.isSharedDecision && (
                  <InfoModalLegendItem
                    glyph="insert_chart"
                    size="md"
                    text="Key Data Calculations"
                  />
                )}

                {tool?.isPatientFAQ && (
                  <InfoModalLegendItem
                    glyph="grid_on"
                    size="md"
                    text={
                      <>
                        <em>Option Grid</em> patient FAQs
                      </>
                    }
                  />
                )}
              </div>
            )}
        </Content>
      )}
    </Root>
  );
};

InfoModal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isSDM: PropTypes.bool.isRequired
};

export default InfoModal;
