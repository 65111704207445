import { TYPE_ERROR } from "../../store/fields/validators";
import isNil from "lodash/isNil";

export const getValidationErrors = state =>
  Object.keys(state.fields.ui)
    .filter(key => !state.fields.ui[key].isHidden)
    .map(key => state.fields.ui[key].validation)
    .filter(validation => !isNil(validation))
    .filter(validation => Array.isArray(validation))
    .map(validation => validation.filter(isErrorType))
    .reduce((prev, curr) => prev.concat(curr || []), []);

const isErrorType = error => error.type === TYPE_ERROR;
