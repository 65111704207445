import React from "react";
import componentStateMapper from "../componentsStateMapper";
import BasicText from "../basic-text";
import { SectionTitleRoot } from "./index.styled";

export class SectionTitle extends BasicText {
  renderCompositeComponent = () => {
    return (
      <SectionTitleRoot
        className="Heading"
        element="h2"
        fontFamily="secondary"
        fontWeight="normal"
      >
        {this.getBaseContent()}
      </SectionTitleRoot>
    );
  };
}

export default componentStateMapper(SectionTitle);
