import styled from "styled-components";
import { Flex } from "discover.medical.react-component-library";
import DynamicForm from "../../../../../content-components";

export const Header = styled(Flex)`
  flex-direction: column-reverse;

  @media screen and (min-width: 48rem) {
    flex-direction: row;
  }
`;

export const Logo = styled.img`
  margin-bottom: ${({ theme }) => theme.space.sm};
`;

export const Content = styled(DynamicForm)`
  margin-bottom: ${({ theme }) => theme.space.sm};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 80%;
  }
`;

export const Toolbar = styled(Flex)`
  flex: none;
  flex-direction: column-reverse;

  @media screen and (min-width: 48rem) {
    flex-direction: row;
  }
`;
