import styled from "styled-components";
import { ValidationMessaging } from "discover.medical.react-component-library";

export const Message = styled(ValidationMessaging)`
  margin-top: ${({ theme }) => theme.space.sm};

  p {
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
  }
`;
