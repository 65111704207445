import React, { Fragment } from "react";
import BackgroundQuestion from "../background-question";
import BackgroundText from "../background-text";
import PropTypes from "prop-types";

export const SingleColumnBackgroundInfo = ({ questions }) => {
  return (
    <Fragment dataAuto="option-grid-background-option-name">
      {questions.map(question => (
        <Fragment>
          {question.answers[0].backgroundText && (
            <Fragment>
              <BackgroundQuestion question={question.question} />
              <BackgroundText
                bgText={question.answers[0].backgroundText[0].content}
              />
            </Fragment>
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

SingleColumnBackgroundInfo.propTypes = {
  questions: PropTypes.array.isRequired
};

export default SingleColumnBackgroundInfo;
