/**
 * Creates a client object with methods for retrieving data required by the UI.
 * This client abstracts away the exact manner in which the data is retrieved
 * (particularly useful if we ever changed HTTP clients or made UI/Edge a single process).
 * Each method must return a Promise to allow chaining / error-handling by callers.
 * @param {import("axios").AxiosInstance} axios
 */

const getApiClient = axios => ({
  fetchAsyncDemoData: () => axios.get("/mood"),
  /**
   * EXAMPLE CODE
   * Retrieve detail record for given an/db combination.
   *
   * @param {string} an
   * @param {string} db
   * @returns {Promise<any>}
   */
  fetchDetails: (an, db) => axios.get(`/details/${an}/db/${db}`)
});

export default getApiClient;
