import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import MustacheExtender from "../../../../../../../helpers/mustache-override";
import contentParser from "../../../../../../../helpers/content-parser";

import "./_index.css";

const OptionGridAnswer = ({ className, answer, fields, optionKey, type }) => {
  return (
    <div className={cn("OptionGridAnswer", className)}>
      {contentParser(
        MustacheExtender.render(answer, {
          ...fields,
          outcome: fields.optionGridOutcome[optionKey]
        }),
        type === "list"
      )}
    </div>
  );
};

OptionGridAnswer.propTypes = {
  className: PropTypes.string,
  answer: PropTypes.string,
  fields: PropTypes.object,
  optionKey: PropTypes.string,
  type: PropTypes.oneOfType(["list", "table"])
};

OptionGridAnswer.defaultProps = {
  className: "",
  answer: null,
  fields: {},
  optionKey: "",
  type: "table"
};

export default OptionGridAnswer;
