import styled from "styled-components";
import { Type } from "discover.medical.react-component-library";

export const EditorialPersonDetails = styled(Type)`
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

export const BackgroundInformation = styled(Type)`
  margin-top: 0.25rem;
  font-style: italic;
`;

export const EditorialInformation = styled.div`
  margin-bottom: 1.5rem;
`;
