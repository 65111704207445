import React, { Fragment } from "react";
import OptionGridAnswer from "../../option-grid-answer";
import contentParser from "../../../../../../../../helpers/content-parser";
import "../_index.css";

export const ListView = ({ options, questions, fields, getOptionName }) => {
  return (
    <Fragment>
      {questions.map((question, questionIndex) => {
        return (
          <div
            key={question.question}
            data-auto="option-grid-list-question-block"
            className="OptionGridList_question"
          >
            <div className="OptionGridList_questionHeading">
              {contentParser(question.question)}
            </div>

            {question.answers.map((answer, answerIndex) => (
              <div
                key={answer.optionKey}
                className="OptionGridList_option"
                data-auto={`OP${answerIndex + 1}FAQ${questionIndex +
                  1}-content-component`}
              >
                <div className="OptionGridList_optionName">
                  {getOptionName(options, answer.optionKey)}
                </div>
                <OptionGridAnswer
                  answer={answer.answer}
                  fields={fields}
                  optionKey={answer.optionKey}
                  type="list"
                />
              </div>
            ))}
          </div>
        );
      })}
    </Fragment>
  );
};

export default ListView;
